import React from "react";
import { Row, Col } from "antd";
import Button from "../../../Common/Button";
import RetrieveButton from "./RetrieveButton";
import { HistoryOutlined, DeleteOutlined } from "@ant-design/icons";
import { translate } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";

const ActionButtons = (props) => {
  const {
    discardSale,
    parkSale,
    retrieveSale,
    isSaleInProgress = false,
  } = props;
  const title = `${translate("ParkSale", {
    namespace: TRANSLATION_NAMESPACE.Sell,
  })}`;
  return (
    <div className="sell-bucket-actions">
      <Row>
        <Col xs={12} md={8}>
          <RetrieveButton onClick={retrieveSale} />
        </Col>
        <Col xs={12} md={8}>
          <Button
            type="link"
            icon={<HistoryOutlined />}
            layout="inputfield"
            secondaryButtonTitle={title}
            disabled={isSaleInProgress === true ? false : true}
            onClick={parkSale}
          >
            {title}
          </Button>
        </Col>
        <Col xs={12} md={8}>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={discardSale}
            disabled={isSaleInProgress === true ? false : true}
          >
            {translate("DiscardSale", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ActionButtons;
