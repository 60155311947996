import React from "react";
import { Row, Col, Typography } from "antd";
import PriceLabel from "~/components/Common/PriceLabel";
import PriceInput from "~/components/Common/Input/PriceInput";
import { translate } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";

const { Title, Text } = Typography;

const AmountToPay = (props) => {
  const {
    amountToPay,
    amountLeftToPay,
    onTotalAmountChange,
    payment = 0,
    disabled = false,
  } = props;
  return (
    <Row>
      <Col xs={8}>
        <Title level={4}>
          {translate("Payment", {
            namespace: TRANSLATION_NAMESPACE.Sell,
          })}
        </Title>
      </Col>
      <Col xs={16} className="amountToPay-input-container">
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <PriceInput
              disabled={disabled}
              size="large"
              value={payment}
              onChange={onTotalAmountChange}
              className="totalAmount-input"
              autoFocus
            />
          </Col>
          <Col xs={24}>
            {amountLeftToPay >= 0 && (
              <>
                <PriceLabel value={amountLeftToPay} />{" "}
                <Text>
                  {`${translate("LeftToPay", {
                    namespace: TRANSLATION_NAMESPACE.Sell,
                  })}`}
                </Text>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AmountToPay;
