import React from "react";
import { Prompt as RouterPrompt } from "react-router";

class Prompt extends React.Component {
  constructor(props) {
    super(props);

    this.handleWindowBeforeUnload = this.handleWindowBeforeUnload.bind();
  }
  componentDidMount() {
    if (window) {
      window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
    }
  }
  componentWillUnmount() {
    if (window) {
      window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    }
  }
  handleWindowBeforeUnload = (event) => {
    const { when = false } = this.props;
    if (when === true) {
      event.returnValue = null; //return value is treated as a flag, can be any value. The message cannot be customized
    }
  };
  render() {
    const _this = this;

    return (
      <RouterPrompt
        message={(location, action) => {
          const {
            when = false,
            excludedNavigationPaths = [],
            onlyPromptOnWindowUnload = false,
          } = _this.props;

          const prompt =
            onlyPromptOnWindowUnload === false &&
            excludedNavigationPaths.find((excludedPath) => {
              if (location.pathname.substring(excludedPath) !== -1) {
                return false;
              }
              return true;
            });

          const hasUnSavedChanges = prompt !== false && when;

          return hasUnSavedChanges
            ? `You have unsaved changes, are you sure you want to leave?`
            : true;
        }}
      />
    );
  }
}

export default Prompt;
