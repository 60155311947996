import React from "react";
import { Space } from "antd";
import {
  ShopOutlined,
  SettingOutlined,
  AuditOutlined,
  DownOutlined,
} from "@ant-design/icons";
import Button from "~/components/Common/Button";
import { getLocalCompanySettings } from "~/_utils";
import { Menu, Dropdown } from "antd";
import { Trans, withNamespaces } from "react-i18next";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";
import { constructUrl } from "~/config";

const menu = (props) => {
  const { disabled = false, history, match } = props;
  return (
    <Menu className="topMenu companyMenu">
      <Menu.Item
        key={"companySetting"}
        onClick={() => {
          history.push(
            constructUrl({
              match,
              to: `/setup/settings?tab=company`,
            })
          );
        }}
        disabled={disabled}
      >
        <span>
          <SettingOutlined />
          <span style={{ marginLeft: "8px" }}>
            <Trans i18nKey="CompanySetting">Company settings</Trans>
          </span>
        </span>
      </Menu.Item>
      <Menu.Item
        key={"subscriptionOverview"}
        onClick={() => {
          history.push(
            constructUrl({
              match,
              to: `/subscription`,
            })
          );
        }}
        disabled={disabled}
      >
        <span>
          <AuditOutlined />
          <span style={{ marginLeft: "8px" }}>
            <Trans i18nKey="Subscription">Subscription</Trans>
          </span>
        </span>
      </Menu.Item>
    </Menu>
  );
};

const CompanyMenu = (props = {}) => {
  const company = getLocalCompanySettings() || {};

  return (
    <div className="menuContainer companyMenuContainer">
      <Dropdown
        overlay={menu(props)}
        trigger={["click"]}
        arrow={{ pointAtCenter: true }}
      >
        <Space style={{ cursor: "pointer" }}>
          <Button
            type="link"
            className="avatarContainer"
            icon={<ShopOutlined />}
          >
            <span className="buttonText">{company.companyName}</span>
          </Button>
          <DownOutlined />
        </Space>
      </Dropdown>
    </div>
  );
};

export default withNamespaces([TRANSLATION_NAMESPACE.Common])(CompanyMenu);
