import React from "react";
import PropTypes from "prop-types";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import "./MenuToggle.css";

const MenuToggle = (props) => {
  const { collapsed, toggle } = props;

  return collapsed ? (
    <MenuUnfoldOutlined className="trigger" onClick={() => toggle(collapsed)} />
  ) : (
    <MenuFoldOutlined className="trigger" onClick={() => toggle(collapsed)} />
  );
};

MenuToggle.propTypes = {
  toggle: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
};
export default MenuToggle;
