import {
  SELL_PAY,
  SELL_PAY_COMPLETESALE,
  SELL_PAY_CUSTOMER_CHANGE,
} from "~/actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SELL_PAY:
      //if payload is empty then return empty
      if (Object.keys(action.payload).length === 0) {
        return {};
      }
      return {
        ...state,
        ...action.payload,
      };
    case SELL_PAY_COMPLETESALE:
      return {}; // on SaleComplete make current sale empty to start from scratch
    case SELL_PAY_CUSTOMER_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
