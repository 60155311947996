import { translateFieldValidation, translate } from "~/_utils";
import { FIELD_VALIDATION_TYPE, TRANSLATION_NAMESPACE } from "~/_utils/consts";

let viewModel = {
  name: {
    props: {
      placeholder: translate("CategoryName", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.name.props.placeholder
          );
        },
      },
    ],
  },
  description: {
    props: {
      placeholder: translate("Description", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
};

export default viewModel;
