import React from "react";
import Card from "./Card";
import { getLowInventoryStatistics } from "~/actions/dashboardActions";
import { FallOutlined } from "@ant-design/icons";
import Spin from "~/components/Common/Spin";
import { translate } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";
import { constructUrl } from "~/config";

class ProductsLowInventoryCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, lowInventoryProducts: "" };
    this.onClick = this.onClick.bind();
  }
  componentDidMount() {
    const _this = this;
    getLowInventoryStatistics()
      .then(function(result = {}) {
        _this.setState({
          loading: false,
          lowInventoryProducts: result.data,
        });
      })
      .catch(function(err) {
        _this.setState({ loading: false });
        console.log(err);
      });
  }
  onClick = () => {
    const { history, match } = this.props;
    history.push(
      constructUrl({
        match,
        to: `/products?lowInventory=true`,
      })
    );
  };
  render() {
    const { lowInventoryProducts = "", loading = true } = this.state;
    const title = `${translate("LowInventory", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })}`;
    const footer = `${translate("ProductsWithLowInventory", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })}`;

    return (
      <Card
        icon={<FallOutlined />}
        iconStyle={{ color: "#c90000", background: "#c900001a" }}
        title={title}
        footer={loading === true ? "" : footer}
        onClick={this.onClick}
      >
        <Spin spinning={loading}>
          <h1 className="font-weight-bold">{lowInventoryProducts}</h1>
        </Spin>
      </Card>
    );
  }
}

export default ProductsLowInventoryCard;
