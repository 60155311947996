import React from "react";
import { price } from "../../_utils";

const PriceLabel = props => {
  const { value, ...rest } = props;

  return <span {...rest}>{`${price(value)}`}</span>;
};

export default PriceLabel;
