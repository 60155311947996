import React from "react";
import Media from "react-media";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Divider, Input, Typography, Card, Form } from "antd";
import {
  EditTwoTone,
  DeleteTwoTone,
  ApartmentOutlined,
  SearchOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import Button from "~/components/Common/Button";
import FormItem from "~/components/Form/FormItem";
import { PageTitle } from "~/components/Common/Text";
import CategoryModal from "~/pages/Products/Categories/Category/CategoryModal";
import { getCategories, deleteCategory } from "~/actions/productActions";
import Table from "~/components/Common/Table";

const { Text } = Typography;

const Filters = (props) => {
  const { onSearch } = props;
  const onFinish = (values) => {
    onSearch(values);
  };
  return (
    <Form name="filter" onFinish={onFinish}>
      <Row gutter={[8, 8]}>
        <Col xs={12} md={6}>
          <FormItem
            propertyName="name_contains"
            placeholder="Name"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {({ placeholder, ...restOptions }) => (
              <Input prefix={<ApartmentOutlined />} placeholder={placeholder} />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              Search
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

class Categories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      categoryModalVisible: false,
      selectedCategoryId: "",
      filterValues: props.filterValues,
    };

    this.onDelete = this.onDelete.bind();
    this.resetTableState = this.resetTableState.bind();
    this.handleFetch = this.handleFetch.bind();
    this.updateData = this.updateData.bind();
    this.handleTableChange = this.handleTableChange.bind();

    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: true,
      },
      { title: "Description", dataIndex: "description", key: "description" },

      {
        title: "",
        dataIndex: "",
        key: "actions",
        width: 85,
        render: (text, record) => (
          <Row>
            <Col xs={12}>
              <Button
                type="link"
                icon={<EditTwoTone />}
                onClick={() =>
                  this.setState({
                    selectedCategoryId: record.categoryId,
                    categoryModalVisible: true,
                  })
                }
              />
            </Col>
            <Col xs={12}>
              <Button
                layout="confirm"
                confirmText="Are you sure ?"
                type="link"
                icon={<DeleteTwoTone />}
                onClick={() => this.onDelete({ id: record.categoryId })}
              />
            </Col>
          </Row>
        ),
      },
    ];
  }

  componentDidMount() {
    this.handleFetch();
  }

  resetTableState = (options, callback) => {
    const {
      sort = this.state.sort,
      filterValues = this.state.filterValues,
    } = options;
    this.setState(
      {
        sort: sort || {},
        pageInfo: {}, // always reset page to page 1
        filterValues: filterValues || {},
      },
      callback
    );
  };

  handleFetch = (options = {}) => {
    const _this = this;

    let { filterValues = {}, sort = {}, pageInfo = {} } = this.state;
    if (pageInfo.hasNextPage === false) {
      return;
    }

    this.setState({ loading: true });
    getCategories({ filterValues, sort, pageInfo })
      .then(function(result) {
        const { data, totalCount, pageInfo = {} } = result;
        _this.updateData(data);
        _this.setState({
          loading: false,
          pageInfo: pageInfo,
          totalCount: totalCount,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  updateData = (data = []) => {
    const _this = this;
    const {
      categories: alreadyLoadedCategories = [],
      pageInfo = {},
    } = this.state;
    let categories =
      Object.keys(pageInfo).length === 0 ? [] : [...alreadyLoadedCategories]; //If page is reset start from empty list

    const startIndex = categories.length;

    data.forEach((category, index) => {
      const key = startIndex + index;
      categories.push({ key: key, ...category });
    });

    _this.setState({
      categories: categories,
    });
  };

  handleTableChange = (pagination, filters, sorter = {}) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";

    const sort = JSON.parse(`{"${sortField}": "${sortOrder}"}`);
    this.resetTableState({ sort: sort }, this.handleFetch);
  };

  onDelete = (options) => {
    const _this = this;
    const { sort, filterValues } = this.state;
    const { deleteCategory } = this.props;
    deleteCategory(options)
      .then(function() {
        //keep existing filtr & sort state
        _this.resetTableState(
          {
            filterValues: filterValues,
            sort: sort,
          },
          _this.handleFetch
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  render() {
    const columns = this.columns;
    const { categories = [], selectedCategoryId, pageInfo = {} } = this.state;
    const _this = this;

    return (
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={12}>
              <PageTitle>Categories</PageTitle>
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              <Button
                type="link"
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  
                  this.setState({
                    selectedCategoryId: "",
                    categoryModalVisible: true,
                  });
                }}
              >
                Add new category
              </Button>
            </Col>
            <Divider />
            <br />
          </Row>
        </Col>
        <Col xs={24} xl={18}>
          <Filters
            onSearch={(filterValues) => {
              _this.resetTableState(
                { filterValues: filterValues },
                _this.handleFetch
              );
            }}
          />
        </Col>
        <Col xs={24}>
          <Divider />
          <Table
            loading={this.state.loading}
            columns={columns}
            onChange={this.handleTableChange}
            dataSource={categories}
            hasNextPage={pageInfo.hasNextPage}
            onPageChange={this.handleFetch}
          />
        </Col>
        <CategoryModal
          categoryId={selectedCategoryId}
          visible={this.state.categoryModalVisible}
          onCancel={() =>
            this.setState({
              categoryModalVisible: false,
              selectedCategoryId: "",
            })
          }
          onSave={() => {
            this.setState(
              {
                selectedCategoryId: "",
                categoryModalVisible: false,
              },
              _this.resetTableState({}, _this.handleFetch)
            );
          }}
        />
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  { deleteCategory }
)(withRouter(withNamespaces()(Categories)));
