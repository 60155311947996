import { translateFieldValidation, translate } from "~/_utils";
import { FIELD_VALIDATION_TYPE, TRANSLATION_NAMESPACE } from "~/_utils/consts";

let viewModel = {
  paymentDateTimeUtc: {
    props: {
      placeholder: translate("PaymentDateTimeUtc", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.paymentDateTimeUtc.props.placeholder
          );
        },
      },
    ],
  },
  notes: {
    props: {
      placeholder: translate("Notes", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        type: "string",
        max: 300,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Invalid,
            viewModel.notes.props.placeholder
          );
        },
      },
    ],
  },
  amount: {
    props: {
      placeholder: translate("Amount", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.amount.props.placeholder
          );
        },
      },
    ],
  },
  customerId: {
    props: {
      placeholder: translate("Customer", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.customerId.props.placeholder
          );
        },
      },
    ],
  },
};

export default viewModel;
