import React from "react";
import { Modal } from "antd";
import ProductDetail from "~/components/Sell/ProductDetail";
import Offline from "~/components/Offline";
import { isOnline } from "~/_utils";

const ProductDetailModal = (props = {}) => {
  const { barcode, ...restProps } = props;
  return (
    <Modal
      title="Product"
      centered
      footer={null}
      destroyOnClose={true}
      {...restProps}
    >
      {isOnline() === false ? <Offline /> : <ProductDetail barcode={barcode} />}
    </Modal>
  );
};

export default ProductDetailModal;
