import React from "react";
import InputDecimal from "~/components/Common/InputDecimal";
import { price } from "~/_utils";

const PriceInput = (props) => {
  const { defaultValue, ...otherProps } = props;

  return (
    <InputDecimal
      {...otherProps}
      step={0.01}
      defaultValue={price(defaultValue, false)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
};

export default PriceInput;
