import React from "react";
import { DatePicker as DatePickerAntd } from "antd";
import moment from "moment";
import { DateFormat } from "~/config";

const { RangePicker: RangePickerAntd } = DatePickerAntd;

const RangePicker = (props) => {
  const { onChange, ...restProps } = props;
  const { picker = "" } = restProps;
  const onDateChange = (dates, dateStrings) => {
    if (!dates || dates.length === 0) {
      onChange(dates, []);
      return;
    }

    let startDate = dates[0]._d; // localdateTime
    let endDate = dates[1]._d; //localDatetime

    startDate.setHours(0, 0, 0, 0); //start from beginning of the day

    endDate.setDate(endDate.getDate() + 1); //till end of the day (till last milli second)
    endDate.setHours(0, 0, 0, -1);

    onChange(dates, [startDate, endDate]);
  };

  let ranges =
    picker === ""
      ? {
          Today: [moment(), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
        }
      : { "This Month": [moment().startOf("month"), moment().endOf("month")] };

  return (
    <>
      <RangePickerAntd
        format={DateFormat}
        ranges={ranges}
        onChange={onDateChange}
        {...restProps}
      />
    </>
  );
};

export default RangePicker;
