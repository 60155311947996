import React from "react";
import { Row, Col, Select, Tabs, Input, Switch, Space } from "antd";
import { RetweetOutlined } from "@ant-design/icons";

import { toFloat } from "~/_utils";
import FormItem from "~/components/Form/FormItem";
import PriceInput from "~/components/Common/Input/PriceInput";

import Button from "~/components/Common/Button";

import InputDecimal from "~/components/Common/InputDecimal";
import viewModel from "./viewModel";
import {
  calculatePurchasePrice,
  calculateMarkup,
  calculateRetailPriceExTaxFromRetailPriceIncTax,
  calculateRetailPriceExTax,
  calculateRetailPriceIncTax,
} from "./Price";

const { Option } = Select;
const { TabPane } = Tabs;

const GetPricePropNames = (index) => {
  return {
    purchasePricePropName: [index, "purchasePrice"],
    avgPurchasePricePropName: [index, "avgPurchasePrice"],
    retailPriceExTaxPropName: [index, "retailPriceExTax"],
    retailPriceIncTaxPropName: [index, "retailPriceIncTax"],
    taxIdPropName: [index, "taxId"],
    markupPropName: [index, "markup"],
  };
};

class VariantDefinitionExpandSection extends React.Component {
  constructor(props) {
    super(props);

    this.onMarkupChange = this.onMarkupChange.bind();
    this.onTaxChange = this.onTaxChange.bind();
    this.onPurchasePriceChange = this.onPurchasePriceChange.bind();
    this.onRetailPriceExTaxChange = this.onRetailPriceExTaxChange.bind();
    this.onRetailPriceIncTaxChange = this.onRetailPriceIncTaxChange.bind();
    this.onAvgPriceReset = this.onAvgPriceReset.bind();
  }

  onMarkupChange = (value) => {
    const { form = {}, index, taxes } = this.props;
    const markup = toFloat(value);

    let variantDefinitions =
      (form && form.getFieldValue("productVariants")) || [];

    const variantDefinition = variantDefinitions[index] || {};

    const taxId = variantDefinition.taxId;
    let purchasePrice = toFloat(variantDefinition.purchasePrice);
    let retailPriceExTax = toFloat(variantDefinition.retailPriceExTax);
    let retailPriceIncTax = toFloat(variantDefinition.retailPriceIncTax);

    const isAutoCalculatePurchasePrice =
      variantDefinition.autoCalculatePurchasePrice;

    if (isAutoCalculatePurchasePrice === true) {
      purchasePrice = calculatePurchasePrice({
        markup: markup,
        retailPriceExTax: retailPriceExTax,
      });
      variantDefinitions[index].purchasePrice = purchasePrice;
    } else {
      retailPriceExTax = calculateRetailPriceExTax({
        purchasePrice: purchasePrice,
        markup: markup,
      });

      retailPriceIncTax = calculateRetailPriceIncTax({
        purchasePrice: purchasePrice,
        markup: markup,
        taxId: taxId,
        taxes: taxes,
      });
      variantDefinitions[index].retailPriceExTax = retailPriceExTax;
      variantDefinitions[index].retailPriceIncTax = retailPriceIncTax;
    }

    form && form.setFieldsValue({ productVariants: [...variantDefinitions] });
  };
  onTaxChange = (value) => {
    const { form = {}, index, taxes } = this.props;

    let variantDefinitions =
      (form && form.getFieldValue("productVariants")) || [];

    const variantDefinition = variantDefinitions[index] || {};
    const retailPriceExTax = toFloat(variantDefinition.retailPriceExTax);

    if (retailPriceExTax !== 0) {
      this.onRetailPriceExTaxChange(retailPriceExTax);
      return;
    }

    const purchasePrice = toFloat(variantDefinition.purchasePrice);

    const markup = toFloat(variantDefinition.markup);
    const retailPriceIncTax = calculateRetailPriceIncTax({
      purchasePrice: purchasePrice,
      markup: markup,
      taxId: value,
      taxes: taxes,
    });
    variantDefinitions[index].retailPriceIncTax = retailPriceIncTax;

    form && form.setFieldsValue({ productVariants: [...variantDefinitions] });
  };
  onPurchasePriceChange = (value) => {
    const { form = {}, index, taxes } = this.props;
    const purchasePrice = toFloat(value);

    let variantDefinitions =
      (form && form.getFieldValue("productVariants")) || [];

    const variantDefinition = variantDefinitions[index] || {};

    const markup = toFloat(variantDefinition.markup);
    const taxId = variantDefinition.taxId;

    const retailPriceExTax = calculateRetailPriceExTax({
      purchasePrice: purchasePrice,
      markup: markup,
    });

    const retailPriceIncTax = calculateRetailPriceIncTax({
      purchasePrice: purchasePrice,
      markup: markup,
      taxId: taxId,
      taxes: taxes,
    });

    variantDefinitions[index].retailPriceExTax = retailPriceExTax;
    variantDefinitions[index].retailPriceIncTax = retailPriceIncTax;

    form && form.setFieldsValue({ productVariants: [...variantDefinitions] });
  };
  onRetailPriceExTaxChange = (value) => {
    const { form = {}, index, taxes } = this.props;
    const retailPriceExTax = toFloat(value);

    let variantDefinitions =
      (form && form.getFieldValue("productVariants")) || [];

    const variantDefinition = variantDefinitions[index] || {};
    const taxId = variantDefinition.taxId;
    let purchasePrice = toFloat(variantDefinition.purchasePrice);
    let markup = toFloat(variantDefinition.markup);
    const isAutoCalculatePurchasePrice =
      variantDefinition.autoCalculatePurchasePrice;

    if (isAutoCalculatePurchasePrice === true) {
      purchasePrice = calculatePurchasePrice({
        markup: markup,
        retailPriceExTax: retailPriceExTax,
      });
      variantDefinitions[index].purchasePrice = purchasePrice;
    } else {
      markup = calculateMarkup({
        purchasePrice: purchasePrice,
        retailPriceExTax: retailPriceExTax,
      });
      variantDefinitions[index].markup = markup;
    }

    const retailPriceIncTax = calculateRetailPriceIncTax({
      purchasePrice: purchasePrice,
      markup: markup,
      taxId: taxId,
      taxes: taxes,
    });

    variantDefinitions[index].retailPriceIncTax = retailPriceIncTax;

    form && form.setFieldsValue({ productVariants: [...variantDefinitions] });
  };
  onRetailPriceIncTaxChange = (value) => {
    const { form = {}, index, taxes } = this.props;
    const retailPriceIncTax = toFloat(value);

    let variantDefinitions =
      (form && form.getFieldValue("productVariants")) || [];

    const variantDefinition = variantDefinitions[index] || {};

    const taxId = variantDefinition.taxId;
    let purchasePrice = toFloat(variantDefinition.purchasePrice);
    let markup = toFloat(variantDefinition.markup);
    const isAutoCalculatePurchasePrice =
      variantDefinition.autoCalculatePurchasePrice;

    const retailPriceExTax = calculateRetailPriceExTaxFromRetailPriceIncTax({
      retailPriceIncTax: retailPriceIncTax,
      taxId: taxId,
      taxes: taxes,
    });

    if (isAutoCalculatePurchasePrice === true) {
      purchasePrice = calculatePurchasePrice({
        markup: markup,
        retailPriceExTax: retailPriceExTax,
      });
      variantDefinitions[index].purchasePrice = purchasePrice;
    } else {
      markup = calculateMarkup({
        purchasePrice: purchasePrice,
        retailPriceExTax: retailPriceExTax,
      });

      variantDefinitions[index].markup = markup;
    }

    variantDefinitions[index].retailPriceExTax = retailPriceExTax;

    form && form.setFieldsValue({ productVariants: [...variantDefinitions] });
  };
  onAvgPriceReset = () => {
    let { form = {}, index } = this.props;

    let variantDefinitions =
      (form && form.getFieldValue("productVariants")) || [];
    const variantDefinition = variantDefinitions[index] || {};
    const purchasePrice = toFloat(variantDefinition.purchasePrice);

    variantDefinitions[index].avgPurchasePrice = purchasePrice;

    form && form.setFieldsValue({ productVariants: [...variantDefinitions] });
  };
  render() {
    const { form = {}, index, taxes } = this.props;
    const propNames = GetPricePropNames(index);

    return (
      <Tabs defaultActiveKey="1">
        <TabPane tab="Inventory" key="1">
          <Row gutter={[16, 8]}>
            <Col xs={8}>
              <FormItem
                propertyName={[index, "barcode"]}
                viewModelKeyValue={viewModel.productVariants[0].barcode}
              >
                {(options) => (
                  <Input
                    {...options}
                    onPressEnter={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      return;
                    }}
                  />
                )}
              </FormItem>
            </Col>

            <Col xs={8}>
              <FormItem
                propertyName={[index, "currentInventory"]}
                viewModelKeyValue={
                  viewModel.productVariants[0].currentInventory
                }
              >
                {({ placeholder, ...restOptions }) => (
                  <InputDecimal style={{ width: "100%" }} {...restOptions} />
                )}
              </FormItem>
            </Col>
            <Col xs={8}>
              <FormItem
                propertyName={[index, "reOrderPoint"]}
                viewModelKeyValue={viewModel.productVariants[0].reOrderPoint}
              >
                {({ placeholder, ...restOptions }) => (
                  <InputDecimal style={{ width: "100%" }} {...restOptions} />
                )}
              </FormItem>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Price" key="2">
          <Row gutter={[16, 8]}>
            <Col xs={24}>
              <FormItem
                valuePropName="checked"
                propertyName={[index, "autoCalculatePurchasePrice"]}
                showFormTitle={false}
              >
                {({ placeholder, ...restOptions }) => (
                  <Switch
                    checkedChildren={"Calculate purchase price"}
                    unCheckedChildren={"Calculate purchase price"}
                    onChange={(checked, e) => {
                      console.log(checked);
                    }}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={8}>
              <FormItem
                propertyName={propNames.purchasePricePropName}
                viewModelKeyValue={viewModel.productVariants[0].purchasePrice}
              >
                {({ placeholder, ...restOptions }) => (
                  <PriceInput
                    style={{ width: "100%" }}
                    {...restOptions}
                    onBlur={this.onPurchasePriceChange}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={8}>
              <FormItem
                propertyName={propNames.taxIdPropName}
                viewModelKeyValue={viewModel.productVariants[0].taxId}
                form={form}
              >
                {(options, setValue) => (
                  <Select
                    allowClear={true}
                    style={{ width: "100%" }}
                    placeholder={options.placeholder}
                    onChange={(value) => {
                      this.onTaxChange(value);
                    }}
                  >
                    {taxes.map((tax, index) => {
                      const key = `${tax.taxId}${index}`;
                      return (
                        <Option key={key} value={tax.taxId}>
                          {tax.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={8}>
              <Space size={0}>
                <FormItem
                  propertyName={propNames.avgPurchasePricePropName}
                  viewModelKeyValue={
                    viewModel.productVariants[0].avgPurchasePrice
                  }
                >
                  {({ placeholder, ...restOptions }) => (
                    <PriceInput
                      disabled={true}
                      style={{ width: "100%" }}
                      {...restOptions}
                    />
                  )}
                </FormItem>
                <Button
                  layout="confirm"
                  confirmText="Reset Avg price same as purchase price ?"
                  type="link"
                  icon={<RetweetOutlined />}
                  onClick={this.onAvgPriceReset}
                >
                  Reset
                </Button>
              </Space>
            </Col>

            <Col xs={8}>
              <FormItem
                propertyName={propNames.markupPropName}
                viewModelKeyValue={viewModel.productVariants[0].markup}
              >
                {({ placeholder, ...restOptions }) => (
                  <InputDecimal
                    style={{ width: "100%" }}
                    {...restOptions}
                    onBlur={this.onMarkupChange}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={8}>
              <FormItem
                propertyName={propNames.retailPriceExTaxPropName}
                viewModelKeyValue={
                  viewModel.productVariants[0].retailPriceExTax
                }
              >
                {({ placeholder, ...restOptions }) => (
                  <PriceInput
                    style={{ width: "100%" }}
                    {...restOptions}
                    onBlur={this.onRetailPriceExTaxChange}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={8}>
              <FormItem
                propertyName={propNames.retailPriceIncTaxPropName}
                viewModelKeyValue={
                  viewModel.productVariants[0].retailPriceIncTax
                }
              >
                {({ placeholder, ...restOptions }) => (
                  <PriceInput
                    style={{ width: "100%" }}
                    {...restOptions}
                    onBlur={this.onRetailPriceIncTaxChange}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    );
  }
}

export default VariantDefinitionExpandSection;
