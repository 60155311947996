import React from "react";
import { Row, Col, Input, Form, Divider, Button, Switch, Tooltip } from "antd";
import { DeleteOutlined, CalculatorOutlined } from "@ant-design/icons";
import FormItem, { FormItemTitle } from "~/components/Form/FormItem";
import DatePicker from "~/components/Common/DatePicker";
import PriceInput from "~/components/Common/Input/PriceInput";
import PriceLabel from "~/components/Common/PriceLabel";
import InputDecimal from "~/components/Common/InputDecimal";
import { toFloat } from "~/_utils";
import viewModel from "./viewModel";
import PurchaseProductPriceCalculatorModal from "~/pages/Purchases/Purchase/PurchaseProductPriceCalculator/PurchaseProductPriceCalculatorModal";

const Inverse = (val) => {
  return val > 0 ? val * -1 : val;
};

const RecalculateProductPricesWithoutAdditionalTaxAndDisc = (options) => {
  const { purchaseProducts = [] } = options;
  const updatedPurchaseProducts = purchaseProducts.map(
    (purchaseProduct = {}, index) => {
      let purchasePrice = toFloat(
        CalculateUnitPriceIncTaxDisc({
          discPercent: purchaseProduct.discountPercentage,
          taxPercent: purchaseProduct.taxPercentage,
          unitPriceExTaxAndDisc: purchaseProduct.purchasePriceExTax,
        })
      );
      let totalPurchasePrice = toFloat(
        purchaseProduct.quantity * purchasePrice
      );

      const discountAmount =
        (purchaseProduct.purchasePriceExTax *
          purchaseProduct.discountPercentage) /
        100;
      const taxableAmount = purchaseProduct.purchasePriceExTax - discountAmount;

      let totalTaxableAmount = toFloat(
        purchaseProduct.quantity * taxableAmount
      );

      if (purchaseProduct.quantity < 0) {
        purchasePrice = Inverse(purchasePrice);
        totalPurchasePrice = Inverse(totalPurchasePrice);
        totalTaxableAmount = Inverse(totalTaxableAmount);
      }

      return {
        ...purchaseProduct,
        purchasePrice: toFloat(purchasePrice),
        totalPurchasePrice: toFloat(totalPurchasePrice),
        totalTaxableAmount: toFloat(totalTaxableAmount),
      };
    }
  );

  return [...updatedPurchaseProducts];
};

const RecalculateProductPricesWithAdditionalTaxAndDisc = (options) => {
  const {
    purchaseProducts = [],
    additionalAmount = 0,
    totalPurchasePriceWithoutAdditionalTaxAndDisc = 0,
    purchaseProductsWithoutAdditionalTaxAndDisc = [],
  } = options;

  const updatedPurchaseProducts = purchaseProducts.map(
    (purchaseProduct = {}, index) => {
      const purchaseProductWeight =
        purchaseProduct.totalPurchasePrice /
        (totalPurchasePriceWithoutAdditionalTaxAndDisc === 0
          ? 1
          : totalPurchasePriceWithoutAdditionalTaxAndDisc);
      let weightedAdditionalTaxDisc = additionalAmount * purchaseProductWeight;

      //TODO:check
      if (purchaseProduct.quantity < 0) {
        weightedAdditionalTaxDisc = Inverse(weightedAdditionalTaxDisc);
      }

      let updatedTotalPurchasePrice =
        purchaseProduct.totalPurchasePrice + weightedAdditionalTaxDisc;
      let updatedPricePerUnit =
        updatedTotalPurchasePrice /
        (purchaseProduct.quantity == 0 ? 1 : purchaseProduct.quantity);

      if (purchaseProduct.quantity < 0) {
        updatedTotalPurchasePrice = Inverse(updatedTotalPurchasePrice);
        updatedPricePerUnit = Inverse(updatedPricePerUnit);
      }

      return {
        ...purchaseProduct,
        purchasePrice: toFloat(updatedPricePerUnit),
        totalPurchasePrice: toFloat(updatedTotalPurchasePrice),
        totalTaxableAmount: (
          purchaseProductsWithoutAdditionalTaxAndDisc[index] || {}
        ).totalTaxableAmount,
      };
    }
  );

  return updatedPurchaseProducts;
};

const CalculateTotalPriceIncTaxDisc = (options) => {
  const {
    totalPurchasePrice = 0,
    additionalDiscountPercentage = 0,
    additionalTaxPercentage = 0,
  } = options;
  let updatedTotalPurchasePrice = totalPurchasePrice;
  const additionalDiscAmount =
    (updatedTotalPurchasePrice * additionalDiscountPercentage) / 100;
  updatedTotalPurchasePrice = updatedTotalPurchasePrice - additionalDiscAmount;

  const additionalTaxAmount =
    (updatedTotalPurchasePrice * additionalTaxPercentage) / 100;
  updatedTotalPurchasePrice = updatedTotalPurchasePrice + additionalTaxAmount;

  return updatedTotalPurchasePrice;
};

const CalculateUnitPriceIncTaxDisc = (options) => {
  const {
    discPercent = 0,
    taxPercent = 0,
    unitPriceExTaxAndDisc = 0,
  } = options;

  const discountAmount = (unitPriceExTaxAndDisc * discPercent) / 100;
  const unitPriceIncDiscount = unitPriceExTaxAndDisc - discountAmount;
  const taxAmount = (unitPriceIncDiscount * taxPercent) / 100;

  return unitPriceIncDiscount + taxAmount;
};

export const CalculatePrices = (options = {}) => {
  const { index = -1, form, field, value } = options;
  let purchaseProducts = form.getFieldValue("purchaseProducts") || [];
  let currentPurchaseProduct = purchaseProducts[index] || {};
  let currentProductQuantity = toFloat(currentPurchaseProduct.quantity);
  let currentProductPurchasePriceExTax = toFloat(
    currentPurchaseProduct.purchasePriceExTax
  );
  let currentProductTotalPurchasePriceExTax = toFloat(
    currentPurchaseProduct.totalPurchasePriceExTax
  );
  let currentProductDiscountPercentage = toFloat(
    currentPurchaseProduct.discountPercentage
  );
  let currentProductTaxPercentage = toFloat(
    currentPurchaseProduct.taxPercentage
  );
  let currentProductPurchasePrice = toFloat(
    currentPurchaseProduct.purchasePrice
  );
  let currentProductTotalPurchasePrice = toFloat(
    currentPurchaseProduct.totalPurchasePrice
  );

  let additionalDiscountPercentage = toFloat(
    form.getFieldValue("additionalDiscountPercentage") || 0
  );
  let additionalTaxPercentage = toFloat(
    form.getFieldValue("additionalTaxPercentage") || 0
  );
  let includeAdditionalTaxInProductPrice =
    form.getFieldValue("includeAdditionalTaxInProductPrice") || false;
  let totalPurchasePriceExTax = toFloat(
    form.getFieldValue("totalPurchasePriceExTax") || 0
  );
  let totalPurchasePrice = toFloat(
    form.getFieldValue("totalPurchasePrice") || 0
  );

  let updatePricesIncTaxAndDisc = false;

  if (field === "quantity") {
    currentProductQuantity = toFloat(value);
    currentProductTotalPurchasePriceExTax =
      currentProductQuantity * currentProductPurchasePriceExTax;
    updatePricesIncTaxAndDisc = true;
  }
  if (field === "purchasePriceExTax") {
    currentProductPurchasePriceExTax = toFloat(value);
    currentProductTotalPurchasePriceExTax =
      currentProductQuantity * currentProductPurchasePriceExTax;

    updatePricesIncTaxAndDisc = true;
  }
  if (field === "totalPurchasePriceExTax") {
    currentProductTotalPurchasePriceExTax = toFloat(value);
    currentProductPurchasePriceExTax =
      currentProductTotalPurchasePriceExTax /
      (currentProductQuantity === 0 ? 1 : currentProductQuantity);

    updatePricesIncTaxAndDisc = true;
  }
  if (field === "discountPercentage") {
    currentProductDiscountPercentage = toFloat(value);
    updatePricesIncTaxAndDisc = true;
  }
  if (field === "taxPercentage") {
    currentProductTaxPercentage = toFloat(value);
    updatePricesIncTaxAndDisc = true;
  }

  if (updatePricesIncTaxAndDisc === true) {
    currentProductPurchasePrice = CalculateUnitPriceIncTaxDisc({
      discPercent: currentProductDiscountPercentage,
      taxPercent: currentProductTaxPercentage,
      unitPriceExTaxAndDisc: currentProductPurchasePriceExTax,
    });
    currentProductTotalPurchasePrice =
      currentProductQuantity * currentProductPurchasePrice;
  }

  //calculate prices with negative quantity
  if (currentProductQuantity < 0) {
    currentProductPurchasePriceExTax = Inverse(
      currentProductPurchasePriceExTax
    );
    currentProductTotalPurchasePriceExTax = Inverse(
      currentProductTotalPurchasePriceExTax
    );
    currentProductPurchasePrice = Inverse(currentProductPurchasePrice);
    totalPurchasePrice = Inverse(totalPurchasePrice);
  }

  const updatedCurrentPurchaseProduct = {
    ...currentPurchaseProduct,
    quantity: currentProductQuantity,
    purchasePriceExTax: toFloat(currentProductPurchasePriceExTax),
    totalPurchasePriceExTax: toFloat(currentProductTotalPurchasePriceExTax),
    discountPercentage: toFloat(currentProductDiscountPercentage),
    taxPercentage: toFloat(currentProductTaxPercentage),
    purchasePrice: toFloat(currentProductPurchasePrice),
    totalPurchasePrice: toFloat(currentProductTotalPurchasePrice),
  };
  let updatedPurchaseProducts =
    index >= 0
      ? Object.assign([], purchaseProducts, {
          [index]: updatedCurrentPurchaseProduct,
        })
      : Object.assign([], purchaseProducts);

  // totalPurchasePriceExTax = updatedPurchaseProducts
  //   .map((p) => toFloat(p.totalPurchasePriceExTax))
  //   .reduce((a, b) => a + b, 0);

  totalPurchasePrice = updatedPurchaseProducts
    .map((p) => toFloat(p.totalPurchasePrice))
    .reduce((a, b) => a + b, 0);

  const purchaseProductsWithoutAdditionalTaxAndDisc = RecalculateProductPricesWithoutAdditionalTaxAndDisc(
    { purchaseProducts: updatedPurchaseProducts }
  );

  const totalPurchasePriceWithoutAdditionalTaxAndDisc = purchaseProductsWithoutAdditionalTaxAndDisc
    .map((p) => toFloat(p.totalPurchasePrice))
    .reduce((a, b) => a + b, 0);

  //total price including additional taxes & Disc
  const updatedTotalPurchasePriceIncAdditionalTaxDisc = CalculateTotalPriceIncTaxDisc(
    {
      totalPurchasePrice: totalPurchasePriceWithoutAdditionalTaxAndDisc,
      additionalDiscountPercentage: additionalDiscountPercentage,
      additionalTaxPercentage: additionalTaxPercentage,
    }
  );

  const additionalAmountWithTaxAndDiscount =
    updatedTotalPurchasePriceIncAdditionalTaxDisc - totalPurchasePrice;

  const additionalDiscAmount =
    (totalPurchasePriceWithoutAdditionalTaxAndDisc *
      additionalDiscountPercentage) /
    100;

  //if additional discount or addition tax is selected then re-calculate all product prices
  if (includeAdditionalTaxInProductPrice === true) {
    updatedPurchaseProducts = RecalculateProductPricesWithAdditionalTaxAndDisc({
      purchaseProducts: updatedPurchaseProducts,
      additionalAmount: additionalAmountWithTaxAndDiscount,
      totalPurchasePriceWithoutAdditionalTaxAndDisc: totalPurchasePrice,
      purchaseProductsWithoutAdditionalTaxAndDisc: purchaseProductsWithoutAdditionalTaxAndDisc,
    });
  } else {
    updatedPurchaseProducts = purchaseProductsWithoutAdditionalTaxAndDisc;
  }

  totalPurchasePrice = updatedTotalPurchasePriceIncAdditionalTaxDisc;

  totalPurchasePriceExTax = updatedPurchaseProducts
    .map((p) => toFloat(p.totalTaxableAmount))
    .reduce((a, b) => a + b, 0);

  totalPurchasePriceExTax = totalPurchasePriceExTax - additionalDiscAmount;

  form.setFieldsValue({
    totalPurchasePrice: toFloat(totalPurchasePrice),
    totalPurchasePriceExTax: toFloat(totalPurchasePriceExTax),
    purchaseProducts: [...updatedPurchaseProducts],
  });
};

const PurchaseProductsFooter = (props) => {
  const {
    totalPurchasePrice = 0,
    totalPurchasePriceExTax = 0,
    form,
    updateTotalPrice,
    style = {},
  } = props;
  return (
    <Row
      gutter={[16, 8]}
      style={{ ...style }}
      className="purchaseProducts-footer"
    >
      <Col xs={4}>
        {/* <FormItem
          propertyName={"additionalDiscountPercentage"}
          viewModelKeyValue={viewModel.additionalDiscountPercentage}
          showFormTitle={true}
        >
          {(options) => (
            <InputDecimal
              style={{ width: "100%" }}
              {...options}
              onBlur={(val) => {
                CalculatePrices({
                  form: form,
                });

                updateTotalPrice();
              }}
            />
          )}
        </FormItem> */}
      </Col>
      <Col xs={4}>
        {/* <FormItem
          propertyName={"additionalTaxPercentage"}
          viewModelKeyValue={viewModel.additionalTaxPercentage}
          showFormTitle={true}
        >
          {(options) => (
            <InputDecimal
              style={{ width: "100%" }}
              {...options}
              onBlur={(val) => {
                CalculatePrices({
                  form: form,
                });

                updateTotalPrice();
              }}
            />
          )}
        </FormItem> */}
      </Col>
      <Col xs={6}>
        {/* <FormItem
          propertyName={"includeAdditionalTaxInProductPrice"}
          viewModelKeyValue={viewModel.includeAdditionalTaxInProductPrice}
          showFormTitle={true}
          valuePropName="checked"
          form={form}
        >
          {({ placeholder, ...restOptions }) => (
            <Switch
              defaultChecked={false}
              checkedChildren={"Yes"}
              unCheckedChildren={"No"}
              onChange={(checked, e) => {
                CalculatePrices({
                  form: form,
                  field: "includeAdditionalTaxInProductPrice",
                });

                updateTotalPrice();
              }}
            />
          )}
        </FormItem> */}
      </Col>
      <Col xs={4}>
        <FormItemTitle title="Total Price (Excl. Tax)" />
        <PriceLabel value={totalPurchasePriceExTax} />
      </Col>
      <Col xs={2}>
        <FormItemTitle title="Tax" />
        <PriceLabel value={totalPurchasePrice - totalPurchasePriceExTax} />
      </Col>
      <Col xs={4}>
        <FormItemTitle title="Total Price (Incl. Tax)" />
        <PriceLabel value={totalPurchasePrice} />
      </Col>
      <div style={{ height: "0px" }}>
        <FormItem
          propertyName={"totalPurchasePrice"}
          viewModelKeyValue={viewModel.totalPurchasePrice}
          showFormTitle={false}
        >
          {(options) => <Input type="hidden" {...options} />}
        </FormItem>
        <FormItem
          propertyName={"totalPurchasePriceExTax"}
          viewModelKeyValue={viewModel.totalPurchasePriceExTax}
          showFormTitle={false}
        >
          {(options) => <Input type="hidden" {...options} />}
        </FormItem>
      </div>
    </Row>
  );
};

const PurchaseProductsHeader = () => {
  return (
    <Row gutter={[4, 8]} style={{ fontSize: 12 }}>
      <Col xs={4}>
        <FormItemTitle title="Product" />
      </Col>

      <Col xs={2}>
        <FormItemTitle title="Batch#" />
      </Col>
      <Col xs={2}>
        <FormItemTitle title="Expiry" />
      </Col>
      <Col xs={1}>
        <FormItemTitle title="" />
      </Col>
      <Col xs={2}>
        <FormItemTitle title="Qty" />
      </Col>

      <Col xs={2}>
        <FormItemTitle title={"Price/Unit\n (Ex Tax)"} />
      </Col>
      <Col xs={2}>
        <FormItemTitle title={"Total Price\n (Ex Tax)"} />
      </Col>
      <Col xs={2}>
        <FormItemTitle title="Disc %" />
      </Col>
      <Col xs={2}>
        <FormItemTitle title="Tax %" />
      </Col>
      <Col xs={2}>
        <FormItemTitle title={"Price/Unit\n (Tax&Disc)"} />
      </Col>
      <Col xs={2}>
        <FormItemTitle title={"Total Price\n (Tax&Disc)"} />
      </Col>
    </Row>
  );
};

const PurchaseProductsLines = (props) => {
  const {
    form,
    initialValues,
    purchaseProducts = [],
    updateTotalPrice,
    onProductRemove,
    onCalculateUnitPurchasePrice,
  } = props;

  return (
    <Form.List name={"purchaseProducts"}>
      {() => (
        <div>
          {purchaseProducts.map((purchaseProduct = {}, index) => {
            const {
              name = "",
              variant = "",
              barcode = "",
              productId,
              purchaseProductId,
              inStock,
            } = purchaseProduct;

            return (
              <Row gutter={[4, 4]} key={index} className="purchaseProducts-row">
                <Col xs={4}>
                  <Row>
                    <Col xs={24} className="productNameContainer">
                      <span className="productName">{`${name}`}</span>
                    </Col>
                    <Col xs={24}>
                      <span className="variant">{variant}</span>
                    </Col>
                    <Col xs={24}>
                      <span className="barcode">{barcode}</span>
                    </Col>
                  </Row>
                  <div style={{ height: "0px" }}>
                    <FormItem
                      form={form}
                      formListName={"purchaseProducts"}
                      propertyName={[index, "productId"]}
                      viewModelKeyValue={
                        viewModel.purchaseProducts[0].productId
                      }
                      showFormTitle={false}
                      value={productId}
                    >
                      {(options) => <Input type="hidden" {...options} />}
                    </FormItem>
                    <FormItem
                      form={form}
                      formListName={"purchaseProducts"}
                      propertyName={[index, "inStock"]}
                      viewModelKeyValue={viewModel.purchaseProducts[0].inStock}
                      showFormTitle={false}
                      value={inStock}
                    >
                      {(options) => <Input type="hidden" {...options} />}
                    </FormItem>
                    {purchaseProductId ? (
                      <FormItem
                        form={form}
                        formListName={"purchaseProducts"}
                        propertyName={[index, "purchaseProductId"]}
                        viewModelKeyValue={
                          viewModel.purchaseProducts[0].purchaseProductId
                        }
                        showFormTitle={false}
                        value={purchaseProductId}
                      >
                        {(options) => <Input type="hidden" {...options} />}
                      </FormItem>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>

                <Col xs={2}>
                  <FormItem
                    propertyName={[index, "batchNumber"]}
                    viewModelKeyValue={
                      viewModel.purchaseProducts[0].batchNumber
                    }
                    showFormTitle={false}
                  >
                    {(options) => <Input {...options} />}
                  </FormItem>
                </Col>
                <Col xs={2}>
                  <FormItem
                    form={form}
                    propertyName={[index, "expiryDate"]}
                    viewModelKeyValue={viewModel.purchaseProducts[0].expiryDate}
                    showFormTitle={false}
                  >
                    {({ options }, setValue) => <DatePicker />}
                  </FormItem>
                </Col>

                <Col xs={1}>
                  <Tooltip
                    title={"Unit price & quantity calculator"}
                    color={"blue"}
                    key={index}
                  >
                    <Button
                      type="link"
                      icon={<CalculatorOutlined />}
                      onClick={() => onCalculateUnitPurchasePrice({ index })}
                    />
                  </Tooltip>
                </Col>

                <Col xs={2}>
                  <FormItem
                    propertyName={[index, "quantity"]}
                    viewModelKeyValue={viewModel.purchaseProducts[0].quantity}
                    showFormTitle={false}
                  >
                    {(options) => (
                      <InputDecimal
                        {...options}
                        onBlur={(val, e) => {
                          CalculatePrices({
                            index: index,
                            form: form,
                            field: "quantity",
                            value: val,
                          });

                          updateTotalPrice();
                        }}
                        style={{ width: "100%" }}
                      />
                    )}
                  </FormItem>
                </Col>

                <Col xs={2}>
                  <FormItem
                    propertyName={[index, "purchasePriceExTax"]}
                    viewModelKeyValue={
                      viewModel.purchaseProducts[0].purchasePriceExTax
                    }
                    showFormTitle={false}
                  >
                    {({ placeholder, ...restOptions }) => (
                      <PriceInput
                        {...restOptions}
                        onBlur={(val) => {
                          CalculatePrices({
                            index: index,
                            form: form,
                            field: "purchasePriceExTax",
                            value: val,
                          });

                          updateTotalPrice();
                        }}
                        style={{ width: "100%" }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={2}>
                  <FormItem
                    propertyName={[index, "totalPurchasePriceExTax"]}
                    viewModelKeyValue={
                      viewModel.purchaseProducts[0].totalPurchasePriceExTax
                    }
                    showFormTitle={false}
                  >
                    {({ placeholder, ...restOptions }) => (
                      <PriceInput
                        {...restOptions}
                        style={{ width: "100%" }}
                        onBlur={(val) => {
                          CalculatePrices({
                            index: index,
                            form: form,
                            field: "totalPurchasePriceExTax",
                            value: val,
                          });

                          updateTotalPrice();
                        }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={2}>
                  <FormItem
                    propertyName={[index, "discountPercentage"]}
                    viewModelKeyValue={
                      viewModel.purchaseProducts[0].discountPercentage
                    }
                    showFormTitle={false}
                  >
                    {(options) => (
                      <InputDecimal
                        style={{ width: "100%" }}
                        {...options}
                        onBlur={(val) => {
                          CalculatePrices({
                            index: index,
                            form: form,
                            field: "discountPercentage",
                            value: val,
                          });

                          updateTotalPrice();
                        }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={2}>
                  <FormItem
                    propertyName={[index, "taxPercentage"]}
                    viewModelKeyValue={
                      viewModel.purchaseProducts[0].taxPercentage
                    }
                    showFormTitle={false}
                  >
                    {(options) => (
                      <InputDecimal
                        style={{ width: "100%" }}
                        {...options}
                        onBlur={(val) => {
                          CalculatePrices({
                            index: index,
                            form: form,
                            field: "taxPercentage",
                            value: val,
                          });

                          updateTotalPrice();
                        }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={2}>
                  <FormItem
                    propertyName={[index, "purchasePrice"]}
                    viewModelKeyValue={
                      viewModel.purchaseProducts[0].purchasePrice
                    }
                    showFormTitle={false}
                  >
                    {(options) => (
                      <InputDecimal
                        style={{ width: "100%" }}
                        disabled={true}
                        {...options}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={2}>
                  <FormItem
                    propertyName={[index, "totalPurchasePrice"]}
                    viewModelKeyValue={
                      viewModel.purchaseProducts[0].totalPurchasePrice
                    }
                    showFormTitle={false}
                  >
                    {(options) => (
                      <InputDecimal
                        style={{ width: "100%" }}
                        disabled={true}
                        {...options}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={1}>
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => onProductRemove(index)}
                  />
                </Col>
                <Divider />
              </Row>
            );
          })}
        </div>
      )}
    </Form.List>
  );
};

class PurchaseProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPurchasePrice: 0,
      totalPurchasePriceExTax: 0,
      numberOfItems: 0,
    };
    this.updateTotalPrice = this.updateTotalPrice.bind();
    this.scrollProductsToBottom = this.scrollProductsToBottom.bind();
    this.onCalculateUnitPurchasePriceSubmit = this.onCalculateUnitPurchasePriceSubmit.bind();
    this.onCalculateUnitPurchasePrice = this.onCalculateUnitPurchasePrice.bind();
  }
  componentDidMount() {
    const { purchaseProducts = [] } = this.props;
    this.setState({ numberOfItems: purchaseProducts.length });
    this.updateTotalPrice();
  }
  componentDidUpdate(prevProps) {
    const { purchaseProducts = [] } = this.props;
    const { numberOfItems = 0 } = this.state;

    if (purchaseProducts.length > numberOfItems) {
      this.scrollProductsToBottom();
    }

    if (purchaseProducts.length !== numberOfItems) {
      this.updateTotalPrice();
      this.setState({ numberOfItems: purchaseProducts.length });
    }
  }
  scrollProductsToBottom = () => {
    this.productsEnd.scrollIntoView({ behavior: "smooth" });
  };
  updateTotalPrice = () => {
    const { form } = this.props;
    const { totalPurchasePrice: totalPurchasePriceFromState = 0 } = this.state;
    const {
      totalPurchasePriceExTax: totalPurchasePriceExTaxFromState = 0,
    } = this.state;

    if (!form) {
      return;
    }
    const totalPurchasePrice = form.getFieldValue("totalPurchasePrice") || 0;
    const totalPurchasePriceExTax =
      form.getFieldValue("totalPurchasePriceExTax") || 0;

    if (totalPurchasePriceFromState !== totalPurchasePrice) {
      this.setState({ totalPurchasePrice: totalPurchasePrice });
    }
    if (totalPurchasePriceExTaxFromState !== totalPurchasePriceExTax) {
      this.setState({ totalPurchasePriceExTax: totalPurchasePriceExTax });
    }
  };
  onCalculateUnitPurchasePrice = (values = {}) => {
    const { index = 0 } = values;
    this.setState({
      currentIndex: index,
      unitPriceCalculatorModalVisible: true,
    });
  };
  onCalculateUnitPurchasePriceSubmit = (values = {}) => {
    const {
      unitsPerPack = 1,
      packPurchasePriceExTax = 0,
      packPurchaseQuantity = 1,
      index,
    } = values;
    const unitQuantity = toFloat(unitsPerPack * packPurchaseQuantity);
    const pricePerUnitExTax = toFloat(
      packPurchasePriceExTax / (unitsPerPack == 0 ? 1 : unitsPerPack)
    );

    const { form } = this.props;
    this.setState({
      unitPriceCalculatorModalVisible: false,
    });
    CalculatePrices({
      index: index,
      form: form,
      field: "quantity",
      value: unitQuantity,
    });
    CalculatePrices({
      index: index,
      form: form,
      field: "purchasePriceExTax",
      value: pricePerUnitExTax,
    });

    this.updateTotalPrice();
  };
  render() {
    const {
      form,
      initialValues,
      purchaseProducts,
      onProductRemove,
    } = this.props;
    const {
      totalPurchasePrice = 0,
      totalPurchasePriceExTax = 0,
      currentIndex = 0,
      unitPriceCalculatorModalVisible = false,
    } = this.state;

    if (!purchaseProducts) {
      return <></>;
    }
    return (
      <>
        <PurchaseProductsHeader />
        <Divider />
        <div className="purchaseProducts-container">
          <PurchaseProductsLines
            form={form}
            initialValues={initialValues}
            purchaseProducts={purchaseProducts}
            updateTotalPrice={this.updateTotalPrice}
            onProductRemove={onProductRemove}
            onCalculateUnitPurchasePrice={this.onCalculateUnitPurchasePrice}
          />
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.productsEnd = el;
            }}
          />
        </div>

        <PurchaseProductsFooter
          totalPurchasePrice={totalPurchasePrice}
          totalPurchasePriceExTax={totalPurchasePriceExTax}
          style={{ marginTop: "2px" }}
          form={form}
          updateTotalPrice={this.updateTotalPrice}
        />
        <PurchaseProductPriceCalculatorModal
          visible={unitPriceCalculatorModalVisible}
          onCancel={() =>
            this.setState({ unitPriceCalculatorModalVisible: false })
          }
          onCalculateUnitPurchasePriceSubmit={
            this.onCalculateUnitPurchasePriceSubmit
          }
          index={currentIndex}
        />
      </>
    );
  }
}

export default PurchaseProducts;
