import React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";

import Select from "~/components/Common/Select";
import SupplierModal from "~/pages/Suppliers/Supplier/SupplierModal";

class SupplierSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suppliers: props.suppliers || [],
      supplierModalVisible: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { newSupplier, suppliers: newSuppliers = [] } = nextProps;
    let { suppliers = [] } = prevState;

    if (
      newSupplier &&
      !suppliers.some((x) => x.supplierId === newSupplier.supplierId)
    ) {
      suppliers.push(newSupplier);

      return {
        suppliers: orderBy(suppliers, ["name"], ["asc"]),
        supplierModalVisible: false,
      };
    }

    if (newSuppliers.length > suppliers.length) {
      return { suppliers: orderBy(newSuppliers, ["name"], ["asc"]) };
    }

    return null;
  }
  render() {
    const { suppliers = [] } = this.state;

    const { placeholder, onChange, value, allowAddNewItem = true } = this.props;

    return (
      <>
        <Select
          onClick={(e) => {
            e.stopPropagation();
          }}
          allowClear={true}
          showSearch
          placeholder={placeholder}
          onChange={onChange}
          optionFilterProp="children"
          newItemOptions={{
            allowAddNewItem: allowAddNewItem,
            title: "Add new supplier",
            onClick: () => {
              this.setState({ supplierModalVisible: true });
            },
          }}
          data={suppliers.map((supplier) => {
            return { value: supplier.supplierId, name: supplier.name };
          })}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={value}
        />
        <SupplierModal
          visible={this.state.supplierModalVisible}
          onCancel={() => this.setState({ supplierModalVisible: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  newSupplier:
    state.general &&
    state.general.lastAction &&
    state.general.lastAction.entity === "supplier" &&
    state.general.lastAction.type === "save" &&
    state.general.lastAction.isSuccess === true &&
    state.general.lastAction.payload,
});

export default connect(mapStateToProps, {})(withNamespaces()(SupplierSelect));
