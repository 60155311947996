import React from "react";
import "./index.scss";
// import { taxes } from "../../../_backend/samples/taxes";
import {
  toFloat,
  generateSaleCode,
  getCurrentDate,
  mapToArray,
  getCurrentDateUtcIso,
  localStorageSetItem,
  localStorageGetItem,
} from "~/_utils";
import { sellBucketStorageName, constructUrl } from "~/config";

import Products from "./Products";
import PayTotal from "./PayTotal";
import ActionButtons from "./ActionButtons";
import SearchCustomer from "./SearchCustomer";

import { Row, Col, Divider } from "antd";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spin from "~/components/Common/Spin";
import { pay } from "~/actions/sellActions";
import { getAllSalesTaxesFromLocalDb } from "~/_offline/offlineProducts";
import Prompt from "~/components/Common/Prompt";

let taxes = [];

function getTotalPrice(products = []) {
  const productTotalPrices = products.map((p) => {
    return toFloat(p.price * (p.quantity !== undefined ? p.quantity : 1));
  });

  return productTotalPrices.reduce((a, b) => a + b, 0);
}
function getTotalItems(products = []) {
  return products
    .map((p) => (p.quantity !== undefined ? p.quantity : 1))
    .reduce((a, b) => a + b, 0);
}

function calculateProductTaxAmount(
  price,
  quantity = 1,
  taxId,
  additionalDiscountPercent = 0,
  isPriceIncludingTax = true
) {
  const associatedTax = taxes.filter((tax) => tax.taxId === taxId)[0] || {};
  const taxPercentForProduct = associatedTax.percentage || 0;
  //TODO: price is inclusive of TAX, so remove tax
  const productTotalPrice = price * quantity;
  const totalTaxIncludedInProductAmount =
    isPriceIncludingTax === true
      ? productTotalPrice - productTotalPrice / (1 + taxPercentForProduct / 100)
      : 0;
  const productTotalPriceExTax =
    productTotalPrice - totalTaxIncludedInProductAmount;
  const additionalDiscountAmount =
    (productTotalPriceExTax * additionalDiscountPercent) / 100;
  const productTaxablePrice = productTotalPriceExTax - additionalDiscountAmount;

  const updatedTaxOnProduct =
    (productTaxablePrice * taxPercentForProduct) / 100;

  return toFloat(updatedTaxOnProduct);
}

function calculateTotalTaxAmount(products = []) {
  const totalTaxAmount = products
    .map((product) => (product.taxAmount ? product.taxAmount : 0))
    .reduce((a, b) => a + b, 0);

  return toFloat(totalTaxAmount);
}

function groupTaxes(products = []) {
  // use map to avoid any duplicates
  let groupedTaxesMap = new Map();
  products.forEach((product) => {
    if (!product.taxId) {
      return;
    }
    let groupedTaxValue = groupedTaxesMap.get(product.taxId)
      ? groupedTaxesMap.get(product.taxId)
      : 0;
    groupedTaxValue += product.taxAmount;

    groupedTaxesMap.set(product.taxId, groupedTaxValue);
  });

  //convert Map to arrat as Redux don't support Map
  let groupedTaxes = mapToArray(groupedTaxesMap);

  return groupedTaxes;
}

function getUpdatedProducts(products, updatedProduct, updatedProductIndex) {
  return Object.assign([...products], {
    [updatedProductIndex]: updatedProduct,
  });
}
function getStateObjectForProductChange(
  updatedProducts = [],
  additionalDiscountPercent = 0
) {
  const subTotal = getTotalPrice(updatedProducts);
  const subTotalWithAdditionalDiscount = toFloat(
    subTotal - (subTotal * additionalDiscountPercent) / 100
  );
  const totalItems = getTotalItems(updatedProducts);
  const totalTaxAmount = calculateTotalTaxAmount(updatedProducts);

  return {
    subTotal: subTotalWithAdditionalDiscount,
    originalSubTotal: subTotal,
    totalItems: totalItems,
    products: updatedProducts,
    totalTaxAmount: totalTaxAmount,
  };
}
function getInitialState(sellBucket, currentState = {}) {
  let cleanState = {};
  Object.keys(currentState).forEach((x) => (cleanState[x] = undefined));

  return {
    ...cleanState,
    saleCode: "",
    customer: {},
    subTotal: sellBucket.subTotal ? sellBucket.subTotal : 0,
    products: sellBucket.products ? sellBucket.products : [],
    additionalDiscountPercent: sellBucket.additionalDiscountPercent
      ? sellBucket.additionalDiscountPercent
      : 0,
    originalSubTotal: sellBucket.originalSubTotal
      ? sellBucket.originalSubTotal
      : 0,
    totalItems: sellBucket.products ? getTotalItems(sellBucket.products) : 0,
    totalTaxAmount: sellBucket.totalTaxAmount ? sellBucket.totalTaxAmount : 0,
    payments: sellBucket.payments,
    amountPaid: sellBucket.amountPaid,
    change: sellBucket.change,
    notes: sellBucket.notes || "",
  };
}

class Bucket extends React.Component {
  constructor(props) {
    super(props);
    const { sellBucket } = props;

    this.state = { ...getInitialState(sellBucket), loading: true };
    this.handlePriceChange = this.handlePriceChange.bind();
    this.handleQuantityChange = this.handleQuantityChange.bind();
    this.updateStateForProductChange = this.updateStateForProductChange.bind();
    this.updateStateForSubTotalChange = this.updateStateForSubTotalChange.bind();
    this.handleDiscountChange = this.handleDiscountChange.bind();
    this.handleSubTotalChange = this.handleSubTotalChange.bind();
    this.handleAdditionalDiscountChange = this.handleAdditionalDiscountChange.bind();
    this.handleProductDelete = this.handleProductDelete.bind();
    this.scrollProductsToBottom = this.scrollProductsToBottom.bind();
    this.discardSale = this.discardSale.bind();
    this.parkSale = this.parkSale.bind();
    this.retrieveSale = this.retrieveSale.bind();
    this.onCustomerSelect = this.onCustomerSelect.bind();
    this.onPay = this.onPay.bind();
    this.getTotalAmountToPay = this.getTotalAmountToPay.bind();
    this.onNotesChange = this.onNotesChange.bind();
    this.parkSaleToStore = this.parkSaleToStore.bind();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      selectedProduct,
      sellBucket = {},
      flexSearchCustomer,
      triggerPay = false,
    } = nextProps;
    const { products, additionalDiscountPercent, saleCode } = prevState;
    if (triggerPay === true && triggerPay !== prevState.triggerPay) {
      return { triggerPay: triggerPay };
    }
    if (selectedProduct && selectedProduct.productId) {
      selectedProduct.originalPrice = selectedProduct.price;
      selectedProduct.quantity = selectedProduct.quantity
        ? selectedProduct.quantity
        : 1;
      selectedProduct.taxAmount = calculateProductTaxAmount(
        selectedProduct.price,
        selectedProduct.quantity,
        selectedProduct.taxId,
        additionalDiscountPercent,
        selectedProduct.isPriceIncludingTax
      );

      let updatedProducts = products ? products : [];
      updatedProducts.push(selectedProduct);
      return {
        triggerPay: false,
        ...getStateObjectForProductChange(
          updatedProducts,
          additionalDiscountPercent
        ),
      };
    }

    if (sellBucket.saleCode && saleCode !== sellBucket.saleCode) {
      return { ...sellBucket, flexSearchCustomer: flexSearchCustomer };
    }

    return { flexSearchCustomer: flexSearchCustomer };
  }
  componentDidMount() {
    const { pay } = this.props;
    const { products = [] } = this.props;
    const _this = this;

    //clear current pending sale from store
    pay({}); // once loaded / received pending sale from app store then remove pending sale from the application store

    getAllSalesTaxesFromLocalDb()
      .then(function(result) {
        taxes = result;
        _this.setState({ loading: false, taxes: taxes });
      })
      .catch(function(err) {
        console.log(err);
        taxes = [];
        _this.setState({ loading: false, taxes: taxes });
      });
  }
  componentDidUpdate(prevProps, prevState) {
    const { onBucketUpdate } = this.props;
    const { triggerPay = false } = this.state;
    const { loading } = prevState;

    if (loading === true) {
      return;
    }

    if (triggerPay === true && triggerPay !== prevState.triggerPay) {
      this.onPay();
      return;
    }

    onBucketUpdate();
    const productsCountBefore = prevState.totalItems ? prevState.totalItems : 0;
    const productsCountNow = this.state.totalItems ? this.state.totalItems : 0;
    if (productsCountNow > productsCountBefore) {
      this.scrollProductsToBottom();
    }
  }
  parkSaleToStore = () => {
    const { pay } = this.props;
    const payload = {
      ...this.state,
      date: getCurrentDate(),
    };

    return pay(payload);
  };
  componentWillUnmount() {
    const { products = [] } = this.state;
    const { history = {} } = this.props;
    const location = history.location || {};
    //If moving away from sell screen and its not a Payment screen then park the sale
    if (
      products.length > 0 &&
      location.pathname &&
      location.pathname.toLowerCase().indexOf("sell/pay") === -1
    ) {
      this.parkSaleToStore();
    }
  }

  updateStateForProductChange = (updatedProducts) => {
    const { additionalDiscountPercent } = this.state;
    this.setState({
      ...getStateObjectForProductChange(
        updatedProducts,
        additionalDiscountPercent
      ),
    });
  };
  updateStateForSubTotalChange = (
    updatedSubTotal,
    additionalDiscountPercent
  ) => {
    const updatedProducts = Object.assign([...this.state.products]);

    updatedProducts.forEach((product) => {
      product.taxAmount = calculateProductTaxAmount(
        product.price,
        product.quantity,
        product.taxId,
        additionalDiscountPercent,
        product.isPriceIncludingTax
      );
    });

    this.setState({
      products: updatedProducts,
      subTotal: updatedSubTotal,
      additionalDiscountPercent: additionalDiscountPercent,
      totalTaxAmount: calculateTotalTaxAmount(updatedProducts),
    });
  };
  handlePriceChange = (value, index) => {
    const price = toFloat(value);

    const originalPrice = this.state.products[index].originalPrice;
    const discountPercent = ((originalPrice - price) / originalPrice) * 100;
    const product = this.state.products[index];

    let updatedProduct = {
      ...product,
      price: price,
      discountPercent: toFloat(discountPercent),
      taxAmount: calculateProductTaxAmount(
        price,
        product.quantity,
        product.taxId,
        this.state.additionalDiscountPercent,
        product.isPriceIncludingTax
      ),
    };
    const updatedProducts = getUpdatedProducts(
      this.state.products,
      updatedProduct,
      index
    );
    this.updateStateForProductChange(updatedProducts);
  };
  handleQuantityChange = (value, index) => {
    const quantity = toFloat(value);
    const product = this.state.products[index];
    let updatedProduct = {
      ...product,
      quantity: quantity,
      taxAmount: calculateProductTaxAmount(
        product.price,
        quantity,
        product.taxId,
        this.state.additionalDiscountPercent,
        product.isPriceIncludingTax
      ),
    };

    const updatedProducts = getUpdatedProducts(
      this.state.products,
      updatedProduct,
      index
    );
    this.updateStateForProductChange(updatedProducts);
  };
  handleDiscountChange = (value, index) => {
    const discountPercent = toFloat(value);
    const originalPrice = this.state.products[index].originalPrice;
    const price = originalPrice - (originalPrice * discountPercent) / 100;
    const product = this.state.products[index];
    let updatedProduct = {
      ...product,
      price: toFloat(price),
      discountPercent: discountPercent,
      taxAmount: calculateProductTaxAmount(
        price,
        product.quantity,
        product.taxId,
        this.state.additionalDiscountPercent,
        product.isPriceIncludingTax
      ),
    };
    const updatedProducts = getUpdatedProducts(
      this.state.products,
      updatedProduct,
      index
    );
    this.updateStateForProductChange(updatedProducts);
  };
  handleProductDelete = (index) => {
    const updatedProducts = Object.assign([...this.state.products]);
    updatedProducts.splice(index, 1);
    this.updateStateForProductChange(updatedProducts);
  };
  handleSubTotalChange = (value) => {
    const subTotal = toFloat(value);

    const originalSubTotal = toFloat(this.state.originalSubTotal);
    const additionalDiscountPercent = toFloat(
      ((originalSubTotal - subTotal) / originalSubTotal) * 100
    );

    this.updateStateForSubTotalChange(subTotal, additionalDiscountPercent);
  };
  handleAdditionalDiscountChange = (value) => {
    const additionalDiscountPercent = toFloat(value);
    const originalSubTotal = toFloat(this.state.originalSubTotal);
    const subTotal = toFloat(
      originalSubTotal - (originalSubTotal * additionalDiscountPercent) / 100
    );

    this.updateStateForSubTotalChange(subTotal, additionalDiscountPercent);
  };
  onCustomerSelect = (value) => {
    const { onCustomerSelect } = this.props;
    this.setState({ customer: value });
    onCustomerSelect && onCustomerSelect(value);
  };
  onNotesChange = (value) => {
    this.setState({ notes: value });
  };
  scrollProductsToBottom = () => {
    this.productsEnd && this.productsEnd.scrollIntoView({ behavior: "smooth" });
  };
  discardSale = () => {
    const _this = this;

    this.setState({
      ...getInitialState({}, _this.state),
    });
  };
  parkSale = (options = {}) => {
    if (this.state.products.length === 0) {
      return;
    }
    const { note = "", isAutoParked = false } = options;
    const saleCode = this.state.saleCode || generateSaleCode();
    const date = getCurrentDate();

    const currentSale = { ...this.state, saleCode, date, note, isAutoParked };

    const sellBucket = localStorageGetItem(sellBucketStorageName);
    const sellBucketDeserialized = !sellBucket ? [] : JSON.parse(sellBucket);

    sellBucketDeserialized.push(currentSale);
    localStorageSetItem(
      sellBucketStorageName,
      JSON.stringify(sellBucketDeserialized)
    );

    this.discardSale();
  };
  retrieveSale = (saleCode) => {
    this.parkSale();
    const sellBucketStorage = localStorageGetItem(sellBucketStorageName);
    const sellBucketJson = sellBucketStorage && JSON.parse(sellBucketStorage);

    let sellBucket;
    if (saleCode) {
      sellBucket = sellBucketJson && {
        ...sellBucketJson.find((x) => x.saleCode === saleCode),
      };
    } else {
      sellBucket = sellBucketJson && {
        ...sellBucketJson.find((x) => x.isAutoParked === true),
      };
    }

    if (!sellBucket || Object.keys(sellBucket).length === 0) {
      return;
    }

    this.setState({ ...sellBucket });

    //remove currently retrieved sale from local storage
    localStorageSetItem(
      sellBucketStorageName,
      JSON.stringify(
        sellBucketJson.filter((x) => x.saleCode !== sellBucket.saleCode)
      )
    );
  };
  onPay = (e) => {
    e && e.preventDefault();

    const {
      saleId,
      customer,
      subTotal,
      additionalDiscountPercent,
      originalSubTotal,
      totalItems,
      totalTaxAmount,
      products,
      payments = [],
      amountPaid = 0,
      date = getCurrentDate(),
      saleDateTimeUtc = getCurrentDateUtcIso(),
      notes,
    } = this.state; //paytments & amountPaid can be populated from Pay & history screen
    const { pay, history, match, isPriceIncludingTax = true } = this.props;

    const groupedTaxes = groupTaxes(this.state.products);
    const saleCode = this.state.saleCode || generateSaleCode();
    const totalAmount = this.getTotalAmountToPay();

    const payload = {
      saleId,
      customer,
      subTotal,
      additionalDiscountPercent,
      originalSubTotal,
      totalItems,
      totalTaxAmount,
      products,
      saleCode,
      taxes: taxes,
      groupedTaxes: groupedTaxes,
      totalAmount,
      payments,
      amountPaid,
      isPriceIncludingTax: isPriceIncludingTax,
      isSubTotalAmountIncTax: isPriceIncludingTax,
      date: date,
      saleDateTimeUtc: saleDateTimeUtc,
      notes: notes,
    };

    pay(payload).then(function(result) {
      history.push(constructUrl({ match, to: "/Pay", isNested: true })); //history is injected from hoc withRouter
    });
  };
  getTotalAmountToPay = () => {
    const { subTotal, totalTaxAmount } = this.state;
    const { isPriceIncludingTax } = this.props;

    const totalAmountToPay =
      isPriceIncludingTax === true
        ? toFloat(subTotal)
        : toFloat(subTotal + totalTaxAmount);

    return totalAmountToPay;
  };
  render() {
    const { products = [] } = this.state;
    const { flexSearchCustomer, customer, loading = false } = this.state;
    const { isPriceIncludingTax = true } = this.props;

    const {
      history = {},
      expand = false,
      setCustomerRef,
      expandSubTotal = false,
    } = this.props;
    const location = history.location || {};

    if (loading === true) {
      return (
        <Spin spinning={this.state.loading}>
          <Row>
            <Col
              xs={24}
              style={{
                textAlign: "center",
                minHeight: "200px",
                paddingTop: "150px",
                fontWeight: "bold",
              }}
            />
          </Row>
        </Spin>
      );
    }

    return (
      <div>
        <Prompt when={products.length > 0} onlyPromptOnWindowUnload={true} />
        <ActionButtons
          discardSale={this.discardSale}
          isSaleInProgress={products && products.length > 0}
          parkSale={this.parkSale}
          retrieveSale={this.retrieveSale}
        />
        <Divider />
        <SearchCustomer
          flexSearch={flexSearchCustomer}
          defaultSelectedCustomer={customer}
          onSelect={this.onCustomerSelect}
          setRef={(el) => setCustomerRef && setCustomerRef(el)}
        />
        <Divider />
        <div className="sell-bucket-form">
          <Row type="flex" justify="space-between" align="top" className="top">
            <Col xs={24}>
              <Products
                expand={expand}
                products={this.state.products}
                onProductDelete={this.handleProductDelete}
                onPriceChange={this.handlePriceChange}
                onQuantityChange={this.handleQuantityChange}
                onDiscountChange={this.handleDiscountChange}
              />
              <div
                style={{ float: "left", clear: "both" }}
                ref={(el) => {
                  this.productsEnd = el;
                }}
              />
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="bottom"
            className="bottom"
          >
            <Col xs={24}>
              <PayTotal
                notes={this.state.notes}
                numberofProducts={products.length}
                originalSubTotal={this.state.originalSubTotal}
                subTotal={this.state.subTotal}
                toPay={this.getTotalAmountToPay()}
                totalItems={this.state.totalItems}
                totalTaxAmount={this.state.totalTaxAmount}
                groupedTaxes={groupTaxes(this.state.products)}
                taxes={this.state.taxes}
                additionalDiscountPercent={this.state.additionalDiscountPercent}
                onSubTotalChange={this.handleSubTotalChange}
                onAdditionalDiscountChange={this.handleAdditionalDiscountChange}
                onPay={this.onPay}
                onNotesChange={this.onNotesChange}
                expandKey={expandSubTotal === true ? "subTotal" : ""}
                isPriceIncludingTax={isPriceIncludingTax}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
Bucket.defaultProps = {
  sellBucket: {},
};

const mapStateToProps = (state) => ({
  flexSearchCustomer: state.offline.flexSearchCustomer,
});

export default connect(mapStateToProps, { pay })(
  withRouter(withNamespaces()(Bucket))
);
