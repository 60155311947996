import React from "react";
import { Modal, Typography } from "antd";
import PurchaseProductPriceCalculator from "~/pages/Purchases/Purchase/PurchaseProductPriceCalculator";

const { Text } = Typography;
const PurchaseProductPriceCalculatorModal = (props) => {
  const {
    index,
    onCalculateUnitPurchasePriceSubmit,
    visible,
    ...restProps
  } = props;
  return (
    <Modal
      title={"Unit price & quantity calculator"}
      centered
      footer={null}
      destroyOnClose={true}
      visible={visible === true}
      {...restProps}
    >
      <>
        <div style={{ paddingBottom: "20px" }}>
          <Text>
            {
              "This tool assists in determining the cost and quantity per individual item when a product is bought in packs but sold individually."
            }
          </Text>
        </div>
        <PurchaseProductPriceCalculator
          index={index}
          onCalculateUnitPurchasePriceSubmit={
            onCalculateUnitPurchasePriceSubmit
          }
          showTitle={false}
        />
      </>
    </Modal>
  );
};

export default PurchaseProductPriceCalculatorModal;
