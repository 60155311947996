import { translateFieldValidation, translate } from "~/_utils";
import { FIELD_VALIDATION_TYPE, TRANSLATION_NAMESPACE } from "~/_utils/consts";

let viewModel = {
  title: {
    props: {
      placeholder: translate("Title", {
        namespace: TRANSLATION_NAMESPACE.Common,
      }),
    },
  },
  header: {
    props: {
      placeholder: translate("Header", {
        namespace: TRANSLATION_NAMESPACE.Common,
      }),
    },
  },
  footer: {
    props: {
      placeholder: translate("Footer", {
        namespace: TRANSLATION_NAMESPACE.Common,
      }),
    },
  },
  autoPrintOnSaleComplete: {
    props: {
      placeholder: translate("AutoPrintOnSaleComplete", {
        namespace: TRANSLATION_NAMESPACE.Setup,
      }),
    },
  },
  printCustomerName: {
    props: {
      placeholder: translate("PrintCustomerName", {
        namespace: TRANSLATION_NAMESPACE.Setup,
      }),
    },
  },
  printNotes: {
    props: {
      placeholder: translate("PrintNotes", {
        namespace: TRANSLATION_NAMESPACE.Setup,
      }),
    },
  },
};

export default viewModel;
