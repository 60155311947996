import React from "react";
import { constructUrl } from "~/config";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Divider, Typography } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { PageTitle } from "~/components/Common/Text";
import Button from "~/components/Common/Button";
import Table from "~/components/Common/Table";
import { getReceivables } from "~/actions/reportActions";
import PriceLabel from "~/components/Common/PriceLabel";
import { ColumnTextSearch } from "~/components/Common/Table/TableColumnFilter";
import "../index.scss";

const { Text } = Typography;
const Footer = (options = {}) => {
  const { totalReceivable = 0 } = options;
  return (
    <Row
      className="report-footer-container"
      gutter={[8, 0]}
      style={{
        maxWidth: "370px",
      }}
    >
      <Col xs={16}>
        <Text className="primaryTitle">{"Total Receivable: "}</Text>
      </Col>
      <Col xs={8}>
        <PriceLabel className="primaryText" value={totalReceivable} />
      </Col>
    </Row>
  );
};

class Receivables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
    };

    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...ColumnTextSearch(["name"]),
      },
      {
        title: "Receivable",
        dataIndex: "receivable",
        key: "receivable",
        sorter: (a, b) => a.receivable - b.receivable,
      },
      {
        title: "",
        dataIndex: "",
        key: "actions",
        // fixed: "right",
        width: 45,
        render: (text, record) => (
          <Row>
            <Col xs={24}>
              <Button
                type="link"
                icon={<FileTextOutlined />}
                onClick={() =>
                  this.onDetail({
                    accountId: record.accountId,
                    accountCode: record.accountCode,
                  })
                }
              />
            </Col>
          </Row>
        ),
      },
    ];

    this.handleFetch = this.handleFetch.bind();
    this.onDetail = this.onDetail.bind();
  }
  componentDidMount() {
    this.handleFetch();
  }
  handleFetch = () => {
    const _this = this;
    this.setState({ loading: true });
    let { sort = {} } = this.state;

    getReceivables({ sort })
      .then(function(result = {}) {
        const { data = [] } = result;
        const totalReceivable = data[0].totalReceivable
          ? data[0].totalReceivable
          : 0;
        _this.setState({
          loading: false,
          data: { totalReceivable: totalReceivable, receivables: [...data] },
        });
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });
        console.log(err);
      });
  };

  onDetail = (options = {}) => {
    const { history, match } = this.props;
    const { accountId = "", accountCode = "" } = options;
    console.log("accountCode: ", accountCode);

    history.push(
      constructUrl({
        match,
        to: `/reports/receivablesDetails?accountId=${accountId}&accountCode=${accountCode}`,
        isNested: false,
      })
    );
  };

  render() {
    const columns = this.columns;
    const { loading = false, data = {} } = this.state;
    const { totalReceivable, receivables = [] } = data;
    return (
      <div className="report-container">
        <Row>
          <Col xs={24}>
            <PageTitle>Receivables Report</PageTitle>
            <Divider />
            <br />
          </Col>
          <Col xs={24}>
            <Table
              style={{ marginBottom: -30 }}
              allowCsvExport={true}
              clientSidePaging={true}
              loading={loading}
              columns={columns}
              dataSource={receivables}
              rowKey="accountId"
              footer={<Footer totalReceivable={totalReceivable} />}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withNamespaces()(Receivables)));
