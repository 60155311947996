import React from "react";
import Card from "./Card";
import { getTodaySales } from "~/actions/dashboardActions";
import { LineChartOutlined } from "@ant-design/icons";
import Spin from "~/components/Common/Spin";
import { translate, formatInSqlDateTime, price } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";
import { constructUrl } from "~/config";

class SaleCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, todaySale: {} };
    this.onClick = this.onClick.bind();
  }
  componentDidMount() {
    const _this = this;
    getTodaySales()
      .then(function(result = {}) {
        _this.setState({
          loading: false,
          todaySale: result.data,
        });
      })
      .catch(function(err) {
        _this.setState({ loading: false });
        console.log(err);
      });
  }
  onClick = () => {
    const { history, match } = this.props;

    const sellFrom = new Date();
    const sellTo = new Date();
    sellTo.setDate(sellTo.getDate() + 1);

    history.push(
      constructUrl({
        match,
        to: `/reports/saledetails`,
      })
    );
  };
  render() {
    const { todaySale = {}, loading = true } = this.state;
    const { totalSaleAmount = 0, totalCash = 0 } = todaySale;
    const title = `${translate("TodaySale", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })}`;

    return (
      <Card
        onClick={this.onClick}
        icon={<LineChartOutlined />}
        iconStyle={{ color: "#3f87f5", backgroundColor: "#3f87f51a" }}
        title={title}
        footer={`${translate("Cash", {
          namespace: TRANSLATION_NAMESPACE.Report,
        })} ${price(totalCash)} | ${translate("Other", {
          namespace: TRANSLATION_NAMESPACE.Report,
        })} ${price(totalSaleAmount - totalCash)}`}
      >
        <Spin spinning={loading}>
          <h1 className="font-weight-bold">{price(totalSaleAmount)}</h1>
        </Spin>
      </Card>
    );
  }
}

export default SaleCard;
