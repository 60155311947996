import React from "react";
// import Particles from "react-particles-js";
import "./index.scss";

const ConnectedParticles = () => {
  return (
    <div className="particles-container">
      {/* <Particles
        params={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true
              }
            },
            size: {
              value: 3
            },
            color: {
              value: "#3c3a3a"
            },
            line_linked: {
              enable: true,
              color: "#3c3a3a",
              opacity: 0.2,
              distance: 150
            },
            opacity: {
              anim: {
                enable: true,
                speed: 1,
                sync: false
              },
              value: 0.2
            }
          },
          interactivity: {
            events: {
              onhover: {
                enable: false,
                mode: "repulse"
              }
            }
          }
        }}
      /> */}
    </div>
  );
};

export default ConnectedParticles;
