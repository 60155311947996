import React from "react";
import { TagOutlined } from "@ant-design/icons";
import { Row, Col, Input, Form, Divider, Button } from "antd";
import queryString from "query-string";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveAttribute, getAttribute } from "~/actions/productActions";

import { PageTitle } from "~/components/Common/Text";
import FormItem from "~/components/Form/FormItem";

import { propName } from "~/_utils";
import viewModel from "./viewModel";

const { TextArea } = Input;

const GetInitialValues = (attribute = {}) => {
  const initialValues = {
    attributeId: attribute.attributeId,
    name: attribute.name,
  };

  return initialValues;
};

class Attribute extends React.Component {
  constructor(props) {
    super(props);

    let { location = {}, attribute } = this.props;

    this.state = { attribute: attribute, formRef: null };

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.getAttribute = this.getAttribute.bind();
    this.setFormRef = this.setFormRef.bind();
  }

  getAttribute = (id) => {
    const _this = this;

    getAttribute({ id: id })
      .then(function(result) {
        _this.setState(
          {
            attribute: result.data,
            isEditMode: true, //getAttribute is only called in edit mode
          },
          _this.state.formRef.setFieldsValue(result.data)
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  componentDidMount() {
    const { location, attributeId: attributeIdFromProps } = this.props;

    const queryParams = queryString.parse(location.search);
    const attributeId = queryParams.attributeId || attributeIdFromProps;

    attributeId && this.getAttribute(attributeId);
  }

  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });

    const { saveAttribute, onSave } = this.props;
    const { formRef, isEditMode } = this.state;
    const _this = this;

    saveAttribute(values)
      .then(function(result) {
        onSave && onSave(result);

        _this.setState({
          loading: false,
        });

        if (isEditMode === true) {
          formRef.setFieldsValue({
            attributeId: result.attributeId,
          });
          _this.setState({
            attribute: result,
          });
        } else {
          _this.setState(
            {
              attribute: {},
            },
            formRef.resetFields
          );
        }
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const { showTitle } = this.props;
    const { formRef, loading = false, attribute = {} } = this.state;
    const initialValues = GetInitialValues(attribute);

    return (
      <Row gutter={[16, 8]}>
        <Col xs={24}>
          {showTitle === true && (
            <>
              <PageTitle>Attribute</PageTitle>
              <Divider />
              <br />
            </>
          )}
          <Col xs={24}>
            <Form
              validateTrigger="onBlur"
              onFinish={this.handleSubmit}
              onFinishFailed={this.onFinishFailed}
              className="attribute-form"
              initialValues={initialValues}
              ref={this.setFormRef}
            >
              <Row gutter={[16, 8]}>
                <Col xs={24}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.name)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.name)]
                    }
                  >
                    {({ options }) => (
                      <Input prefix={<TagOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="save-button"
                      loading={loading}
                    >
                      <Trans i18nKey="Save">Save</Trans>
                    </Button>
                  </Form.Item>
                </Col>

                <FormItem
                  form={formRef}
                  propertyName={propName(initialValues, (o) => o.attributeId)}
                  viewModelKeyValue={propName(
                    initialValues,
                    (o) => o.attributeId
                  )}
                >
                  {(options) => <Input type="hidden" {...options} />}
                </FormItem>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveAttribute })(
  withRouter(withNamespaces()(Attribute))
);
