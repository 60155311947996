import {
  RECORD_SAVE_SUCCESS,
  RECORD_SAVE_FAILED,
  RECORD_DELETE_SUCCESS,
  RECORD_DELETE_FAILED,
  GENERAL_ERROR,
  GENERAL_SUCCESS,
  GENERAL_DIALOG,
  VERSION_UPDATE,
} from "./types";
import {
  generateCode,
  localStorageSetItem,
  localStorageGetItem,
} from "~/_utils";
import { LOCALSTORAGE } from "~/_utils/consts";
import { version as localVersion } from "~/config";

export const generalError = (entity, payload = {}) => (dispatch) => {
  dispatch({
    type: GENERAL_ERROR,
    payload: {
      actionId: generateCode(),
      entity: entity,
      payload: { ...payload },
    },
  });
};

export const generalSuccess = (entity, payload = {}) => (dispatch) => {
  dispatch({
    type: GENERAL_SUCCESS,
    payload: {
      actionId: generateCode(),
      entity: entity,
      payload: { ...payload },
    },
  });
};

export const showDialog = (payload = {}) => (dispatch) => {
  dispatch({
    type: GENERAL_DIALOG,
    payload: { ...payload, show: true, dialogId: generateCode() },
  });
};

export const hideDialog = (dialogId, payload = {}) => (dispatch) => {
  dispatch({
    type: GENERAL_DIALOG,
    payload: { ...payload, show: false, dialogId: dialogId },
  });
};

export const recordSavedSuccess = (entity, payload = {}) => (dispatch) => {
  dispatch({
    type: RECORD_SAVE_SUCCESS, //after successful API call dispatch save success event
    payload: {
      actionId: generateCode(),
      entity: entity,
      payload: { ...payload },
    },
  });
};

export const recordSavedFailed = (entity, payload = {}) => (dispatch) => {
  dispatch({
    type: RECORD_SAVE_FAILED,
    payload: {
      actionId: generateCode(),
      entity: entity,
      payload: { ...payload },
    },
  });
};

export const recordDeleteSuccess = (entity, payload = {}) => (dispatch) => {
  dispatch({
    type: RECORD_DELETE_SUCCESS,
    payload: {
      actionId: generateCode(),
      entity: entity,
      payload: { ...payload },
    },
  });
};

export const recordDeleteFailed = (entity, payload = {}) => (dispatch) => {
  dispatch({
    type: RECORD_DELETE_FAILED,
    payload: {
      actionId: generateCode(),
      entity: entity,
      payload: { ...payload },
    },
  });
};

export const getNotifications = () => (dispatch) => {
  const timestamp = Date.now(); // Generate a unique timestamp
  const notifictionFullPath = `/notifications.json?${timestamp}`; // timestamp is used as cache-busting to avoid cahed response

  return new Promise(function(resolve, reject) {
    fetch(notifictionFullPath)
      .then((response) => {
        console.log("response", response);
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error: " + response.status);
        }
      })
      .then((data = {}) => {
        dispatch({
          type: VERSION_UPDATE,
          payload: {
            version: data.version,
          },
        });

        return resolve(mergeNotificationsInLocalDb(data));
      })
      .catch((err) => {
        // Handle any errors
        return reject(err);
      });
  });
};

const mergeNotificationsInLocalDb = (notifications = {}) => {
  const localDbNotifications = localStorageGetItem(LOCALSTORAGE.notifications);
  const existingNotifications =
    (localDbNotifications && JSON.parse(localDbNotifications)) || {};
  const mergedNotifications = { ...notifications };
  //Update releaseNotes
  mergedNotifications.releaseNotes &&
    mergedNotifications.releaseNotes.forEach((newNote) => {
      const existingNote =
        existingNotifications.releaseNotes &&
        existingNotifications.releaseNotes.find(
          (note) => note.epoch === newNote.epoch
        );
      if (existingNote) {
        newNote.isRead = existingNote.isRead;
      }
    });

  localStorageSetItem(
    LOCALSTORAGE.notifications,
    JSON.stringify(mergedNotifications)
  );
  return { ...mergedNotifications };
};
