import React from "react";
import { Spin as AntdSpin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Spin = (props) => {
  const { children, ...restProps } = props;
  return (
    <AntdSpin indicator={antIcon} {...restProps}>
      {children}
    </AntdSpin>
  );
};

export default Spin;
