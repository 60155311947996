import React from "react";
import { Row, Col, Typography, Button, Form } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import { removeItemFromIndex } from "~/_utils";
import Select from "~/components/Common/Select";
import FormItem, { FormItemTitle } from "~/components/Form/FormItem";

import EditableTagGroup from "~/components/Common/Tag/EditableTagGroup";
import VariantsDefinitions from "./VariantsDefinitions";
import orderBy from "lodash/orderBy";
import AttributeModal from "~/pages/Products/Attributes/Attribute/AttributeModal";

import { getAttributes } from "~/actions/productActions";

const { Title } = Typography;
const MAX_VARIANTS = 3;

const Attribute = (props) => {
  const {
    onAttributeChange,
    onAttributeValuesChange,
    onVariantDelete,
    showDeleteButton = true,
    index,
    form = {},
    attributes = [],
    onNewAttribute,
  } = props;

  const productAttributes =
    (form && form.getFieldValue("productAttributes")) || [];
  const selectedAttributeIds = productAttributes.map((x) => x && x.attributeId);

  const currentProductAttribute = productAttributes[index] || {};

  const filteredVariantAttributes = attributes.filter(
    (x) =>
      x.attributeId === currentProductAttribute.attributeId ||
      !selectedAttributeIds.includes(x.attributeId)
  );

  return (
    <>
      <Row gutter={[16, 8]} style={{ marginBottom: "20px" }}>
        <Col xs={24} md={8}>
          <FormItemTitle title="Attribute"> </FormItemTitle>
          <FormItem
            propertyName={[index, "attributeId"]}
            form={form}
            showFieldTitle={false}
          >
            {(options, setValue) => (
              <Select
                style={{ width: "100%" }}
                allowClear={true}
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                  onAttributeChange(value);
                }}
                newItemOptions={{
                  allowAddNewItem: true,
                  title: "Add new attribute",
                  onClick: () => {
                    onNewAttribute && onNewAttribute();
                  },
                }}
                data={filteredVariantAttributes.map((attribute) => {
                  return { value: attribute.attributeId, name: attribute.name };
                })}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              />
            )}
          </FormItem>
        </Col>
        <Col xs={22} md={14}>
          <FormItemTitle title="Values"> </FormItemTitle>

          <div style={{ marginTop: "4px" }}>
            <EditableTagGroup
              notAllowedValues={["/"]}
              tags={currentProductAttribute.values || []}
              newTagTitle={"Value"}
              onTagsChange={(tags) => {
                let productAttributes =
                  form.getFieldValue("productAttributes") || [];

                if (productAttributes[index]) {
                  form.setFieldsValue({
                    productAttributes: Object.assign([], productAttributes, {
                      [index]: { ...productAttributes[index], values: tags },
                    }),
                  });
                }

                onAttributeValuesChange();
              }}
            />
          </div>
        </Col>
        <Col xs={2}>
          {showDeleteButton && (
            <Button
              style={{ marginTop: 15 }}
              type="link"
              icon={<DeleteOutlined />}
              onClick={onVariantDelete}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

class Attributes extends React.Component {
  constructor(props) {
    super(props);
    const { initialValues = {}, attributes = [] } = this.props;
    const { productAttributes = [] } = initialValues;
    this.state = {
      attributeModalVisible: false,
      productAttributes: productAttributes,
      attributes: attributes,
    };
    this.onAttributeChange = this.onAttributeChange.bind();
    this.onAttributeValuesChange = this.onAttributeValuesChange.bind();
    this.onAddAnotherAttributeClick = this.onAddAnotherAttributeClick.bind();
    this.onVariantDelete = this.onVariantDelete.bind();
    this.onSaveAttribute = this.onSaveAttribute.bind();
    this.onNewAttribute = this.onNewAttribute.bind();
  }
  componentDidMount() {
    const _this = this;
    getAttributes({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          attributes: orderBy([...data], ["name"], ["asc"]),
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  onNewAttribute = () => {
    this.setState({ attributeModalVisible: true });
  };

  onSaveAttribute = (attribute) => {
    let { attributes } = this.state;
    attributes.push(attribute);

    this.setState({
      attributeModalVisible: false,
      attributes: orderBy(attributes, ["name"], ["asc"]),
    });
  };

  onAttributeChange = (value, index) => {
    const { form } = this.props;
    const productAttributes = form && form.getFieldValue("productAttributes");
    this.setState({
      productAttributes: [...productAttributes],
    });
  };
  onAttributeValuesChange = (values, index) => {
    const { form } = this.props;
    const productAttributes = form && form.getFieldValue("productAttributes");
    this.setState({
      productAttributes: [...productAttributes],
    });
  };

  onAddAnotherAttributeClick = (add) => {
    const { form = {} } = this.props;

    const productAttributes =
      (form && form.getFieldValue("productAttributes")) || [];

    if (productAttributes.length >= MAX_VARIANTS) {
      return;
    }

    add();
  };
  onVariantDelete = (index, field, remove) => {
    let { productAttributes } = this.state;
    productAttributes = removeItemFromIndex(productAttributes, index);

    this.setState({
      productAttributes: productAttributes,
    });

    remove(field.name);
  };

  render() {
    const { initialValues, form = {}, suppliers = [], taxes = [] } = this.props;
    const { productAttributes = [], attributes = [] } = this.state;

    return (
      <Row gutter={[16, 8]}>
        <Col xs={24}>
          <Title level={4}>Attributes</Title>
        </Col>
        <AttributeModal
          visible={this.state.attributeModalVisible}
          onCancel={() => this.setState({ attributeModalVisible: false })}
          onSave={this.onSaveAttribute}
        />
        <Form.List name="productAttributes">
          {(fields, { add, remove }) => {
            return (
              <>
                <Col xs={24}>
                  {fields.map((field, index) => {
                    return (
                      <Attribute
                        key={`${field.key}`}
                        attributes={attributes}
                        showDeleteButton={index !== 0}
                        index={index}
                        form={form}
                        onNewAttribute={this.onNewAttribute}
                        onAttributeChange={(value) =>
                          this.onAttributeChange(value, index)
                        }
                        onAttributeValuesChange={(values) =>
                          this.onAttributeValuesChange(values, index)
                        }
                        onVariantDelete={() =>
                          this.onVariantDelete(index, field, remove)
                        }
                      />
                    );
                  })}
                </Col>
                {fields.length < MAX_VARIANTS && (
                  <Col xs={24}>
                    <Button
                      style={{ paddingLeft: "unset" }}
                      onClick={() => this.onAddAnotherAttributeClick(add)}
                      type="link"
                      icon={<PlusCircleOutlined />}
                    >{`Add another attribute`}</Button>
                  </Col>
                )}
              </>
            );
          }}
        </Form.List>
        <Col xs={24}>
          <VariantsDefinitions
            variants={productAttributes}
            suppliers={suppliers}
            taxes={taxes}
            form={form}
            initialValues={initialValues}
          />
        </Col>
      </Row>
    );
  }
}

export default Attributes;
