import React from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { hideDialog } from "~/actions/generalActions";

import { GENERAL_DIALOG } from "~/actions/types";

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dialogId: props.dialogId || "" };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      show = false,
      dialogId,
      isModalDialog = true,
      hideDialog,
      type = "warning",
      title = "",
      content = "",
      action,
    } = nextProps;

    if (show !== true) {
      return null;
    }

    type === "warning" &&
      Modal.warning({
        title: title,
        content: content,
        onOk() {
          console.log("ok");
          hideDialog && hideDialog(dialogId);
          action && action();
        },
      });

    return null;
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (state, ownProps = {}) => {
  const dialog = state.general.dialog;

  if (dialog && dialog.actionType === GENERAL_DIALOG && dialog.show === true) {
    return { ...(state.general.dialog || {}) };
  }
  return {};
};

export default connect(mapStateToProps, { hideDialog })(Dialog);
