import { combineReducers } from "redux";
import sideMenuReducer from "./sideMenuReducer";
import userReducer from "./userReducer";
import offlineReducer from "./offlineReducer";
import generalReducer from "./generalReducer";
import sellReducer from "./sellReducer";

export default combineReducers({
  sideMenu: sideMenuReducer,
  user: userReducer,
  offline: offlineReducer,
  general: generalReducer,
  sale: sellReducer
});
