import React from "react";
import { Layout, Alert, Row, Col, Divider } from "antd";
import {
  ApiOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import MenuToggle from "../SiderMenu/MenuToggle";
import ProfileMenu from "./ProfileMenu";
import CompanyMenu from "./CompanyMenu";
import NotificationMenu from "./NotificationMenu";
import PropTypes from "prop-types";
import { HelpPageUrl, constructUrl } from "~/config";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, withNamespaces } from "react-i18next";
import Button from "~/components/Common/Button";

const { Header } = Layout;

class TopHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { processedActionId: props.actionId || "", notifictions: [] };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { lastAction } = nextProps;

    if (lastAction && prevState.processedActionId !== lastAction.actionId) {
      return { processedActionId: lastAction.actionId };
    }
    return null;
  }

  render() {
    const {
      collapsed,
      toggle,
      isOnline = true,
      history,
      match,
      subscription = {},
    } = this.props;
    const {
      subscriptionRemainingDays = 0,
      isSubscriptionExpired = false,
    } = subscription;
    return (
      <Header className="header">
        <Row gutter={12}>
          <Col xs={0} md={2}>
            <MenuToggle toggle={toggle} collapsed={collapsed} />
          </Col>
          <Col xs={24} md={22}>
            <Row
              justify="end"
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              {isOnline === false && (
                <Alert
                  style={{
                    position: "absolute",
                    zIndex: "99999",
                    left: "12px",
                    width: "140px",
                  }}
                  message="Offline mode"
                  type="warning"
                  showIcon
                  icon={<ApiOutlined style={{ fontSize: "20px" }} />}
                />
              )}
              {isOnline === true &&
                isSubscriptionExpired == false &&
                subscriptionRemainingDays > 0 &&
                subscriptionRemainingDays <= 15 && (
                  <Alert
                    style={{
                      position: "absolute",
                      zIndex: "99999",
                      left: "12px",
                      width: "340px",
                    }}
                    message={`Subscription expires in ${subscriptionRemainingDays} days`}
                    type="warning"
                    showIcon
                    icon={<WarningOutlined style={{ fontSize: "20px" }} />}
                    action={
                      <Button
                        size="small"
                        type="link"
                        onClick={() => {
                          history.push(
                            constructUrl({
                              match,
                              to: `/subscription`,
                            })
                          );
                        }}
                      >
                        Extend
                      </Button>
                    }
                    closable
                  />
                )}

              <Col className="menuTitle company">
                <CompanyMenu
                  disabled={isOnline === false}
                  history={history}
                  match={match}
                />
              </Col>
              <Divider type="vertical" className="divider company" />
              <Col className="menuTitle">
                <ProfileMenu
                  disabled={isOnline === false}
                  history={history}
                  match={match}
                />
              </Col>
              <Divider type="vertical" />
              <Col>
                <NotificationMenu
                  disabled={isOnline === false}
                  history={history}
                  match={match}
                />
              </Col>
              <Divider type="vertical" />
              <Col>
                <a href={HelpPageUrl} target="_blank">
                  <QuestionCircleOutlined style={{ fontSize: "16px" }} />
                  <span style={{ marginLeft: "5px" }}>Help</span>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
    );
  }
}

TopHeader.propTypes = {
  toggle: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps = {}) => {
  const lastAction = state.general.lastAction;

  if (
    lastAction &&
    lastAction.type === "save" &&
    lastAction.entity === "companySettings"
  ) {
    return { lastAction: state.general.lastAction };
  }
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withNamespaces()(TopHeader)));
