import React from "react";
import { Row, Col } from "antd";
import { ApiOutlined } from "@ant-design/icons";
import "./index.scss";

class Offline extends React.Component {
  render() {
    return (
      <Row gutter={12} className="offline-container">
        <Col xs={24}>
          <ApiOutlined /> Offline
        </Col>
      </Row>
    );
  }
}

export default Offline;
