import React from "react";
import { Row, Col, Form, Input, Button, Switch } from "antd";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { savePrintSettings, getPrintSettings } from "~/actions/setupActions";
import FormItem from "~/components/Form/FormItem";
import receiptTemplate from "~/assets/Print_Receipt_Template_trans.png";

import { propName } from "~/_utils";
import viewModel from "./viewModel";

const { TextArea } = Input;

const GetInitialValues = (printSettings = {}) => {
  const initialValues = {
    title: printSettings.title,
    header: printSettings.header,
    footer: printSettings.footer,
    autoPrintOnSaleComplete: printSettings.autoPrintOnSaleComplete,
    printCustomerName: printSettings.printCustomerName,
    printNotes: printSettings.printNotes,
  };

  return initialValues;
};

class PrintSettings extends React.Component {
  constructor(props) {
    super(props);

    let { location = {}, printSettings } = this.props;

    this.state = { printSettings: printSettings, formRef: null };

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.getPrintSettings = this.getPrintSettings.bind();
    this.setFormRef = this.setFormRef.bind();
  }
  getPrintSettings = () => {
    const _this = this;

    getPrintSettings()
      .then(function(result) {
        _this.setState(
          {
            printSettings: result.data,
          },
          _this.state.formRef.setFieldsValue(result.data)
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  componentDidMount() {
    this.getPrintSettings();
  }
  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });

    const { savePrintSettings } = this.props;
    const { formRef } = this.state;
    const _this = this;

    savePrintSettings(values)
      .then(function(result) {
        _this.setState({
          loading: false,
        });
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  render() {
    const { formRef, loading = false, printSettings = {} } = this.state;
    const initialValues = GetInitialValues(printSettings);

    return (
      <Row gutter={[16, 16]}>
        <Col xs={24} md={14}>
          <Form
            validateTrigger="onBlur"
            onFinish={this.handleSubmit}
            onFinishFailed={this.onFinishFailed}
            className="printSettings-form"
            initialValues={initialValues}
            ref={this.setFormRef}
          >
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <FormItem
                  form={formRef}
                  propertyName={propName(initialValues, (o) => o.title)}
                  viewModelKeyValue={
                    viewModel[propName(initialValues, (o) => o.title)]
                  }
                >
                  {({ options }) => <Input {...options} />}
                </FormItem>
              </Col>
              <Col xs={24}>
                <FormItem
                  form={formRef}
                  propertyName={propName(initialValues, (o) => o.header)}
                  viewModelKeyValue={
                    viewModel[propName(initialValues, (o) => o.header)]
                  }
                >
                  {({ options }) => (
                    <TextArea
                      rows={2}
                      style={{ resize: "none" }}
                      {...options}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24}>
                <FormItem
                  form={formRef}
                  propertyName={propName(initialValues, (o) => o.footer)}
                  viewModelKeyValue={
                    viewModel[propName(initialValues, (o) => o.footer)]
                  }
                >
                  {({ options }) => (
                    <TextArea
                      rows={2}
                      style={{ resize: "none" }}
                      {...options}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={14} lg={8}>
                <FormItem
                  propertyName={propName(
                    initialValues,
                    (o) => o.printCustomerName
                  )}
                  viewModelKeyValue={
                    viewModel[
                      propName(initialValues, (o) => o.printCustomerName)
                    ]
                  }
                  form={formRef}
                  valuePropName="checked"
                  showFormTitle={false}
                >
                  {(options, setValue) => {
                    return (
                      <Switch
                        checkedChildren={"Print customer name"}
                        unCheckedChildren={"Print customer name"}
                      />
                    );
                  }}
                </FormItem>
              </Col>
              <Col xs={10} lg={6}>
                <FormItem
                  propertyName={propName(initialValues, (o) => o.printNotes)}
                  viewModelKeyValue={
                    viewModel[propName(initialValues, (o) => o.printNotes)]
                  }
                  form={formRef}
                  valuePropName="checked"
                  showFormTitle={false}
                >
                  {(options, setValue) => {
                    return (
                      <Switch
                        checkedChildren={"Print notes"}
                        unCheckedChildren={"Print notes"}
                      />
                    );
                  }}
                </FormItem>
              </Col>
              <Col xs={24} lg={10}>
                <FormItem
                  propertyName={propName(
                    initialValues,
                    (o) => o.autoPrintOnSaleComplete
                  )}
                  viewModelKeyValue={
                    viewModel[
                      propName(initialValues, (o) => o.autoPrintOnSaleComplete)
                    ]
                  }
                  form={formRef}
                  valuePropName="checked"
                  showFormTitle={false}
                >
                  {(options, setValue) => {
                    return (
                      <Switch
                        checkedChildren={"Auto print on sale complete"}
                        unCheckedChildren={"Auto print on sale complete"}
                      />
                    );
                  }}
                </FormItem>
              </Col>
              <Col xs={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="save-button"
                    loading={loading}
                  >
                    <Trans i18nKey="Save">Save</Trans>
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={0} md={10}>
          <img
            style={{
              boxShadow: "10px 10px 5px #ccc",
              border: "1px solid #ccc",
            }}
            src={receiptTemplate}
            alt="template"
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { savePrintSettings })(
  withRouter(withNamespaces()(PrintSettings))
);
