import React from "react";
import {
  Row,
  Col,
  Divider,
  Input,
  Typography,
  Form as FormAntd,
  Tooltip,
  Switch,
} from "antd";
import moment from "moment";
import {
  EditTwoTone,
  BarcodeOutlined,
  SearchOutlined,
  PrinterOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Button from "~/components/Common/Button";

import { Trans, withNamespaces } from "react-i18next";
import queryString from "query-string";

import Products from "~/components/Sell/Bucket/Products";
import PayTotal from "~/components/Sell/Bucket/PayTotal";
import Payments from "~/pages/Sell/Pay/Payments"; //TODO: move it to the component
import SearchCustomer from "~/components/Sell/Bucket/SearchCustomer";
import Table from "~/components/Common/Table";
import Form from "~/components/Form";
import FormItem from "~/components/Form/FormItem";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { pay } from "~/actions/sellActions";
import { constructUrl, DateFormat } from "~/config";
import { price } from "~/_utils";
import RangePicker from "~/components/Common/DatePicker/RangePicker";

import { selectCustomerFromLocalDb } from "~/_offline/offlineCustomers";
import { getSales } from "~/actions/sellActions";
import { PageTitle } from "~/components/Common/Text";
import ReactToPrint from "react-to-print";
import Receipt from "~/pages/Sell/Pay/Receipt";
import "./index.scss";
import { recordDeleteFailed } from "~/actions/generalActions";

const { Title, Text } = Typography;

const ExpandedRow = (props = {}) => {
  const { children } = props;
  return (
    <Row gutter={[16, 16]} className="pay-container">
      <Col xs={24} lg={10} xl={8} className="sell-bucket-container readonly">
        <Row>
          <Col className="sell-bucket-form">
            <Row className="bottom">
              <Col xs={24}>{children}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

class ExpandedReceipt extends React.Component {
  render() {
    const { completeSale = {} } = this.props;
    return (
      <Receipt
        {...{
          ...completeSale,
          updatedTotalAmount: completeSale.remainingAmountToPay,
          originalTotalAmount: completeSale.totalAmount,
          readonly: true,
        }}
      />
    );
  }
}

const Filters = (props) => {
  const {
    defaultCustomerCode = "",
    flexSearchCustomer,
    onDateRangeChange,
    initialValues = {},
    onSearch,
  } = props;

  const { selectedDateRange: defaultSelectedDateRange = [] } = initialValues;

  const onFinish = (values) => {
    onSearch(values);
  };

  return (
    <Form name="filter" onFinish={onFinish}>
      {({ formRef } = props) => (
        <Row gutter={[8, 8]}>
          <Col xs={8} md={4}>
            <FormItem
              form={formRef}
              propertyName="saleCode_eq"
              placeholder="Code"
              showFormTitle={false}
              showPlaceholder={true}
            >
              {({ placeholder, ...restOptions }) => (
                <Input placeholder={placeholder} prefix={<BarcodeOutlined />} />
              )}
            </FormItem>
          </Col>
          <Col xs={16} md={6}>
            <FormItem
              form={formRef}
              propertyName={"customerId_eq"}
              showFormTitle={false}
            >
              {({ options }, setValue) => (
                <SearchCustomer
                  flexSearch={flexSearchCustomer}
                  defaultCustomerCode={defaultCustomerCode}
                  onSelect={(value) => {
                    console.log(value.customerId);
                    setValue(value.customerId);
                  }}
                  showAddCustomer={false}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={20} md={8} xl={8} xxl={8}>
            <RangePicker
              onChange={onDateRangeChange}
              placeholder={["Start date", "End date"]}
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              defaultValue={defaultSelectedDateRange}
            />
          </Col>
          <Col xs={4} md={3}>
            <FormItem
              propertyName={"notes"}
              showFormTitle={false}
              valuePropName="checked"
            >
              {({ placeholder, ...restOptions }) => (
                <Switch
                  defaultChecked={false}
                  checkedChildren={"Notes"}
                  unCheckedChildren={"Notes"}
                  onChange={(checked, e) => {}}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} md={3}>
            <FormAntd.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </FormAntd.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
};

class History extends React.Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    const { customerCode } = queryParams;
    const sellFrom = queryParams.sellFrom;
    const sellTo = queryParams.sellTo;

    let selectedDateRange =
      sellFrom && sellTo ? [moment(sellFrom), moment(sellTo)] : [];

    if (selectedDateRange.length === 0) {
      selectedDateRange = [moment().startOf("day"), moment().endOf("day")];
    }

    const filterValues = {
      ...(props.filterValues || {}),
    };

    this.state = {
      saleHistory: [],
      selectedCustomer: {},
      filterValues: { ...filterValues },
      initialValues: { ...filterValues, selectedDateRange: selectedDateRange },
      sort: props.sort || {},
      pageInfo: props.pageInfo || {},
      selectedDateRange: selectedDateRange,
      defaultCustomerCode: customerCode || props.customerCode,
    };
    this.onEdit = this.onEdit.bind();
    this.onDelete = this.onDelete.bind();

    this.handleTableChange = this.handleTableChange.bind();
    this.handleFetch = this.handleFetch.bind();
    this.updateData = this.updateData.bind();
    this.resetTableState = this.resetTableState.bind();
    this.onDateRangeChange = this.onDateRangeChange.bind();
    this.expandRow = this.expandRow.bind();

    this.printComponentRef = [];

    this.columns = [
      {
        title: "Code",
        dataIndex: "saleCode",
        key: "saleCode",
        width: 110,
        fixed: "left",
      },
      { title: "Date", dataIndex: "date", key: "date", sorter: true },

      { title: "Customer", dataIndex: "customer", key: "customer" },
      {
        title: "Total Sale",
        dataIndex: "saleTotal",
        key: "saleTotal",
        sorter: true,
      },
      {
        title: "",
        dataIndex: "",
        key: "actions",
        width: 120,
        render: (text, record = {}) => (
          <Row>
            <Col xs={8}>
              <Button
                type="link"
                icon={<EditTwoTone />}
                onClick={() => this.onEdit(record.completeSale)}
              />
            </Col>
            <Col xs={8}>
              <ReactToPrint
                bodyClass={"receipt-container print"}
                onBeforeGetContent={() => {}}
                copyStyles={false}
                trigger={(e) => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return (
                    <Button
                      type="link"
                      onClick={(e) => {}}
                      icon={<PrinterOutlined />}
                    />
                  );
                }}
                content={() => this.printComponentRef[record.key]}
              />
            </Col>
            <Col xs={8}>
              {record.notes ? (
                <Tooltip title={record.notes} color={"blue"} key={record.key}>
                  <Button
                    type="link"
                    icon={<InfoCircleOutlined />}
                    onClick={() => {}}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        ),
      },
    ];
  }

  componentDidMount() {
    const _this = this;
    const { defaultCustomerCode } = this.state;
    if (defaultCustomerCode) {
      selectCustomerFromLocalDb(defaultCustomerCode).then(function(customer) {
        _this.resetTableState(
          { filterValues: { customerId_eq: customer.customerId } },
          _this.handleFetch
        );
      });
      return;
    }

    this.handleFetch();
  }

  resetTableState = (options, callback) => {
    const {
      sort = this.state.sort,
      filterValues = this.state.filterValues,
    } = options;
    this.setState(
      {
        sort: sort || {},
        pageInfo: {}, // always reset page to page 1
        filterValues: filterValues || {},
      },
      callback
    );
  };
  handleFetch = (options = {}) => {
    const _this = this;

    let {
      filterValues = {},
      sort = {},
      pageInfo = {},
      selectedDateRange = [],
    } = this.state;
    if (pageInfo.hasNextPage === false) {
      return;
    }

    if (selectedDateRange.length > 0) {
      filterValues.saleDateTimeUtc_gte = selectedDateRange[0].toISOString();
      filterValues.saleDateTimeUtc_lte = selectedDateRange[1].toISOString();
    }

    this.setState({ loading: true });
    getSales(filterValues, sort, pageInfo)
      .then(function(result) {
        const { data, totalCount, pageInfo = {} } = result;
        _this.updateData(data);
        _this.setState({
          loading: false,
          pageInfo: pageInfo,
          totalCount: totalCount,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };
  handleTableChange = (pagination, filters, sorter = {}) => {
    let sortField = sorter.field;
    sortField = sortField === "saleTotal" ? "totalAmount" : sortField;
    sortField = sortField === "date" ? "saleDateTimeUtc" : sortField;

    const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";

    const sort = JSON.parse(`{"${sortField}": "${sortOrder}"}`);

    this.resetTableState({ sort: sort }, this.handleFetch);
  };

  updateData = (data = []) => {
    const _this = this;
    const {
      saleHistory: alreadyLoadedSaleHistory = [],
      pageInfo = {},
    } = this.state;
    let saleHistory =
      Object.keys(pageInfo).length === 0 ? [] : [...alreadyLoadedSaleHistory]; //If page is reset start from empty list

    const startIndex = saleHistory.length;
    ////
    data.forEach((sale, index) => {
      const {
        date,
        saleCode,
        customer = {},
        totalAmount,
        status,
        notes,
      } = sale;
      const { name: customerName } = customer;

      saleHistory.push({
        key: `${saleCode}_${startIndex}_${index}_${Date.now()}`,
        date: date,
        saleCode: saleCode,
        customer: customerName,
        saleTotal: price(totalAmount, true),
        status: status,
        notes: notes,
        completeSale: sale || {},
      });
    });

    _this.setState({
      saleHistory: saleHistory,
    });
  };

  onEdit = (sale) => {
    const { pay, history, match } = this.props;
    pay(sale).then(function(result) {
      history.push(constructUrl({ match, to: "/sell", isNested: false })); //history is injected from hoc withRouter
    });
  };
  onDelete = (saleCode) => {
    console.log(saleCode);
    //TODO: call action functio to make API call
  };
  onDateRangeChange = (dates, dateRangeArray) => {
    this.setState({ selectedDateRange: dateRangeArray });
  };
  expandRow = (record = {}) => {
    return (
      <ExpandedRow>
        <ExpandedReceipt {...record} />
      </ExpandedRow>
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { flexSearchCustomer } = nextProps;

    return { flexSearchCustomer: flexSearchCustomer };
  }

  render() {
    const {
      saleHistory = [],
      selectedDateRange,
      flexSearchCustomer,
      pageInfo = {},
      defaultCustomerCode = "",
      initialValues = {},
    } = this.state;

    const columns = this.columns;
    const _this = this;

    return (
      <Row className="sellHistory-container">
        <Col xs={24}>
          <PageTitle>Sales History</PageTitle>
          <Divider />
          <br />
        </Col>
        <Col xs={24} xl={18} xxl={12}>
          <Filters
            initialValues={initialValues}
            flexSearchCustomer={flexSearchCustomer}
            defaultCustomerCode={defaultCustomerCode}
            onDateRangeChange={this.onDateRangeChange}
            selectedDateRange={selectedDateRange}
            onSearch={(filterValues) => {
              _this.resetTableState(
                { filterValues: filterValues },
                _this.handleFetch
              );
            }}
          />
        </Col>
        <Col xs={24}>
          <Divider />
          <Table
            rowkey={(record) => record.key}
            loading={this.state.loading}
            columns={columns}
            onChange={this.handleTableChange}
            expandedRowRender={(record) => this.expandRow(record)}
            dataSource={saleHistory}
            hasNextPage={pageInfo.hasNextPage}
            onPageChange={this.handleFetch}
          />
        </Col>
        <Col
          xs={24}
          style={{ width: "0px", height: "0px", visibility: "hidden" }}
        >
          {saleHistory.map((record = {}) => {
            return (
              <ExpandedReceipt
                {...record}
                ref={(el) => (this.printComponentRef[record.key] = el)}
              />
            );
          })}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  flexSearchCustomer: state.offline.flexSearchCustomer,
});

export default connect(mapStateToProps, { pay })(
  withRouter(withNamespaces()(History))
);
