import React from "react";
import LogoSvg from "../../assets/LogoSvg";
import "./index.scss";
import mainLogo from "../../assets/HexLogo-blue.png";
import { Row, Col } from "antd";

const logoInitialLetter = "";
const logoSuffix = "Cloud Winkel";
export const LogoTitle = `${logoInitialLetter}${logoSuffix}`;

const Logo = () => {
  return (
    <Row className="logo" id="logo">
      {/* <LogoSvg style={{ width: "55px", height: "45px", marginRight: "4px" }} /> */}
      <Col style={{ width: "32px" }}>
        <img
          src={mainLogo}
          style={{
            width: "32px",
            marginRight: "4px",
            marginTop: "6px",
            marginLeft: "6px",
          }}
          alt="cloudwinkel"
        />
      </Col>
      <Col style={{ width: "135px" }}>
        <h1>{logoSuffix}</h1>
      </Col>
    </Row>
  );
};

export default Logo;
