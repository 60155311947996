import React from "react";
import { Card as AntdCard, Col, Row, Avatar, Radio } from "antd";

const Card = (props) => {
  const {
    icon,
    iconStyle = {},
    title,
    footer,
    style = {},
    children,
    ...restProps
  } = props;
  return (
    <AntdCard
      bordered={true}
      style={{ ...style, cursor: props.onClick && "pointer" }}
      {...restProps}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        {icon && (
          <div className="icon-container">
            <Avatar size={50} style={{ ...iconStyle }} icon={icon} />
          </div>
        )}
        <div className="card-content-container">
          <div className="card-title">{title}</div>
          <div className="card-body">{children}</div>
          <div className="card-footer">{footer}</div>
        </div>
      </div>
    </AntdCard>
  );
};

export default Card;
