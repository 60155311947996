import React from "react";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Input, Row, Col, AutoComplete } from "antd";
import PriceLabel from "~/components/Common/PriceLabel";
import { productsIndex } from "~/config";
import Button from "~/components/Common/Button";
import ProductDetailModal from "~/components/Sell/ProductDetail/ProductDetailModal";
import { translate } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";

const { Option } = AutoComplete;
let enterKeyIsPressed = false;

function highlightText(element) {
  //had to add delayed select of the input field
  setTimeout(function() {
    element.input.select();
  }, 100);
}

function clearText(element) {
  //had to add delayed select of the input field
  setTimeout(function() {
    element.input.value = "";
  }, 100);
}

class SearchProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      autoCompleteText: "",
      productDetailModalVisible: false,
      showProductDetailBarcode: "",
    };
    this.handleSearch = this.handleSearch.bind();
    this.handleFocus = this.handleFocus.bind();
    this.handleSelect = this.handleSelect.bind();
    this.handleChange = this.handleChange.bind();
    this.searchProductAutoCompleteRenderOption = this.searchProductAutoCompleteRenderOption.bind();
    this.onShowProductDetail = this.onShowProductDetail.bind();
  }
  onShowProductDetail = (props = {}) => {
    const { barcode = "" } = props;
    this.setState({
      showProductDetailBarcode: barcode,
      productDetailModalVisible: true,
    });
  };
  searchProductAutoCompleteRenderOption = (item, index) => {
    const { showPrice = true } = this.props;
    const variant = item.variant ? `/ ${item.variant}` : "";
    const title = `${item.name} ${variant}`;
    const price = item.retailPriceIncTax;
    const barcode = item.barcode;
    const productId = item.productId;
    const _this = this;
    return {
      key: productId,
      value: productId,
      text: title,
      label: (
        <div className="search-product-container">
          <Row gutter={12}>
            <Col xs={24}>
              <span className="title">{title}</span>
              <div className="price">
                {showPrice === true ? <PriceLabel value={price} /> : <></>}

                <Button
                  type="link"
                  icon={<InfoCircleOutlined />}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    _this.onShowProductDetail({ barcode: item.barcode });
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="subSection">
              <span>{barcode}</span>
            </Col>
          </Row>
        </div>
      ),
    };
  };

  handleSelect = (value, option) => {
    const { onSelect, clearTextOnSelect = false } = this.props;
    if (clearTextOnSelect === true) {
      clearText(this.searchInput);
      this.setState({ autoCompleteText: "" });
    } else {
      highlightText(this.searchInput);
      this.setState({ autoCompleteText: option.text });
    }

    onSelect(option.key);
  };
  handleChange = (value) => {
    this.setState({ autoCompleteText: value });
  };
  handleSearch = (value) => {
    const { flexSearch, onProductsIndexOutOfSync } = this.props;

    if (!flexSearch) {
      console.log("searching is not available");
      return;
    }
    const _this = this;
    try {
      flexSearch.search(
        {
          query: `${value}`,
          suggest: true,
          limit: 30,
          field: productsIndex,
          bool: "or",
        },
        function(result) {
          //index out of sync then re-index

          if (result && result.length > 0 && !result[0]) {
            //get values from DB
            onProductsIndexOutOfSync && onProductsIndexOutOfSync();
          } else {
            //remove duplicates if any
            const updatedResult = [...new Set(result)];
            _this.setState({ dataSource: updatedResult });
          }
        }
      );
    } catch (ex) {
      console.log("index out of sync, re-initilaize index");
      onProductsIndexOutOfSync && onProductsIndexOutOfSync();
    }
  };
  handleFocus = (e) => {
    e.target.select();
    enterKeyIsPressed = false;
  };
  render() {
    const {
      dataSource,
      autoCompleteText,
      productDetailModalVisible = false,
      showProductDetailBarcode = "",
    } = this.state;
    const { flexSearch, disabled = false, setRef } = this.props;
    const _this = this;

    const placeHolderLoadingProducts = `${translate("LoadingProducts", {
      namespace: TRANSLATION_NAMESPACE.Product,
    })}`;
    const placeHolderSearchProduct = `${translate("SearchProduct", {
      namespace: TRANSLATION_NAMESPACE.Product,
    })}`;

    return (
      <>
        <AutoComplete
          options={dataSource.map(_this.searchProductAutoCompleteRenderOption)}
          onSearch={this.handleSearch}
          onSelect={(value, option) => {
            !enterKeyIsPressed && this.handleSelect(value, option);
            enterKeyIsPressed = false;
          }}
          onChange={(value) => {
            !enterKeyIsPressed && this.handleChange(value);
          }}
          style={{ width: "100%" }}
          autoFocus={true}
          value={autoCompleteText}
          disabled={!flexSearch || disabled}
        >
          <Input
            ref={(input) => {
              this.searchInput = input;
              setRef && setRef(input);
            }}
            onFocus={this.handleFocus}
            prefix={<SearchOutlined />}
            placeholder={
              !flexSearch
                ? `${placeHolderLoadingProducts}...`
                : `${placeHolderSearchProduct}`
            }
            onKeyDown={(e) => {
              // if there is one suggestion then select it on enter
              if (e.key === "Enter" && dataSource && dataSource.length === 1) {
                enterKeyIsPressed = true;
                _this.handleSelect(dataSource[0].productId, {
                  key: dataSource[0].productId,
                  text: dataSource[0].name,
                });
              } else if (enterKeyIsPressed === true) {
                enterKeyIsPressed = false;
              }
            }}
          />
        </AutoComplete>
        <ProductDetailModal
          barcode={showProductDetailBarcode}
          visible={productDetailModalVisible}
          onCancel={() =>
            this.setState({
              productDetailModalVisible: false,
              showProductDetailBarcode: "",
            })
          }
        />
      </>
    );
  }
}

export default SearchProduct;
