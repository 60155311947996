import React from "react";
import { Row, Col, Button as AntdButton, Menu, Dropdown, Input } from "antd";
import Button from "./";
const { TextArea } = Input;

const MenuContent = (props = {}) => {
  let textArea;
  const { onClick, note, secondaryButtonTitle = "ok" } = props;
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <TextArea
            rows={2}
            style={{ resize: "none" }}
            placeholder="Note"
            defaultValue={note}
            ref={(el) => {
              textArea = el;
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Button
            type="primary"
            style={{ width: "100%", marginTop: "8px" }}
            onClick={() => onClick({ textArea })}
          >
            {secondaryButtonTitle}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const MenuWithTextArea = (props = {}) => {
  return (
    <Menu>
      <Menu.ItemGroup>
        <MenuContent {...props} />
      </Menu.ItemGroup>
    </Menu>
  );
};

class ButtonWithInputField extends React.Component {
  constructor(props) {
    super(props);

    this.state = { visible: false, note: "" };
    this.onClick = this.onClick.bind();
    this.onDropdownClick = this.onDropdownClick.bind();
    this.handleVisibleChange = this.handleVisibleChange.bind();
  }
  onClick = (options = {}) => {
    const { onClick } = this.props;
    const { textArea = {} } = options;

    const rsizeableTextAreaProps =
      (textArea.resizableTextArea && textArea.resizableTextArea.props) || {};
    const resizeableTextArea =
      (textArea.resizableTextArea && textArea.resizableTextArea.textArea) || {};

    const note = resizeableTextArea.value || rsizeableTextAreaProps.value || "";
    onClick({ note: note });

    this.setState({
      visible: false,
      note: "",
    });
  };
  onDropdownClick = () => {
    const { visible } = this.state;
    this.setState({
      visible: !visible,
    });
  };
  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };
  render() {
    const {
      children,
      secondaryButtonTitle,
      onClick,
      ...otherProps
    } = this.props;

    const { note } = this.state;
    return (
      <Dropdown
        overlay={MenuWithTextArea({
          secondaryButtonTitle: secondaryButtonTitle,
          onClick: this.onClick,
          onTextChange: this.textChange,
          note: note,
        })}
        onClick={this.onDropdownClick}
        onVisibleChange={this.handleVisibleChange}
        visible={this.state.visible}
        trigger={["click"]}
        {...otherProps}
      >
        <AntdButton {...otherProps}>{children}</AntdButton>
      </Dropdown>
    );
  }
}

export default ButtonWithInputField;
