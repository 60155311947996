import React from "react";
import { TagOutlined } from "@ant-design/icons";
import { Row, Col, Input, Form, Divider, Button } from "antd";
import queryString from "query-string";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveSalesTax, getSalesTax } from "~/actions/setupActions";
import PriceInput from "~/components/Common/Input/PriceInput";

import { PageTitle } from "~/components/Common/Text";
import FormItem from "~/components/Form/FormItem";

import { propName } from "~/_utils";
import viewModel from "./viewModel";

const { TextArea } = Input;

const GetInitialValues = (salesTax = {}) => {
  const initialValues = {
    taxId: salesTax.taxId,
    name: salesTax.name,
    percentage: salesTax.percentage,
  };

  return initialValues;
};

class SalesTax extends React.Component {
  constructor(props) {
    super(props);

    let { location = {}, tax } = this.props;

    this.state = { tax: tax, formRef: null };

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.getSalesTax = this.getSalesTax.bind();
    this.setFormRef = this.setFormRef.bind();
  }

  getSalesTax = (id) => {
    const _this = this;

    getSalesTax({ id: id })
      .then(function(result) {
        _this.setState(
          {
            salesTax: result.data,
            isEditMode: true, //getSalesTax is only called in edit mode
          },
          _this.state.formRef.setFieldsValue(result.data)
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  componentDidMount() {
    const { location, salesTaxId: salesTaxIdFromProps } = this.props;

    const queryParams = queryString.parse(location.search);
    const salesTaxId = queryParams.salesTaxId || salesTaxIdFromProps;

    salesTaxId && this.getSalesTax(salesTaxId);
  }

  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });

    const { saveSalesTax, onSave } = this.props;
    const { formRef, isEditMode } = this.state;
    const _this = this;

    saveSalesTax(values)
      .then(function(result) {
        onSave && onSave(result);

        _this.setState({
          loading: false,
        });

        if (isEditMode === true) {
          formRef.setFieldsValue({
            taxId: result.taxId,
          });
          _this.setState({
            salesTax: result,
          });
        } else {
          _this.setState(
            {
              salesTax: {},
            },
            formRef.resetFields
          );
        }
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const { showTitle } = this.props;
    const {
      formRef,
      loading = false,
      salesTax = {},
      isEditMode = false,
    } = this.state;
    const initialValues = GetInitialValues(salesTax);

    return (
      <Row gutter={[16, 8]}>
        <Col xs={24}>
          {showTitle === true && (
            <>
              <PageTitle>SalesTax</PageTitle>
              <Divider />
              <br />
            </>
          )}
          <Col xs={24}>
            <Form
              validateTrigger="onBlur"
              onFinish={this.handleSubmit}
              onFinishFailed={this.onFinishFailed}
              className="salesTax-form"
              initialValues={initialValues}
              ref={this.setFormRef}
            >
              <Row gutter={[16, 8]}>
                <Col xs={24}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.name)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.name)]
                    }
                  >
                    {({ options }) => (
                      <Input prefix={<TagOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.percentage)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.percentage)]
                    }
                  >
                    {({ placeholder, ...restOptions }) => (
                      <PriceInput
                        disabled={isEditMode === true}
                        readOnly={false}
                        style={{ width: "100%" }}
                        {...restOptions}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="save-button"
                      loading={loading}
                    >
                      <Trans i18nKey="Save">Save</Trans>
                    </Button>
                  </Form.Item>
                </Col>

                <FormItem
                  form={formRef}
                  propertyName={propName(initialValues, (o) => o.taxId)}
                  viewModelKeyValue={propName(initialValues, (o) => o.taxId)}
                >
                  {(options) => <Input type="hidden" {...options} />}
                </FormItem>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveSalesTax })(
  withRouter(withNamespaces()(SalesTax))
);
