import React from "react";
import Media from "react-media";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { constructUrl } from "~/config";
import {
  Row,
  Col,
  Divider,
  Input,
  Typography,
  Card,
  Form,
  Switch,
  Select as SelectAntD,
} from "antd";
import {
  EditTwoTone,
  TagOutlined,
  TagsOutlined,
  SearchOutlined,
  BarcodeOutlined,
} from "@ant-design/icons";
import Button from "~/components/Common/Button";
import FormItem, { FormItemTitle } from "~/components/Form/FormItem";
import { PageTitle } from "~/components/Common/Text";
import RangePicker from "~/components/Common/DatePicker/RangePicker";
import { convertUtcDateToLocalDateTime } from "~/_utils";
import SupplierSelect from "~/pages/Products/Product/SupplierSelect";
import orderBy from "lodash/orderBy";
import { getSuppliers } from "~/actions/supplierActions";
import moment from "moment";

import {
  getPurchases,
  deletePurchase,
  updatePurchaseProduct,
} from "~/actions/purchaseActions";
import Table from "~/components/Common/Table";
import queryString from "query-string";
import "./index.scss";

const { Text } = Typography;
const { Option } = SelectAntD;

const Filters = (props) => {
  const {
    onSearch,
    onPurchaseDateRangeChange,
    onExpiryDateRangeChange,
    suppliers = [],
    initialValues = {},
  } = props;
  const onFinish = (values) => {
    onSearch(values);
  };

  const { selectedExpiryDateRange = [] } = initialValues;
  return (
    <Form name="filter" onFinish={onFinish} initialValues={initialValues}>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={4}>
          <FormItem
            propertyName="refNumber_eq"
            placeholder="RefNumber"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {({ placeholder, ...restOptions }) => (
              <Input prefix={<TagOutlined />} placeholder={placeholder} />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={4}>
          <FormItem
            propertyName="productBarCode_eq"
            placeholder="Product barcode"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {({ placeholder, ...restOptions }) => (
              <Input prefix={<BarcodeOutlined />} placeholder={placeholder} />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={4}>
          <FormItem
            propertyName="supplierId_eq"
            placeholder="Supplier"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {({ placeholder, ...restOptions }) => (
              <SupplierSelect
                suppliers={suppliers}
                placeholder={placeholder}
                allowAddNewItem={false}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <RangePicker
            onChange={onPurchaseDateRangeChange}
            placeholder={["Purchase Start", "Purchase End"]}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormItem
            propertyName="batchNumber_eq"
            placeholder="Batch#"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {({ placeholder, ...restOptions }) => (
              <Input prefix={<TagsOutlined />} placeholder={placeholder} />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={6}>
          <FormItem
            propertyName="inStock_eq"
            placeholder="InStock"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {(options, setValue) => (
              <SelectAntD placeholder={options.placeholder} allowClear={true}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </SelectAntD>
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RangePicker
              onChange={onExpiryDateRangeChange}
              placeholder={["Expiry Start", "Expiry End"]}
              defaultValue={selectedExpiryDateRange}
            />
            <Form.Item style={{ marginBottom: "0px", marginLeft: "12px" }}>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

const ExpandedRow_PurchaseProductsHeader = () => {
  return (
    <Row gutter={[16, 8]} className="expand-purchaseProducts-header">
      <Col xs={6}>
        <FormItemTitle title="Product" />
      </Col>
      <Col xs={3}>
        <FormItemTitle title="Barcode" />
      </Col>
      <Col xs={3}>
        <FormItemTitle title="Batch#" />
      </Col>
      <Col xs={2}>
        <FormItemTitle title="Expiry" />
      </Col>
      <Col xs={3}>
        <FormItemTitle title="Quantity" />
      </Col>
      <Col xs={2}>
        <FormItemTitle title="Price / unit" />
      </Col>
      <Col xs={2}>
        <FormItemTitle title="Total price" />
      </Col>
    </Row>
  );
};

const ExpandedRow_PurchaseProductsLines = (props) => {
  const { purchaseProducts, updatePurchaseProduct } = props;
  if (!purchaseProducts) {
    return <></>;
  }
  return purchaseProducts.map((purchaseProduct = {}, index) => {
    const {
      purchaseProductId,
      productName,
      productVariantName,
      productBarCode,
      batchNumber,
      expiryDate,
      purchasePrice,
      totalPurchasePrice,
      quantity,
      inStock,
    } = purchaseProduct;
    return (
      <Row
        gutter={[16, 8]}
        key={purchaseProductId + index} //important! concatinat purchaseProductId with index to make the row key unique. Just using "index" will have issues after "search"
        className="expand-purchaseProducts-row"
      >
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <span className="productName">{`${productName}`}</span>
            </Col>
            <Col xs={24}>
              <span className="variant">{productVariantName}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <span className="barcode">{productBarCode}</span>
        </Col>
        <Col xs={3}>
          <span className="batchNumber">{batchNumber}</span>
        </Col>
        <Col xs={2}>
          <span className="expiryDate">
            {convertUtcDateToLocalDateTime(expiryDate, true, false)}
          </span>
        </Col>
        <Col xs={3}>
          <span className="quantity">{quantity}</span>
        </Col>
        <Col xs={2}>
          <span className="purchasePrice">{purchasePrice}</span>
        </Col>
        <Col xs={2}>
          <span className="purchasePrice">{totalPurchasePrice}</span>
        </Col>
        <Col xs={3}>
          <Switch
            defaultChecked={inStock}
            checked={inStock}
            checkedChildren={"In Stock"}
            unCheckedChildren={"Out of Stock"}
            size="small"
            onChange={(checked, e) => {
              updatePurchaseProduct({
                purchaseProductId,
                inStock: checked,
              });
            }}
          />
        </Col>

        <Divider />
      </Row>
    );
  });
};

const ExpandedRow = (props) => {
  const { purchase = {}, updatePurchaseProduct } = props;
  const purchaseProducts = purchase.purchaseProducts;

  return (
    <div className="purchaseoverview-purchaseProducts-container">
      <ExpandedRow_PurchaseProductsHeader />
      <ExpandedRow_PurchaseProductsLines
        purchaseProducts={purchaseProducts}
        updatePurchaseProduct={updatePurchaseProduct}
      />
    </div>
  );
};

class Purchases extends React.Component {
  constructor(props) {
    super(props);

    const { location = {} } = props;
    const queryParams = queryString.parse(location.search);
    const inStock = queryParams.inStock;
    const expiryFrom = queryParams.expiryFrom;
    const expiryTo = queryParams.expiryTo;

    const filterValues = {
      ...(props.filterValues || {}),
      inStock_eq: inStock === undefined ? undefined : inStock === "1",
    };

    const selectedExpiryDateRange =
      expiryFrom && expiryTo ? [moment(expiryFrom), moment(expiryTo)] : [];

    this.state = {
      purchases: [],
      selectedPurchaseId: "",
      filterValues: { ...filterValues },
      initialValues: {
        ...filterValues,
        selectedExpiryDateRange: selectedExpiryDateRange,
      },
      selectedExpiryDateRange: selectedExpiryDateRange,
    };

    this.onDelete = this.onDelete.bind();
    this.resetTableState = this.resetTableState.bind();
    this.handleFetch = this.handleFetch.bind();
    this.updateData = this.updateData.bind();
    this.handleTableChange = this.handleTableChange.bind();
    this.onPurchaseDateRangeChange = this.onPurchaseDateRangeChange.bind();
    this.onExpiryDateRangeChange = this.onExpiryDateRangeChange.bind();
    this.onUpdatePurchaseProduct = this.onUpdatePurchaseProduct.bind();
    this.onEdit = this.onEdit.bind();

    this.columns = [
      {
        title: "RefNumber",
        dataIndex: "refNumber",
        key: "refNumber",
        fixed: "left",
        sorter: true,
      },
      {
        title: "Supplier",
        dataIndex: "supplierName",
        key: "supplierName",
        sorter: true,
      },
      {
        title: "Tax Amount",
        dataIndex: "totalTaxAmount",
        key: "totalTaxAmount",
        sorter: true,
      },
      {
        title: "Total Amount",
        dataIndex: "totalPurchasePrice",
        key: "totalPurchasePrice",
        sorter: true,
      },
      {
        title: "Purchase Date",
        dataIndex: "purchaseDate",
        key: "purchaseDate",
        sorter: true,
        render: (text, record) => <>{convertUtcDateToLocalDateTime(text)}</>,
      },

      {
        title: "",
        dataIndex: "",
        key: "actions",
        width: 85,
        render: (text, record) => (
          <Row>
            <Col xs={12}>
              <Button
                type="link"
                icon={<EditTwoTone />}
                onClick={() =>
                  this.onEdit({
                    id: record.purchaseId,
                  })
                }
              />
            </Col>
            {/* <Col xs={12}>
              <Button
                layout="confirm"
                confirmText="Are you sure ?"
                type="link"
                icon={<DeleteTwoTone />}
                onClick={() => this.onDelete({ id: record.purchaseId })}
              />
            </Col> */}
          </Row>
        ),
      },
    ];
  }

  componentDidMount() {
    const _this = this;
    getSuppliers({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          suppliers: orderBy([...data], ["name"], ["asc"]),
        });
      })
      .catch(function(err) {
        console.log(err);
      });

    this.handleFetch();
  }

  resetTableState = (options, callback) => {
    const {
      sort = this.state.sort,
      filterValues = this.state.filterValues,
    } = options;
    this.setState(
      {
        sort: sort || {},
        pageInfo: {}, // always reset page to page 1
        filterValues: filterValues || {},
      },
      callback
    );
  };

  handleFetch = (options = {}) => {
    const _this = this;

    let {
      filterValues = {},
      sort = {},
      pageInfo = {},
      selectedPurchaseDateRange = [],
      selectedExpiryDateRange = [],
    } = this.state;
    if (pageInfo.hasNextPage === false) {
      return;
    }

    if (selectedPurchaseDateRange.length > 0) {
      filterValues.purchaseDate_gte = selectedPurchaseDateRange[0].toISOString();
      filterValues.purchaseDate_lte = selectedPurchaseDateRange[1].toISOString();
    }
    if (selectedExpiryDateRange.length > 0) {
      filterValues.expiryDate_gte = selectedExpiryDateRange[0].toISOString();
      filterValues.expiryDate_lte = selectedExpiryDateRange[1].toISOString();
    }

    this.setState({ loading: true });
    getPurchases({ filterValues, sort, pageInfo })
      .then(function(result) {
        const { data, totalCount, pageInfo = {} } = result;
        _this.updateData(data);
        _this.setState({
          loading: false,
          pageInfo: pageInfo,
          totalCount: totalCount,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  updateData = (data = []) => {
    const _this = this;
    const {
      purchases: alreadyLoadedPurchases = [],
      pageInfo = {},
    } = this.state;
    let purchases =
      Object.keys(pageInfo).length === 0 ? [] : [...alreadyLoadedPurchases]; //If page is reset start from empty list

    const startIndex = purchases.length;
    ////
    data.forEach((purchase, index) => {
      const {
        purchaseId,
        refNumber,
        supplierName,
        purchaseDate,
        totalPurchasePrice,
        purchaseProducts,
        totalTaxAmount,
      } = purchase;

      purchases.push({
        key: startIndex + index,
        purchaseId: purchaseId,
        refNumber: refNumber,
        supplierName: supplierName,
        purchaseDate: purchaseDate,
        totalPurchasePrice: totalPurchasePrice,
        purchaseProducts: purchaseProducts,
        totalTaxAmount: totalTaxAmount,
      });
    });

    _this.setState({
      purchases: purchases,
    });
  };

  onEdit = ({ id }) => {
    const { history, match } = this.props;

    history.push(
      constructUrl({
        match,
        to: `/purchase?purchaseId=${id}`,
        isNested: false,
      })
    ); //history is injected from hoc withRouter
  };

  onDelete = (options) => {
    const _this = this;
    const { sort, filterValues } = this.state;
    const { deletePurchase } = this.props;
    deletePurchase(options)
      .then(function() {
        //keep existing filtr & sort state
        _this.resetTableState(
          {
            filterValues: filterValues,
            sort: sort,
          },
          _this.handleFetch
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  handleTableChange = (pagination, filters, sorter = {}) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";

    const sort = JSON.parse(`{"${sortField}": "${sortOrder}"}`);

    this.resetTableState({ sort: sort }, this.handleFetch);
  };

  onPurchaseDateRangeChange = (dates, dateRangeArray) => {
    this.setState({ selectedPurchaseDateRange: dateRangeArray });
  };

  onExpiryDateRangeChange = (dates, dateRangeArray) => {
    this.setState({ selectedExpiryDateRange: dateRangeArray });
  };
  onUpdatePurchaseProduct = (options) => {
    const _this = this;
    const { sort, filterValues } = this.state;
    const { updatePurchaseProduct } = this.props;

    this.setState({ loading: true });

    updatePurchaseProduct(options)
      .then(function() {
        _this.setState({ loading: false });
        //keep existing filtr & sort state
        _this.resetTableState(
          {
            filterValues: filterValues,
            sort: sort,
          },
          _this.handleFetch
        );
      })
      .catch(function(err) {
        _this.setState({ loading: false });
        console.log(err);
      });
  };

  render() {
    const columns = this.columns;
    const {
      purchases = [],
      selectedPurchaseId,
      pageInfo = {},
      suppliers = [],
      initialValues = {},
    } = this.state;

    const _this = this;

    return (
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={12}>
              <PageTitle>Purchases</PageTitle>
            </Col>
            <Divider />
            <br />
          </Row>
        </Col>
        <Col xs={24} xl={18}>
          <Filters
            initialValues={initialValues}
            suppliers={suppliers}
            onPurchaseDateRangeChange={this.onPurchaseDateRangeChange}
            onExpiryDateRangeChange={this.onExpiryDateRangeChange}
            onSearch={(filterValues) => {
              _this.resetTableState(
                { filterValues: filterValues },
                _this.handleFetch
              );
            }}
          />
        </Col>
        <Col xs={24}>
          <Divider />

          <Table
            style={{ marginBottom: 12 }}
            loading={this.state.loading}
            columns={columns}
            onChange={this.handleTableChange}
            dataSource={purchases}
            hasNextPage={pageInfo.hasNextPage}
            onPageChange={this.handleFetch}
            expandedRowRender={(record) => (
              <ExpandedRow
                purchase={record}
                updatePurchaseProduct={this.onUpdatePurchaseProduct}
              />
            )}
          />
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  deletePurchase,
  updatePurchaseProduct,
})(withRouter(withNamespaces()(Purchases)));
