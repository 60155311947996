import React from "react";
import { Row, Col, Input, Switch, Tooltip } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import DatePicker from "~/components/Common/DatePicker";
import { propName } from "~/_utils";
import viewModel from "./viewModel";
import FormItem, { FormItemTitle } from "~/components/Form/FormItem";
import SupplierSelect from "~/pages/Products/Product/SupplierSelect";

import SearchProduct from "~/components/Sell/SearchProduct";

class PurchaseInfo extends React.Component {
  render() {
    const {
      initialValues,
      form,
      suppliers = [],
      handleProductSelect,
      flexSearch,
      onProductsIndexOutOfSync,
      onUpdateProductPriceChange,
    } = this.props;
    return (
      <Row gutter={[16, 0]}>
        <Col xs={24} md={4}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.refNumber)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.refNumber)]
            }
          >
            {(options) => <Input {...options} />}
          </FormItem>
        </Col>
        <Col xs={24} md={6}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.supplierId)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.supplierId)]
            }
            form={form}
          >
            {(options, setValue) => (
              <SupplierSelect
                suppliers={suppliers}
                placeholder={options.placeholder}
                onChange={(value) => setValue(value)}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={6}>
          <FormItem
            form={form}
            propertyName={propName(initialValues, (o) => o.purchaseDate)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.purchaseDate)]
            }
          >
            {({ options }, setValue) => (
              <DatePicker style={{ width: "100%" }} />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.updateProductPrice)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.updateProductPrice)]
            }
            form={form}
            valuePropName="checked"
            info={{
              text:
                "The purchase price of the product will be modified in conjunction with the selling price, in accordance with the pre-defined markup.",
            }}
          >
            {(options, setValue) => {
              return (
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(checked) => {
                    onUpdateProductPriceChange(checked);
                  }}
                />
              );
            }}
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItemTitle title={"Product"} />
          <SearchProduct
            onSelect={handleProductSelect}
            flexSearch={flexSearch}
            onProductsIndexOutOfSync={onProductsIndexOutOfSync}
            clearTextOnSelect={true}
            showPrice={false}
          />
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.notes)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.notes)]
            }
          >
            {(options) => <Input {...options} />}
          </FormItem>
        </Col>
        <div style={{ height: "0px" }}>
          <FormItem
            form={form}
            propertyName={propName(initialValues, (o) => o.purchaseId)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.purchaseId)]
            }
            showFormTitle={false}
          >
            {(options) => <Input type="hidden" {...options} />}
          </FormItem>
        </div>
      </Row>
    );
  }
}

export default PurchaseInfo;
