import React from "react";
import PropTypes from "prop-types";
import "./MainLayout.scss";
import { Layout } from "antd";
import SiderMenu from "../components/SiderMenu";
import TopHeader from "../components/TopHeader";
import { connect } from "react-redux";
import { toggleSideMenu } from "../actions/sideMenuActions";
import Notification from "~/components/Notification";
import Dialog from "~/components/Common/Dialog";
import { dispatchForOfflineFlexSearchReady } from "~/actions/offlineActions";

const { Content } = Layout;

class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOnline: this.props.isOnline };
  }
  componentDidMount() {
    const { dispatchForOfflineFlexSearchReady, location = {} } = this.props;

    //if its not a sell page then flex search needs to be loaded
    if (location.pathname.toLowerCase().endsWith("/sell") === false) {
      dispatchForOfflineFlexSearchReady();
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { isOnline = true } = prevState;

    if (isOnline !== nextProps.isOnline) {
      return { isOnline: nextProps.isOnline };
    }

    return null;
  }
  render() {
    //TODO: if offline , then render Offline component. Subscribe to online/offline state change in "General reducer". Children should be "offline component"
    const {
      collapsed,
      toggleSideMenu,
      children,
      subscription = {},
    } = this.props;
    const { isOnline = true } = this.state;
    return (
      <div className="mainlayout-main-container">
        <Layout>
          <SiderMenu
            collapsed={collapsed}
            accordion={true}
            isOnline={isOnline}
          />
          <Layout>
            <TopHeader
              toggle={toggleSideMenu}
              collapsed={collapsed}
              isOnline={isOnline}
              subscription={subscription}
            />
            <Content>{children}</Content>
          </Layout>
        </Layout>
        <Notification />
        <Dialog />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  collapsed: state.sideMenu.collapsed,
  isOnline: state.offline.isOnline,
});

MainLayout.propTypes = {
  toggleSideMenu: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
  toggleSideMenu,
  dispatchForOfflineFlexSearchReady,
})(MainLayout);
