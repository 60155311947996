import React from "react";
import Button from "~/components/Common/Button";
import { Row, Col, Menu, Dropdown, Divider } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { sellBucketStorageName } from "~/config";
import { localStorageGetItem, translate } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";
import "./retrieveButton.scss";

const menu = (props) => {
  const { onClick } = props;
  const parkedSellBucketJson = localStorageGetItem(sellBucketStorageName);
  let parkedSellBuckets =
    parkedSellBucketJson && JSON.parse(parkedSellBucketJson);

  if (!parkedSellBuckets || parkedSellBuckets.length === 0) {
    return <></>;
  }

  //latest first
  parkedSellBuckets = parkedSellBuckets.reverse();

  return (
    <Menu className="menu">
      {parkedSellBuckets.map((bucket, index) => {
        const bucketProduct = bucket.products[0] ? bucket.products[0] : {};
        const bucketProductText = `${bucketProduct.name} ${
          bucketProduct.variant ? bucketProduct.variant : ""
        } x ${bucketProduct.quantity} ...`;
        return (
          <Menu.Item key={index} onClick={() => onClick(bucket.saleCode)}>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <span className="date">{bucket.date}</span>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                <span>{bucketProductText}</span>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <span className="note">{bucket.note ? bucket.note : ""}</span>
              </Col>
            </Row>
            <Divider />
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const RetrieveButton = (props) => {
  const { onClick, ...otherProps } = props;
  return (
    <Dropdown overlay={menu({ onClick, ...props })} trigger={["click"]}>
      <Button type="link" icon={<ReloadOutlined />} {...otherProps}>
        {translate("RetrieveSale", {
          namespace: TRANSLATION_NAMESPACE.Sell,
        })}
      </Button>
    </Dropdown>
  );
};

export default RetrieveButton;
