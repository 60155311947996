import { translateFieldValidation, translate } from "~/_utils";
import { FIELD_VALIDATION_TYPE, TRANSLATION_NAMESPACE } from "~/_utils/consts";

let viewModel = {
  purchaseId: {},
  refNumber: {
    props: {
      placeholder: translate("RefNumber", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.refNumber.props.placeholder
          );
        },
      },
    ],
  },
  updateProductPrice: {
    props: {
      placeholder: translate("UpdateProductPrice", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  supplierId: {
    props: {
      placeholder: translate("Supplier", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.supplierId.props.placeholder
          );
        },
      },
    ],
  },
  purchaseDate: {
    props: {
      placeholder: translate("PurchaseDate", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.purchaseDate.props.placeholder
          );
        },
      },
    ],
  },
  notes: {
    props: {
      placeholder: translate("Remarks", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  additionalDiscountPercentage: {
    props: {
      placeholder: translate("Additional Disc %", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  additionalTaxPercentage: {
    props: {
      placeholder: translate("Additional Tax %", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  includeAdditionalTaxInProductPrice: {
    props: {
      placeholder: translate("Add additional (Tax & Disc) in product price", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  totalPurchasePriceExTax: {
    props: {
      placeholder: translate("totalPriceExTax", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  totalPurchasePrice: {
    props: {
      placeholder: translate("totalPriceIncTax", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  purchaseProducts: [
    {
      purchaseProductId: {},
      productId: {},
      inStock: {},
      purchasePriceTotalPerProduct: {},
      batchNumber: {
        props: {
          placeholder: translate("BatchNumber", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
        rules: [
          {
            required: true,
            get message() {
              return translateFieldValidation(
                FIELD_VALIDATION_TYPE.Required,
                viewModel.purchaseProducts[0].batchNumber.props.placeholder
              );
            },
          },
        ],
      },
      purchasePrice: {
        props: {
          placeholder: translate("Price/Unit (Inc Tax)", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      totalPurchasePrice: {
        props: {
          placeholder: translate("Total Price (Inc Tax)", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      expiryDate: {
        props: {
          placeholder: translate("ExpiryDate", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      quantity: {
        props: {
          placeholder: translate("Quantity", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      discountPercentage: {
        props: {
          placeholder: translate("Discount %", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      taxPercentage: {
        props: {
          placeholder: translate("Tax %", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      purchasePriceExTax: {
        props: {
          placeholder: translate("Price/Unit (Ex Tax)", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      totalPurchasePriceExTax: {
        props: {
          placeholder: translate("Total Price (Ex Tax)", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
    },
  ],
};

export default viewModel;
