import orderBy from "lodash/orderBy";
import {
  recordSavedSuccess,
  recordSavedFailed,
  recordDeleteSuccess,
  recordDeleteFailed,
} from "./generalActions";
import { updateClientDbForQuickKeys } from "~/_offline";
import { prepareOfflineSell } from "~/actions/offlineActions";
import { mutate, query } from "~/_utils/httpClient";

////////////// PRODUCT ///////////////////////////////////////////////

const GET_PRODUCTS_COLUMNS = `
      productId,
      description
      barcode
      name
      brandId
      brandName
      categoryId
      categoryName
      supplierId
      supplierName
      currentInventory
      reOrderPoint
      purchasePrice
      avgPurchasePrice
      retailPriceExTax
      retailPriceIncTax
      taxName
      isLowInventory      
      markup      
      productVariants{
        productId
        barcode
        name
        supplierName
        purchasePrice
        avgPurchasePrice
        retailPriceExTax
        retailPriceIncTax
        sellOnPointOfSale
        currentInventory
        reOrderPoint
        taxName
        isLowInventory
        markup
      }
  `;
const GET_PRODUCT_COLUMNS = `
      productId
      name
      barcode
      brandId
      categoryId
      currentInventory
      description
      markup
      parentProductId
      productAttributes{
        attributeId        
        values
      }
      productType
      productVariants{
        barcode
        currentInventory
        markup
        name
        parentProductId
        productId
        purchasePrice
        avgPurchasePrice
        reOrderPoint
        retailPriceExTax
        retailPriceIncTax
        sellOnPointOfSale
        sku
        supplierId
        taxId      
      }
      purchasePrice
      avgPurchasePrice
      reOrderPoint
      retailPriceExTax
      retailPriceIncTax
      sellOnPointOfSale
      sku
      supplierId
      taxId
`;

const GET_PRODUCT_INFO_COLUMNS = `
productId,
name,
barcode,
purchasePrice,
avgPurchasePrice,
tax {
  taxId
  percentage
}
`;
const SAVE_PRODUCT_COLUMNS = GET_PRODUCT_COLUMNS;
const SAVE_QUICKKEY_COLUMNS = `
quickKeyId
`;

const GET_QUICKKEY_COLUMNS = `
quickKeyId,
quickKeyProducts{
  productId
  name
  variant
  imageUrl
  color
  sequence
}
`;

export const getProduct = (options) => {
  const { id, infoOnly = false } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns:
          infoOnly === true ? GET_PRODUCT_INFO_COLUMNS : GET_PRODUCT_COLUMNS,
        entity: "product",
        id: id,
        idType: "Uuid!",
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const saveProduct = (product) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      let updatedProduct = product;
      mutate({
        command: product.productId ? "updateProduct" : "createProduct",
        commandType: product.productId
          ? "UpdateProductCommandInput!"
          : "CreateProductCommandInput!",
        data: product,
        columns: SAVE_PRODUCT_COLUMNS,
      })
        .then(function(result) {
          updatedProduct = result.data;
          dispatch(recordSavedSuccess("product", updatedProduct));
          resolve(updatedProduct);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("product", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getProducts = (options = {}) => {
  //To use OR in whre clause => OR:[{name:""}, {barcode:""}] ===> E-g: where: {or: [{barcode: {eq: "XTDDDSXROWRK"}}, {productVariants: {some: {barcode: {eq: "XTDDDSXROWRK"}}}}] }
  const { filterValues, sort, pageInfo = {}, enablePaging = true } = options;
  const { onlyLowInventory = false, ...restFilterValues } = filterValues;

  return new Promise(function(resolve, reject) {
    query({
      columns: GET_PRODUCTS_COLUMNS,
      entity: "products",
      filter: adjustProductFilter(restFilterValues),
      filterType: "ProductDtoFilterInput",
      additionalParameterName: "query",
      additionalParameterType: "GetProductsListQueryInput",
      additionalParameter: {
        onlyIncludeLowInventory: onlyLowInventory === true ? true : null,
      },
      sort: sort,
      sortType: "[ProductDtoSortInput!]",
      isList: true,
      pageInfo: pageInfo,
      enablePaging: enablePaging,
    })
      .then(function(result) {
        resolve(applyFilterToProductVariants(result, filterValues));
      })
      .catch(function(err) {
        reject(err);
      });
  });
};

const applyFilterToProductVariants = (result = {}, filter = {}) => {
  const { data = [] } = result;
  const updatedData = data.map((product) => {
    let filteredProductVariants = product.productVariants;

    filteredProductVariants =
      filter.onlyLowInventory === true
        ? filteredProductVariants.filter(
            (x) => x.isLowInventory === filter.onlyLowInventory
          )
        : filteredProductVariants;

    filteredProductVariants = filter.barcode
      ? filteredProductVariants.filter((x) => x.barcode === filter.barcode)
      : filteredProductVariants;

    return { ...product, productVariants: filteredProductVariants };
  });

  return { ...result, data: updatedData };
};

const adjustProductFilter = (filter) => {
  const barcode = filter && filter["barcode"];
  const supplierId = filter && filter["supplierId"];

  if (barcode && supplierId) {
    const barcodeSupplierId = `[
      {
        barcode: {eq: "${barcode}"},
          and: {or: ${queryBuilderProductWithVariants(
            "supplierId",
            supplierId,
            "eq"
          )} }
     }, 
     {
       productVariants: {some: {barcode: {eq: "${barcode}"}}},
       and: {or: ${queryBuilderProductWithVariants(
         "supplierId",
         supplierId,
         "eq"
       )} }
     }
    ]`;

    filter["or"] = eval(barcodeSupplierId);

    delete filter["barcode"];
    delete filter["supplierId"];
  } else if (barcode) {
    //or: [{barcode: {eq: "XTDDDSXROWRK"}}, {productVariants: {some: {barcode: {eq: "XTDDDSXROWRK"}}}}]
    const barcodeValue = queryBuilderProductWithVariants(
      "barcode",
      barcode,
      "eq"
    );
    filter["or"] = eval(barcodeValue);
    delete filter["barcode"];
  } else if (supplierId) {
    const supplierIdValue = queryBuilderProductWithVariants(
      "supplierId",
      supplierId,
      "eq"
    );
    filter["or"] = eval(supplierIdValue);
    delete filter["supplierId"];
  }

  return filter;
};

const queryBuilderProductWithVariants = (propertyName, value, operator) => {
  return `[{${propertyName}: {${operator}: "${value}"}}, {productVariants: {some: {${propertyName}: {${operator}: "${value}"}}}}]`;
};

export const deleteProduct = ({ id }) => (dispatch) => {
  const product = { productId: id };
  return new Promise(function(resolve, reject) {
    try {
      const deleteCommand = {
        productId: product.productId,
      };
      mutate({
        command: "deleteProduct",
        commandType: "DeleteProductCommandInput",
        data: deleteCommand,
        columns: {},
      })
        .then(function(result) {
          dispatch(recordDeleteSuccess("product", product));
          resolve(result);
        })
        .catch(function(err) {
          dispatch(recordDeleteFailed("product"));
          reject(err);
        });
    } catch (err) {
      dispatch(recordDeleteFailed("product."));
      reject(err);
    }
  });
};

//////////////////////////////////////////////////////////////

/////////// QUICK KEYS ///////////////////////////////////////

export const saveQuickKey = (quickKey = {}) => (dispatch) => {
  const quickKeysProducts = quickKey.quickKeyProducts || [];
  let savePayload = {
    quickKeyProducts: quickKeysProducts.map(
      ({ productId, sequence, color, ...restValues }) => {
        return {
          productId: productId,
          sequence: sequence,
          color: color,
        };
      }
    ),
  };

  if (quickKey.quickKeyId) {
    savePayload.quickKeyId = quickKey.quickKeyId;
  }

  return new Promise(function(resolve, reject) {
    try {
      mutate({
        command: quickKey.quickKeyId ? "updateQuickKey" : "createQuickKey",
        commandType: quickKey.quickKeyId
          ? "UpdateQuickKeyCommandInput!"
          : "CreateQuickKeyCommandInput!",
        data: savePayload,
        columns: SAVE_QUICKKEY_COLUMNS,
      })
        .then(function(result = {}) {
          quickKey.quickKeyId = result.data && result.data.quickKeyId;
          updateClientDbForQuickKeys({ ...quickKey });

          dispatch(recordSavedSuccess("quickkey", quickKey));
          resolve(quickKey);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("quickKey", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getQuickKey = (options = {}) => {
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_QUICKKEY_COLUMNS,
        entity: "quickKey",
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

////////////////////////////////////////////////////////

//////////// BRANDS /////////////////////////////////
const SAVE_BRAND_COLUMNS = `
    brandId
    name
`;

const GET_BRANDS_COLUMNS = `
      brandId,
      name
      description
      `;
const GET_BRAND_COLUMNS = GET_BRANDS_COLUMNS;

export const saveBrand = (brand) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      let updatedBrand = brand;
      mutate({
        command: brand.brandId ? "updateBrand" : "createBrand",
        commandType: brand.brandId
          ? "UpdateBrandCommandInput!"
          : "CreateBrandCommandInput!",
        data: brand,
        columns: SAVE_BRAND_COLUMNS,
      })
        .then(function(result) {
          updatedBrand.brandId = result.data.brandId;
          dispatch(recordSavedSuccess("brand", updatedBrand));
          resolve(updatedBrand);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("brand", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getBrand = (options) => {
  const { id } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_BRAND_COLUMNS,
        entity: "brand",
        id: id,
        idType: "Uuid!",
      })
        .then(function(result) {
          resolve(result);
        })
        .catch(function(err) {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getBrands = (options = {}) => {
  const { filterValues, sort, pageInfo = {}, enablePaging = true } = options;

  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_BRANDS_COLUMNS,
        entity: "brands",
        filter: filterValues,
        filterType: "BrandDtoFilterInput",
        sort: sort,
        sortType: "[BrandDtoSortInput!]",
        isList: true,
        pageInfo: pageInfo,
        enablePaging: enablePaging,
      })
        .then(function(result) {
          resolve(result);
        })
        .catch(function(err) {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const deleteBrand = ({ id }) => (dispatch) => {
  const brand = { brandId: id };
  return new Promise(function(resolve, reject) {
    try {
      const deleteCommand = {
        brandId: brand.brandId,
      };
      mutate({
        command: "deleteBrand",
        commandType: "DeleteBrandCommandInput",
        data: deleteCommand,
        columns: {},
      })
        .then(function(result) {
          dispatch(recordDeleteSuccess("brand", brand));
          resolve(result);
        })
        .catch(function(err) {
          dispatch(recordDeleteFailed("brand"));
          reject(err);
        });
    } catch (err) {
      dispatch(recordDeleteFailed("brand"));
      reject(err);
    }
  });
};

/////////////////////////////////////////////////////////////////////////

//////////////////// CATEGORIES ////////////////////////////////////////

const SAVE_CATEGORY_COLUMNS = `
    categoryId
    name
`;

const GET_CATEGORIES_COLUMNS = `
      categoryId,
      name
      description
      `;
const GET_CATEGORY_COLUMNS = GET_CATEGORIES_COLUMNS;

export const saveCategory = (category) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      let updatedCategory = category;
      mutate({
        command: category.categoryId ? "updateCategory" : "createCategory",
        commandType: category.categoryId
          ? "UpdateCategoryCommandInput!"
          : "CreateCategoryCommandInput!",
        data: category,
        columns: SAVE_CATEGORY_COLUMNS,
      })
        .then(function(result) {
          updatedCategory.categoryId = result.data.categoryId;
          dispatch(recordSavedSuccess("category", updatedCategory));
          resolve(updatedCategory);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("category", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCategory = (options) => {
  const { id } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_CATEGORY_COLUMNS,
        entity: "category",
        id: id,
        idType: "Uuid!",
      })
        .then(function(result) {
          resolve(result);
        })
        .catch(function(err) {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCategories = (options = {}) => {
  const { filterValues, sort, pageInfo = {}, enablePaging = true } = options;

  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_CATEGORIES_COLUMNS,
        entity: "categories",
        filter: filterValues,
        filterType: "CategoryDtoFilterInput",
        sort: sort,
        sortType: "[CategoryDtoSortInput!]",
        isList: true,
        pageInfo: pageInfo,
        enablePaging: enablePaging,
      })
        .then(function(result) {
          resolve(result);
        })
        .catch(function(err) {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const deleteCategory = ({ id }) => (dispatch) => {
  const category = { categoryId: id };
  return new Promise(function(resolve, reject) {
    try {
      const deleteCommand = {
        categoryId: category.categoryId,
      };
      mutate({
        command: "deleteCategory",
        commandType: "DeleteCategoryCommandInput",
        data: deleteCommand,
        columns: {},
      })
        .then(function(result) {
          dispatch(recordDeleteSuccess("category", category));
          resolve(result);
        })
        .catch(function(err) {
          dispatch(recordDeleteFailed("category"));
          reject(err);
        });
    } catch (err) {
      dispatch(recordDeleteFailed("category"));
      reject(err);
    }
  });
};

//////////////////// ATTRIBUTES ////////////////////////////////////////

const GET_ATTRIBUTES_COLUMNS = `
      attributeId,
      name
      `;
const GET_ATTRIBUTE_COLUMNS = GET_ATTRIBUTES_COLUMNS;
const SAVE_ATTRIBUTE_COLUMNS = GET_ATTRIBUTES_COLUMNS;

export const getAttributes = (options = {}) => {
  const { filterValues, sort, pageInfo = {}, enablePaging = true } = options;

  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_ATTRIBUTES_COLUMNS,
        entity: "attributes",
        filter: filterValues,
        filterType: "AttributeDtoFilterInput",
        sort: sort,
        sortType: "[AttributeDtoSortInput!]",
        isList: true,
        pageInfo: pageInfo,
        enablePaging: enablePaging,
      })
        .then(function(result) {
          resolve(result);
        })
        .catch(function(err) {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getAttribute = (options) => {
  const { id } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_ATTRIBUTE_COLUMNS,
        entity: "attribute",
        id: id,
        idType: "Uuid!",
      })
        .then(function(result) {
          resolve(result);
        })
        .catch(function(err) {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const saveAttribute = (attribute) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      let updatedAttribute = attribute;
      mutate({
        command: attribute.attributeId ? "updateAttribute" : "createAttribute",
        commandType: attribute.attributeId
          ? "UpdateAttributeCommandInput!"
          : "CreateAttributeCommandInput!",
        data: attribute,
        columns: SAVE_ATTRIBUTE_COLUMNS,
      })
        .then(function(result) {
          updatedAttribute.attributeId = result.data.attributeId;
          dispatch(recordSavedSuccess("attribute", updatedAttribute));
          resolve(updatedAttribute);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("attribute", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};
