import { dbCustomers, customersIndex } from "../config";
import FlexSearch from "flexsearch";
import {
  getAllDocuments,
  selectDocumentFromLocalDb,
  addUpdateDocumentsInDb,
} from "./";
import {
  isOnline,
  generateCode,
  removeLocalDbMetaData,
  initializeDB,
} from "~/_utils";

import store from "~/store";

import { mutate, query } from "~/_utils/httpClient";
import PouchDB from "pouchdb-browser";
import PouchdbFind from "pouchdb-find";

PouchDB.plugin(PouchdbFind);

const dbKeyPrefix = "customers";
const dbKeyProperty = "customerCode";
const dbIndexName = "customerIndex";

const SAVE_CUSTOMER_COLUMNS = `
    customerId
    customerCode
    balance
`;

const GET_CUSTOMER_OFFLINE_COLUMNS = `
      customerId 
      name
      customerCode      
      address
      description
      email
      openingBalance
      balance
      phone
`;

export const getCustomersForOffline = (options = {}) => {
  const {
    filterValues,
    sort,
    pageInfo = {},
    enablePaging = true,
    pageSizeValue,
  } = options;

  return new Promise(function(resolve, reject) {
    query({
      columns: GET_CUSTOMER_OFFLINE_COLUMNS,
      entity: "customersForOffline",
      filter: filterValues,
      filterType: "CustomerDtoFilterInput",
      sort: sort,
      sortType: "[CustomerDtoSortInput!]",
      isList: true,
      pageInfo: pageInfo,
      enablePaging: enablePaging,
      pageSizeValue: pageSizeValue,
    })
      .then(function(customers = {}) {
        const finalCustomers = [];
        customers.data.forEach((c) => {
          finalCustomers.push({
            ...c,
            id: c.customerCode,
            excludeFromSearch: c.isDeleted === true,
            isSynced: true,
          });
        });
        resolve({ ...customers, data: finalCustomers });
      })
      .catch(function(err) {
        reject(err);
      });
  });
};

export function getCustomersDb(options = {}) {
  const { uniqueCompanyName } = options;
  return initializeDB(dbCustomers, {
    uniqueCompanyName: uniqueCompanyName,
    revs_limit: 1,
    auto_compaction: true,
  });
}

function initializeCustomersIndex() {
  return new FlexSearch("customersIndex", {
    encode: "icase",
    tokenize: "forward",
    doc: {
      id: dbKeyProperty,
      field: customersIndex,
    },
  });
}

export const selectCustomerFromLocalDb = (value) => {
  return selectDocumentFromLocalDb({
    dbName: dbCustomers,
    keyPrefix: dbKeyPrefix,
    value: value,
  });
};

export const addUpdateCustomersInDb = (db, customers = []) => {
  if (!db) {
    db = getCustomersDb();
  }

  return new Promise(function(resolve, reject) {
    if (customers.length === 0) {
      return resolve();
    }

    addUpdateDocumentsInDb(db, dbKeyPrefix, dbKeyProperty, customers).then(
      function(result) {
        resolve();
      }
    );
  });
};

export const deleteCustomerInDb = (db, customer = {}) => {
  return new Promise(function(resolve, reject) {
    selectCustomerFromLocalDb(customer.customerCode)
      .then(function(result) {
        result._deleted = true;
        resolve(addUpdateCustomersInDb(db, [result]));
      })
      .catch(function(err) {
        reject(err);
      });
  });
};

export function getAllCustomersFromLocalDb() {
  return new Promise(function(resolve, reject) {
    const db = getCustomersDb();

    getAllDocuments(db, dbKeyPrefix, true)
      .then(function(result = {}) {
        resolve(result.rows && result.rows.map((x) => x.doc));
      })
      .catch(function(err) {
        console.log(err);
        reject(err);
      });
  });
}

export function getAllCustomersFromLocalDbNotSynced(options = {}) {
  return new Promise(function(resolve, reject) {
    const db = getCustomersDb(options);
    //Create an index if it doesn’t exist, or do nothing if it already exists. (Check if indexing is cheaper of getting allDocs is cheaper)
    db.createIndex({
      index: {
        fields: ["isSynced"],
      },
    })
      .then(function(result) {
        db.find({
          selector: { isSynced: false },
        })
          .then(function(result) {
            console.log("Customer not synced: ", result);
            resolve(result);
          })
          .catch(function(err) {
            console.log(err);
            reject(err);
          });
      })
      .catch(function(err) {
        console.log(err);
        reject(err);
      });
  });
}

export const getCustomerFlexSearch = (options = {}) => {
  const { areCustomersInLocalDBChanged = false } = options;

  return new Promise(function(resolve, reject) {
    var flexSearchFromState = store.getState().offline.flexSearchCustomer;

    if (flexSearchFromState && areCustomersInLocalDBChanged === false) {
      console.log("got existing flexsearch");
      return resolve(flexSearchFromState);
    }

    console.log("re-creating flexsearch...");
    var flexSearch = initializeCustomersIndex();
    getAllCustomersFromLocalDb()
      .then(function(customers) {
        flexSearch.add([
          ...customers.filter(
            (x) => !(x._deleted === true || x.excludeFromSearch === true)
          ),
        ]);
        resolve(flexSearch);
      })
      .catch(function() {
        resolve(flexSearch);
      });
  });
};

export const saveCustomerToServerAndInLocal = (
  customer,
  userToken,
  options = {}
) => {
  console.log("offlineCustomer - customer : ", customer);

  return new Promise(function(resolve, reject) {
    let updatedCustomer = customer;
    updatedCustomer.customerCode = customer.customerCode || generateCode();
    updatedCustomer.isSynced = false;

    if (isOnline() === false) {
      updatedCustomer.isSynced = false;
      updateCustomerClientDb(updatedCustomer)
        .then(function(updatedCustomer) {
          return resolve(updatedCustomer);
        })
        .catch(function() {
          return reject(updatedCustomer);
        });
    }

    try {
      mutate({
        command: customer.customerId ? "updateCustomer" : "createCustomer",
        commandType: customer.customerId
          ? "UpdateCustomerCommandInput!"
          : "CreateCustomerCommandInput!",
        data: getSaveModel(updatedCustomer),
        columns: SAVE_CUSTOMER_COLUMNS,
        userToken: userToken,
      })
        .then(function(result) {
          updatedCustomer.customerId = result.data.customerId;
          updatedCustomer.customerCode = result.data.customerCode;
          updatedCustomer.balance = result.data.balance;
          updatedCustomer.isSynced = true;

          updateCustomerClientDb(updatedCustomer, options)
            .then(function(updatedCustomer) {
              return resolve(updatedCustomer);
            })
            .catch(function() {
              return reject(updatedCustomer);
            });
        })
        .catch(function(err) {
          if (
            err &&
            err.validationErrors &&
            err.validationErrors.length > 0 &&
            err.validationErrors[0].name &&
            err.validationErrors[0].name.toLowerCase() ===
              "dbuniquekeyexception"
          ) {
            //err is DbUniqueKey violation then mark local customer as synced
            updatedCustomer.isSynced = true;
          } else {
            updatedCustomer.isSynced = false;
          }
          updateCustomerClientDb(updatedCustomer, options)
            .then(function(updatedCustomer) {
              return resolve(updatedCustomer);
            })
            .catch(function() {
              return reject(updatedCustomer);
            });
        });
    } catch {
      return resolve(updatedCustomer);
    }
  });
};

const updateCustomerClientDb = (customer = {}, options = {}) => {
  return new Promise(function(resolve, reject) {
    const updatedCustomer = {
      ...customer,
      id: customer.customerCode,
      excludeFromSearch: customer.isDeleted === true,
    };
    addUpdateCustomersInDb(getCustomersDb(options), [updatedCustomer])
      .then(function(result) {
        resolve(updatedCustomer);
      })
      .catch(function(err) {
        console.log(err);
        reject(err);
      });
  });
};

const getSaveModel = (customer = {}) => {
  delete customer.balance; // balance is computed from the server;
  delete customer.id;
  delete customer.isSynced;
  delete customer.excludeFromSearch;
  return removeLocalDbMetaData(customer);
};
