import React from "react";
import Media from "react-media";
import { Table as AntdTable, Divider } from "antd";
import {
  PrinterOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import Spin from "~/components/Common/Spin";
import Button from "~/components/Common/Button";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router-dom";
import { constructUrl } from "~/config";

import "./index.scss";

class Content extends React.Component {
  render() {
    const {
      inPrintPreview = false,
      style = {},
      footer,
      printOptions = {},
      clientSidePaging = false,
      onChange,
      ...restOptions
    } = this.props;
    let { height, ...restStyle } = style;
    height = inPrintPreview === true ? "unset" : height;
    const { printHeader } = printOptions;

    return (
      <div className="tableContainer">
        {printHeader ? (
          <div className="printHeader">
            {printHeader}
            <Divider />
          </div>
        ) : (
          <></>
        )}
        <div className="tableWrapper" style={{ height: height, ...restStyle }}>
          <AntdTable
            scroll={{
              scrollToFirstRowOnChange: false,
            }}
            onChange={onChange}
            pagination={clientSidePaging === true}
            {...restOptions}
          />
        </div>
        {footer ? (
          <div style={{ paddingTop: "10px" }}>
            <Divider />
            {footer}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inPrintPreview: false,
    };

    this.needClientSideLoading = this.needClientSideLoading.bind();
  }
  componentDidMount() {
    const _this = this;
    const { onPageChange } = this.props;

    let isScrolling = false;

    const node = document.querySelector(".tableWrapper");
    if (node && !_this.needClientSideLoading() === true) {
      node.addEventListener("scroll", () => {
        const perc =
          (node.scrollTop / (node.scrollHeight - node.clientHeight)) * 100;

        if (perc >= 98 && isScrolling === false) {
          isScrolling = true;
          onPageChange && onPageChange();
        }
      });
      node.addEventListener("scrollend", () => {
        isScrolling = false;
      });
    }
  }
  needClientSideLoading = () => {
    const { clientSidePaging = false, dataSource = [] } = this.props;
    return clientSidePaging === true && dataSource.length > 0;
  };

  render() {
    const {
      scroll = {},
      style = {},
      footer,
      allowPrint: allowPrintProps = false,
      allowCsvExport = false,
      dataSource = [],
      clientSidePaging = false,
      loading = false,
      onChange,
      history,
      match,
      links,
      ...restProps
    } = this.props;
    const { heightMd = 530, heightS = 330, widthS = 600 } = scroll;
    const { marginBottom: marginBottomProps = 0 } = style;
    const { columns = [] } = restProps || {};
    const { inPrintPreview = false, clientSideDataSource = [] } = this.state;
    const csvHeaders = columns.map(({ title, key, ...restValues }) => {
      return { label: title, key: key };
    });

    const allowPrint = clientSidePaging === true ? false : allowPrintProps;

    const _this = this;

    return (
      <>
        <Media query="(min-height: 768px)">
          {(matches) => {
            const showExportOptions =
              allowPrint === true || allowCsvExport === true;
            const scrollY = matches ? heightMd : heightS;
            const scrollX = widthS;
            let marginBottom = matches ? 335 : 235;
            marginBottom = marginBottom + marginBottomProps;
            marginBottom = marginBottom + (showExportOptions === true ? 30 : 0);

            return (
              <Spin spinning={loading}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    float: "right",
                  }}
                >
                  {allowPrint === true && (
                    <div>
                      <ReactToPrint
                        bodyClass={"tableContent-container print"}
                        onBeforeGetContent={() => {
                          return new Promise(function(resolve, reject) {
                            _this.setState({ inPrintPreview: true }, () => {
                              resolve();
                            });
                          });
                        }}
                        onAfterPrint={() => {
                          _this.setState({ inPrintPreview: false });
                        }}
                        trigger={() => {
                          // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                          // to the root node of the returned component as it will be overwritten.
                          const _this = this;
                          return (
                            <Button
                              type="link"
                              onClick={(e) => {}}
                              icon={<PrinterOutlined />}
                            >
                              {"Print"}
                            </Button>
                          );
                        }}
                        content={() => this.contentRef}
                      />
                    </div>
                  )}
                  {allowCsvExport === true && (
                    <div>
                      <CSVLink
                        data={dataSource}
                        headers={csvHeaders}
                        filename={"data.csv"}
                      >
                        <Button
                          type="link"
                          onClick={(e) => {}}
                          icon={<ContainerOutlined />}
                        >
                          <span style={{ marginLeft: "4px" }}>{"CSV"}</span>
                        </Button>
                      </CSVLink>
                    </div>
                  )}
                  {links &&
                    links.length > 0 &&
                    links.map((link = {}) => {
                      const { text = "", url = "" } = link;
                      return (
                        <div>
                          {" | "}
                          <Button
                            type="link"
                            onClick={(e) => {
                              const absoluteUrl = constructUrl({
                                match,
                                to: `${url}`,
                              });
                              history && history.push(absoluteUrl);
                            }}
                          >
                            <span>{text}</span>
                          </Button>
                        </div>
                      );
                    })}
                </div>
                <Content
                  inPrintPreview={inPrintPreview}
                  clientSidePaging={clientSidePaging}
                  style={{
                    width: "100%",
                    height: `calc(100vh - ${marginBottom}px)`,
                    overflowX: "auto",
                  }}
                  dataSource={dataSource}
                  footer={footer}
                  onChange={onChange}
                  {...restProps}
                  ref={(el) => (this.contentRef = el)}
                />
              </Spin>
            );
          }}
        </Media>
      </>
    );
  }
}

export default withRouter(Table);
