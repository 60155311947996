import React from "react";
import { Row, Col, Typography, Select as SelectAntD, Input } from "antd";

import { propName } from "~/_utils";
import viewModel from "./viewModel";
import FormItem from "~/components/Form/FormItem";

import InputDecimal from "~/components/Common/InputDecimal";
import SupplierSelect from "./SupplierSelect";

const { Title, Text } = Typography;
const { Option } = SelectAntD;

class Inventory extends React.Component {
  render() {
    const { initialValues, form = {}, suppliers = [] } = this.props;

    return (
      <Row gutter={[16, 8]}>
        <Col xs={24}>
          <Title level={4}>Inventory</Title>
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.barcode)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.barcode)]
            }
          >
            {(options) => (
              <Input
                {...options}
                onPressEnter={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }}
              />
            )}
          </FormItem>
        </Col>

        <Col xs={24} md={12}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.supplierId)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.supplierId)]
            }
            form={form}
          >
            {(options, setValue) => (
              <SupplierSelect
                suppliers={suppliers}
                placeholder={options.placeholder}
                onChange={(value) => setValue(value)}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.currentInventory)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.currentInventory)]
            }
          >
            {({ placeholder, ...restOptions }) => (
              <InputDecimal style={{ width: "100%" }} {...restOptions} />
            )}
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.reOrderPoint)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.reOrderPoint)]
            }
          >
            {({ placeholder, ...restOptions }) => (
              <InputDecimal style={{ width: "100%" }} {...restOptions} />
            )}
          </FormItem>
        </Col>
      </Row>
    );
  }
}

export default Inventory;
