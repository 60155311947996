import i18n from "../i18n";
import {
  TRANSLATION_NAMESPACE,
  RIGHTS,
  PAYMENTMETHODS,
  SESSIONSTORAGE,
} from "./consts";
import { defaultCurrency } from "../config";
import round from "lodash/round";
import camelCase from "lodash/camelCase";
import store from "~/store";
import { auth } from "~/_auth/base";
import parseWithMoment from "./jsonParserWithMoment";
import PouchDB from "pouchdb-browser";
import { LOCALSTORAGE } from "~/_utils/consts";

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function convertToCamelCase(value) {
  return camelCase(value);
}

export function isUrl(path) {
  return reg.test(path);
}

export function hasRights(functionPoint, permissions = [], options = {}) {
  if (!options.user || !options.user.FunctionPoints) {
    return false;
  }

  const userPermissionsForFunctionPoint = getUserPermissionsForFunctionPoint(
    functionPoint,
    options.user
  );

  if (options.rights === RIGHTS.All) {
    return permissions.every((p) =>
      userPermissionsForFunctionPoint.includes(p)
    );
  }

  //TODO: check not working.. only full match is working/....any permissions matched with user permissions for a function point
  return permissions.some((p) => userPermissionsForFunctionPoint.includes(p));
}

function getUserPermissionsForFunctionPoint(functionPoint, user) {
  //functionPoint ends with wildcard "*" (e-g: main menu "products" and scope is more granular like "products_overview", "products_stockcontrol")
  const matchedUserFunctionPoint = functionPoint.endsWith("*")
    ? user.FunctionPoints.filter((fp) =>
        fp.Name.toUpperCase().startsWith(
          functionPoint.substring(0, functionPoint.length - 1).toUpperCase()
        )
      )
    : user.FunctionPoints.filter(
        (fp) => fp.Name.toUpperCase() === functionPoint.toUpperCase()
      );
  const userPermissionsForFunctionPoint = matchedUserFunctionPoint
    ? matchedUserFunctionPoint.map((fp) => fp.Permission)
    : [];

  return userPermissionsForFunctionPoint;
}

export function translateFieldValidation(fieldValidationType, fieldName) {
  const options = {
    placeholder: {
      fieldName: fieldName,
    },
    namespace: TRANSLATION_NAMESPACE.Validation,
  };

  return translate(fieldValidationType, options);
}

export function translate(key, options = {}) {
  const translationKey = options.namespace
    ? `${options.namespace}:${key}`
    : key;

  if (options.defaultText) {
    return i18n.t([translationKey, options.defaultText], options.placeholder);
  }
  return i18n.t(translationKey, options.placeholder);
}

export function toFloat(value, precision = 2) {
  const floatValue = round(value, precision) || 0;
  return floatValue;
}

export function price(value, includeCurrency = true) {
  let price = priceFloat(value).toFixed(2);
  const formatter = new Intl.NumberFormat("en-US", {});
  price = formatter.format(price);
  const companySettings = getLocalCompanySettings();
  const currency = companySettings.currency || defaultCurrency;
  return includeCurrency === true ? `${currency}${price}` : `${price}`;
}

export function formatNumber(value) {
  let number = toFloat(value).toFixed(2);
  const formatter = new Intl.NumberFormat("en-US", {});
  number = formatter.format(number);
  return `${number}`;
}

export function priceFloat(value) {
  const price = toFloat(value, 2);
  return price;
}

export function generateSaleCode() {
  return generateCode(7);
}

export function generateCode(length = 14) {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 12 characters
  // after the decimal.
  return Math.random()
    .toString(36)
    .substr(2, length)
    .toUpperCase();
}

export function getCurrentDate() {
  var date = new Date();
  return formatDateTime(date);
}

export function getDateWithTimeRange(fromDate, toDate) {
  fromDate = fromDate || new Date();
  toDate = toDate || new Date();
  let startDate = fromDate._d || fromDate; // localdateTime
  let endDate = toDate._d || toDate; //localDatetime

  startDate.setHours(0, 0, 0, 0); //start from beginning of the day

  endDate.setDate(endDate.getDate() + 1); //till end of the day (till last milli second)
  endDate.setHours(0, 0, 0, -1);

  return [startDate, endDate];
}

export function getCurrentDateUtcIso() {
  var date = new Date();
  return date.toISOString();
}

export function convertDateToUtcIso(date) {
  return date.toISOString();
}

export function treatDateAsUtcDateTime(date) {
  if (!date) {
    return "";
  }

  date = new Date(date);

  var utcDateWithCorrectTimeZone = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  ).toISOString();

  return utcDateWithCorrectTimeZone;
}

export function convertUtcDateToLocalDateTime(
  utcDate,
  formatDate = true,
  includeTime = true
) {
  if (!utcDate) {
    return "";
  }
  var utcDateWithCorrectTimeZone = treatDateAsUtcDateTime(utcDate);

  let localDateTime = new Date(utcDateWithCorrectTimeZone);
  if (formatDate === true) {
    return (localDateTime = formatDateTime(localDateTime, includeTime));
  }

  return localDateTime.toLocaleString();
}

export function formatDateTime(date, includeTime = true) {
  date = date._isAMomentObject === true ? date._d : date;

  const time =
    includeTime === true
      ? `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      : "";

  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}  ${
    includeTime === true ? time : ""
  }`;
}

export function formatInSqlDateTime(date, includeTime = true) {
  const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}  ${
    includeTime === true ? time : ""
  }`;
}

export function getUniqueCompanyNameFromEmail(email = "") {
  return email.substring(email.lastIndexOf("@") + 1, email.lastIndexOf("."));
}

export function getCurrentUserNameWithoutUniqueCompanyName() {
  const user = getLoggedInUser() || {};
  const email = user.email || "";
  const userName = email.substring(0, email.lastIndexOf("@"));

  return userName || "";
}

export function getUniqueCompanyName(options) {
  try {
    const uniqueCompanyNameFromSession = sessionStorage.getItem(
      SESSIONSTORAGE.uniqueCompanyName
    );
    if (
      uniqueCompanyNameFromSession &&
      uniqueCompanyNameFromSession.length > 0
    ) {
      return uniqueCompanyNameFromSession;
    }

    let { user = getLoggedInUser() || {} } = options;
    const email = user.email || "";
    const uniqueCompanyName = getUniqueCompanyNameFromEmail(email);
    return uniqueCompanyName || "";
  } catch {
    return "";
  }
}

export function getLoggedInUser() {
  let user = {};

  try {
    user = auth().currentUser;
  } catch {}

  return user ? user : {};
}

export function mapToArray(map) {
  let objArray = Array.from(map).reduce(
    (objArray, [key, value]) => Object.assign(objArray, { [key]: value }),
    {}
  );

  return objArray;
}

export function propName(obj, expression) {
  var res = {};

  Object.keys(obj).map((k) => {
    res[k] = () => k;
  });

  return Object.keys(res).length > 0 && expression(res)();
}

export function constructFormJson(propertyName, value) {
  if (value === undefined) {
    value = null;
  }
  let json = `{"${propertyName}": ${value}}`;
  if (typeof value === "string") {
    json = `{"${propertyName}": "${value ? value : ""}"}`;
  }
  if (value && value._isAMomentObject === true) {
    json = `{"${propertyName}": "${value ? value._d.toISOString() : ""}"}`;

    return parseWithMoment(json);
  }

  //console.log(json);
  return JSON.parse(json);
}

export function removeItemFromIndex(items, index) {
  return items.slice(0, index).concat(items.slice(index + 1, items.length));
}

export const combineArrayValues = (
  [head, ...[headTail, ...tailTail]],
  separator = ""
) => {
  if (!headTail) return head;

  const combined = headTail.reduce((acc, x) => {
    return acc.concat(head.map((h) => `${h}${separator}${x}`));
  }, []);

  return combineArrayValues([combined, ...tailTail], separator);
};

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const isEmpty = (o) =>
  o === "undefined" ||
  o == null ||
  (Object.keys(o).length === 0 &&
    typeof o !== "boolean" &&
    typeof o !== "number");

export const removeEmptyObject = (function() {
  const isNotObject = (v) => v === null || typeof v !== "object";

  return function(obj) {
    if (isNotObject(obj) && isEmpty(obj)) {
      return {};
    }
    if (isNotObject(obj)) {
      return obj;
    }

    if (obj instanceof Array) {
      for (let i = 0; i < obj.length; i += 1) {
        if (isNotObject(obj[i])) continue;
        obj[i] = removeEmptyObject(obj[i]);
        if (isEmpty(obj[i])) obj.splice(i--, 1);
        else obj[i] = removeEmptyObject(obj[i]);
      }
    } else {
      for (let p in obj) {
        if (p === "neq") continue; //skip neq operator. e-g: notes: {neq: ""}, and: {notes: {neq: null}}
        if (isNotObject(obj[p]) && isEmpty(obj[p])) delete obj[p];
        if (isNotObject(obj[p]) && !isEmpty(obj[p])) continue;
        if (!isEmpty(obj[p])) obj[p] = removeEmptyObject(obj[p]);
        if (isEmpty(obj[p])) delete obj[p];
      }
    }
    return obj;
  };
})();

export const getScreenHeight = () => {
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  return vh;
};

export const isOnline = () => {
  try {
    const appState = store.getState() || {};
    const isOnline = appState.offline.isOnline !== false;
    console.log("Utility isOnline: ", isOnline);
    return isOnline;
  } catch {}

  return true;
};

export const getPaymentMethodString = (paymentMethod) => {
  if (paymentMethod === PAYMENTMETHODS.CASH) {
    return "Cash";
  }
  if (paymentMethod === PAYMENTMETHODS.CREDITCARD) {
    return "Credit card";
  }
  if (paymentMethod === PAYMENTMETHODS.CREDIT) {
    return "Credit";
  }
};

export const removeLocalDbMetaData = (obj) => {
  const updatedObject = JSON.parse(
    JSON.stringify(obj, (k, v) => (k.startsWith("_") ? undefined : v))
  );
  return updatedObject;
};

export const getClientTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export function initializeDB(dbName = "", options = {}) {
  const { uniqueCompanyName, ...restOptions } = options;
  dbName = appendUniqueCompanyName(dbName, uniqueCompanyName);
  return new PouchDB(dbName, restOptions);
}

export function localStorageSetItem(key = "", val = "") {
  key = appendUniqueCompanyName(key);
  localStorage.setItem(key, val);
}

export function localStorageGetItem(key = "") {
  key = appendUniqueCompanyName(key);
  return localStorage.getItem(key);
}

export function tryConvertToGuid(str = "") {
  if (str.includes("-") === true && isValidGuid(str)) {
    return str;
  }
  var parts = [];
  parts.push(str.slice(0, 8));
  parts.push(str.slice(8, 12));
  parts.push(str.slice(12, 16));
  parts.push(str.slice(16, 20));
  parts.push(str.slice(20, 32));
  var GUID = parts.join("-");
  if (isValidGuid(GUID) === false) {
    return;
  }

  return GUID;
}

export function isValidGuid(val = "") {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  if (val.includes("-") === false) {
    val = tryConvertToGuid(val);
  }
  return regexExp.test(val);
}

export function getLocalCompanySettings() {
  const companySettings = JSON.parse(
    localStorageGetItem(LOCALSTORAGE.companySettings) || "{}"
  );

  return companySettings;
}

export function reloadPageWithQueryParam(queryParamName, queryParamValue) {
  // Get the current URL
  const currentUrl = new URL(window.location.href);

  if (queryParamName) {
    // Add or update the query parameter
    currentUrl.searchParams.set(queryParamName, queryParamValue);
  }

  // Reload the page with the modified URL
  window.location.href = currentUrl.toString();
}

function appendUniqueCompanyName(key = "", uniqueCompanyName) {
  uniqueCompanyName =
    uniqueCompanyName && uniqueCompanyName.length > 0
      ? uniqueCompanyName
      : getUniqueCompanyName();
  return `${uniqueCompanyName}_${key}`;
}
