import React from "react";
import { notification } from "antd";
import { connect } from "react-redux";
import { HTTPSTATUS } from "~/_utils/consts";

const SUCCESS = "success";
const FAILED = "error";
const SAVE = "save";
const DELETE = "delete";
const GENERAL = "general";

const openNotificationWithIcon = (
  actionType,
  notificationType,
  statusCode,
  customMessage
) => {
  if (!notificationType) {
    return;
  }

  notification[notificationType]({
    message: getTitle(notificationType),
    description: getDescription(
      actionType,
      notificationType,
      statusCode,
      customMessage
    ),
    duration: notificationType === FAILED ? 4 : 2,
  });
};

const getTitle = (notificationType) => {
  if (notificationType === SUCCESS) {
    return "Success";
  }
  if (notificationType === FAILED) {
    return "Error";
  }
};
const getDescription = (
  actionType,
  notificationType,
  statusCode,
  customMessage
) => {
  if (actionType === GENERAL && !statusCode) {
    return customMessage;
  }
  if (
    notificationType === FAILED &&
    statusCode === HTTPSTATUS.AuthenticationError
  ) {
    return "Unable to authenticate. Please check credentials.";
  }
  if (
    notificationType === FAILED &&
    statusCode === HTTPSTATUS.AuthNetworkError
  ) {
    return "Network error. Please check your internet.";
  }
  if (actionType === SAVE) {
    if (notificationType === SUCCESS) {
      return "Record saved successfully";
    }
    if (
      notificationType === FAILED &&
      statusCode === HTTPSTATUS.ValidationError
    ) {
      return "Some values are not correct. Please enter correct values";
    }
    if (notificationType === FAILED) {
      return "Unable to save the record. Please try again.";
    }
  }
  if (actionType === DELETE) {
    if (notificationType === SUCCESS) {
      return "Record deleted successfully";
    }
    if (notificationType === FAILED) {
      return "Unable to delete the record. Please try again.";
    }
  }
};

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = { processedActionId: props.actionId || "" };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { lastAction } = nextProps;

    //on successful login no need to show any message
    if (
      lastAction &&
      lastAction.entity === "login" &&
      lastAction.isSuccess === true
    ) {
      return null;
    }

    if (lastAction && prevState.processedActionId !== lastAction.actionId) {
      const { payload = {} } = lastAction;
      let notificationType = lastAction.isSuccess === true ? SUCCESS : "";
      notificationType =
        notificationType === "" && lastAction.isSuccess === false
          ? FAILED
          : notificationType;
      openNotificationWithIcon(
        lastAction.type,
        notificationType,
        payload.code,
        payload.customMessage
      );
      return { processedActionId: lastAction.actionId };
    }
    return null;
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (state, ownProps = {}) => {
  const lastAction = state.general.lastAction;

  if (
    lastAction &&
    (lastAction.type === SAVE ||
      lastAction.type === DELETE ||
      lastAction.type === GENERAL)
  ) {
    return { lastAction: state.general.lastAction };
  }
  return {};
};

export default connect(mapStateToProps, {})(Notification);
