import React from "react";
import { useBarcode } from "react-barcodes";

export default function Barcode(props) {
  const { value } = props;
  const { inputRef } = useBarcode({
    value: value,
    options: {
      background: "#ffffff",
      width: 1.25,
      height: 50,
    },
  });

  return <svg ref={inputRef} />;
}
