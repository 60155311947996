import React from "react";
import { Select as AntdSelect, Divider } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { timeout } from "~/_utils";

import Button from "../Button";

const { Option } = AntdSelect;

class Select extends React.Component {
  render() {
    const {
      placeholder,
      data = [],
      newItemOptions = {},
      ...restProps
    } = this.props;
    const { allowAddNewItem, title = "", onClick } = newItemOptions;
    return (
      <AntdSelect
        placeholder={placeholder}
        dropdownRender={(menu) => (
          <>
            {menu}
            {allowAddNewItem === true && (
              <>
                <Divider />
                <Button
                  style={{ paddingLeft: "10px" }}
                  onClick={(e) => {
                    //in order to make the dropdown close need to add a delay in calling onClick
                    timeout(100).then(function() {
                      onClick(e);
                    });
                  }}
                  type="link"
                  icon={<PlusCircleOutlined />}
                >{`${title}`}</Button>
              </>
            )}
          </>
        )}
        {...restProps}
      >
        {data.length > 0 &&
          data.map((item, index) => {
            const key = `${index}`;
            return (
              <Option key={key} value={item.value}>
                {item.name}
              </Option>
            );
          })}
      </AntdSelect>
    );
  }
}

export default Select;
