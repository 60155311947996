import React from "react";
import { constructUrl } from "~/config";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Divider, Typography, Space } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { PageTitle } from "~/components/Common/Text";
import Button from "~/components/Common/Button";
import Table from "~/components/Common/Table";
import { getStockDetails } from "~/actions/reportActions";
import PriceLabel from "~/components/Common/PriceLabel";
import { formatNumber } from "~/_utils";
import { ColumnTextSearch } from "~/components/Common/Table/TableColumnFilter";

import "../index.scss";

const { Text } = Typography;

const Footer = (options = {}) => {
  const {
    totalStockCost = 0,
    totalStockWorth = 0,
    totalProducts = 0,
  } = options;
  return (
    <Row
      className="report-footer-container"
      gutter={[8, 0]}
      style={{
        maxWidth: "370px",
      }}
    >
      <Col xs={16}>
        <Text className="primaryTitle">{"Total stock cost: "}</Text>
      </Col>
      <Col xs={8}>
        <PriceLabel className="primaryText" value={totalStockCost} />
      </Col>
      <Col xs={16}>
        <Text className="primaryTitle">{"Total retail value: "}</Text>
      </Col>
      <Col xs={8}>
        <PriceLabel className="primaryText" value={totalStockWorth} />
      </Col>
      <Col xs={16}>
        <Text className="primaryTitle">{"Total products: "}</Text>
      </Col>
      <Col xs={8}>
        <Text className="primaryText"> {formatNumber(totalProducts)} </Text>
      </Col>
    </Row>
  );
};

class StockDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
    };

    this.columns = [
      {
        title: "Name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.name.localeCompare(b.name),
        dataIndex: "name",
        key: "name",
        width: 250,
        ...ColumnTextSearch(["name", "barcode"]),
        render: (text, record) => (
          <Row>
            <Col xs={24}>
              <Space direction="vertical">
                <Text>{record.name}</Text>
                <Text>{record.barcode}</Text>
              </Space>
            </Col>
          </Row>
        ),
      },
      {
        title: "Purchase price",
        dataIndex: "purchasePrice",
        key: "purchasePrice",
        sorter: (a, b) => a.purchasePrice - b.purchasePrice,
      },
      {
        title: "Avg purchase price",
        dataIndex: "avgPurchasePrice",
        key: "avgPurchasePrice",
        sorter: (a, b) => a.avgPurchasePrice - b.avgPurchasePrice,
      },
      {
        title: "Retail price (Excl. tax)",
        dataIndex: "retailPriceExTax",
        key: "retailPriceExTax",
        sorter: (a, b) => a.retailPriceExTax - b.retailPriceExTax,
      },
      {
        title: "Inventory",
        dataIndex: "inventory",
        key: "inventory",
        sorter: (a, b) => a.inventory - b.inventory,
      },
      {
        title: "Stock cost",
        dataIndex: "stockCost",
        key: "stockCost",
        sorter: (a, b) => a.stockCost - b.stockCost,
      },
      {
        title: "Stock retail value",
        dataIndex: "stockWorth",
        key: "stockWorth",
        sorter: (a, b) => a.stockWorth - b.stockWorth,
      },
      {
        title: "",
        dataIndex: "",
        key: "actions",
        // fixed: "right",
        width: 45,
        render: (text, record) => (
          <Row>
            <Col xs={24}>
              <Button
                type="link"
                icon={<FileTextOutlined />}
                onClick={() =>
                  this.onDetail({
                    barcode: record.barcode,
                  })
                }
              />
            </Col>
          </Row>
        ),
      },
    ];

    this.handleFetch = this.handleFetch.bind();
    this.onDetail = this.onDetail.bind();
  }
  componentDidMount() {
    this.handleFetch();
  }
  handleFetch = () => {
    const _this = this;
    this.setState({ loading: true });
    let { sort = {} } = this.state;

    getStockDetails({ sort })
      .then(function(result = {}) {
        const { data = [] } = result;
        const totalProducts = data[0].totalProducts || 0;
        const totalStockCost = data[0].totalStockCost || 0;
        const totalStockWorth = data[0].totalStockWorth || 0;

        _this.setState({
          loading: false,
          data: {
            totalProducts: totalProducts,
            totalStockCost: totalStockCost,
            totalStockWorth: totalStockWorth,
            stockDetails: [...data],
          },
        });
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });
        console.log(err);
      });
  };

  onDetail = (options = {}) => {
    const { history, match } = this.props;

    const { barcode = "" } = options;

    history.push(
      constructUrl({
        match,
        to: `/products?barcode=${barcode}`,
        isNested: false,
      })
    );
  };

  render() {
    const columns = this.columns;
    const { loading = false, data = {} } = this.state;
    const {
      totalProducts,
      totalStockCost,
      totalStockWorth,
      stockDetails = [],
    } = data;
    return (
      <div className="report-container">
        <Row>
          <Col xs={24}>
            <PageTitle>Stock Report</PageTitle>
            <Divider />
            <br />
          </Col>
          <Col xs={24}>
            <Table
              allowCsvExport={true}
              style={{ marginBottom: 20 }}
              loading={loading}
              columns={columns}
              dataSource={stockDetails}
              clientSidePaging={true}
              rowKey="productId"
              footer={
                <Footer
                  totalProducts={totalProducts}
                  totalStockCost={totalStockCost}
                  totalStockWorth={totalStockWorth}
                />
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withNamespaces()(StockDetails)));
