import React from "react";
import { Row, Col, Divider, Input, Typography, Card, Form } from "antd";

import {
  EditTwoTone,
  DeleteTwoTone,
  UserOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "~/components/Common/Button";
import { getCustomers, deleteCustomer } from "~/actions/customerActions";
import { constructUrl } from "~/config";
import { price } from "~/_utils";
import { PageTitle } from "~/components/Common/Text";
import FormItem from "~/components/Form/FormItem";
import Table from "~/components/Common/Table";
import "./index.scss";

const { Title, Text } = Typography;

const Filters = (props) => {
  const { onSearch } = props;
  const onFinish = (values) => {
    onSearch(values);
  };
  return (
    <Form name="filter" onFinish={onFinish}>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={6}>
          <FormItem
            propertyName="name_contains"
            placeholder="Customer"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {({ placeholder, ...restOptions }) => (
              <Input prefix={<UserOutlined />} placeholder={placeholder} />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              Search
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const CardGrid = (props) => {
  const { translateKey, title, value } = props;
  const gridStyle = {
    width: "100%",
    textAlign: "left",
  };

  return (
    <Card.Grid hoverable={false} style={gridStyle}>
      <div style={{ float: "left", paddingRight: 10 }}>
        <Text>
          <Trans i18nKey={translateKey}>{title}</Trans>
          {": "}
        </Text>
      </div>
      <div style={{ float: "left" }}>
        <Text>{value}</Text>
      </div>
    </Card.Grid>
  );
};

const ExpandedRow = (props) => {
  const { customer = {} } = props;

  const { name, email, phone, address, openingBalance, balance } = customer;

  return (
    <Row>
      <Col xs={24} lg={12} xl={8}>
        <Card
          title={name}
          bordered={false}
          style={{
            width: "100%",
            height: 302,
            marginTop: "unset",
            background: "unset",
          }}
        >
          <CardGrid translateKey="Email" title="Email" value={email} />
          <CardGrid translateKey="Phone" title="Phone" value={phone} />
          <CardGrid translateKey="Address" title="Address" value={address} />
          <CardGrid
            translateKey="OpeningBalance"
            title="Opening Balance"
            value={openingBalance}
          />
          <CardGrid translateKey="Balance" title="Balance" value={balance} />
        </Card>
      </Col>
    </Row>
  );
};

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      filterValues: props.filterValues || {},
      sort: props.sort || {},
      pageInfo: props.pageInfo || {},
    };
    this.onEdit = this.onEdit.bind();
    this.onDelete = this.onDelete.bind();
    this.onSaleHistoryClick = this.onSaleHistoryClick.bind();
    this.updateData = this.updateData.bind();
    this.handleTableChange = this.handleTableChange.bind();
    this.handleFetch = this.handleFetch.bind();
    this.resetTableState = this.resetTableState.bind();

    this.columns = [
      {
        title: "Code",
        dataIndex: "customerCode",
        key: "customerCode",
        width: 130,
        fixed: "left",
      },

      { title: "Full Name", dataIndex: "name", key: "name", sorter: true },
      { title: "Phone", dataIndex: "phone", key: "phone" },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        sorter: true,
      },
      {
        title: "",
        dataIndex: "",
        key: "actions",
        width: 85,

        render: (text, record) => (
          <Row>
            <Col xs={12}>
              <Button
                type="link"
                icon={<ShoppingCartOutlined />}
                onClick={() => this.onSaleHistoryClick(record.customerCode)}
              />
            </Col>
            <Col xs={12}>
              <Button
                type="link"
                icon={<EditTwoTone />}
                onClick={() =>
                  this.onEdit({
                    code: record.customerCode,
                    id: record.customerId,
                  })
                }
              />
            </Col>
          </Row>
        ),
      },
    ];
  }
  componentDidMount() {
    this.handleFetch();
  }

  resetTableState = (options, callback) => {
    const {
      sort = this.state.sort,
      filterValues = this.state.filterValues,
    } = options;
    this.setState(
      {
        sort: sort || {},
        pageInfo: {}, // always reset page to page 1
        filterValues: filterValues || {},
      },
      callback
    );
  };
  handleFetch = (options = {}) => {
    const _this = this;

    let { filterValues = {}, sort = {}, pageInfo = {} } = this.state;
    if (pageInfo.hasNextPage === false) {
      return;
    }

    this.setState({ loading: true });
    getCustomers(filterValues, sort, pageInfo)
      .then(function(result) {
        const { data, totalCount, pageInfo = {} } = result;
        _this.updateData(data);
        _this.setState({
          loading: false,
          pageInfo: pageInfo,
          totalCount: totalCount,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };
  handleTableChange = (pagination, filters, sorter = {}) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";

    const sort = JSON.parse(`{"${sortField}": "${sortOrder}"}`);

    this.resetTableState({ sort: sort }, this.handleFetch);
  };

  updateData = (data = []) => {
    const _this = this;
    const {
      customers: alreadyLoadedCustomers = [],
      pageInfo = {},
    } = this.state;
    let customers =
      Object.keys(pageInfo).length === 0 ? [] : [...alreadyLoadedCustomers]; //If page is reset start from empty list

    const startIndex = customers.length;
    ////
    data.forEach((customer, index) => {
      const {
        customerId,
        customerCode,
        name,
        email,
        phone,
        balance,
        address,
        openingBalance,
      } = customer;

      customers.push({
        key: startIndex + index,
        customerId: customerId,
        customerCode: customerCode,
        name: name,
        email: email,
        phone: phone,
        balance: price(balance, true),
        openingBalance: price(openingBalance, true),
        address: address,
        customer: customer,
      });
    });

    _this.setState({
      customers: customers,
    });
  };

  onSaleHistoryClick = (customerCode) => {
    const { history, match } = this.props;

    history.push(
      constructUrl({
        match,
        to: `/sell/history?customerCode=${customerCode}`,
        isNested: false,
      })
    );
  };

  onEdit = ({ code, id }) => {
    const { history, match } = this.props;

    history.push(
      constructUrl({
        match,
        to: `/customer?customerId=${id}&customerCode=${code}`,
        isNested: true,
      })
    ); //history is injected from hoc withRouter
  };
  onDelete = (options) => {
    const _this = this;
    const { sort, filterValues } = this.state;
    const { deleteCustomer } = this.props;
    deleteCustomer(options)
      .then(function() {
        //keep existing filtr & sort state
        _this.resetTableState(
          {
            filterValues: filterValues,
            sort: sort,
          },
          _this.handleFetch
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  render() {
    const { customers, pageInfo = {} } = this.state;

    const columns = this.columns;
    const _this = this;

    return (
      <Row>
        <Col xs={24}>
          <PageTitle>Customers</PageTitle>
          <Divider />
          <br />
        </Col>
        <Col xs={24} xl={18}>
          <Filters
            onSearch={(filterValues) => {
              _this.resetTableState(
                { filterValues: filterValues },
                _this.handleFetch
              );
            }}
          />
        </Col>
        <Col xs={24}>
          <Divider />
          <Table
            loading={this.state.loading}
            columns={columns}
            onChange={this.handleTableChange}
            expandedRowRender={(record) => <ExpandedRow customer={record} />}
            dataSource={customers}
            hasNextPage={pageInfo.hasNextPage}
            onPageChange={this.handleFetch}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { deleteCustomer })(
  withRouter(withNamespaces()(Customers))
);
