import React from "react";
import { Form as FormAntd } from "antd";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.setFormRef = this.setFormRef.bind();
    this.state = {};
  }
  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };
  render() {
    const { children, ...restProps } = this.props;
    const { formRef } = this.state;
    return (
      <FormAntd ref={this.setFormRef} {...restProps}>
        {children({ formRef })}
      </FormAntd>
    );
  }
}

export default Form;
