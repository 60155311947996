import React from "react";
import { Form, Typography, Space } from "antd";
import { constructFormJson } from "~/_utils";
import Info from "~/components/Common/Info";

const { Title, Text } = Typography;

export const FormItemTitle = (props = {}) => {
  const { title } = props;
  return (
    <div style={{ paddingBottom: 4, fontWeight: "bold" }}>
      <Text style={{ whiteSpace: "pre-wrap" }}>{title}</Text>
    </div>
  );
};

class FormItem extends React.Component {
  constructor(props) {
    super(props);

    this.setValue = this.setValue.bind();
    this.onChange = this.onChange.bind();
  }
  setValue = (value) => {
    const { form, propertyName, formListName } = this.props;

    if (!form) {
      return;
    }

    if (Array.isArray(propertyName)) {
      let index = propertyName[0];
      let propertyFieldName = propertyName[1];
      let listValues = form.getFieldValue(formListName);
      let updatedListValues = Object.assign([], listValues, {
        [index]: { ...listValues[index], [`${propertyFieldName}`]: value },
      });

      form.setFieldsValue({
        [`${formListName}`]: updatedListValues,
      });

      return;
    }

    const json = constructFormJson(propertyName, value); //TODO: setArray
    form.setFieldsValue(json);
  };
  onChange = (e) => {
    const { form = {}, propertyName } = this.props;
    form.setFields &&
      form.setFields([
        {
          name: propertyName,
          errors: [],
        },
      ]);
  };
  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setValue(value);
    }
  }

  render() {
    const {
      children,
      viewModelKeyValue = {},
      propertyName,
      showPlaceholder,
      showFormTitle = true,
      placeholder = "",
      valuePropName,
      info,
    } = this.props;

    const { rules, props } = viewModelKeyValue;
    const viewModelPlaceholder = props ? props.placeholder : placeholder;
    const options = {
      placeholder: showPlaceholder === true ? viewModelPlaceholder : "",
    };

    return (
      <>
        {showFormTitle === true && !info && (
          <FormItemTitle title={viewModelPlaceholder} />
        )}
        {showFormTitle === true && info && (
          <Space direction="horizontal" style={{ paddingLeft: "1px" }}>
            <FormItemTitle title={viewModelPlaceholder} />
            <Info title={info.text} />
          </Space>
        )}
        <Form.Item
          key={propertyName}
          name={propertyName}
          rules={rules}
          onBlur={this.onChange}
          valuePropName={valuePropName}
        >
          {children(options, this.setValue)}
        </Form.Item>
      </>
    );
  }
}

export default FormItem;
