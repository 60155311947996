import React from "react";

class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openKeys: this.props.defaultOpenKeys,
      selectedKey: this.props.defaultSelectedKey,
      collapsed: this.props.collapsed,
    };
    this.onOpenChange = this.onOpenChange.bind();
    this.onSelect = this.onSelect.bind();
  }
  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.props.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };
  onSelect = (options) => {
    const { key } = options;
    this.setState({ selectedKey: key });

    //if selected key is a root key (with or without sub menu) then collapse others
    if (this.props.rootSubmenuKeys.indexOf(key) !== -1) {
      this.onOpenChange([key]);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { selectedKey } = prevState;
    const { keySeparator } = nextProps;
    const separaterIndex = selectedKey && selectedKey.indexOf(keySeparator);
    const rootKey = selectedKey.substring(
      0,
      separaterIndex && separaterIndex === -1
        ? selectedKey.length
        : separaterIndex
    );

    // on Collapse close all (panels) openKeys & on expand Open the Panel for selected key
    if (prevState.collapsed !== nextProps.collapsed) {
      return {
        openKeys: nextProps.collapsed === true ? [] : [rootKey], //this line needed here otherwise there is a delay closer of panels on collapse
        collapsed: nextProps.collapsed,
      };
    }
    return null;
  }
  render() {
    const { openKeys } = this.state;
    const accordionOptions = {
      openKeys: openKeys,
      onOpenChange: this.onOpenChange,
      onSelect: this.onSelect,
    };
    const { children } = this.props;
    return children(accordionOptions);
  }
}

export default Accordion;
