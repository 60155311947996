import React from "react";
import { constructUrl } from "~/config";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Form as FormAntd,
  Select as SelectAntD,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { PageTitle } from "~/components/Common/Text";
import Button from "~/components/Common/Button";
import Table from "~/components/Common/Table";
import { getSaleDetails } from "~/actions/reportActions";
import PriceLabel from "~/components/Common/PriceLabel";
import moment from "moment";
import DatePicker from "~/components/Common/DatePicker";
import { ColumnTextSearch } from "~/components/Common/Table/TableColumnFilter";
import Form from "~/components/Form";
import FormItem from "~/components/Form/FormItem";
import { price, formatNumber } from "~/_utils";

import "../index.scss";

const { Text } = Typography;
const { Option } = SelectAntD;

const Filters = (props) => {
  const {
    groupBy = 0,
    onSearch,
    onDateRangeChange,
    initialValues = {},
    onGroupByChange,
    selectedDate,
  } = props;
  const onFinish = (values) => {
    onSearch(values);
  };

  const { selectedDate: defaultSelectedDate } = initialValues;
  return (
    <Form name="filter" onFinish={onFinish} initialValues={initialValues}>
      {({ formRef } = props) => (
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <Space>
              <FormItem
                form={formRef}
                propertyName="groupBy"
                placeholder="Group by"
                showFormTitle={false}
                showPlaceholder={true}
              >
                {(options, setValue) => (
                  <SelectAntD
                    placeholder={options.placeholder}
                    allowClear={false}
                    defaultValue={groupBy}
                    onChange={onGroupByChange}
                  >
                    <Option value={0}>Group by date</Option>
                    <Option value={1}>Group by month</Option>
                  </SelectAntD>
                )}
              </FormItem>
              <div style={{ marginTop: "-13px" }}>
                <DatePicker
                  picker={groupBy === 0 ? "date" : "month"}
                  onChange={onDateRangeChange}
                  placeholder={`Select ${groupBy === 0 ? "date" : "month"}`}
                  defaultValue={defaultSelectedDate}
                  inputReadOnly={true}
                  allowClear={false}
                  value={selectedDate}
                />
              </div>
              <FormAntd.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </FormAntd.Item>
            </Space>
          </Col>
        </Row>
      )}
    </Form>
  );
};

const Footer = (options = {}) => {
  const { totalProfit = 0, totalSaleExTax = 0 } = options;
  return (
    <Row
      className="report-footer-container"
      gutter={[8, 0]}
      style={{
        maxWidth: "370px",
      }}
    >
      <Col xs={16}>
        <Text className="primaryTitle">{"Total sale(Excl. tax): "}</Text>
      </Col>
      <Col xs={8}>
        <PriceLabel className="primaryText" value={totalSaleExTax} />
      </Col>
      <Col xs={16}>
        <Text className="primaryTitle">{"Total profit: "}</Text>
      </Col>
      <Col xs={8}>
        <PriceLabel className="primaryText" value={totalProfit} />
      </Col>
    </Row>
  );
};

class SaleDetails extends React.Component {
  constructor(props) {
    super(props);

    var defaultGroupBy = 0; //group by date
    var defaultSelectedDate =
      defaultGroupBy === 0
        ? moment().startOf("day")
        : moment().startOf("month");

    this.state = {
      loading: false,
      data: {},
      groupBy: defaultGroupBy,
      selectedDate: defaultSelectedDate,
      initialValues: { selectedDate: defaultSelectedDate },
    };

    this.columns = [
      {
        title: "Date/Month",
        defaultSortOrder: "ascend",
        dataIndex: "date",
        key: "date",
        // sorter: (a, b) => moment(a.saleDate).unix() - moment(b.saleDate).unix(),
      },
      {
        title: "Product",
        dataIndex: "productName",
        key: "productName",
        sorter: (a, b) => a.productName.localeCompare(b.productName),
        ...ColumnTextSearch(["productName"]),
      },
      {
        title: "Quantity sold",
        dataIndex: "quantity",
        key: "quantity",
        sorter: (a, b) => a.quantity - b.quantity,
        render: (text, record) => <>{formatNumber(text)}</>,
      },
      {
        title: "Sale (Excl. tax)",
        dataIndex: "saleExTax",
        key: "saleExTax",
        sorter: (a, b) => a.saleExTax - b.saleExTax,
        render: (text, record) => <>{price(text, false)}</>,
      },
      {
        title: "Profit",
        dataIndex: "profit",
        key: "profit",
        sorter: (a, b) => a.profit - b.profit,
        render: (text, record) => <>{price(text, false)}</>,
      },
    ];

    this.resetTableState = this.resetTableState.bind();
    this.onDateRangeChange = this.onDateRangeChange.bind();
    this.handleFetch = this.handleFetch.bind();
  }
  componentDidMount() {
    this.handleFetch();
  }
  onDateRangeChange = (date) => {
    debugger;
    if (!date) {
      return;
    }
    this.setState({ selectedDate: date });
  };

  resetTableState = (options, callback) => {
    const { sort = this.state.sort } = options;
    this.setState(
      {
        sort: sort || {},
      },
      callback
    );
  };
  handleFetch = () => {
    const _this = this;
    this.setState({ loading: true });

    let {
      filterValues = {},
      sort = {},
      selectedDate,
      groupBy = 0,
    } = this.state;

    filterValues.groupBy = groupBy;

    if (selectedDate) {
      filterValues.saleDateTimeUtcFrom = (filterValues.groupBy === 1
        ? selectedDate.startOf("month")
        : selectedDate.startOf("day")
      ).toISOString();
      filterValues.saleDateTimeUtcTo = (filterValues.groupBy === 1
        ? selectedDate.endOf("month")
        : selectedDate.endOf("day")
      ).toISOString();

      this.setState({
        selectedDateRange: [
          filterValues.saleDateTimeUtcFrom,
          filterValues.saleDateTimeUtcTo,
        ],
      });
    }

    getSaleDetails({ filterValues, sort })
      .then(function(result = {}) {
        const { data = [] } = result;
        const totalSaleExTax = (data.length > 0 && data[0].totalSaleExTax) || 0;
        const totalProfit = (data.length > 0 && data[0].totalProfit) || 0;

        _this.setState({
          loading: false,
          data: {
            totalSaleExTax: totalSaleExTax,
            totalProfit: totalProfit,
            saleDetails: data.map((record, index) => ({
              ...record,
              key: index,
            })),
          },
        });
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });
        console.log(err);
      });
  };

  render() {
    const _this = this;
    const columns = this.columns;
    const {
      loading = false,
      data = {},
      initialValues = {},
      groupBy,
      selectedDate,
      selectedDateRange = [],
    } = this.state;
    const { totalSaleExTax, totalProfit, saleDetails = [] } = data;
    return (
      <div className="report-container">
        <Row>
          <Col xs={24}>
            <PageTitle>Sales Report</PageTitle>
            <Divider />
            <br />
          </Col>
          <Col xs={24} xl={18}>
            <Filters
              initialValues={initialValues}
              selectedDate={selectedDate}
              groupBy={groupBy}
              onDateRangeChange={this.onDateRangeChange}
              onGroupByChange={(value) => {
                _this.setState({
                  groupBy: value,
                  selectedDate:
                    value === 0
                      ? moment().startOf("day")
                      : moment().startOf("month"),
                });
              }}
              onSearch={(filterValues) => {
                _this.resetTableState(
                  { filterValues: filterValues },
                  _this.handleFetch
                );
              }}
            />
          </Col>
          <Col xs={24}>
            <Divider />
            <br />
          </Col>
          <Col xs={24}>
            <Table
              allowCsvExport={true}
              links={[
                {
                  text: "Details",
                  url: `/sell/history?sellFrom=${selectedDateRange &&
                    selectedDateRange[0]}&sellTo=${selectedDateRange &&
                    selectedDateRange[1]}`,
                },
              ]}
              style={{ marginBottom: 20 }}
              loading={loading}
              columns={columns}
              dataSource={saleDetails}
              clientSidePaging={true}
              rowKey="key"
              footer={
                <Footer
                  totalSaleExTax={totalSaleExTax}
                  totalProfit={totalProfit}
                />
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withNamespaces()(SaleDetails)));
