import { translateFieldValidation, translate } from "~/_utils";
import {
  FIELD_VALIDATION_TYPE,
  TRANSLATION_NAMESPACE,
  REGEX,
} from "~/_utils/consts";

let viewModel = {
  uniqueCompanyName: {
    props: {
      placeholder: translate("Unique company name", {
        namespace: TRANSLATION_NAMESPACE.Common,
      }),
    },
  },
  companyName: {
    props: {
      placeholder: translate("Company name", {
        namespace: TRANSLATION_NAMESPACE.Common,
      }),
    },
    rules: [
      {
        type: "string",
        required: true,
        pattern: new RegExp(REGEX.Name, "g"),
        max: 200,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.companyName.props.placeholder
          );
        },
      },
    ],
  },
  currency: {
    props: {
      placeholder: translate("Currency", {
        namespace: TRANSLATION_NAMESPACE.Common,
      }),
    },
  },
};

export default viewModel;
