import React from "react";
import {
  UserOutlined,
  MailOutlined,
  HomeOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Row, Col, Input, Form, Divider, Typography } from "antd";
import { Trans, withNamespaces } from "react-i18next";
import { saveCustomer } from "~/actions/customerActions";
import Button from "~/components/Common/Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { getCustomer } from "~/actions/customerActions";
import { propName, priceFloat } from "~/_utils";
import FormItem, { FormItemTitle } from "~/components/Form/FormItem";
import PriceInput from "~/components/Common/Input/PriceInput";
import { PageTitle } from "~/components/Common/Text";
import Prompt from "~/components/Common/Prompt";
import viewModel from "./viewModel";
import "./index.scss";

const { Title, Text } = Typography;

class Customer extends React.Component {
  constructor(props) {
    super(props);

    const { customer = {} } = props;
    this.state = { customer: customer, formRef: null };

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.setFormRef = this.setFormRef.bind();
    this.getCustomer = this.getCustomer.bind();
  }
  getCustomer = (id, code) => {
    const _this = this;

    getCustomer(id, code)
      .then(function(result) {
        _this.setState(
          {
            customer: result.data,
            isEditMode: true, //getCustomer is only called in edit mode
          },
          _this.state.formRef.setFieldsValue(result.data)
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });
    const { saveCustomer, onSave } = this.props;
    const { formRef, isEditMode } = this.state;
    const _this = this;

    saveCustomer(values)
      .then(function(result) {
        onSave && onSave(result);

        _this.setState({
          loading: false,
          isFormChanged: false,
        });

        if (isEditMode === true) {
          formRef.setFieldsValue({
            customerId: result.customerId,
            customerCode: result.customerCode,
            balance: result.balance,
          });
          _this.setState({
            customer: result,
          });
        } else {
          formRef.resetFields();
          _this.setState({
            customer: {},
          });
        }
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };
  componentDidMount() {
    const { location, customer = {} } = this.props;
    const queryParams = queryString.parse(location.search);
    const customerId = queryParams.customerId || customer.customerId;
    const customerCode = queryParams.customerCode || customer.customerCode;

    if (customerId || customerCode) {
      this.getCustomer({ id: customerId, code: customerCode });
    }
    //make API call to get customer latest info from server. (Need to do it in getDerivedStateFromProps)
  }

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  render() {
    const _this = this;
    const { showTitle = true, size } = this.props;
    const {
      customer = {},
      formRef,
      loading = false,
      isFormChanged = false,
    } = this.state;
    const initialValues = {
      customerId: customer.customerId,
      customerCode: customer.customerCode,
      name: customer.name,
      phone: customer.phone,
      address: customer.address,
      email: customer.email,
      openingBalance: priceFloat(customer.openingBalance),
      balance: priceFloat(customer.balance),
    };

    const windowSizeLg = { xs: 24, md: 18, lg: 16, xl: 12 };
    const windowSizeMd = { xs: 24 };
    const windowSize =
      size && size.toUpperCase() === "MD" ? windowSizeMd : windowSizeLg;

    return (
      <div className="customer-form-container">
        {showTitle === true && (
          <>
            <PageTitle>Customer</PageTitle>
            <Divider />
            <br />
          </>
        )}
        <Row>
          <Col {...windowSize}>
            <Form
              validateTrigger="onBlur"
              onFinish={this.handleSubmit}
              onFinishFailed={this.onFinishFailed}
              className="customer-form"
              initialValues={initialValues}
              ref={this.setFormRef}
              onFieldsChange={() => {
                isFormChanged !== true &&
                  _this.setState({ isFormChanged: true });
              }}
            >
              <Prompt when={isFormChanged} />
              <Row gutter={[16, 8]}>
                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.name)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.name)]
                    }
                  >
                    {({ options }) => (
                      <Input prefix={<UserOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.email)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.email)]
                    }
                  >
                    {(options) => (
                      <Input prefix={<MailOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.phone)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.phone)]
                    }
                  >
                    {(options) => (
                      <Input prefix={<PhoneOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.address)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.address)]
                    }
                  >
                    {(options) => (
                      <Input prefix={<HomeOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} md={12}>
                  <FormItem
                    info={{
                      text:
                        "If you have an outstanding amount from the customer, please enter a negative value to reflect the amount you are expecting to receive.",
                    }}
                    form={formRef}
                    propertyName={propName(
                      initialValues,
                      (o) => o.openingBalance
                    )}
                    viewModelKeyValue={
                      viewModel[
                        propName(initialValues, (o) => o.openingBalance)
                      ]
                    }
                  >
                    {({ placeholder, ...restOptions }) => (
                      <PriceInput style={{ width: "100%" }} {...restOptions} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.balance)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.balance)]
                    }
                  >
                    {({ placeholder, ...restOptions }) => (
                      <PriceInput
                        readOnly={true}
                        style={{ width: "100%" }}
                        {...restOptions}
                      />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="save-button"
                      loading={loading}
                    >
                      <Trans i18nKey="Save">Save</Trans>
                    </Button>
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <FormItem
                    propertyName={propName(
                      initialValues,
                      (o) => o.customerCode
                    )}
                    viewModelKeyValue={propName(
                      initialValues,
                      (o) => o.customerCode
                    )}
                  >
                    {(options) => <Input type="hidden" {...options} />}
                  </FormItem>
                  <FormItem
                    propertyName={propName(initialValues, (o) => o.customerId)}
                    viewModelKeyValue={propName(
                      initialValues,
                      (o) => o.customerId
                    )}
                  >
                    {(options) => <Input type="hidden" {...options} />}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveCustomer })(
  withRouter(withNamespaces()(Customer))
);
