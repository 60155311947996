import {
  SELL_PAY,
  SELL_PAY_COMPLETESALE,
  SELL_PAY_CUSTOMER_CHANGE,
} from "./types";

import { recordSavedFailed } from "./generalActions";

import { addUpdateSaleInDb } from "~/_offline/offlineSales";
import { query } from "~/_utils/httpClient";
import {
  getCurrentDateUtcIso,
  isOnline,
  timeout,
  getLoggedInUser,
  localStorageSetItem,
  localStorageGetItem,
  getUniqueCompanyName,
} from "~/_utils";

import { LOCALSTORAGE, SESSIONSTORAGE } from "~/_utils/consts";
import sellWorker from "~/webworkers/sell.worker"; //Note: cannot refer his file in sell.worker otherwise casue a circular reference and compilation takes for ever

const GET_SALES_COLUMNS = `
    saleId     
    payload
    customerId
    notes
    customer{
      customerId
      customerCode
      name
      balance
    }
    saleProducts{
      saleProductId
      saleProductCode
    }
    salePayments{
      salePaymentId
      salePaymentCode
    }
      `;
const GET_SALE_COLUMNS = GET_SALES_COLUMNS;

let worker;

export const startBackgroundSaleSync = (options = {}) => {
  const { forceStart = false } = options;

  return new Promise(async function(resolve, reject) {
    const isWorkerInProgress = sessionStorage.getItem(
      SESSIONSTORAGE.isSellWorkerInProgress
    );

    if (
      forceStart !== true &&
      (isWorkerInProgress === true || isWorkerInProgress === "true")
    ) {
      console.log("sell worker is already in Progress");
      return resolve();
    }

    //set in storage that worker is in progress
    sessionStorage.setItem(SESSIONSTORAGE.isSellWorkerInProgress, true);

    worker = !worker ? new sellWorker() : worker;
    const currentUser = getLoggedInUser() || {};

    const isAuthenticated = !!currentUser;
    let token =
      isAuthenticated === true &&
      currentUser.getIdToken &&
      (await currentUser.getIdToken());

    console.log("currentUserIsAuthenticated: ", isAuthenticated);

    const uniqueCompanyName = getUniqueCompanyName();

    worker.postMessage({
      isOnline: isOnline(),
      userToken: token,
      uniqueCompanyName: uniqueCompanyName,
    });
    worker.onmessage = (e) => {
      console.log("sale is synced successfully");

      sessionStorage.setItem(SESSIONSTORAGE.isSellWorkerInProgress, false);

      //Once exsiting sales are syned then schedule a new syn in 30-sec. Keep the loop running even if isOffline to monitor the bg-job run time
      timeout(30000).then(function() {
        console.log("starting worker from loop / timeout");
        startBackgroundSaleSync();
      });
    };

    sessionStorage.setItem(
      SESSIONSTORAGE.saleSyncRunUtcDateTime,
      getCurrentDateUtcIso()
    );

    resolve();
  });
};

export const lastSaleSyncRunUtcDateTime = () => {
  return sessionStorage.getItem(SESSIONSTORAGE.saleSyncRunUtcDateTime);
};

export const durationSinceLastSaleSyncRun = () => {
  console.log("checking duration since last sync");

  const lastSaleSyncRun = lastSaleSyncRunUtcDateTime();
  if (!lastSaleSyncRun) {
    return -1; //-1 indicates that the job never runs as there is no record in storage for job run
  }

  const lastSaleSyncRunUtc = new Date(
    lastSaleSyncRunUtcDateTime(lastSaleSyncRun)
  );
  const currentUtc = new Date(getCurrentDateUtcIso());

  const durationSinceLastSaleSyncRunInSec =
    (currentUtc - lastSaleSyncRunUtc) / 1000;

  console.log("duration since last sync: ", durationSinceLastSaleSyncRunInSec);

  return durationSinceLastSaleSyncRunInSec;
};

export const getSales = (filterValues, sort, pageInfo = {}) => {
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_SALES_COLUMNS,
        entity: "sales",
        filter: adjustFilter({ filter: filterValues }),
        filterType: "SaleDtoFilterInput",
        sort: sort,
        sortType: "[SaleDtoSortInput!]",
        isList: true,
        pageInfo: pageInfo,
      }).then(function(result = {}) {
        const jsonPayload =
          result.data &&
          result.data.map((d) => {
            const payload = d.payload && JSON.parse(d.payload);
            const updatedPayload = updatePayloadWithIds(payload, d);
            return { ...updatedPayload, saleId: d.saleId };
          });

        const jsonResult = { ...result, data: jsonPayload };
        resolve(jsonResult);
      });
    } catch (err) {
      reject(err);
    }
  });
};

const adjustFilter = (props = {}) => {
  const { filter = {} } = props;
  let notesFilter = {};

  if (filter.notes === true) {
    notesFilter = {
      notes: { neq: "" },
      and: { notes: { neq: null } },
    };
    delete filter["notes"];
  } else {
    delete filter["notes"];
  }

  return { ...filter, ...notesFilter };
};

const updatePayloadWithIds = (payload, data) => {
  if (!payload || !data) {
    return;
  }
  payload.payments &&
    payload.payments.forEach((x) => {
      x.salePaymentId = getSalePaymentId(x.salePaymentCode, data);
    });
  payload.products &&
    payload.products.forEach((x) => {
      x.saleProductId = getSaleProductId(x.saleProductCode, data);
    });
  payload.taxes && payload.taxes.forEach((x) => (x.saleId = data.saleId));

  if (payload.customer && data.customer) {
    payload.customer = data.customer;
  }

  return payload;
};

const getSalePaymentId = (salePaymentCode, data) => {
  const salePayments =
    data.salePayments &&
    data.salePayments.filter((sp) => sp.salePaymentCode === salePaymentCode);

  const salePaymentId =
    salePayments && salePayments.length > 0 && salePayments[0].salePaymentId;

  return salePaymentId ? salePaymentId : null;
};

const getSaleProductId = (saleProductCode, data) => {
  const saleProducts =
    data.saleProducts &&
    data.saleProducts.filter((sp) => sp.saleProductCode === saleProductCode);

  const saleProductId =
    saleProducts && saleProducts.length > 0 && saleProducts[0].saleProductId;
  return saleProductId ? saleProductId : null;
};

export const pay = (sale) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      dispatch({
        type: SELL_PAY,
        payload: { ...sale },
      });
      resolve(sale);
    } catch (err) {
      reject(err);
    }
  });
};

export const completeSale = (payload) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    payload.isSynced = false;
    updateCientDbForSale(payload, dispatch, resolve);
  }).catch(console.log.bind(console)); // shouldn't throw an error
};

const updateCientDbForSale = (payload, dispatch, resolve) => {
  addUpdateSaleInDb(payload)
    .then(function(result) {
      dispatch({
        type: SELL_PAY_COMPLETESALE,
        payload: { ...payload },
      });
      resolve(payload);
    })
    .catch(function(err) {
      console.log(err);
    });
};

export const changeCustomer = (payload) => (dispatch) => {
  dispatch({
    type: SELL_PAY_CUSTOMER_CHANGE,
    payload: { ...payload },
  });
};
