import React from "react";
import Card from "./Card";
import { getSalesStatistics } from "~/actions/dashboardActions";
import Spin from "~/components/Common/Spin";
import { GROUPBY_DATE_ENUM } from "~/_utils/consts";
import { Radio } from "antd";
import { Chart, LineAdvance, Axis, Tooltip } from "bizcharts";
import { translate } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";
import { price } from "~/_utils";

const SaleChartTitle = (props = {}) => {
  const { onGroupByChange } = props;
  const title = `${translate("Sale", {
    namespace: TRANSLATION_NAMESPACE.Report,
  })} / ${translate("Profit", {
    namespace: TRANSLATION_NAMESPACE.Report,
  })}`;
  const monthTitle = `${translate("30-Days", {
    namespace: TRANSLATION_NAMESPACE.Report,
  })}`;
  const yearTitle = `${translate("12-Months", {
    namespace: TRANSLATION_NAMESPACE.Report,
  })}`;
  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <div>
        <span> {title}</span>
      </div>
      <div style={{ textAlign: "right", width: "100%", fontWeight: "normal" }}>
        <Radio.Group
          defaultValue="0"
          buttonStyle="outline"
          onChange={onGroupByChange}
        >
          <Radio.Button value="0">{monthTitle}</Radio.Button>
          <Radio.Button value="1">{yearTitle}</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
};

const ChartToolTip = (options = {}) => {
  const { sale, profit, title = "" } = options;
  return (
    <div style={{ marginTop: "5px", fontSize: "13px" }}>
      <span>{title}</span>
      <ul style={{ paddingLeft: "15px", paddingTop: "10px" }}>
        {sale ? (
          <li style={{ paddingBottom: "10px" }}>
            <span>{sale.name}</span>:{" "}
            <span style={{ fontWeight: "bold" }}>{price(sale.value)}</span>
          </li>
        ) : (
          <></>
        )}
        {profit ? (
          <li>
            <span>{profit.name}</span>:{" "}
            <span style={{ fontWeight: "bold" }}>{price(profit.value)}</span>
          </li>
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
};
const LineChart = React.memo(({ data }) => {
  return (
    <Chart padding={[20, 20, 60, 60]} autoFit height={300} data={data}>
      <Axis
        name="value"
        label={{ formatter: (val) => `${price(val, false)}` }}
      />
      <Tooltip showTitle={true}>
        {(title, items) => {
          const sale = items[0];
          const profit = items[1];
          return <ChartToolTip sale={sale} profit={profit} title={title} />;
        }}
      </Tooltip>

      <LineAdvance
        shape="smooth"
        point
        area
        position="xAxis*value"
        color="title"
      />
    </Chart>
  );
});

class SaleChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, saleData: [] };
    this.onGroupByChange = this.onGroupByChange.bind();
    this.getSaleStats = this.getSaleStats.bind();
  }
  componentDidMount() {
    this.getSaleStats();
  }
  getSaleStats = (options = {}) => {
    const { groupBy = GROUPBY_DATE_ENUM.Day } = options;
    const _this = this;
    this.setState({ loading: true });
    getSalesStatistics({ groupBy: groupBy })
      .then(function(result = {}) {
        _this.setState({
          loading: false,
          saleData: result.data,
        });
      })
      .catch(function(err) {
        _this.setState({ loading: false });
        console.log(err);
      });
  };
  onGroupByChange = (e) => {
    console.log("radio checked", e.target.value);
    let groupBy = parseInt(e.target.value);

    groupBy = isNaN(groupBy) ? 0 : groupBy;
    this.getSaleStats({ groupBy: groupBy });
  };
  render() {
    const { saleData = [], loading = true } = this.state;

    return (
      <Card
        title={<SaleChartTitle onGroupByChange={this.onGroupByChange} />}
        style={{ height: "375px" }}
      >
        <Spin spinning={loading}>
          <LineChart data={saleData} />
        </Spin>
      </Card>
    );
  }
}

export default SaleChart;
