import { translateFieldValidation, translate } from "~/_utils";
import {
  FIELD_VALIDATION_TYPE,
  TRANSLATION_NAMESPACE,
  REGEX,
} from "~/_utils/consts";

let viewModel = {
  productId: {},
  barcode: {
    props: {
      placeholder: translate("BarCode", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  sku: {
    props: {
      placeholder: translate("SKU", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  name: {
    props: {
      placeholder: translate("Name", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.name.props.placeholder
          );
        },
      },
    ],
  },
  brandId: {
    props: {
      placeholder: translate("Brand", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  description: {
    props: {
      placeholder: translate("Description", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  categoryId: {
    props: {
      placeholder: translate("Category", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  sellOnPointOfSale: {
    props: {
      placeholder: translate("SellOnPointOfSale", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  productType: {
    props: {
      placeholder: translate("ProductType", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  supplierId: {
    props: {
      placeholder: translate("Supplier", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  purchasePrice: {
    props: {
      placeholder: translate("PurchasePriceExTax", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  avgPurchasePrice: {
    props: {
      placeholder: translate("AvgPurchasePrice", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  currentInventory: {
    props: {
      placeholder: translate("CurrentInventory", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  reOrderPoint: {
    props: {
      placeholder: translate("ReOrderPoint", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  taxId: {
    props: {
      placeholder: translate("SalesTax", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  markup: {
    props: {
      placeholder: `${translate("Markup", {
        namespace: TRANSLATION_NAMESPACE.Product,
      })} (%)`,
    },
  },
  retailPriceExTax: {
    props: {
      placeholder: translate("RetailPriceExTax", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  retailPriceIncTax: {
    props: {
      placeholder: translate("RetailPriceIncTax", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
  },
  productAttributes: [
    {
      attributeId: {
        props: {
          placeholder: translate("Attribute", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      sequence: {},
      values: [
        {
          props: {
            placeholder: translate("Value", {
              namespace: TRANSLATION_NAMESPACE.Product,
            }),
          },
        },
      ],
    },
  ],
  productVariants: [
    {
      productId: {},
      name: {
        props: {
          placeholder: translate("VariantName", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      barcode: {
        props: {
          placeholder: translate("BarCode", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      sku: {
        props: {
          placeholder: translate("SKU", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      supplierId: {
        props: {
          placeholder: translate("Supplier", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      currentInventory: {
        props: {
          placeholder: translate("CurrentInventory", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      reOrderPoint: {
        props: {
          placeholder: translate("ReOrderPoint", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      purchasePrice: {
        props: {
          placeholder: translate("PurchasePriceExTax", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      avgPurchasePrice: {
        props: {
          placeholder: translate("AvgPurchasePrice", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      retailPriceExTax: {
        props: {
          placeholder: translate("RetailPriceExTax", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      retailPriceIncTax: {
        props: {
          placeholder: translate("RetailPriceIncTax", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      taxId: {
        props: {
          placeholder: translate("SalesTax", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
      markup: {
        props: {
          placeholder: `${translate("Markup", {
            namespace: TRANSLATION_NAMESPACE.Product,
          })} (%)`,
        },
      },

      sellOnPointOfSale: {
        props: {
          placeholder: translate("SellOnPointOfSale", {
            namespace: TRANSLATION_NAMESPACE.Product,
          }),
        },
      },
    },
  ],
};

export default viewModel;
