import React from "react";
import Card from "./Card";
import { getExpiringProducts } from "~/actions/dashboardActions";
import { ExceptionOutlined } from "@ant-design/icons";
import Spin from "~/components/Common/Spin";
import { translate, formatInSqlDateTime } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";
import { constructUrl } from "~/config";

class ProductsExpiringCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, prductsExpiring: "" };
    this.onClick = this.onClick.bind();
  }
  componentDidMount() {
    const _this = this;
    getExpiringProducts()
      .then(function(result = {}) {
        _this.setState({
          loading: false,
          prductsExpiring: result.data,
        });
      })
      .catch(function(err) {
        _this.setState({ loading: false });
        console.log(err);
      });
  }
  onClick = () => {
    const { history, match } = this.props;

    //expiry from 1yera back
    const expiryFrom = new Date();
    expiryFrom.setFullYear(expiryFrom.getFullYear() - 1);

    //expiry to date 3-months in future
    const expiryTo = new Date();
    expiryTo.setMonth(expiryTo.getMonth() + 3);

    history.push(
      constructUrl({
        match,
        to: `/purchase/overview?inStock=1&expiryFrom=${formatInSqlDateTime(
          expiryFrom,
          false
        )}&expiryTo=${formatInSqlDateTime(expiryTo, false)}`,
      })
    );
  };
  render() {
    const { prductsExpiring = "", loading = true } = this.state;
    const title = `${translate("ProductsExpiring", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })}`;
    const footer = `${translate("ProductsExpiringInMonths", {
      namespace: TRANSLATION_NAMESPACE.Report,
    }).replace("{0}", "3")}`;
    return (
      <Card
        icon={<ExceptionOutlined />}
        iconStyle={{ color: "#cb9a08", backgroundColor: "#f5f0e3" }}
        title={title}
        footer={footer}
        onClick={this.onClick}
      >
        <Spin spinning={loading}>
          <h1 className="font-weight-bold">{prductsExpiring}</h1>
        </Spin>
      </Card>
    );
  }
}

export default ProductsExpiringCard;
