import {
  RECORD_SAVE_SUCCESS,
  RECORD_SAVE_FAILED,
  RECORD_DELETE_SUCCESS,
  RECORD_DELETE_FAILED,
  GENERAL_ERROR,
  GENERAL_SUCCESS,
  GENERAL_DIALOG,
  VERSION_UPDATE,
} from "~/actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GENERAL_ERROR:
      return {
        ...state,
        lastAction: { type: "general", isSuccess: false, ...action.payload },
      };
    case GENERAL_SUCCESS:
      return {
        ...state,
        lastAction: { type: "general", isSuccess: true, ...action.payload },
      };
    case RECORD_SAVE_SUCCESS:
      return {
        ...state,
        lastAction: { type: "save", isSuccess: true, ...action.payload },
      };
    case RECORD_SAVE_FAILED:
      return {
        ...state,
        lastAction: { type: "save", isSuccess: false, ...action.payload },
      };
    case RECORD_DELETE_SUCCESS:
      return {
        ...state,
        lastAction: { type: "delete", isSuccess: true, ...action.payload },
      };
    case RECORD_DELETE_FAILED:
      return {
        ...state,
        lastAction: { type: "delete", isSuccess: false, ...action.payload },
      };
    case GENERAL_DIALOG: {
      return {
        ...state,
        dialog: { actionType: GENERAL_DIALOG, ...action.payload },
      };
    }
    case VERSION_UPDATE: {
      return {
        ...state,
        version: action.payload && action.payload.version,
      };
    }
    default:
      return state;
  }
}
