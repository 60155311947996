import React from "react";
import { Row, Col, Divider } from "antd";
import Products from "~/components/Sell/Bucket/Products";
import PayTotal from "~/components/Sell/Bucket/PayTotal";
import Payments from "./Payments";
import Barcode from "~/components/Common/Barcode";
import { LOCALSTORAGE } from "~/_utils/consts";
import { localStorageGetItem } from "~/_utils";

const ReceiptHeader = (props = {}) => {
  const { date, printSettings = {}, customer = {} } = props;
  const { title = "", header = "", printCustomerName = false } = printSettings;
  const { name = "" } = customer;

  return (
    <Row className="receipt-header">
      {title ? (
        <Col xs={24} className="title">
          {title}
        </Col>
      ) : (
        <></>
      )}
      {date ? (
        <Col xs={24} className="date">
          {date}
        </Col>
      ) : (
        <></>
      )}
      {printCustomerName === true && name ? (
        <Col xs={24} className="customerName">
          {name}
        </Col>
      ) : (
        <></>
      )}
      {header ? (
        <Col xs={24} className="headerText">
          {header}
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
};

const ReceiptFooter = (props = {}) => {
  const { printSettings = {}, notes } = props;
  const { footer = "", printNotes = false } = printSettings;
  return (
    <Row className="receipt-footer">
      {printNotes === true && notes ? (
        <Col xs={24} className="notes">
          {notes}
        </Col>
      ) : (
        <></>
      )}
      {footer ? (
        <Col xs={24} className="footerText">
          {footer}
          <br />
        </Col>
      ) : (
        <></>
      )}

      <Col xs={24} className="poweredBy">
        {"www.cloudwinkel.com"}
        <Divider />
      </Col>
    </Row>
  );
};

class Receipt extends React.Component {
  render() {
    let {
      products,
      originalSubTotal,
      subTotal,
      totalItems,
      totalTaxAmount,
      groupedTaxes,
      taxes,
      additionalDiscountPercent,
      originalTotalAmount,
      payments = [],
      updatedTotalAmount,
      change = 0,
      className = "",
      saleCode,
      date,
      onDeletePayment,
      readonly = false,
      customer = {},
      notes,
      isPriceIncludingTax = true,
    } = this.props;

    const updatedClassNames = `receipt-container ${className}`;
    const printSettings = JSON.parse(
      localStorageGetItem(LOCALSTORAGE.printSettings) || "{}"
    );
    return (
      <>
        <div className={updatedClassNames} style={{ marginRight: "30px" }}>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap"
            rel="stylesheet"
          />
          <style type="text/css" media="print">
            {`
            @page {
              margin-left: -8px;
              display: block;
              max-width:100%;
            }
              @media print {
                .ant-collapse {
                  margin: 0px;
                  pading:0px;
                  
                }
                .ant-collapse
                {
                  padding-right: 10px;
                }
                .subSection .priceContainer{
                  margin-right:-10px;
                }
                
                h4{
                  margin-top:0px;
                }
                              
                .ant-row {
                  display: flex;
                  flex-flow: row wrap;
              }
              .ant-col-xs-24 {
                display: block;
                flex: 0 0 100%;
                max-width: 100%;
            }
            .ant-col-xs-12 {
              display: block;
              flex: 0 0 50%;
              max-width: 50%;
          }
            .ant-col {
                position: relative;
                min-height: 1px;
            }
            .receipt-container.print {
              font-family: "JetBrains Mono", monospace;
              font-size: 14px;
              font-weight: 600;
              padding-top: 25px;
              background-color: white;
              width: 100%;
              text-align: left;
              padding-left: 5px;
              padding-right: 15px;
          }
            
            .sell-bucket-form-total-price {
              text-align: right;
              }
                .receipt-header {
                  padding-bottom: 5px;
              }
                .receipt-header .title {
                  text-align: center;
                  font-size: 16px;
                  font-weight: bold;
                  padding-bottom: 5px;
              }
                .receipt-header .customerName {
                  text-align: left;
                  font-size: 12px;
                  padding-top: 5px;
                  border-bottom: 1px solid black;
              }
                .receipt-header .date {
                  text-align: left;
                  font-size: 10px;
                  padding-top: 10px;
              }
                .receipt-header .headerText {
                  white-space: pre-wrap;
                  text-align: left;
                  font-size: 12px;
                  padding-top: 5px;
                  padding-bottom: 10px;
                  margin-bottom: 10px;
                  border-bottom: 1px solid black;
              }
                .receipt-footer .notes, .receipt-footer .footerText {
                  text-align: left;
                  white-space: pre-wrap;
                  font-size: 12px;
              }
                .receipt-footer .footerText {
                  padding-top: 10px;
                  margin-top: 10px;
                  padding-bottom: 10px;
                  border-top: 1px solid black;
                  border-bottom: 1px solid black;
                  margin-bottom: 10px;
              }
                .receipt-footer .poweredBy {
                  font-size: 12px;
                  margin-top: 10px;
              }
                .barcode-container {
                  text-align: center;
              }
                
                .receipt-container.print .deletePaymentButton {
                  display: none;
              }
                .receipt-container.print .ant-divider-horizontal {
                  border-top: 3px solid black;
              }
                .receipt-container.print .ant-collapse {
                  border: unset;
                  background-color: unset;
              }
                .receipt-container.print .ant-collapse >
                .ant-collapse-item {
                  border-bottom: 0px solid white;
                  border-radius: 0px;
              }
                .receipt-container.print .ant-collapse-content {
                  border-top: unset;
                  background-color: unset;
              }
                .receipt-container.print .ant-collapse-item {
                  border-left: 0px solid transparent;
              }
                .receipt-container.print .ant-collapse-item.ant-collapse-item-active {
                  border-left: 0px solid white;
              }
                .receipt-container.print .ant-collapse >
                .ant-collapse-item >
                .ant-collapse-header {
                  padding-left: 0px;
                  padding-right: 0px;
                  padding-top: 0px;
                  padding-bottom: 0px;
                  color: black;
              }
                .receipt-container.print .ant-form-item {
                  margin-bottom: 10px;
              }
                .receipt-container.print .ant-divider {
                  margin: 0px 0;
              }
                .receipt-container.print .form-item.left {
                  text-align: left;
              }
                .receipt-container.print .form-item.right {
                  text-align: right;
              }
                .receipt-container.print .sell-bucket-form-total-title {
                  padding-left: 0px;
              }
                .receipt-container.print h4, .receipt-container.print .toPay, .receipt-container.print .payments-container, .receipt-container.print .payments, .receipt-container.print .sell-bucket-form-total-title, .receipt-container.print .sell-bucket-form-total-price, .receipt-container.print .productHeader, .receipt-container.print .paySubTotalHeader, .receipt-container.print .paySubTotalTax {
                  color: black;
                  font-family: inherit;
                  
              }
                .receipt-container.print h4 .subSection, .receipt-container.print .toPay .subSection, .receipt-container.print .payments-container .subSection, .receipt-container.print .payments .subSection, .receipt-container.print .sell-bucket-form-total-title .subSection, .receipt-container.print .sell-bucket-form-total-price .subSection, .receipt-container.print .productHeader .subSection, .receipt-container.print .paySubTotalHeader .subSection, .receipt-container.print .paySubTotalTax .subSection {
                  
                  min-height: 22px;
              }
                .receipt-container.print h4 .subSection .priceContainer, .receipt-container.print .toPay .subSection .priceContainer, .receipt-container.print .payments-container .subSection .priceContainer, .receipt-container.print .payments .subSection .priceContainer, .receipt-container.print .sell-bucket-form-total-title .subSection .priceContainer, .receipt-container.print .sell-bucket-form-total-price .subSection .priceContainer, .receipt-container.print .productHeader .subSection .priceContainer, .receipt-container.print .paySubTotalHeader .subSection .priceContainer, .receipt-container.print .paySubTotalTax .subSection .priceContainer {
                  padding-right: 0px;
                  text-decoration: line-through;
              }
                .receipt-container.print h4 .priceContainer, .receipt-container.print .toPay .priceContainer, .receipt-container.print .payments-container .priceContainer, .receipt-container.print .payments .priceContainer, .receipt-container.print .sell-bucket-form-total-title .priceContainer, .receipt-container.print .sell-bucket-form-total-price .priceContainer, .receipt-container.print .productHeader .priceContainer, .receipt-container.print .paySubTotalHeader .priceContainer, .receipt-container.print .paySubTotalTax .priceContainer {
                  float: right;
                  padding-left: 0px;
              }
                .receipt-container.print h4 .priceContainer .anticon, .receipt-container.print .toPay .priceContainer .anticon, .receipt-container.print .payments-container .priceContainer .anticon, .receipt-container.print .payments .priceContainer .anticon, .receipt-container.print .sell-bucket-form-total-title .priceContainer .anticon, .receipt-container.print .sell-bucket-form-total-price .priceContainer .anticon, .receipt-container.print .productHeader .priceContainer .anticon, .receipt-container.print .paySubTotalHeader .priceContainer .anticon, .receipt-container.print .paySubTotalTax .priceContainer .anticon {
                  padding-left: 0px;
              }
                .receipt-container.print .payments {
                  font-size: 10px;
                  padding: 0px;
              }
                .receipt-container.print .paySubTotalTax .subSection {
                  display: none;
              }
                .receipt-container.print .expandTaxDetil-container .ant-collapse-content.ant-collapse-content-active {
                  font-size: 8px;
              }
                .receipt-container.print .sell-bucket-form-total-price {
                  padding-right: 0px;
              }
                .receipt-container.print .toPay, .receipt-container.print .sell-bucket-form-total-title, .receipt-container.print .sell-bucket-form-total-price, .receipt-container.print .paySubTotalHeader {
                  font-weight: bold;
                  padding-top: 2px;
                  padding-bottom: 2px;
              }
                .receipt-container.print .toPay .subSection, .receipt-container.print .sell-bucket-form-total-title .subSection, .receipt-container.print .sell-bucket-form-total-price .subSection, .receipt-container.print .paySubTotalHeader .subSection {
                  font-size: 10px;
                  font-weight: normal;
              }
                .receipt-container.print .toPay .subSection .priceContainer, .receipt-container.print .sell-bucket-form-total-title .subSection .priceContainer, .receipt-container.print .sell-bucket-form-total-price .subSection .priceContainer, .receipt-container.print .paySubTotalHeader .subSection .priceContainer {
                  padding-right: 0px;
              }
                .receipt-container.print .productHeader {
                  width: 100%;
              }
                .receipt-container.print .productHeader .variant {
                  font-size: 10px;
              }
              .receipt-container.print .payments-container{
                font-size: 10px;
              }
            }
          `}
          </style>
          <ReceiptHeader
            date={date}
            customer={customer}
            printSettings={printSettings}
          />

          <Products products={products} readonly={true} />
          <PayTotal
            originalSubTotal={originalSubTotal}
            subTotal={subTotal}
            totalItems={totalItems}
            totalTaxAmount={totalTaxAmount}
            groupedTaxes={groupedTaxes}
            taxes={taxes}
            additionalDiscountPercent={additionalDiscountPercent}
            readonly={true}
            expandKey="subTotalTax"
            toPay={originalTotalAmount}
            isPriceIncludingTax={isPriceIncludingTax}
          />
          <Payments
            readonly={readonly}
            payments={payments}
            remainingAmountToPay={updatedTotalAmount}
            totalAmountToPay={originalTotalAmount}
            change={change}
            onDeletePayment={onDeletePayment}
          />
          <div className="barcode-container">
            <Barcode value={saleCode} />
          </div>
          <ReceiptFooter printSettings={printSettings} notes={notes} />
        </div>
      </>
    );
  }
}

export default Receipt;
