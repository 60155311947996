import React from "react";
import "./index.scss";
import QuickKeyCard from "./QuickKeyCard";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Col, Row, Typography, Button } from "antd";

const { Title, Text } = Typography;

const SortableItem = SortableElement(
  ({ key, index, productIndex, isEditable, value, onDelete, onSelect }) => {
    return (
      <QuickKeyCard
        id={value.productId}
        index={productIndex} //had to add productIndex separately , as "index" is used by HOC and become undefined
        title={value.name}
        description={value.variant}
        img={value.ImageUrl}
        color={value.color}
        isEditable={isEditable}
        onDelete={onDelete}
        onSelect={onSelect}
      />
    );
  }
);

const SortableList = SortableContainer(
  ({ items, size, isSortable, onDelete, onSelect }) => {
    const screenSizeLg = { xs: 8, md: 4, xl: 4, xxl: 3 };
    const screenSizeMd = { xs: 8, md: 6, xl: 4, xxl: 3 };
    const screenSize =
      size && size.toUpperCase() === "MD" ? screenSizeMd : screenSizeLg;
    return (
      <div className="quickkeys-sortable-container">
        <Row gutter={[16, 16]} className="quickkeys-item-container">
          {items.map((p, index) => {
            const key = `${p.productId}_${index}`;
            return (
              <Col key={key} {...screenSize} className="quickkeys-item">
                <SortableItem
                  index={index}
                  productIndex={index}
                  value={p}
                  disabled={!isSortable}
                  isEditable={isSortable}
                  onDelete={onDelete}
                  onSelect={onSelect}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
);

class QuickKeys extends React.Component {
  constructor(props) {
    super(props);
    this.onSortEnd = this.onSortEnd.bind();
    this.handleDelete = this.handleDelete.bind();
    this.state = { items: this.props.products };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { products } = nextProps;

    return { items: products };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    const { onOrderChange } = this.props;
    const newItems = arrayMove(items, oldIndex, newIndex);

    onOrderChange(newItems);
  };

  handleDelete = (index) => {
    const { onDelete } = this.props;
    onDelete(index);
  };
  render() {
    const { items } = this.state;
    const { size, isEditable, onSelect } = this.props;
    return (
      <>
        <SortableList
          axis="xy"
          items={items}
          onSortEnd={this.onSortEnd}
          size={size}
          isSortable={isEditable}
          onDelete={this.handleDelete}
          onSelect={onSelect}
        />
      </>
    );
  }
}

export default QuickKeys;
