import React from "react";
import { Route } from "react-router-dom";

const PublicLayoutRoute = ({
  component: ComponentToRender,
  layout: Layout,
  ...rest
}) => {
  if (!ComponentToRender || !Layout) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout {...matchProps}>
          <ComponentToRender />
        </Layout>
      )}
    />
  );
};

export default PublicLayoutRoute;
