import React from "react";
import Media from "react-media";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Divider, Input, Typography, Form, Tabs, Switch } from "antd";
import {
  EditTwoTone,
  DeleteTwoTone,
  BarcodeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Button from "~/components/Common/Button";
import FormItem from "~/components/Form/FormItem";
import { PageTitle } from "~/components/Common/Text";
import Select from "~/components/Common/Select";
import Table from "~/components/Common/Table";
import { constructUrl } from "~/config";

import {
  getProducts,
  deleteProduct,
  getBrands,
  getCategories,
} from "~/actions/productActions";
import { getSuppliers } from "~/actions/supplierActions";
import orderBy from "lodash/orderBy";
import queryString from "query-string";

const { Text } = Typography;
const { TabPane } = Tabs;

const RowPrice = (props = {}) => {
  const { record = {} } = props;
  const variantRetailPrices =
    record.productVariants.map((x) => x.retailPriceIncTax) || [];
  const minValue = Math.min(...variantRetailPrices);
  const maxValue = Math.max(...variantRetailPrices);

  const textToRender =
    minValue !== maxValue ? `${minValue} - ${maxValue}` : `${minValue}`;

  return <>{textToRender}</>;
};

const Filters = (props) => {
  const {
    onSearch,
    categories = [],
    brands = [],
    suppliers = [],
    initialValues = {},
  } = props;
  const onFinish = (values) => {
    onSearch(values);
  };
  return (
    <Form name="filter" onFinish={onFinish} initialValues={initialValues}>
      <Row gutter={[8, 8]}>
        <Col xs={12} md={8}>
          <FormItem
            propertyName="barcode"
            placeholder="Barcode"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {({ placeholder, ...restOptions }) => (
              <Input placeholder={placeholder} />
            )}
          </FormItem>
        </Col>
        <Col xs={12} md={8}>
          <FormItem
            propertyName="name_contains"
            placeholder="Name"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {({ placeholder, ...restOptions }) => (
              <Input placeholder={placeholder} />
            )}
          </FormItem>
        </Col>
        <Col xs={12} md={8}>
          <FormItem
            propertyName="supplierId"
            placeholder="Supplier"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {(options) => (
              <Select
                allowClear={true}
                showSearch
                placeholder={options.placeholder}
                optionFilterProp="children"
                data={
                  suppliers.length > 0 &&
                  suppliers.map((supplier) => {
                    return {
                      value: supplier.supplierId,
                      name: supplier.name,
                    };
                  })
                }
                filterOption={(input, option) =>
                  typeof option.children === "string"
                    ? option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : option.children
                }
              />
            )}
          </FormItem>
        </Col>
        <Col xs={12} md={8}>
          <FormItem
            propertyName="categoryId_eq"
            placeholder="Category"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {(options) => (
              <Select
                allowClear={true}
                showSearch
                placeholder={options.placeholder}
                optionFilterProp="children"
                data={
                  categories.length > 0 &&
                  categories.map((category) => {
                    return {
                      value: category.categoryId,
                      name: category.name,
                    };
                  })
                }
                filterOption={(input, option) =>
                  typeof option.children === "string"
                    ? option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : option.children
                }
              />
            )}
          </FormItem>
        </Col>
        <Col xs={12} md={8}>
          <FormItem
            propertyName="brandId_eq"
            placeholder="Brand"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {(options) => (
              <Select
                allowClear={true}
                showSearch
                placeholder={options.placeholder}
                optionFilterProp="children"
                data={
                  brands.length > 0 &&
                  brands.map((brand) => {
                    return {
                      value: brand.brandId,
                      name: brand.name,
                    };
                  })
                }
                filterOption={(input, option) =>
                  typeof option.children === "string"
                    ? option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : option.children
                }
              />
            )}
          </FormItem>
        </Col>
        <Col xs={12} md={4}>
          <FormItem
            propertyName={"onlyLowInventory"}
            showFormTitle={false}
            valuePropName="checked"
          >
            {({ placeholder, ...restOptions }) => (
              <Switch
                defaultChecked={false}
                checkedChildren={"Low inventory"}
                unCheckedChildren={"Low inventory"}
                onChange={(checked, e) => {}}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              Search
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const DescriptionTab = (props) => {
  const { description = "" } = props;
  return (
    <Row gutter={[16, 8]}>
      <Col xs={24}>{description}</Col>
    </Row>
  );
};

const ExpandedRowInventory = (props) => {
  const {
    name,
    barcode,
    supplierName = "",
    currentInventory = 0,
    reOrderPoint = 0,
    isVariant = false,
    index = 0,
  } = props;
  return (
    <Row gutter={[16, 8]}>
      <Col xs={24} md={20} xl={16}>
        {index === 0 && (
          <Row gutter={[16, 8]}>
            <Col xs={7}>
              <Text style={{ fontWeight: "bold" }}>
                {" "}
                {isVariant ? "Variant" : "Name"}{" "}
              </Text>{" "}
            </Col>
            <Col xs={7}>
              <Text style={{ fontWeight: "bold" }}>Supplier</Text>{" "}
            </Col>
            <Col xs={5}>
              <Text style={{ fontWeight: "bold" }}> Inventory</Text>{" "}
            </Col>
            <Col xs={5}>
              <Text style={{ fontWeight: "bold" }}> Reorder point</Text>{" "}
            </Col>
          </Row>
        )}
        <Row gutter={[16, 8]}>
          <Col xs={7}>
            <Row>
              <Col xs={24}>
                <Text>{name}</Text>
              </Col>
              <Col xs={24}>
                <Text style={{ fontSize: "x-small" }}>{barcode}</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={7}>{supplierName}</Col>
          <Col xs={5}>{currentInventory}</Col>
          <Col xs={5}>{reOrderPoint}</Col>
        </Row>
      </Col>
    </Row>
  );
};

const ExpandedRowPrice = (props) => {
  const {
    name,
    barcode,
    purchasePrice,
    avgPurchasePrice,
    retailPriceExTax,
    retailPriceIncTax,
    taxName,
    isVariant = false,
    index = 0,
    markup,
  } = props;
  return (
    <Row gutter={[16, 8]}>
      <Col xs={24}>
        {index === 0 && (
          <Row gutter={[16, 8]}>
            <Col xs={6}>
              <Text style={{ fontWeight: "bold" }}>
                {" "}
                {isVariant ? "Variant" : "Name"}{" "}
              </Text>{" "}
            </Col>
            <Col xs={4}>
              <Text style={{ fontWeight: "bold" }}> Purchase price</Text>{" "}
            </Col>
            <Col xs={4}>
              <Text style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
                {"Avg price"}
              </Text>
            </Col>
            <Col xs={3}>
              <Text style={{ fontWeight: "bold" }}> Markup%</Text>{" "}
            </Col>

            <Col xs={3}>
              <Text style={{ fontWeight: "bold" }}> Sales tax%</Text>{" "}
            </Col>
            <Col xs={4}>
              <Text style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>
                {"Retail price\n (Inc Tax)"}
              </Text>
            </Col>
          </Row>
        )}
        <Row gutter={[16, 8]}>
          <Col xs={6}>
            <Row>
              <Col xs={24}>
                <Text>{name}</Text>
              </Col>
              <Col xs={24}>
                <Text style={{ fontSize: "x-small" }}>{barcode}</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={4}>{purchasePrice}</Col>
          <Col xs={4}>{avgPurchasePrice}</Col>
          <Col xs={3}>{markup}</Col>
          <Col xs={3}>{taxName}</Col>
          <Col xs={4}>{retailPriceIncTax}</Col>
        </Row>
      </Col>
    </Row>
  );
};

const ExpandedRow = (props) => {
  const { product = {} } = props;
  const {
    productVariants = [],
    currentInventory = 0,
    reOrderPoint = 0,
    name,
    barcode,
    purchasePrice,
    avgPurchasePrice,
    retailPriceExTax,
    retailPriceIncTax,
    taxName,
    supplierName,
    description,
    markup,
  } = product;

  const numberOfVariants = productVariants.length;

  return (
    <Tabs defaultActiveKey="1" style={{ marginLeft: "45px" }}>
      <TabPane tab="Inventory" key="1">
        {numberOfVariants === 0 && (
          <ExpandedRowInventory
            name={name}
            barcode={barcode}
            supplierName={supplierName}
            currentInventory={currentInventory}
            reOrderPoint={reOrderPoint}
          />
        )}
        {numberOfVariants > 0 &&
          productVariants.map(function(variantDefinition, index) {
            const { name, productId } = variantDefinition;
            return (
              <ExpandedRowInventory
                key={productId}
                name={name}
                supplierName={variantDefinition.supplierName}
                barcode={variantDefinition.barcode}
                currentInventory={variantDefinition.currentInventory}
                reOrderPoint={variantDefinition.reOrderPoint}
                index={index}
                isVariant={true}
              />
            );
          })}
      </TabPane>
      <TabPane tab="Price" key="2">
        {numberOfVariants === 0 && (
          <ExpandedRowPrice
            name={name}
            barcode={barcode}
            purchasePrice={purchasePrice}
            avgPurchasePrice={avgPurchasePrice}
            markup={markup}
            retailPriceExTax={retailPriceExTax}
            retailPriceIncTax={retailPriceIncTax}
            taxName={taxName}
          />
        )}
        {numberOfVariants > 0 &&
          productVariants.map(function(variantDefinition, index) {
            const { name, productId } = variantDefinition;
            return (
              <ExpandedRowPrice
                key={productId}
                name={name}
                barcode={variantDefinition.barcode}
                purchasePrice={variantDefinition.purchasePrice}
                avgPurchasePrice={variantDefinition.avgPurchasePrice}
                markup={variantDefinition.markup}
                retailPriceExTax={variantDefinition.retailPriceExTax}
                retailPriceIncTax={variantDefinition.retailPriceIncTax}
                taxName={variantDefinition.taxName}
                index={index}
                isVariant={true}
              />
            );
          })}
      </TabPane>
      <TabPane tab="Description" key="3">
        <DescriptionTab description={description} />
      </TabPane>
    </Tabs>
  );
};

class Products extends React.Component {
  constructor(props) {
    super(props);

    const { location = {} } = props;
    const queryParams = queryString.parse(location.search);
    const lowInventory = queryParams.lowInventory || "";
    const barcode = queryParams.barcode || "";

    const filterValues = {
      ...(props.filterValues || {}),
      onlyLowInventory: lowInventory.toLowerCase() === "true",
      barcode: barcode,
    };

    this.state = {
      products: [],
      categories: [],
      brands: [],
      suppliers: [],
      filterValues: { ...filterValues },
      initialValues: {
        ...filterValues,
      },
    };

    this.onDelete = this.onDelete.bind();
    this.onEdit = this.onEdit.bind();

    this.resetTableState = this.resetTableState.bind();
    this.handleFetch = this.handleFetch.bind();
    this.updateData = this.updateData.bind();
    this.handleTableChange = this.handleTableChange.bind();

    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record) => (
          <Row>
            <Col xs={24}>{text}</Col>
            <Col xs={24} style={{ fontSize: "x-small" }}>
              {record.productVariants && record.productVariants.length > 0
                ? `${record.productVariants.length} variants`
                : record.barcode}
            </Col>
          </Row>
        ),
        fixed: "left",
      },
      {
        title: "Brand",
        dataIndex: "brandName",
        key: "brandName",
        sorter: true,
      },
      {
        title: "Category",
        dataIndex: "categoryName",
        key: "categoryName",
        sorter: true,
      },
      {
        title: "Supplier",
        dataIndex: "supplierName",
        key: "supplierName",
        sorter: true,
      },
      {
        title: "Retail price",
        dataIndex: "retailPriceIncTax",
        key: "retailPriceIncTax",
        sorter: true,
        render: (text, record) => (
          <Row>
            <Col xs={24}>
              {record.productVariants && record.productVariants.length > 0 ? (
                <RowPrice record={record} />
              ) : (
                record.retailPriceIncTax
              )}
            </Col>
          </Row>
        ),
      },
      {
        title: "Inventory",
        dataIndex: "currentInventory",
        key: "currentInventory",
        sorter: true,
      },

      {
        title: "",
        dataIndex: "",
        key: "actions",
        width: 85,
        render: (text, record) => (
          <Row>
            <Col xs={12}>
              <Button
                type="link"
                icon={<EditTwoTone />}
                onClick={() => {
                  this.onEdit(record.productId);
                }}
              />
            </Col>
            <Col xs={12}>
              <Button
                layout="confirm"
                confirmText="Are you sure ?"
                type="link"
                icon={<DeleteTwoTone />}
                onClick={() => this.onDelete({ id: record.productId })}
              />
            </Col>
          </Row>
        ),
      },
    ];
  }
  componentDidMount() {
    const _this = this;

    getCategories({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          categories: orderBy([...data], ["name"], ["asc"]),
        });
      })
      .catch(function(err) {
        console.log(err);
      });

    getBrands({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          brands: orderBy([...data], ["name"], ["asc"]),
        });
      })
      .catch(function(err) {
        console.log(err);
      });

    getSuppliers({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          suppliers: orderBy([...data], ["name"], ["asc"]),
        });
      })
      .catch(function(err) {
        console.log(err);
      });

    this.handleFetch();
  }
  resetTableState = (options, callback) => {
    const {
      sort = this.state.sort,
      filterValues = this.state.filterValues,
    } = options;
    this.setState(
      {
        sort: sort || {},
        pageInfo: {}, // always reset page to page 1
        filterValues: filterValues || {},
      },
      callback
    );
  };
  handleFetch = (options = {}) => {
    const _this = this;

    let { filterValues = {}, sort = {}, pageInfo = {} } = this.state;
    if (pageInfo.hasNextPage === false) {
      return;
    }

    this.setState({ loading: true });

    getProducts({ filterValues, sort, pageInfo })
      .then(function(result) {
        const { data, totalCount, pageInfo = {} } = result;

        _this.updateData(data);
        _this.setState({
          loading: false,
          pageInfo: pageInfo,
          totalCount: totalCount,
        });
      })
      .catch(function(err) {
        console.log(err);
        _this.setState({ loading: false });
      });
  };
  updateData = (data = []) => {
    const _this = this;
    const { products: alreadyLoadedProducts = [], pageInfo = {} } = this.state;
    let products =
      Object.keys(pageInfo).length === 0 ? [] : [...alreadyLoadedProducts]; //If page is reset start from empty list

    const startIndex = products.length;
    ////
    data.forEach((product, index) => {
      products.push({ ...product, key: startIndex + index });
    });

    _this.setState({
      products: products,
    });
  };
  handleTableChange = (pagination, filters, sorter = {}) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";

    const sort = JSON.parse(`{"${sortField}": "${sortOrder}"}`);

    this.resetTableState({ sort: sort }, this.handleFetch);
  };

  onDelete = (options) => {
    const _this = this;
    const { sort, filterValues } = this.state;
    const { deleteProduct } = this.props;
    deleteProduct(options)
      .then(function() {
        //keep existing filtr & sort state
        _this.resetTableState(
          {
            filterValues: filterValues,
            sort: sort,
          },
          _this.handleFetch
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };
  onEdit = (productId) => {
    const { history, match } = this.props;
    history.push(
      constructUrl({
        match,
        to: `/product?productId=${productId}`,
        isNested: true,
      })
    );
  };
  render() {
    const {
      products = [],
      brands = [],
      categories = [],
      suppliers = [],
      pageInfo = {},
      loading = false,
      initialValues = {},
    } = this.state;
    const columns = this.columns;

    const _this = this;

    return (
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <PageTitle>Products</PageTitle>
            </Col>

            <Divider />
            <br />
          </Row>
        </Col>
        <Col xs={24} xl={18}>
          <Filters
            initialValues={initialValues}
            categories={categories}
            brands={brands}
            suppliers={suppliers}
            onSearch={(filterValues) => {
              _this.resetTableState(
                { filterValues: filterValues },
                _this.handleFetch
              );
            }}
          />
        </Col>
        <Col xs={24}>
          <Divider />
          <Table
            loading={loading}
            columns={columns}
            onChange={this.handleTableChange}
            dataSource={products}
            hasNextPage={pageInfo.hasNextPage}
            onPageChange={this.handleFetch}
            expandedRowRender={(record) => <ExpandedRow product={record} />}
          />
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { deleteProduct })(
  withRouter(withNamespaces()(Products))
);
