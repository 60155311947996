import { mutate, query } from "~/_utils/httpClient";
import {
  convertDateToUtcIso,
  getDateWithTimeRange,
  getClientTimeZone,
} from "~/_utils";
import { GROUPBY_DATE_ENUM, PAYMENTMETHODS } from "~/_utils/consts";
import { translate } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";

export const getLowInventoryStatistics = (options) => {
  return new Promise(function(resolve, reject) {
    try {
      query({
        entity: "productsInventoryStatistics",
      }).then(function(result) {
        const { data } = result;
        const productsWithLowInventory = typeof data !== "number" ? 0 : data;
        resolve({ data: productsWithLowInventory });
      });
    } catch (err) {
      reject(err);
    }
  });
};
export const getProductsStockStatistics = (options) => {
  const columns = `stockCost,
  stockWorth,
  totalProducts`;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: columns,
        entity: "productsStockStatistics",
      }).then(function(result) {
        const { data = {} } = result;

        resolve({ data: { ...data } });
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getReceivableStatistics = (options) => {
  return new Promise(function(resolve, reject) {
    try {
      query({
        entity: "receivableStatistics",
      }).then(function(result = {}) {
        const { data } = result;
        const totalReceivables = typeof data !== "number" ? 0 : data;
        resolve({ data: totalReceivables });
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getExpiringProducts = (options) => {
  return new Promise(function(resolve, reject) {
    const currentDate = new Date();
    const dateFromUtc = new Date(
      Date.UTC(
        currentDate.getFullYear() - 1,
        currentDate.getMonth(),
        currentDate.getDate()
      )
    ).toISOString();
    const dateAfterThreeMonthsUtc = new Date(
      Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth() + 3,
        currentDate.getDate()
      )
    ).toISOString();
    try {
      query({
        entity: "productsExpiryStatistics",
        additionalParameterName: "query",
        additionalParameter: {
          expiryDateUtcFrom: dateFromUtc,
          expiryDateUtcTo: dateAfterThreeMonthsUtc,
        },
        additionalParameterType: "GetProductsExpiryStatisticsQueryInput",
      }).then(function(result = {}) {
        const { data } = result;
        const totalProductsExpiring = typeof data !== "number" ? 0 : data;
        resolve({ data: totalProductsExpiring });
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getTodaySales = (options) => {
  return new Promise(function(resolve, reject) {
    let todayDateTimeRange = getDateWithTimeRange(new Date(), new Date());
    let dateFromUtc = convertDateToUtcIso(todayDateTimeRange[0]);
    let dateToUtc = convertDateToUtcIso(todayDateTimeRange[1]);

    const columns = `key
    payments{
      paymentMethod
      totalAmount
    }
    totalProfit
    totalSaleAmount`;

    try {
      query({
        columns: columns,
        entity: "salesStatistics",
        additionalParameterName: "query",
        additionalParameter: {
          saleDateTimeUtcFrom: dateFromUtc,
          saleDateTimeUtcTo: dateToUtc,
          groupBy: GROUPBY_DATE_ENUM.Day,
          clientDateTimeZone: getClientTimeZone(),
        },
        additionalParameterType: "GetSalesStatisticsQueryInput",
      }).then(function(result = {}) {
        const { data = [] } = result;

        let totalSaleAmount = parseInt(data[0] && data[0].totalSaleAmount);
        totalSaleAmount = isNaN(totalSaleAmount) ? 0 : totalSaleAmount;

        const cashPayment = ((data[0] && data[0].payments) || []).find(
          (x) => x.paymentMethod === PAYMENTMETHODS.CASH
        );
        let totalCash = parseInt(cashPayment && cashPayment.totalAmount);
        totalCash = isNaN(totalCash) ? 0 : totalCash;

        resolve({
          data: { totalSaleAmount: totalSaleAmount, totalCash: totalCash },
        });
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getSalesStatistics = (options = {}) => {
  const { groupBy = GROUPBY_DATE_ENUM.Day } = options;

  return new Promise(function(resolve, reject) {
    let dateFrom = new Date();
    let dateTo = new Date();

    if (groupBy === GROUPBY_DATE_ENUM.Day) {
      dateFrom.setDate(dateFrom.getDate() - 30); //past 30-days
    } else {
      dateFrom.setMonth(dateFrom.getMonth() - 12); //past 12-months
    }

    let dateTimeRange = getDateWithTimeRange(dateFrom, dateTo);
    let dateFromUtc = convertDateToUtcIso(dateTimeRange[0]);
    let dateToUtc = convertDateToUtcIso(dateTimeRange[1]);

    const columns = `key
      payments{
        paymentMethod
        totalAmount
      }
      totalProfit
      totalSaleAmount`;

    try {
      query({
        columns: columns,
        entity: "salesStatistics",
        additionalParameterName: "query",
        additionalParameter: {
          includePayments: false,
          saleDateTimeUtcFrom: dateFromUtc,
          saleDateTimeUtcTo: dateToUtc,
          groupBy: groupBy,
          clientDateTimeZone: getClientTimeZone(),
        },
        additionalParameterType: "GetSalesStatisticsQueryInput",
      }).then(function(result = {}) {
        const { data = [] } = result;
        let updatedData = [];

        const iterable = data.length;

        for (let i = 0; i < iterable; i++) {
          const saleStats = data[i];

          let totalSaleAmount = parseInt(saleStats.totalSaleAmount);
          totalSaleAmount = isNaN(totalSaleAmount) ? 0 : totalSaleAmount;

          let totalProfit = parseInt(saleStats.totalProfit);
          totalProfit = isNaN(totalProfit) ? 0 : totalProfit;

          const graphValueSale = {
            xAxis: saleStats.key,
            title: `${translate("Sale", {
              namespace: TRANSLATION_NAMESPACE.Report,
            })}`,
            value: totalSaleAmount,
          };
          const graphValueProfit = {
            xAxis: saleStats.key,
            title: `${translate("Profit", {
              namespace: TRANSLATION_NAMESPACE.Report,
            })}`,
            value: totalProfit,
          };

          updatedData.push(graphValueSale);
          updatedData.push(graphValueProfit);
        }

        resolve({
          data: [...updatedData],
        });
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getTopProducts = (options) => {
  return new Promise(function(resolve, reject) {
    const columns = `productId
      quantitySold
      name
      variant`;

    try {
      query({
        columns: columns,
        entity: "topProductsStatistics",
      }).then(function(result = {}) {
        const { data = [] } = result;
        let updatedData = [];

        for (let i = 0; i < data.length; i++) {
          let productName = `${data[i] && data[i].name}`;
          let productVariant = `${data[i] && data[i].variant}`;

          productName = `${productName.substring(0, 10)}...`;
          productVariant = productVariant
            ? `${productVariant.substring(0, 10)}...`
            : "";

          const name = `${productName}${
            productVariant ? `${productVariant}` : ""
          }`;
          let quantitySold = parseInt(data[i] && data[i].quantitySold);
          quantitySold = isNaN(quantitySold) ? 0 : quantitySold;

          updatedData.push({ type: name, value: quantitySold });
        }

        resolve({
          data: [...updatedData],
        });
      });
    } catch (err) {
      reject(err);
    }
  });
};
