import React from "react";
import { toFloat } from "../../_utils";
import { InputNumber, Input } from "antd";

class InputDecimal extends React.Component {
  render() {
    let {
      defaultValue,
      hideIncrementButtons,
      onChange,
      onBlur,
      setRef,
      ...otherProps
    } = this.props;
    defaultValue = toFloat(defaultValue).toFixed(2);

    return hideIncrementButtons === true ? (
      <Input
        {...otherProps}
        onChange={(e) => onChange && onChange(e.target.value)}
        onBlur={(e) => onBlur && onBlur(e.target.value)}
        onFocus={(e) => {
          e.target.select();
        }}
        ref={(el) => {
          setRef && setRef(el);
        }}
      />
    ) : (
      <InputNumber
        {...otherProps}
        step={0.01}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={(e) => onBlur && onBlur(e.target.value)}
        onFocus={(e) => {
          e.target.select();
        }}
        ref={(el) => {
          setRef && setRef(el);
        }}
      />
    );
  }
}

export default InputDecimal;
