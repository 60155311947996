import { translateFieldValidation, translate } from "~/_utils";
import { FIELD_VALIDATION_TYPE, TRANSLATION_NAMESPACE } from "~/_utils/consts";

let viewModel = {
  displayName: {
    props: {
      placeholder: translate("DisplayName", {
        namespace: TRANSLATION_NAMESPACE.Account,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.displayName.props.placeholder
          );
        },
      },
    ],
  },
  email: {
    props: {
      placeholder: translate("Email", {
        namespace: TRANSLATION_NAMESPACE.Account,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.email.props.placeholder
          );
        },
      },
      {
        type: "email",
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Invalid,
            viewModel.email.props.placeholder
          );
        },
      },
    ],
  },
  userName: {
    props: {
      placeholder: translate("UserName", {
        namespace: TRANSLATION_NAMESPACE.Account,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.userName.props.placeholder
          );
        },
      },
      {
        pattern: new RegExp(/^[a-z0-9]+$/i),
        get message() {
          return translate(FIELD_VALIDATION_TYPE.CharectersAndNumbersOnly, {
            namespace: TRANSLATION_NAMESPACE.Validation,
          });
        },
      },
    ],
  },
  password: {
    props: {
      placeholder: translate("Password", {
        namespace: TRANSLATION_NAMESPACE.Account,
      }),
    },
    rules: [
      {
        pattern: new RegExp(/^(\S{6,19})?$/i),
        get message() {
          return "Password must be minimum 6 characters and less than 20 characters long";
        },
      },
    ],
  },
};

export default viewModel;
