import { translateFieldValidation, translate } from "~/_utils";
import { FIELD_VALIDATION_TYPE, TRANSLATION_NAMESPACE } from "~/_utils/consts";

let viewModel = {
  password: {
    props: {
      placeholder: translate("New Password", {
        namespace: TRANSLATION_NAMESPACE.Account,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.password.props.placeholder
          );
        },
      },
      { min: 6, message: "Password must be minimum 6 characters long." },
      { max: 20, message: "Password must be less than 20 characters long." },
    ],
  },
};

export default viewModel;
