import React from "react";
import {
  UserOutlined,
  HomeOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Row, Col, Input, Form, Divider, Button } from "antd";
import queryString from "query-string";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveSupplier, getSupplier } from "~/actions/supplierActions";

import { PageTitle } from "~/components/Common/Text";
import FormItem from "~/components/Form/FormItem";

import { propName } from "~/_utils";
import viewModel from "./viewModel";

const { TextArea } = Input;

const GetInitialValues = (supplier = {}) => {
  const initialValues = {
    supplierId: supplier.supplierId,
    name: supplier.name,
    email: supplier.email,
    phone: supplier.phone,
    address: supplier.address,
    description: supplier.description,
  };

  return initialValues;
};

class Supplier extends React.Component {
  constructor(props) {
    super(props);

    const { supplier = {} } = props;

    this.state = { supplier: supplier, formRef: null };

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.getSupplier = this.getSupplier.bind();
    this.setFormRef = this.setFormRef.bind();
  }
  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };
  componentDidMount() {
    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    const supplierId = queryParams.supplierId;

    supplierId && this.getSupplier(supplierId);
  }

  getSupplier = (id) => {
    const _this = this;

    getSupplier({ id: id })
      .then(function(result) {
        _this.setState(
          {
            supplier: result.data,
            isEditMode: true, //getSupplier is only called in edit mode
          },
          _this.state.formRef.setFieldsValue(result.data)
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });

    const { saveSupplier, onSave } = this.props;
    const { formRef, isEditMode } = this.state;
    const _this = this;

    saveSupplier(values)
      .then(function(result) {
        onSave && onSave(result);

        _this.setState({
          loading: false,
        });

        if (isEditMode === true) {
          formRef.setFieldsValue({
            supplierId: result.supplierId,
          });
          _this.setState({
            supplier: result,
          });
        } else {
          _this.setState(
            {
              supplier: {},
            },
            formRef.resetFields
          );
        }
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };
  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const { showTitle = true, size } = this.props;
    const { formRef, loading = false, supplier = {} } = this.state;
    const initialValues = GetInitialValues(supplier);
    const windowSizeLg = { xs: 24, md: 18, lg: 16, xl: 12 };
    const windowSizeMd = { xs: 24 };

    const windowSize =
      size && size.toUpperCase() === "MD" ? windowSizeMd : windowSizeLg;

    return (
      <Row gutter={[16, 8]}>
        <Col xs={24}>
          {showTitle === true && (
            <>
              <PageTitle>Supplier</PageTitle>
              <Divider />
              <br />
            </>
          )}
          <Col {...windowSize}>
            <Form
              validateTrigger="onBlur"
              onFinish={this.handleSubmit}
              onFinishFailed={this.onFinishFailed}
              className="supplier-form"
              initialValues={initialValues}
              ref={this.setFormRef}
            >
              <Row gutter={[16, 8]}>
                <Col xs={24} md={12}>
                  {/* <Form.Item key={"name"} name={"name"}>
                    <Input prefix={<UserOutlined />} />
                  </Form.Item> */}
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.name)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.name)]
                    }
                  >
                    {({ options }) => (
                      <Input prefix={<UserOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.email)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.email)]
                    }
                  >
                    {(options) => (
                      <Input prefix={<MailOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.phone)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.phone)]
                    }
                  >
                    {(options) => (
                      <Input prefix={<PhoneOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.address)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.address)]
                    }
                  >
                    {(options) => (
                      <Input prefix={<HomeOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.description)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.description)]
                    }
                  >
                    {({ options }) => (
                      <TextArea
                        rows={2}
                        style={{ resize: "none" }}
                        {...options}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="save-button"
                      loading={loading}
                    >
                      <Trans i18nKey="Save">Save</Trans>
                    </Button>
                  </Form.Item>
                </Col>

                <FormItem
                  form={formRef}
                  propertyName={propName(initialValues, (o) => o.supplierId)}
                  viewModelKeyValue={propName(
                    initialValues,
                    (o) => o.supplierId
                  )}
                >
                  {(options) => <Input type="hidden" {...options} />}
                </FormItem>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveSupplier })(
  withRouter(withNamespaces()(Supplier))
);
