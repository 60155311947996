import { prepareOfflineSell } from "./offlineActions";
import {
  recordSavedSuccess,
  recordSavedFailed,
  recordDeleteSuccess,
} from "./generalActions";
import { updateClientDbForDeletedCustomer } from "~/_offline";
import { saveCustomerToServerAndInLocal } from "~/_offline/offlineCustomers";
import { mutate, query } from "~/_utils/httpClient";

const GET_CUSTOMERS_COLUMNS = `
      customerId,
      customerCode
      name
      email
      phone
      address
      openingBalance
      balance
      `;
const GET_CUSTOMER_COLUMNS = GET_CUSTOMERS_COLUMNS;

const GET_CUSTOMERPAYMENTS_COLUMNS = `
      customerPaymentId,
      customerId,
      customerCode,
      customerName,      
      paymentDateTimeUtc,
      amount,
      notes
`;
const GET_CUSTOMERPAYMENT_COLUMNS = GET_CUSTOMERPAYMENTS_COLUMNS;

const SAVE_CUSTOMERPAYMENT_COLUMNS = `
  customerPaymentId
`;

export const saveCustomer = (customer) => (dispatch) => {
  console.log("customerAction - customer : ", customer);
  return new Promise(function(resolve, reject) {
    saveCustomerToServerAndInLocal(customer)
      .then(function(updatedCustomer) {
        dispatch(prepareOfflineSell({ isNewCustomerAdded: true }));
        dispatch(recordSavedSuccess("customer", updatedCustomer));
        resolve(updatedCustomer);
      })
      .catch(function(err) {
        dispatch(recordSavedFailed("customer", err));
        reject(err);
      });
  });
};

export const deleteCustomer = ({ id, code }) => (dispatch) => {
  const customer = { customerId: id, customerCode: code };
  return new Promise(function(resolve, reject) {
    try {
      const deleteCommand = {
        id: customer.customerId,
        code: customer.customerCode,
      };
      mutate({
        command: "deleteCustomer",
        commandType: "DeleteCustomerCommandInput",
        data: deleteCommand,
        columns: {},
      }).then(function(result) {
        dispatch(recordDeleteSuccess("customer", customer));

        updateClientDbForDeletedCustomer(customer)
          .then(function(result) {
            //dispatch(prepareOfflineSell({dispatchOfflinePayLoad: true}));
            resolve(customer);
          })
          .catch(function(err) {
            resolve(customer);
            //reject(err);
            //TODO: log error
          });
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCustomers = (filterValues, sort, pageInfo = {}) => {
  return new Promise(function(resolve, reject) {
    try {
      //TODO: If API call fails because of no connectivity then get customers from local storage

      query({
        columns: GET_CUSTOMERS_COLUMNS,
        entity: "customers",
        filter: filterValues,
        filterType: "CustomerDtoFilterInput",
        sort: sort,
        sortType: "[CustomerDtoSortInput!]",
        isList: true,
        pageInfo: pageInfo,
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCustomer = (options) => {
  const { id, code } = options;
  return new Promise(function(resolve, reject) {
    try {
      //TODO: If API call fails because of no connectivity then get customers from local storage

      query({
        columns: GET_CUSTOMER_COLUMNS,
        entity: "customer",
        id: id,
        idType: "Uuid",
        code: code,
        codeType: "String",
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCustomerPayments = (options) => {
  const { filterValues, sort, pageInfo = {} } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_CUSTOMERPAYMENTS_COLUMNS,
        entity: "customerPayments",
        filter: filterValues,
        filterType: "CustomerPaymentDtoFilterInput",
        sort: sort,
        sortType: "[CustomerPaymentDtoSortInput!]",
        isList: true,
        pageInfo: pageInfo,
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const saveCustomerPayment = (customerPayment) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      let updatedCustomerPayment = customerPayment;
      mutate({
        command: customerPayment.customerPaymentId
          ? "updateCustomerPayment"
          : "createCustomerPayment",
        commandType: customerPayment.customerPaymentId
          ? "UpdateCustomerPaymentCommandInput!"
          : "CreateCustomerPaymentCommandInput!",
        data: customerPayment,
        columns: SAVE_CUSTOMERPAYMENT_COLUMNS,
      })
        .then(function(result) {
          updatedCustomerPayment.customerPaymentId =
            result.data.customerPaymentId;
          dispatch(
            recordSavedSuccess("customerPayment", updatedCustomerPayment)
          );
          resolve(updatedCustomerPayment);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("customerPayment", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCustomerPayment = (options) => {
  const { id } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_CUSTOMERPAYMENT_COLUMNS,
        entity: "customerPayment",
        id: id,
        idType: "Uuid!",
      })
        .then(function(result) {
          resolve(result);
        })
        .catch(function(err) {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const deleteCustomerPayment = () => {};
