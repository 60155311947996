import { mutate, query } from "~/_utils/httpClient";
import { getClientTimeZone } from "~/_utils";
const GET_RECEIVABLES_COLUMNS = `
accountId
accountCode
receivable
name
totalReceivable`;

const GET_RECEIVABLES_DETAILS_COLUMNS = `
id
receivableType
accountId
openingBalance
amountPaid
creditAmount
dateUtc
name
reference`;

const GET_TAX_PAYABLES_COLUMNS = `
taxType
year
month
taxableAmount
taxPercentage
taxPayable
totalTaxPayable`;

const GET_STOCK_DETAILS_COLUMNS = `
    productId,
    name,
    barcode,
    inventory,
    avgPurchasePrice,
    purchasePrice,
    retailPriceExTax,
    stockCost,
    stockWorth,
    totalProducts,
    totalStockCost,
    totalStockWorth`;

const GET_SALE_DETAILS_COLUMNS = `
    date,
    saleDate,
    productId,
    productName,
    quantity,
    saleExTax,
    profit,
    totalProfit,
    totalSaleExTax`;

export const getReceivables = (options = {}) => {
  const { sort } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_RECEIVABLES_COLUMNS,
        sort: sort,
        sortType: "[ReceivableDtoSortInput!]",
        isList: true,
        entity: "receivables",
        loadAllRecords: true,
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getStockDetails = (options = {}) => {
  const { sort } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_STOCK_DETAILS_COLUMNS,
        sort: sort,
        sortType: "[StockDetailsDtoSortInput!]",
        isList: true,
        entity: "stockDetails",
        loadAllRecords: true,
      }).then(function(result) {
        debugger;
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getReceivablesDetails = (options = {}) => {
  const { sort, ...param } = options;

  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_RECEIVABLES_DETAILS_COLUMNS,
        sort: sort,
        param: convertReceivableDetailParam(param),
        paramName: "query",
        paramType: "GetReceivablesDetailsQueryInput",
        sortType: "[ReceivableDetailDtoSortInput!]",
        isList: true,
        entity: "receivablesDetails",
        loadAllRecords: true,
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};
const convertReceivableDetailParam = (param = {}) => {
  const { accountId, ...restParams } = param;
  let updatedParam = accountId ? { customerId: accountId } : {};

  return { ...updatedParam, ...restParams };
};

export const getTaxPayables = (options = {}) => {
  const { sort, ...param } = options;
  const { filterValues = {} } = param || {};
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_TAX_PAYABLES_COLUMNS,
        sort: sort,
        param: { ...filterValues, clientDateTimeZone: getClientTimeZone() },
        paramName: "query",
        paramType: "GetTaxPayablesQueryInput",
        sortType: "[TaxPayableDtoSortInput!]",
        isList: true,
        entity: "taxPayables",
        loadAllRecords: true,
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getSaleDetails = (options = {}) => {
  const { sort, ...param } = options;
  const { filterValues = {} } = param || {};

  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_SALE_DETAILS_COLUMNS,
        sort: sort,
        param: { ...filterValues, clientDateTimeZone: getClientTimeZone() },
        paramName: "query",
        paramType: "GetSaleDetailsQueryInput",
        sortType: "[SaleDetailsDtoSortInput!]",
        isList: true,
        entity: "saleDetails",
        loadAllRecords: true,
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};
