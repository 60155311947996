import React from "react";
import { Row, Col, Typography, Divider } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import PriceLabel from "~/components/Common/PriceLabel";
import { getPaymentMethodString, toFloat } from "~/_utils";
import Button from "~/components/Common/Button";

const { Title, Text } = Typography;

const Payments = (props) => {
  let {
    payments = [],
    remainingAmountToPay,
    totalAmountToPay,
    change = 0,
    previousChange = [],
    onDeletePayment,
    readonly = false,
  } = props;

  const cashBackPayments = payments.filter((p) => p.amount < 0) || {};
  const customerPaymets = payments.filter((p) => p.amount > 0) || {};

  const paymentsComponent = customerPaymets.map((payment, index) => {
    const { paymentMethod, amount, salePaymentCode } = payment;
    const key = `${paymentMethod}_${index}`;

    return (
      <Row key={key} className="payments">
        <Col xs={12} style={{ textAlign: "left" }}>
          <Text>{getPaymentMethodString(paymentMethod)}</Text>
        </Col>
        <Col xs={12} style={{ textAlign: "right" }}>
          <PriceLabel value={amount} />
          {readonly === false && (
            <Button
              className={"deletePaymentButton"}
              style={{ height: "10px" }}
              type="link"
              icon={<CloseCircleOutlined style={{ height: "100%" }} />}
              onClick={() => onDeletePayment({ paymentCode: salePaymentCode })}
            ></Button>
          )}
        </Col>
      </Row>
    );
  });

  const balance = toFloat(
    toFloat(remainingAmountToPay) - toFloat(totalAmountToPay)
  );

  return (
    <Row className="payments-container">
      <Col xs={24}>
        <Row>
          <Col xs={24}>{paymentsComponent}</Col>
        </Row>
        {cashBackPayments.length > 0 ? (
          <Row className="change">
            <Col xs={24}>
              <Divider />
            </Col>
            <Col xs={24} style={{ marginTop: "5px" }}>
              {cashBackPayments.map((c, index) => {
                return (
                  <Row key={index} style={{ width: "100%" }}>
                    <Col xs={12} style={{ textAlign: "left" }}>
                      <Text>Change</Text>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <PriceLabel value={c.amount * -1} />
                      {readonly === false && (
                        <Button
                          className={"deletePaymentButton"}
                          style={{ height: "10px" }}
                          type="link"
                          icon={
                            <CloseCircleOutlined style={{ height: "100%" }} />
                          }
                          onClick={() =>
                            onDeletePayment({ paymentCode: c.salePaymentCode })
                          }
                        ></Button>
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {remainingAmountToPay !== 0 && (
          <>
            <Divider />
            <Row className="toPay">
              <Col xs={12} style={{ textAlign: "left" }}>
                <Title level={4}>Balance</Title>
              </Col>
              <Col xs={12} style={{ textAlign: "right" }} className="price">
                <PriceLabel value={-1 * remainingAmountToPay} />
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default Payments;
