import { mutate, query } from "~/_utils/httpClient";
import {
  recordSavedFailed,
  recordSavedSuccess,
  showDialog,
} from "./generalActions";
import authApp, { auth } from "~/_auth/base";
import { HTTPSTATUS } from "~/_utils/consts";
import { getClientTimeZone } from "~/_utils";

const SIGNUP_COLUMNS = `
companyId
`;

const FORGOTPASSWORD_COLUMNS = `
userId
`;

const RESETPASSWORD_COLUMNS = `
userId
`;

const SAVE_USER_COLUMNS = `
userId
`;

const USER_COLUMNS = `
displayName
email
userName
userId
`;

export const login = (uniqueCompanyName, userName, password) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      const email = `${userName.toLowerCase()}@${uniqueCompanyName.toLowerCase()}.com`;

      authApp
        .auth()
        .setPersistence(auth.Auth.Persistence.SESSION)
        .then(() => {
          authApp
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
              dispatch(recordSavedSuccess("login", {}));
              return resolve({ ...userCredential });
            })
            .catch((error) => {
              if (error && error.code === "auth/network-request-failed") {
                console.log("Network issue");
                dispatch(
                  recordSavedFailed("login", {
                    code: HTTPSTATUS.AuthNetworkError,
                  })
                );
              } else {
                dispatch(
                  recordSavedFailed("login", {
                    code: HTTPSTATUS.AuthenticationError,
                  })
                );
              }
              return reject(error);
            });
        })
        .catch((error) => {
          console.log("Network issue");
          dispatch(
            recordSavedFailed("login", {
              code: HTTPSTATUS.AuthNetworkError,
            })
          );
          return reject(error);
        });
    } catch (err) {
      console.log("Network issue");
      dispatch(
        recordSavedFailed("login", {
          code: HTTPSTATUS.AuthNetworkError,
        })
      );
      return reject(err);
    }
  });
}; //TODO: create reducer for account (user success) and add user to state & in the context to be used in hasRights

export const signup = (account = {}) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      account.clientDateTimeZone = getClientTimeZone();
      mutate({
        command: "signupUser",
        commandType: "SignupCommandInput!",
        data: account,
        columns: SIGNUP_COLUMNS,
      })
        .then(function(result) {
          resolve();
        })
        .catch(function(err) {
          console.log(err);
          dispatch(recordSavedFailed("signup", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const signOut = (options = {}) => {
  //sessionStorage.removeItem(SESSIONSTORAGE.saleSyncRunUtcDateTime);

  sessionStorage.clear();
  const { reload = true } = options;
  authApp.auth().signOut();
  if (reload === true) {
    window.location.reload();
  }
};

export const forgotPassword = (data = {}) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      mutate({
        command: "forgotPassword",
        commandType: "ForgotPasswordCommandInput!",
        data: data,
        columns: FORGOTPASSWORD_COLUMNS,
      })
        .then(function(result) {
          resolve();
        })
        .catch(function(err) {
          console.log(err);
          dispatch(recordSavedFailed("forgotPassword", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const resetPassword = (data = {}) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      mutate({
        command: "resetPassword",
        commandType: "ResetPasswordCommandInput!",
        data: data,
        columns: RESETPASSWORD_COLUMNS,
      })
        .then(function(result) {
          resolve();
        })
        .catch(function(err) {
          console.log(err);
          dispatch(recordSavedFailed("resetPassword", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getUser = (options = {}) => {
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: USER_COLUMNS,
        entity: "userProfile",
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const saveUser = (user = {}) => (dispatch) => {
  const isUserLoginInfoChanged = true; //TODO WARNING: Only set it when current logged-in user info changed (that might not be the case for multi-user environment)

  return new Promise(function(resolve, reject) {
    try {
      let updatedUser = user;
      mutate({
        command: user.userId ? "updateUser" : "createUser",
        commandType: user.userId
          ? "UpdateUserCommandInput!"
          : "CreateUserCommandInput!",
        data: user,
        columns: SAVE_USER_COLUMNS,
      })
        .then(function(result) {
          updatedUser.userId = result.data.userId;
          dispatch(recordSavedSuccess("user", updatedUser));
          if (isUserLoginInfoChanged === true) {
            dispatch(
              showDialog({
                title: "Re-Login",
                content: "User information is changed, please re-login",
                action: () => {
                  signOut();
                },
              })
            );
          }
          resolve(updatedUser);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("user", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};
