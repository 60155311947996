import React from "react";
import { Tag, Input, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import "./EditableTagGroup.css";

class EditableTagGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: props.tags ? props.tags : [],
      inputVisible: false,
      inputValue: "",
      editInputIndex: -1,
      editInputValue: "",
    };
  }

  handleClose = (removedTag) => {
    const { onTagsChange } = this.props;
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags });
    onTagsChange && onTagsChange(tags);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    const { onTagsChange, notAllowedValues = [] } = this.props;

    if (
      inputValue &&
      notAllowedValues.indexOf(inputValue) === -1 &&
      !notAllowedValues.some((v) => inputValue.includes(v)) &&
      tags.filter((x) => x.toLowerCase() === inputValue.toLowerCase()).length <=
        0
    ) {
      tags = [...tags, inputValue];
      onTagsChange && onTagsChange(tags);
    }

    this.setState({
      tags,
      inputVisible: false,
      inputValue: "",
    });
  };

  handleEditInputChange = (e) => {
    this.setState({ editInputValue: e.target.value });
  };

  handleEditInputConfirm = () => {
    this.setState(({ tags, editInputIndex, editInputValue }) => {
      const newTags = [...tags];
      newTags[editInputIndex] = editInputValue;

      return {
        tags: newTags,
        editInputIndex: -1,
        editInputValue: "",
      };
    });
  };

  saveInputRef = (input) => {
    this.input = input;
  };

  saveEditInputRef = (input) => {
    this.editInput = input;
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { tags: prevTags = [] } = prevState;
    const { tags = [] } = nextProps;

    if (
      prevTags.length === tags.length &&
      prevTags.every((x) => tags.includes(x))
    ) {
      return null;
    }

    return { tags: tags };
  }

  render() {
    const {
      tags,
      inputVisible,
      inputValue,
      editInputIndex,
      editInputValue,
    } = this.state;
    const { newTagTitle = "New Tag" } = this.props;

    return (
      <>
        {tags.map((tag, index) => {
          const key = `${tag}${index}`;
          if (editInputIndex === index) {
            return (
              <Input
                ref={this.saveEditInputRef}
                key={key}
                size="small"
                className="tag-input"
                value={editInputValue}
                onChange={this.handleEditInputChange}
                onBlur={this.handleEditInputConfirm}
                onPressEnter={this.handleEditInputConfirm}
              />
            );
          }

          const isLongTag = tag.length > 20;

          const tagElem = (
            <Tag
              className="edit-tag"
              key={key}
              closable={true}
              onClose={() => this.handleClose(tag)}
            >
              <span
                onDoubleClick={(e) => {
                  if (index !== 0) {
                    this.setState(
                      { editInputIndex: index, editInputValue: tag },
                      () => {
                        this.editInput.focus();
                      }
                    );
                    e.preventDefault();
                  }
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={key}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            className="tag-input"
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag className="site-tag-plus" onClick={this.showInput}>
            <PlusOutlined /> {newTagTitle}
          </Tag>
        )}
      </>
    );
  }
}

export default EditableTagGroup;
