import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Input,
  Form,
  Divider,
  Typography,
  InputNumber,
  Switch,
} from "antd";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { propName, priceFloat, toFloat } from "~/_utils";
import viewModel from "./viewModel";
import FormItem from "~/components/Form/FormItem";
import { PageTitle } from "~/components/Common/Text";
import Button from "~/components/Common/Button";

import { PRODUCTTYPES } from "~/_utils/consts";
import ProductInfo from "./ProductInfo";
import Inventory from "./Inventory";
import Price from "./Price";
import { calculateRetailPriceIncTax } from "./Price";
import Attributes from "./Attributes";
import "./index.scss";

import { getSuppliers } from "~/actions/supplierActions";
import { saveProduct, getProduct } from "~/actions/productActions";
import { getSalesTaxes } from "~/actions/setupActions";
import Prompt from "~/components/Common/Prompt";

const GetInitialValues = (product = {}) => {
  const initialValues = {
    productId: product.productId,
    barcode: product.barcode,
    sku: product.sku,
    name: product.name,
    brandId: product.brandId,
    description: product.description,
    categoryId: product.categoryId,
    sellOnPointOfSale: product.sellOnPointOfSale === false ? false : true,
    productType: product.productType
      ? product.productType
      : PRODUCTTYPES.StandardProduct,
    supplierId: product.supplierId,
    purchasePrice: priceFloat(product.purchasePrice),
    avgPurchasePrice: priceFloat(product.avgPurchasePrice),
    currentInventory: toFloat(product.currentInventory),
    reOrderPoint: toFloat(product.reOrderPoint),
    taxId: product.taxId,
    markup: toFloat(product.markup),
    retailPriceExTax: priceFloat(product.retailPriceExTax),
    retailPriceIncTax: calculateRetailPriceIncTax({
      purchasePrice: priceFloat(product.purchasePrice),
      markup: toFloat(product.markup),
      taxId: product.taxId,
    }),
    productAttributes: product.productAttributes || [],
    productVariants: product.productVariants || [],
  };

  return initialValues;
};

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.setFormRef = this.setFormRef.bind();
    this.onProductTypeChange = this.onProductTypeChange.bind();
    this.getProduct = this.getProduct.bind();

    const { product = {} } = props;

    this.state = {
      formRef: null,
      taxes: [],
      suppliers: [], //suppliers are used in product info and also in variants so get them one time in product
      productType: PRODUCTTYPES.StandardProduct,
      product: product,
    };
  }

  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  getProduct = (id) => {
    const _this = this;

    getProduct({ id: id })
      .then(function(result) {
        _this.setState(
          {
            product: result.data || {},
            isEditMode: true, //getProduct is only called in edit mode
          },
          _this.state.formRef.setFieldsValue(result.data)
        );
        _this.setState({
          productType: result.data
            ? result.data.productType
            : PRODUCTTYPES.StandardProduct,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  componentDidMount() {
    const _this = this;
    getSuppliers({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          suppliers: [...data],
        });
      })
      .catch(function(err) {
        console.log(err);
      });

    getSalesTaxes({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          taxes: [...data],
        });
      })
      .catch(function(err) {
        console.log(err);
      });

    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    const productId = queryParams.productId;

    productId && this.getProduct(productId);
  }

  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });

    debugger;
    const { saveProduct } = this.props;
    const { formRef, isEditMode } = this.state;
    const _this = this;

    //exclude not form related properties
    const { autoCalculatePurchasePrice, ...otherValues } = values;
    let product = otherValues;
    let updatedVariants = product.productVariants || [];
    updatedVariants = updatedVariants.map(
      ({ autoCalculatePurchasePrice, ...restValues }) => restValues
    ); //remove property autoCalculatePurchasePrice

    saveProduct({ ...product, productVariants: [...updatedVariants] })
      .then(function(result) {
        _this.setState({
          loading: false,
          isFormChanged: false,
        });

        if (isEditMode === true) {
          formRef.setFieldsValue(result);
          _this.setState({
            product: result,
          });
        } else {
          _this.setState(
            {
              product: {},
            },
            formRef.resetFields
          );

          _this.onProductTypeChange(PRODUCTTYPES.StandardProduct);
        }
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  onProductTypeChange = (value) => {
    const { formRef } = this.state;

    this.setState({
      productType: value,
    });

    if (value === PRODUCTTYPES.ProductWithVariants)
      formRef.setFieldsValue({
        barcode: undefined,
        sku: undefined,
        supplierId: undefined,
        currentInventory: 0,
        reOrderPoint: 0,
      });

    if (value === PRODUCTTYPES.StandardProduct)
      formRef.setFieldsValue({
        productAttributes: [],
        productVariants: [],
      });
  };
  render() {
    const _this = this;
    const {
      formRef,
      suppliers = [],
      taxes = [],
      productType,
      loading = false,
      product = {},
      isFormChanged = false,
    } = this.state;

    const initialValues = GetInitialValues(product);

    return (
      <div className="product-container">
        <Row>
          <Col xs={24}>
            <PageTitle>Product</PageTitle>
            <Divider />
            <br />
          </Col>
          <Col xs={24} xl={18} xxl={14}>
            <Form
              scrollToFirstError={true}
              validateTrigger="onBlur"
              onFinish={this.handleSubmit}
              onFinishFailed={this.onFinishFailed}
              className="product-form"
              initialValues={initialValues}
              ref={this.setFormRef}
              onFieldsChange={() => {
                isFormChanged !== true &&
                  _this.setState({ isFormChanged: true });
              }}
            >
              <Prompt when={isFormChanged} />
              <Row gutter={[16, 8]}>
                <Col xs={24}>
                  <ProductInfo
                    form={formRef}
                    initialValues={initialValues}
                    onProductTypeChange={this.onProductTypeChange}
                  />
                </Col>
                {productType === PRODUCTTYPES.StandardProduct && (
                  <>
                    <Col xs={24}>
                      <Divider />
                    </Col>
                    <Col xs={24}>
                      <Inventory
                        form={formRef}
                        initialValues={initialValues}
                        suppliers={suppliers}
                      />
                    </Col>
                  </>
                )}
                <Col xs={24}>
                  <Divider />
                </Col>
                <Col xs={24}>
                  <Price
                    form={formRef}
                    initialValues={initialValues}
                    taxes={taxes}
                    productType={productType}
                  />
                </Col>
                {productType === PRODUCTTYPES.ProductWithVariants && (
                  <>
                    <Col xs={24}>
                      <Divider />
                    </Col>
                    <Col xs={24}>
                      <Attributes
                        form={formRef}
                        initialValues={initialValues}
                        suppliers={suppliers}
                        taxes={taxes}
                      />
                    </Col>
                  </>
                )}

                <Col xs={24}>
                  <Form.Item>
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      className="save-button"
                    >
                      <Trans i18nKey="Save">Save</Trans>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveProduct })(
  withRouter(withNamespaces()(Product))
);
