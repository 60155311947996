import React from "react";
import { Modal } from "antd";
import Attribute from "~/pages/Products/Attributes/Attribute";

const AttributeModal = (props) => {
  const { attributeId, onSave, ...restProps } = props;
  return (
    <Modal
      title="Attribute"
      centered
      footer={null}
      destroyOnClose={true}
      {...restProps}
    >
      <Attribute attributeId={attributeId} onSave={onSave} showTitle={false} />
    </Modal>
  );
};

export default AttributeModal;
