import { LOGIN_SUCCESS } from "~/actions/types";
import { getLoggedInUser } from "~/_utils";

const getUser = () => {
  try {
    return getLoggedInUser();
  } catch {}
  return {};
};

const initialState = { ...getUser() };

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
