import React from "react";
import { Modal } from "antd";
import Brand from "~/pages/Products/Brands/Brand";

const BrandModal = (props) => {
  const { brandId, onSave, ...restProps } = props;
  return (
    <Modal
      title="Brand"
      centered
      footer={null}
      destroyOnClose={true}
      {...restProps}
    >
      <Brand brandId={brandId} onSave={onSave} showTitle={false} />
    </Modal>
  );
};

export default BrandModal;
