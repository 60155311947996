import { getProductsDb, addUpdateProductsInDb } from "./offlineProducts";
import {
  getCustomersDb,
  addUpdateCustomersInDb,
  deleteCustomerInDb,
} from "./offlineCustomers";
import { initializeDB } from "~/_utils";
import { SESSIONSTORAGE } from "~/_utils/consts";

export const updateClientDbForProducts = (products = []) => {
  return new Promise(function(resolve, reject) {
    const dbProducts = getProductsDb();
    dbProducts.on("created", function() {
      addUpdateProductsInDb(dbProducts, products).then(function(result) {
        resolve();
      });
    });
  });
};

export const updateClientDbForQuickKeys = (quickKey = {}) => {
  return new Promise(function(resolve, reject) {
    sessionStorage.setItem(SESSIONSTORAGE.quickKey, JSON.stringify(quickKey));
    resolve(quickKey);
  });
};

export const updateClientDbForCustomers = (customers = []) => {
  return new Promise(function(resolve, reject) {
    const dbCustomers = getCustomersDb();
    dbCustomers.on("created", function() {
      addUpdateCustomersInDb(dbCustomers, customers).then(function(result) {
        resolve(result);
      });
    });
  });
};

export const updateClientDbForDeletedCustomer = (customer = {}) => {
  return new Promise(function(resolve, reject) {
    const dbCustomers = getCustomersDb();
    dbCustomers.on("created", function() {
      deleteCustomerInDb(dbCustomers, customer).then(function(result) {
        resolve(result);
      });
    });
  });
};

export const selectDocumentFromLocalDb = (options) => {
  const { dbName, keyPrefix, value } = options;

  return new Promise(function(resolve, reject) {
    const db = initializeDB(dbName);

    db.get(`${keyPrefix}_${value}`).then(function(result) {
      resolve(result);
    });
  });
};

export const getAllDocuments = (db, keyPrefix, include_docs = false) => {
  return db.allDocs({
    include_docs: include_docs,
    startkey: `${keyPrefix}_`,
    endkey: `${keyPrefix}_\ufff0`,
  });
};

export const addUpdateDocumentsInDbWithoutIteration = (db, records = []) => {
  return db
    .bulkDocs(records)
    .then(function(result) {
      Promise.resolve(result);
    })
    .catch(function(err) {
      console.log(err);
      Promise.reject(err);
    });
};

export const addUpdateDocumentsInDb = (
  db,
  keyPrefix,
  keyProperty,
  documents = []
) => {
  return new Promise(function(resolve, reject) {
    getAllDocuments(db, keyPrefix).then(function(result) {
      documents.forEach((d, index) => {
        d._id = `${keyPrefix}_`;
        d._id = keyProperty ? `${d._id}${d[keyProperty]}` : d._id;
        const resultFromDb =
          result && result.rows.filter((x) => x.id === d._id)[0];
        d._rev = resultFromDb && resultFromDb.value.rev; //To update the existing document need "rev"
      });
      db.bulkDocs(documents)
        .then(function(result) {
          resolve(documents);
        })
        .catch(function(err) {
          console.log(err);
          reject(err);
        });
    });
  });
};
