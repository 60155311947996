import React from "react";
import { Modal } from "antd";
import CustomerPayment from "~/pages/Customers/CustomerPayments/CustomerPayment";

const CustomerPaymentModal = (props) => {
  const { customerPaymentId, onSave, visible, ...restProps } = props;
  return (
    <Modal
      title="Customer payment"
      centered
      footer={null}
      destroyOnClose={true}
      visible={visible === true}
      {...restProps}
    >
      <CustomerPayment
        customerPaymentId={customerPaymentId}
        onSave={onSave}
        showTitle={false}
      />
    </Modal>
  );
};

export default CustomerPaymentModal;
