import { dbSales } from "../config";

import {
  getAllDocuments,
  selectDocumentFromLocalDb,
  addUpdateDocumentsInDb,
  addUpdateDocumentsInDbWithoutIteration,
} from "./";

import PouchDB from "pouchdb-browser";
import { initializeDB } from "~/_utils";

const dbKeyPrefix = "sales";
const dbKeyProperty = "saleCode";

//PouchDB keeps metadata info for delete docs, so to optimize the db size purge it
export const purgeSaleDb = () => {
  try {
    const db = initializeDB(dbSales, { skip_setup: true });

    db.info()
      .then(() => {
        // The database exists.
        db.info().then((info) => {
          console.log("sales doc count : ", info.doc_count);
          if (info.doc_count <= 0) {
            db.destroy();
          }
        });

        //If currently user is not on PaymentScreen then purge saleDB
        //check if saleDB exists & document count == 0 then purgeSaleDb
      })
      .catch((e) => {
        // No database found and it was not created.
        // Do nothing..
      });
  } catch (err) {
    console.log("purgeSaleDb : ", err);
  }
};

export function getSalesDb(options = {}) {
  const { uniqueCompanyName } = options;
  return initializeDB(dbSales, {
    uniqueCompanyName: uniqueCompanyName,
    revs_limit: 1,
    auto_compaction: true,
  });
}

export const getAllSalesFromLocalDb = (options = {}) => {
  const { saleDb, includeRecordMetadata = false, uniqueCompanyName } = options;
  return new Promise(function(resolve, reject) {
    const db = saleDb || getSalesDb({ uniqueCompanyName });

    getAllDocuments(db, dbKeyPrefix, true)
      .then(function(result = {}) {
        includeRecordMetadata === true
          ? resolve(result.rows)
          : resolve(result.rows && result.rows.map((x) => x.doc));
      })
      .catch(function(err) {
        console.log(err);
        reject(err);
      });
  });
};

export const selectSaleFromLocalDb = (code) => {
  return selectDocumentFromLocalDb({
    dbName: dbSales,
    keyPrefix: dbKeyPrefix,
    value: code,
  });
};

export const addUpdateSaleInDb = (sale) => {
  const db = getSalesDb();
  return addUpdateDocumentsInDb(db, dbKeyPrefix, dbKeyProperty, [sale]);
};

export const addUpdateSaleInDbWithoutIteration = (sale) => {
  const db = getSalesDb();
  return addUpdateDocumentsInDbWithoutIteration(db, [sale]);
};

export const deleteSaleFromDbWithoutIteration = (sale, options = {}) => {
  if (!sale || Object.keys(sale).length <= 0) {
    return Promise.resolve();
  }
  const db = getSalesDb(options);
  return db.remove(sale);

  // return db.remove(sale).then(function() {
  //   //db.purgeOnDelete();
  //   db.destroy()
  //     .then(function(response) {
  //       console.log(response);
  //     })
  //     .catch(function(err) {
  //       console.log(err);
  //     })
  //     .finally(function() {
  //       Promise.resolve();
  //     });
  // });
};
