import React from "react";
import { Row, Col, Tabs, Divider } from "antd";
import { PageTitle } from "~/components/Common/Text";
import PrintSettings from "./PrintSettings";
import CompanySettings from "./CompanySettings";
import queryString from "query-string";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, withNamespaces } from "react-i18next";

const { TabPane } = Tabs;

class Settings extends React.Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    const defaultTab = queryParams.tab || "print";
    this.state = { defaultTab: defaultTab };
  }
  render() {
    const { defaultTab } = this.state;
    return (
      <div className="setting-container">
        <Row>
          <Col xs={24}>
            <PageTitle>Settings</PageTitle>
            <Divider />
            <br />
          </Col>
          <Col xs={24} xl={16} xxl={12}>
            <Tabs defaultActiveKey={defaultTab}>
              <TabPane tab="Print receipt" key="print">
                <PrintSettings />
              </TabPane>
              <TabPane tab="Company settings" key="company">
                <CompanySettings />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withNamespaces()(Settings)));
