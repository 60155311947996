import React from "react";
import "./index.scss";
import Logo from "~/components/Logo";
import { Form, Row, Col } from "antd";
import { Button, Input } from "antd";
import { LockOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";
import viewModel from "./viewModel";
import FormItem from "~/components/Form/FormItem";
import Link from "~/components/Link";
import { withRouter } from "react-router-dom";
import { constructUrl } from "~/config";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { login } from "~/actions/userActions";
import { getCompanySettings, getPrintSettings } from "~/actions/setupActions";
import { propName } from "~/_utils";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { formRef: null };
    this.handleSubmit = this.handleSubmit.bind();
    this.setFormRef = this.setFormRef.bind();
    this.loginUser = this.loginUser.bind();
  }
  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  handleSubmit = (values) => {
    // console.log("Received values of form: ", values);
    this.loginUser(values);
  };
  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  loginUser = (values) => {
    const { login, history, match } = this.props;
    const _this = this;
    this.setState({ loading: true });

    login(values.uniqueCompanyName, values.userName, values.password)
      .then(function(currentUser) {
        if (!!currentUser) {
          //load settings
          getPrintSettings().catch(function(err) {
            console.log(err);
            _this.setState({ loading: false });
            history.push(constructUrl({ match, to: "/Dashboard" })); //history is injected from hoc withRouter
          });
          getCompanySettings()
            .then(function() {
              history.push(constructUrl({ match, to: "/Dashboard" })); //history is injected from hoc withRouter
            })
            .catch(function(err) {
              console.log(err);
              _this.setState({ loading: false });
              history.push(constructUrl({ match, to: "/Dashboard" })); //history is injected from hoc withRouter
            });
        }
      })
      .catch(function(err) {
        console.log(err);
        _this.setState({ loading: false });
      });
  };

  render() {
    const { formRef, loading = false } = this.state;
    return (
      <div className="login-form-container">
        <Form
          validateTrigger="onBlur"
          onFinish={this.handleSubmit}
          className="login-form"
          initialValues={{}}
          ref={this.setFormRef}
        >
          <Logo />
          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.uniqueCompanyName)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.uniqueCompanyName)]
            }
          >
            {({ options }) => <Input prefix={<ShopOutlined />} {...options} />}
          </FormItem>
          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.userName)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.userName)]
            }
          >
            {({ options }) => <Input prefix={<UserOutlined />} {...options} />}
          </FormItem>

          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.password)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.password)]
            }
          >
            {({ options }) => (
              <Input.Password prefix={<LockOutlined />} {...options} />
            )}
          </FormItem>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loading}
            >
              <Trans i18nKey="Login">Login</Trans>
            </Button>
            <Row gutter={[16, 16]}>
              <Col>
                <Link to="/Signup" keepQueryParams={true}>
                  <Trans i18nKey="RegisterNow">Register now</Trans>
                </Link>
              </Col>
              <Col>{"|"}</Col>
              <Col>
                <Link to="/ForgotPassword" keepQueryParams={true}>
                  <Trans i18nKey="ForgotPassword">Forgot password</Trans>
                </Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { login })(
  withRouter(withNamespaces()(LoginForm))
);
