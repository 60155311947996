import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { defaultLocale } from "./config";
import { TRANSLATION_NAMESPACE } from "./_utils/consts";

import nlCommon from "./locales/nl/common.json";
import enCommon from "./locales/en/common.json";

import nlValidation from "./locales/nl/validation.json";
import enValidation from "./locales/en/validation.json";

import nlAccount from "./locales/nl/account.json";
import enAccount from "./locales/en/account.json";

import enReport from "./locales/en/report.json";
import enProduct from "./locales/en/product.json";
import enSell from "./locales/en/sell.json";

i18n.use(LanguageDetector).init({
  fallbackLng: defaultLocale,
  detection: {
    order: ["path"],
  },

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  ns: [
    TRANSLATION_NAMESPACE.Common,
    TRANSLATION_NAMESPACE.Validation,
    TRANSLATION_NAMESPACE.Account,
    TRANSLATION_NAMESPACE.Report,
    TRANSLATION_NAMESPACE.Product,
    TRANSLATION_NAMESPACE.Sell,
  ],
  defaultNS: TRANSLATION_NAMESPACE.Common,

  resources: {
    nl: { common: nlCommon, validation: nlValidation, account: nlAccount },
    en: {
      common: enCommon,
      validation: enValidation,
      account: enAccount,
      report: enReport,
      product: enProduct,
      sell: enSell,
    },
  },

  react: {
    wait: true,
  },
});

export default i18n;
