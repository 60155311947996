import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { constructUrl } from "../../config";

const Link = ({
  history = {},
  match,
  children,
  to,
  isNested,
  keepQueryParams = false,
}) => {
  const { location } = history;
  let path = constructUrl({ match, to, isNested });
  path =
    keepQueryParams === true ? `${path}${location && location.search}` : path;
  return <NavLink to={path}>{children}</NavLink>;
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
};
export default withRouter(Link);
