import React from "react";
import { connect } from "react-redux";
import Button from ".";
import { Badge } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";

import { version as localVersion } from "~/config";
import { reloadPageWithQueryParam } from "~/_utils";
import { withRouter } from "react-router-dom";

const versionQueryParam = "version";

class VersionUpdateButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isVersionUpdated: false };
  }

  componentDidMount() {
    const { location, history } = this.props;

    const searchParams = new URLSearchParams(location.search);
    const hasVersionQueryParam =
      searchParams && searchParams.has(versionQueryParam);
    const latestVersion =
      hasVersionQueryParam === true && searchParams.get(versionQueryParam);
    const needsVersionUpdate =
      hasVersionQueryParam === true && isVersionUpdated(latestVersion);

    if (hasVersionQueryParam === true) {
      searchParams.delete(versionQueryParam);
      const newPath = `${location.pathname}?${searchParams.toString()}${
        location.hash
      }`;
      history.replace(newPath);
    }

    if (hasVersionQueryParam === true && needsVersionUpdate === true) {
      reloadPageWithQueryParam("", "");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isVersionUpdated = false } = nextProps;
    const { isVersionUpdated: isVersionUpdatedFromState = false } = prevState;

    // if (isVersionUpdatedFromState === isVersionUpdated) {
    //   return null;
    // }

    return { isVersionUpdated: isVersionUpdated };
  }

  render() {
    const { isVersionUpdated = false } = this.state;
    const { small = false, version } = this.props;
    const ribbonColor = isVersionUpdated === true ? "#f76969" : "#21a8f4";
    const containerHeight = isVersionUpdated === true ? "100px" : "50px";
    const containerWidth = small === true ? "55px" : "195px";

    return (
      <div
        style={{
          position: "fixed",
          overflow: "hidden",
          textAlign: "center",
          minWidth: "55px",
          left: "0px",
          bottom: "0px",
          height: containerHeight,
          backgroundColor: "#ffffff",
          width: containerWidth,
        }}
      >
        {
          <Badge.Ribbon
            text={`v${localVersion}`}
            color={ribbonColor}
            placement="start"
            style={{ left: "0px" }}
          >
            <div></div>
          </Badge.Ribbon>
        }
        {isVersionUpdated === true && (
          <Button
            danger={true}
            style={{
              width: "90%",
              backgroundColor: "#f9e5e5",
              left: "3px",
              top: "50px",
            }}
            icon={<CloudDownloadOutlined />}
            onClick={() => reloadPageWithQueryParam(versionQueryParam, version)}
          >
            {small === false ? "Update version" : ""}
          </Button>
        )}
      </div>
    );
  }
}

function isVersionUpdated(serverVersion = "0.0.0") {
  // Split the versions into an array of numbers
  const version1 = (localVersion || "0.0.0").split(".").map(Number);
  const version2 = serverVersion && serverVersion.split(".").map(Number);

  // Compare each component of the versions
  for (let i = 0; i < version1.length; i++) {
    if (version1[i] > version2[i]) {
      return false;
    } else if (version1[i] < version2[i]) {
      return true;
    }
  }

  // If all components are equal, the versions are the same
  return false;
}
const mapStateToProps = (state, ownProps = {}) => {
  const version = state.general.version;
  return { isVersionUpdated: isVersionUpdated(version), version: version };
};

export default withRouter(connect(mapStateToProps, {})(VersionUpdateButton));
