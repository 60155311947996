import React from "react";
import { DatePicker as DatePickerAntd } from "antd";
import { DateMonthFormat, DateFormat, DateTimeFormat } from "~/config";
import { pick } from "lodash";

const DatePicker = (props) => {
  const { ...restProps } = props;
  const { showTime, picker = "" } = restProps;
  let dateFormat = showTime ? DateTimeFormat : DateFormat;
  if (picker === "month") {
    dateFormat = DateMonthFormat;
  }

  const onOk = (value) => {
    const { onOk } = restProps;
    const selectedDate = value._d;

    console.log(selectedDate);
    onOk && onOk(value, selectedDate);
  };
  const onChange = (value, dateString) => {
    const { onChange } = restProps;

    console.log("onChange: ", value);
    onChange && onChange(value, dateString);
  };
  return (
    <>
      <DatePickerAntd
        {...restProps}
        format={dateFormat}
        onChange={onChange}
        onOk={onOk}
      />
    </>
  );
};

export default DatePicker;
