import {
  ApolloClient,
  gql,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { graphQlEndpoint, defaultPageSize } from "~/config";
import {
  removeEmptyObject,
  getScreenHeight,
  getLoggedInUser,
  convertToCamelCase,
  isEmpty,
} from "~/_utils";
import { HTTPSTATUS } from "~/_utils/consts";

let _userToken = "";

const authLink = setContext(async (_, { headers }) => {
  const currentUser = getLoggedInUser();
  const token =
    (currentUser &&
      currentUser.getIdToken &&
      (await currentUser.getIdToken())) ||
    _userToken;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token || ""}` : "",
    },
  };
});

const getApolloClient = () => {
  const httpLink = createHttpLink({
    uri: graphQlEndpoint,
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({ addTypename: false }),
  });

  return client;
};

export const mutate = (options) => {
  const { command, userToken = "" } = options;
  _userToken = userToken;

  const mutationObj = constructGraphQlMutation(options);
  let mutation = mutationObj.mutation;
  const variables = mutationObj.variables;

  return new Promise(function(resolve, reject) {
    mutation = gql`
      ${mutation}
    `;
    const client = getApolloClient();

    client
      .mutate({
        mutation: mutation,
        variables: variables,
      })
      .then(function(result) {
        const response = {};
        response.data = eval(`result.data.${command}`);
        resolve(response);
      })
      .catch(function(err) {
        const validationErrors = [];
        const errorCode =
          err.graphQLErrors &&
          err.graphQLErrors.length > 0 &&
          err.graphQLErrors[0].extensions &&
          err.graphQLErrors[0].extensions.code;
        const errorMessage = errorCode && err.graphQLErrors[0].message;

        //Badrequest :400
        if (errorCode === HTTPSTATUS.ValidationError) {
          const validationErrorJson = JSON.parse(errorMessage);
          Object.keys(validationErrorJson).forEach((k) =>
            validationErrors.push({
              name: convertToCamelCase(k),
              errors: validationErrorJson[k],
            })
          );
        }

        const error = {
          code: errorCode || HTTPSTATUS.InternalError,
          validationErrors: validationErrors,
        };

        reject(error);
      });
  });
};

const constructGraphQlMutation = (options) => {
  const { command, commandType, columns, data } = options;

  const mutation = `
  mutation Command${command}($data: ${commandType}) {
    ${command}(command: $data) ${
    columns && Object.keys(columns).length !== 0 ? `{ ${columns} }` : ``
  }
  }
`;

  return {
    mutation: mutation,
    variables: {
      data: data,
    },
  };
};

export const query = (options) => {
  const { isList, entity, pageInfo = {}, loadAllRecords = false } = options;

  //If already last page is loaded then don't make API call
  if (isList === true && pageInfo.hasNextPage === false) {
    return;
  }
  const queryObj = !isList
    ? constructGraphQlSingleQuery(options)
    : loadAllRecords === true
    ? constructGraphQlQueryWithoutPaging(options)
    : constructGraphQlQuery(options);

  if (!queryObj) {
    return new Promise(function(resolve, reject) {
      reject("invaid operation");
    });
  }

  let query = queryObj.query;
  let variables = queryObj.variables;

  query = gql`
    ${query}
  `;
  const client = getApolloClient();

  return new Promise(function(resolve, reject) {
    client
      .query({
        query: query,
        variables: variables,
      })
      .then(function(result) {
        let data = result.data;

        data = eval(`data.${entity}`);
        data = data || {};

        const response = {};
        response.data = data.nodes || data;
        response.totalCount = data.totalCount;
        response.pageInfo = data.pageInfo;

        resolve(response);
      })
      .catch(function(err) {
        reject(err);
      });
  });
};

const constructGraphQlSingleQuery = (options) => {
  const {
    entity = "",
    columns = {},
    id,
    idType = "Uuid!",
    code,
    codeType = "String",
    additionalParameterName,
    additionalParameter,
    additionalParameterType,
  } = options;

  const query = `
    query 
    Get${entity} ${
    id || code || additionalParameterName
      ? `(${id ? `$id: ${idType}` : ``}, ${code ? `$code: ${codeType}` : ``}, ${
          additionalParameterName
            ? `$additionalParams: ${additionalParameterType}`
            : ``
        })`
      : ""
  }
                  {
                    ${entity}${
    id || code || additionalParameterName
      ? `(${id ? `id: $id` : ""}, ${code ? `code: $code` : ""}, ${
          additionalParameterName
            ? `${additionalParameterName}: $additionalParams`
            : ""
        })`
      : ""
  }
                    ${
                      isEmpty(columns)
                        ? ""
                        : `{
                        ${columns}
                      }`
                    }
                  }`;
  let variables = {
    id: id,
    code: code,
  };
  variables[`additionalParams`] = additionalParameter;

  variables = removeEmptyObject(variables);

  return { query: query, variables: variables };
};

const constructGraphQlQuery = (options) => {
  const {
    columns = {},
    entity = "",
    filter = {},
    filterType,
    sort = {},
    sortType,
    pageInfo = {},
    enablePaging = true,
    pageSizeValue,
    additionalParameterName,
    additionalParameter,
    additionalParameterType,
  } = options;

  const { hasNextPage, endCursor } = pageInfo;
  const mediumScreenHeight = 768;
  const pageSizeForMediumScreenHeight = defaultPageSize;
  const screenHeight = getScreenHeight();

  //On first load hasNextPage will be undefined so load as much records based on the screen size to show the scrollbar, on subsiquent loads ; load default pageSize
  let pageSize =
    hasNextPage === true
      ? pageSizeValue || defaultPageSize
      : pageSizeValue ||
        Math.ceil(
          (pageSizeForMediumScreenHeight / mediumScreenHeight) * screenHeight
        );

  const query = `
    query 
    Get${entity}(${filterType && `$filter: ${filterType}`}, ${sortType &&
    `$sort: ${sortType}`}, ${
    additionalParameterType
      ? `$additionalParams: ${additionalParameterType}`
      : ""
  } )
                  {
                    ${entity}( 
                      
                      ${
                        enablePaging === true
                          ? `first:${pageSize}, 
                        ${endCursor ? `after: "${endCursor}" ` : ""}`
                          : ""
                      }
                    ,where: $filter, order: $sort, ${
                      additionalParameterType
                        ? `${additionalParameterName}: $additionalParams`
                        : ""
                    }){ 
                      ${`pageInfo{
                        startCursor
                        endCursor
                        hasNextPage
                      }nodes{${columns}}`}
                    }
                  }
                  `;
  let variables = {
    filter: convertToHotChocolateFilter(filter),
    sort: sort,
  };

  variables[`additionalParams`] = additionalParameter;
  variables = removeEmptyObject(variables);

  return { query: query, variables: variables };
};

const constructGraphQlQueryWithoutPaging = (options) => {
  const {
    columns = {},
    entity = "",
    filter = {},
    filterType,
    paramName = "query",
    param = {},
    paramType,
    sort = {},
    sortType,
  } = options;

  const query = `
    query 
    Get${entity}(${paramType ? `$param: ${paramType}` : ""}, ${
    filterType ? `$filter: ${filterType}` : ""
  }, ${sortType && `$sort: ${sortType}`})
                  {
                    ${entity}(                       
                      ${paramType ? `${paramName}: $param ,` : ""} ${
    filterType ? `where: $filter ,` : ""
  } order: $sort){ 
                      ${`${columns}`}
                    }
                  }
                  `;
  let variables = {
    filter: convertToHotChocolateFilter(filter),
    param: param,
    sort: sort,
  };

  variables = removeEmptyObject(variables);
  return { query: query, variables: variables };
};

const convertToHotChocolateFilter = (filter) => {
  const keys = (filter && Object.keys(filter)) || [];

  keys.forEach((key) => {
    //E-g: convert name_contains: "asim" TO name: {contains : "asim"}
    //Operators: eq, contains

    const originalValue = filter[key];

    //if filter property valus is alreay an object then no need to adjust
    if (
      (!originalValue || typeof originalValue === "object") &&
      typeof originalValue !== "boolean"
    ) {
      return;
    }

    const splittedKey = key.split("_");
    const keyWithoutOperator = splittedKey[0];
    const operator = splittedKey[1];
    if (operator) {
      const updatedKey = keyWithoutOperator;
      let updatedValue = JSON.parse(`{"${operator}": "${originalValue}" }`);

      if (typeof originalValue === "boolean") {
        updatedValue[`${operator}`] = originalValue;
      }

      // if key already exists then combine the existing value and new value e-g: saleDateTimeUtc:{gte: "2020-12-01" lte: "2020-12-24"} .. key = "saleDateTimeUtc"
      if (filter[updatedKey]) {
        const existingValue = filter[updatedKey];
        updatedValue = { ...existingValue, ...updatedValue };
      }
      // add new updated key and value and remove the old property
      filter[updatedKey] = updatedValue;
      delete filter[key];
    }

    // if there is no operator (eq, contains etc..) and the value is not an object then remove it as Hotchocolate V11 only expects object as a value
    if (!operator && typeof originalValue !== "object") {
      delete filter[key];
    }
  });

  return filter;
};
