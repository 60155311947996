import React from "react";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Divider, Form as FormAntd, Space } from "antd";
import Form from "~/components/Form";
import SearchCustomer from "~/components/Sell/Bucket/SearchCustomer";
import {
  EditTwoTone,
  SearchOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import Button from "~/components/Common/Button";
import FormItem from "~/components/Form/FormItem";
import { PageTitle } from "~/components/Common/Text";
import {
  getCustomerPayments,
  deleteCustomerPayment,
} from "~/actions/customerActions";
import Table from "~/components/Common/Table";
import RangePicker from "~/components/Common/DatePicker/RangePicker";
import CustomerPaymentModal from "~/pages/Customers/CustomerPayments/CustomerPayment/CustomerPaymentModal";
import { convertUtcDateToLocalDateTime } from "~/_utils";

const Filters = (props) => {
  const { onSearch, flexSearchCustomer, onDateRangeChange } = props;
  const onFinish = (values) => {
    onSearch(values);
  };
  return (
    <Form name="filter" onFinish={onFinish}>
      {({ formRef } = props) => (
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <Space>
              <FormItem
                form={formRef}
                propertyName={"customerId_eq"}
                showFormTitle={false}
              >
                {({ options }, setValue) => (
                  <SearchCustomer
                    flexSearch={flexSearchCustomer}
                    onSelect={(value) => {
                      setValue(value.customerId);
                    }}
                    showAddCustomer={false}
                  />
                )}
              </FormItem>

              <div style={{ marginTop: "-13px" }}>
                <RangePicker onChange={onDateRangeChange} />
              </div>

              <FormAntd.Item style={{ marginTop: "4px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </FormAntd.Item>
            </Space>
          </Col>
        </Row>
      )}
    </Form>
  );
};

class CustomerPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerPayments: [],
      customerPaymentModalVisible: false,
      selectedCustomerPaymentId: "",
      filterValues: props.filterValues,
    };

    this.onDelete = this.onDelete.bind();
    this.resetTableState = this.resetTableState.bind();
    this.handleFetch = this.handleFetch.bind();
    this.updateData = this.updateData.bind();
    this.handleTableChange = this.handleTableChange.bind();
    this.onDateRangeChange = this.onDateRangeChange.bind();

    this.columns = [
      {
        title: "Customer",
        dataIndex: "customerName",
        key: "customerName",
        fixed: "left",
        sorter: true,
      },
      {
        title: "PaymentDateTime",
        dataIndex: "paymentDateTimeUtc",
        key: "paymentDateTimeUtc",
        sorter: true,
        render: (text, record) => <>{convertUtcDateToLocalDateTime(text)}</>,
      },
      { title: "Amount", dataIndex: "amount", key: "amount", sorter: true },
      { title: "Remarks", dataIndex: "notes", key: "notes", sorter: false },
      {
        title: "",
        dataIndex: "",
        key: "actions",
        width: 85,
        render: (text, record) => (
          <Row>
            <Col xs={24}>
              <Button
                type="link"
                icon={<EditTwoTone />}
                onClick={() =>
                  this.setState({
                    selectedCustomerPaymentId: record.customerPaymentId,
                    customerPaymentModalVisible: true,
                  })
                }
              />
            </Col>
          </Row>
        ),
      },
    ];
  }

  componentDidMount() {
    this.handleFetch();
  }

  resetTableState = (options, callback) => {
    const {
      sort = this.state.sort,
      filterValues = this.state.filterValues,
    } = options;
    this.setState(
      {
        sort: sort || {},
        pageInfo: {}, // always reset page to page 1
        filterValues: filterValues || {},
      },
      callback
    );
  };

  onDateRangeChange = (dates, dateRangeArray) => {
    this.setState({ selectedDateRange: dateRangeArray });
  };
  handleFetch = (options = {}) => {
    const _this = this;
    let {
      filterValues = {},
      sort = {},
      pageInfo = {},
      selectedDateRange = [],
    } = this.state;
    if (pageInfo.hasNextPage === false) {
      return;
    }
    if (selectedDateRange.length > 0) {
      filterValues.paymentDateTimeUtc_gte = selectedDateRange[0].toISOString();
      filterValues.paymentDateTimeUtc_lte = selectedDateRange[1].toISOString();
    }

    this.setState({ loading: true });
    getCustomerPayments({ filterValues, sort, pageInfo })
      .then(function(result) {
        const { data, totalCount, pageInfo = {} } = result;
        _this.updateData(data);
        _this.setState({
          loading: false,
          pageInfo: pageInfo,
          totalCount: totalCount,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  updateData = (data = []) => {
    const _this = this;
    const {
      customerPayments: alreadyLoadedCustomerPayments = [],
      pageInfo = {},
    } = this.state;
    let customerPayments =
      Object.keys(pageInfo).length === 0
        ? []
        : [...alreadyLoadedCustomerPayments]; //If page is reset start from empty list

    const startIndex = customerPayments.length;
    ////
    data.forEach((customerPayment, index) => {
      const {
        customerPaymentId,
        customerName,
        paymentDateTimeUtc,
        amount,
        notes,
      } = customerPayment;

      customerPayments.push({
        key: startIndex + index,
        customerPaymentId: customerPaymentId,
        customerName: customerName,
        paymentDateTimeUtc: paymentDateTimeUtc,
        amount: amount,
        notes: notes,
      });
    });

    _this.setState({
      customerPayments: customerPayments,
    });
  };

  onDelete = (options) => {
    const _this = this;
    const { sort, filterValues } = this.state;
    const { deleteCustomerPayment } = this.props;
    deleteCustomerPayment(options)
      .then(function() {
        //keep existing filtr & sort state
        _this.resetTableState(
          {
            filterValues: filterValues,
            sort: sort,
          },
          _this.handleFetch
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  handleTableChange = (pagination, filters, sorter = {}) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";

    const sort = JSON.parse(`{"${sortField}": "${sortOrder}"}`);

    this.resetTableState({ sort: sort }, this.handleFetch);
  };

  render() {
    const columns = this.columns;
    const { flexSearchCustomer } = this.props;
    const {
      customerPayments = [],
      selectedCustomerPaymentId,
      pageInfo = {},
    } = this.state;
    const _this = this;

    return (
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={12}>
              <PageTitle>Customer Payments</PageTitle>
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              <Button
                type="link"
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  this.setState({
                    selectedCustomerPaymentId: "",
                    customerPaymentModalVisible: true,
                  });
                }}
              >
                Add new payment
              </Button>
            </Col>
            <Divider />
            <br />
          </Row>
        </Col>
        <Col xs={24} xl={18}>
          <Filters
            onDateRangeChange={this.onDateRangeChange}
            flexSearchCustomer={flexSearchCustomer}
            onSearch={(filterValues) => {
              _this.resetTableState(
                { filterValues: filterValues },
                _this.handleFetch
              );
            }}
          />
        </Col>
        <Col xs={24}>
          <Divider />

          <Table
            loading={this.state.loading}
            columns={columns}
            onChange={this.handleTableChange}
            dataSource={customerPayments}
            hasNextPage={pageInfo.hasNextPage}
            onPageChange={this.handleFetch}
          />
        </Col>
        <CustomerPaymentModal
          customerPaymentId={selectedCustomerPaymentId}
          visible={this.state.customerPaymentModalVisible}
          onCancel={() =>
            this.setState({
              customerPaymentModalVisible: false,
              selectedCustomerPaymentId: "",
            })
          }
          onSave={() => {
            this.setState(
              {
                selectedCustomerPaymentId: "",
                customerPaymentModalVisible: false,
              },
              _this.resetTableState({}, _this.handleFetch)
            );
          }}
        />
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({
  flexSearchCustomer: state.offline.flexSearchCustomer,
});

export default connect(mapStateToProps, { deleteCustomerPayment })(
  withRouter(withNamespaces()(CustomerPayments))
);
