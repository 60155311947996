import React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Row, Col, Divider, Space, Typography, Form as FormAntd } from "antd";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { PageTitle } from "~/components/Common/Text";
import Button from "~/components/Common/Button";
import Table from "~/components/Common/Table";
import PriceLabel from "~/components/Common/PriceLabel";
import SearchCustomer from "~/components/Sell/Bucket/SearchCustomer";
import RangePicker from "~/components/Common/DatePicker/RangePicker";
import Form from "~/components/Form";
import FormItem from "~/components/Form/FormItem";
import { getReceivablesDetails } from "~/actions/reportActions";
import { convertUtcDateToLocalDateTime, formatDateTime } from "~/_utils";
import { ColumnTextSearch } from "~/components/Common/Table/TableColumnFilter";
import moment from "moment";
import "../index.scss"; //reports scss
import "./index.scss"; //receivableDetails scss

const { Text } = Typography;

const PrintHeader = (options) => {
  const { accountName, startDate, endDate } = options;
  if (!accountName && !startDate) {
    return <></>;
  }

  return (
    <Row>
      {accountName && <Col xs={24}>{accountName}</Col>}
      {startDate && (
        <Col xs={24}>{`${formatDateTime(startDate, false)} - ${formatDateTime(
          endDate,
          false
        )}`}</Col>
      )}
    </Row>
  );
};

const Filters = (props = {}) => {
  const {
    onSearch,
    flexSearchCustomer,
    onDateRangeChange,
    defaultCustomerCode = "",
    initialValues = {},
  } = props;
  const onFinish = (values) => {
    onSearch(values);
  };
  return (
    <Form name="filter" onFinish={onFinish} initialValues={initialValues}>
      {({ formRef } = props) => (
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <Space>
              <FormItem
                form={formRef}
                propertyName={"accountId"}
                showFormTitle={false}
              >
                {({ options }, setValue) => (
                  <SearchCustomer
                    defaultCustomerCode={defaultCustomerCode}
                    flexSearch={flexSearchCustomer}
                    onSelect={(value) => {
                      setValue(value.customerId);
                    }}
                    showAddCustomer={false}
                    isRequired={true}
                  />
                )}
              </FormItem>
              <div style={{ marginTop: "-13px" }}>
                <RangePicker onChange={onDateRangeChange} />
              </div>
              <FormAntd.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </FormAntd.Item>
            </Space>
          </Col>
        </Row>
      )}
    </Form>
  );
};

const Footer = (options = {}) => {
  const {
    totalCreditAmount = 0,
    totalPaidAmount = 0,
    openingBalance = 0,
    netReceivable = 0,
  } = options;
  return (
    <Row
      className="report-footer-container"
      gutter={[8, 0]}
      style={{
        maxWidth: "400px",
      }}
    >
      <Col xs={16}>
        <Text className="secondaryTitle">{"Total Credit Amount: "}</Text>
      </Col>
      <Col xs={8}>
        <PriceLabel className="secondaryText" value={totalCreditAmount} />
      </Col>
      <Col xs={16}>
        <Text className="secondaryTitle">{"Total Amount Settled: "}</Text>
      </Col>
      <Col xs={8}>
        <PriceLabel className="secondaryText" value={totalPaidAmount} />
      </Col>
      <Col xs={16}>
        <Text className="secondaryTitle">
          {`Opening Balance (${
            openingBalance > 0 ? "Payable" : "Receivable"
          }): `}
        </Text>
      </Col>
      <Col xs={8}>
        <PriceLabel
          className="secondaryText"
          value={openingBalance < 0 ? openingBalance * -1 : openingBalance}
        />
      </Col>
      <Divider />
      <Col xs={16}>
        <Text className="primaryTitle">{`Net Receivable: `}</Text>
      </Col>
      <Col xs={8}>
        <PriceLabel className="primaryText" value={netReceivable} />
      </Col>
    </Row>
  );
};

class ReceivablesDetails extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const queryParams = queryString.parse(location.search);
    const accountId = queryParams.accountId;
    const defaultCustomerCode = queryParams.accountCode;

    this.state = {
      loading: false,
      data: {},
      accountId: accountId || props.accountId,
      defaultCustomerCode: defaultCustomerCode || props.accountCode,
    };

    this.columns = [
      {
        title: "Date",
        defaultSortOrder: "descend",
        dataIndex: "dateUtc",
        key: "dateUtc",
        sorter: (a, b) => moment(a.dateUtc).unix() - moment(b.dateUtc).unix(),
        render: (text, record) => <>{convertUtcDateToLocalDateTime(text)}</>,
      },
      {
        title: "Credit amount",
        dataIndex: "creditAmount",
        key: "creditAmount",
        sorter: (a, b) => a.creditAmount - b.creditAmount,
        render: (text, record) => (
          <>{record.creditAmount === 0 ? "" : record.creditAmount}</>
        ),
      },
      {
        title: "Amount settled",
        dataIndex: "amountPaid",
        key: "amountPaid",
        sorter: (a, b) => a.amountPaid - b.amountPaid,
        render: (text, record) => (
          <>{record.amountPaid === 0 ? "" : record.amountPaid}</>
        ),
      },
      {
        title: "Ref",
        dataIndex: "reference",
        key: "reference",
        ...ColumnTextSearch(["reference"]),
        render: (text = "", record) => (
          <>
            {text && record.creditAmount > 0
              ? `Sale Code: ${text}`
              : text && text.length > 55
              ? `${text.substring(0, 55)}...`
              : text}
          </>
        ),
      },
    ];

    this.handleFetch = this.handleFetch.bind();
    this.onDetail = this.onDetail.bind();
    this.resetTableState = this.resetTableState.bind();
    this.transformData = this.transformData.bind();
    this.onDateRangeChange = this.onDateRangeChange.bind();
  }
  componentDidMount() {
    const _this = this;
    const { accountId } = this.state;

    this.setState(
      {
        filterValues: {
          accountId: accountId,
        },
      },
      _this.handleFetch
    );
  }
  handleFetch = (options = {}) => {
    let { sort = {}, filterValues = {}, selectedDateRange = [] } = this.state;
    const { accountId } = filterValues;
    let params = { accountId: accountId };

    if (selectedDateRange.length > 0) {
      params.startDateTimeUtc = selectedDateRange[0].toISOString();
      params.endDateTimeUtc = selectedDateRange[1].toISOString();
    }

    //selection of account/customer is mandatory
    if (!accountId) {
      this.setState({
        data: {},
      });
      return;
    }

    this.setState({ loading: true });

    const _this = this;

    getReceivablesDetails({ sort, ...params })
      .then(function(result = {}) {
        const { data = [] } = result;

        _this.setState({
          loading: false,
          data: { ..._this.transformData(data) },
        });
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });
        console.log(err);
      });
  };
  transformData = (data = []) => {
    const openingBalance = data.length > 0 ? data[0].openingBalance : 0;
    const totalCreditAmount =
      data.length > 0
        ? data
            .map((r) => (r.creditAmount ? r.creditAmount : 0))
            .reduce((acc, curr) => acc + curr, 0)
        : 0;
    const totalPaidAmount =
      data.length > 0
        ? data
            .map((r) => (r.amountPaid ? r.amountPaid : 0))
            .reduce((acc, curr) => acc + curr, 0)
        : 0;

    const openingBalanceReceivable = openingBalance * -1;

    const netReceivable =
      totalCreditAmount + openingBalanceReceivable - totalPaidAmount;

    return {
      openingBalance: openingBalance,
      totalCreditAmount: totalCreditAmount,
      totalPaidAmount: totalPaidAmount,
      netReceivable: netReceivable,
      receivablesDetails: [...data],
    };
  };
  resetTableState = (options, callback) => {
    const {
      sort = this.state.sort,
      filterValues = this.state.filterValues,
    } = options;
    this.setState(
      {
        sort: sort || {},
        filterValues: filterValues || {},
      },
      callback
    );
  };
  onDetail = (options = {}) => {
    const { id, receivableType } = options;
    console.log("id: ", id);
    console.log("receivableType: ", receivableType);
  };
  onDateRangeChange = (dates, dateRangeArray) => {
    this.setState({ selectedDateRange: dateRangeArray });
  };

  render() {
    const columns = this.columns;
    const {
      loading = false,
      data = {},
      defaultCustomerCode = "",
      accountId,
      selectedDateRange = [],
    } = this.state;
    const {
      receivablesDetails = [],
      openingBalance,
      totalCreditAmount,
      totalPaidAmount,
      netReceivable,
    } = data;

    const accountName =
      data.receivablesDetails && data.receivablesDetails.length > 0
        ? data.receivablesDetails[0].name
        : "";

    const { flexSearchCustomer } = this.props;
    const _this = this;
    return (
      <div className="report-container">
        <Row gutter={[8, 8]} className="receivablesDetails-container">
          <Col xs={24}>
            <PageTitle>Receivable Details</PageTitle>
            <Divider />
            <br />
          </Col>
          <Col xs={24}>
            <Filters
              initialValues={{ accountId: accountId }}
              defaultCustomerCode={defaultCustomerCode}
              onDateRangeChange={this.onDateRangeChange}
              flexSearchCustomer={flexSearchCustomer}
              onSearch={(filterValues) => {
                _this.resetTableState(
                  { filterValues: filterValues },
                  _this.handleFetch
                );
              }}
            />
          </Col>
          <Col xs={24}>
            <Divider />
            <Table
              allowCsvExport={true}
              clientSidePaging={true}
              loading={loading}
              columns={columns}
              dataSource={receivablesDetails}
              rowKey="id"
              style={{ marginBottom: 130 }}
              footer={
                <Footer
                  openingBalance={openingBalance}
                  totalCreditAmount={totalCreditAmount}
                  totalPaidAmount={totalPaidAmount}
                  netReceivable={netReceivable}
                />
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  flexSearchCustomer: state.offline.flexSearchCustomer,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withNamespaces()(ReceivablesDetails)));
