import React from "react";
import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Divider, Modal, Typography } from "antd";
import Button from "~/components/Common/Button";
import Tag from "~/components/Common/Tag";
import { customersIndex } from "~/config";
import Customer from "~/pages/Customers/Customer/index";
import { isValidGuid } from "~/_utils";
import { selectCustomerFromLocalDb } from "~/_offline/offlineCustomers";
import { translate } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";

const { Text } = Typography;

function getStateForSelectCustomer(selectedCustomer) {
  return {
    selectedCustomer: selectedCustomer,
    dataSource: [],
    customerName: "",
    autoCompleteText: selectedCustomer ? selectedCustomer.name : "",
    customerModalVisible: false,
  };
}

const CustomerModal = (props) => {
  const { visible, onCancel, customer = {}, onSave } = props;
  const title = `${translate("Customer", {
    namespace: TRANSLATION_NAMESPACE.Sell,
  })}`;
  return (
    <Modal
      title={title}
      centered
      visible={visible}
      onOk={() => alert("Save customer")}
      onCancel={onCancel}
      footer={null}
      destroyOnClose={true}
    >
      <Customer
        customer={customer}
        onSave={onSave}
        showTitle={false}
        size="md"
      />
    </Modal>
  );
};

const options = (props) => {
  let {
    customerName = "",
    dataSource = [],
    onAddCustomerClick,
    showAddCustomer = true,
  } = props;

  if (customerName === "") {
    return [];
  }

  customerName = `'${customerName}'`;

  let autoCompleteOptions = dataSource.map((c) => {
    return {
      value: c.customerCode,
      label: <Text>{c.name}</Text>,
    };
  });

  autoCompleteOptions =
    showAddCustomer === true
      ? autoCompleteOptions.concat([
          {
            label: (
              <div>
                <Divider />
                <Button
                  style={{ paddingLeft: "unset" }}
                  onClick={onAddCustomerClick}
                  type="link"
                  icon={<PlusCircleOutlined />}
                >{`${translate("AddNewCustomer", {
                  namespace: TRANSLATION_NAMESPACE.Account,
                })} ${customerName}`}</Button>
              </div>
            ),
          },
        ])
      : autoCompleteOptions;

  return autoCompleteOptions;
};

class SearchCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      customerName: "",
      selectedCustomer: props.defaultSelectedCustomer,
      customerModalVisible: false,
      autoCompleteText: "",
    };

    this.handleSearch = this.handleSearch.bind();
    this.handleSelect = this.handleSelect.bind();
    this.onDeselectCustomer = this.onDeselectCustomer.bind();
    this.onSelectedCustomerClick = this.onSelectedCustomerClick.bind();
    this.onCustomerModalCancel = this.onCustomerModalCancel.bind();
    this.onAddCustomerClick = this.onAddCustomerClick.bind();
    this.selectCustomer = this.selectCustomer.bind();
    this.onSaveCustomer = this.onSaveCustomer.bind();
    this.handleChange = this.handleChange.bind();
  }
  componentDidMount() {
    const { defaultCustomerCode = "" } = this.props;
    const _this = this;

    if (defaultCustomerCode) {
      selectCustomerFromLocalDb(defaultCustomerCode).then(function(customer) {
        _this.setState({
          selectedCustomer: customer,
        });
      });
    }
  }
  handleSelect = (value) => {
    const { dataSource } = this.state;
    let selectedCustomer = {};
    if (isValidGuid(value)) {
      selectedCustomer = dataSource.find((e) => e.customerId === value);
    } else {
      selectedCustomer = dataSource.find((e) => e.customerCode === value);
    }

    this.selectCustomer(selectedCustomer);
  };

  selectCustomer = (customer) => {
    const { onSelect } = this.props;
    this.setState({
      ...getStateForSelectCustomer(customer),
      autoCompleteText: customer.name,
    });
    onSelect(customer);
  };
  handleChange = (value) => {
    this.setState({ autoCompleteText: value });
  };
  handleSearch = (value) => {
    const { flexSearch } = this.props;
    let dataSource = [];

    if (!flexSearch) {
      console.log("searching is not available");
      return;
    }

    try {
      flexSearch.search(
        {
          query: `${value}`,
          suggest: true,
          limit: 10,
          field: customersIndex,
          bool: "or",
        },
        function(result) {
          if (result) {
            dataSource = [...new Set(result)];
          }
        }
      );
    } catch (ex) {
      console.log(`Error in customer search: ${ex}`);
    }

    this.setState({ customerName: value, dataSource: dataSource });
  };
  onDeselectCustomer = (e) => {
    e.preventDefault();
    const { onSelect } = this.props;
    this.setState(getStateForSelectCustomer({}));
    onSelect({});
  };
  onSelectedCustomerClick = (e) => {
    e.preventDefault();
    this.onAddCustomerClick();
  };
  onCustomerModalCancel = () => {
    this.setState({ customerModalVisible: false });
  };
  onAddCustomerClick = () => {
    this.setState({ customerModalVisible: true });
  };
  onSaveCustomer = (customer) => {
    this.selectCustomer(customer);
  };

  render() {
    const optionProps = {
      dataSource: this.state.dataSource,
      customerName: this.state.customerName,
      onAddCustomerClick: this.onAddCustomerClick,
      showAddCustomer: this.props.showAddCustomer,
    };

    const {
      selectedCustomer,
      customerModalVisible,
      customerName,
      autoCompleteText,
    } = this.state;

    const {
      mode,
      flexSearch,
      disabled = false,
      isRequired = false,
      setRef,
    } = this.props;
    const maxNameLength = 15;
    let selectedCustomerName = selectedCustomer && selectedCustomer.name;
    selectedCustomerName =
      selectedCustomerName && selectedCustomerName.length > maxNameLength
        ? `${selectedCustomerName.substring(0, maxNameLength - 1)} ...`
        : selectedCustomerName;

    const component =
      selectedCustomerName && mode !== "dropdown" ? (
        <Tag closable onClose={this.onDeselectCustomer}>
          <Button
            style={{ paddingRight: "5px", paddingLeft: "5px" }}
            type="link"
            icon={<UserOutlined />}
            onClick={this.onSelectedCustomerClick}
          >
            {}
            {selectedCustomerName}
          </Button>
        </Tag>
      ) : (
        <>
          <AutoComplete
            options={options({ ...optionProps })}
            onSearch={this.handleSearch}
            onSelect={this.handleSelect}
            onChange={this.handleChange}
            value={autoCompleteText}
            style={{ width: "100%" }}
            disabled={!flexSearch || disabled}
          >
            <Input
              ref={(el) => setRef && setRef(el)}
              prefix={<UserOutlined />}
              placeholder={
                !flexSearch
                  ? `${translate("LoadingCustomer", {
                      namespace: TRANSLATION_NAMESPACE.Account,
                    })}...`
                  : `${translate("SearchCustomer", {
                      namespace: TRANSLATION_NAMESPACE.Account,
                    })}`
              }
            />
          </AutoComplete>
          {isRequired === true ? (
            <div
              className="ant-form-item-explain-error"
              style={{ position: "absolute" }}
            >
              {translate("PleaseSelectCustomer", {
                namespace: TRANSLATION_NAMESPACE.Sell,
              })}
            </div>
          ) : (
            <></>
          )}
        </>
      );
    return (
      <>
        {component}
        <CustomerModal
          visible={customerModalVisible}
          onCancel={this.onCustomerModalCancel}
          onSave={this.onSaveCustomer}
          customer={
            selectedCustomer && selectedCustomer.customerCode
              ? selectedCustomer
              : { name: customerName }
          }
        />
      </>
    );
  }
}

export default SearchCustomer;
