import React from "react";
import { Row, Col, Typography, Select, Switch, Space } from "antd";
import { RetweetOutlined } from "@ant-design/icons";

import { propName, toFloat, constructFormJson } from "~/_utils";
import viewModel from "./viewModel";
import FormItem from "~/components/Form/FormItem";

import PriceInput from "~/components/Common/Input/PriceInput";
import InputDecimal from "~/components/Common/InputDecimal";
import { PRODUCTTYPES } from "~/_utils/consts";
import Button from "~/components/Common/Button";

const { Title } = Typography;
const { Option } = Select;

const GetPropNames = (initialValues) => {
  return {
    purchasePricePropName: propName(initialValues, (o) => o.purchasePrice),
    avgPurchasePricePropName: propName(
      initialValues,
      (o) => o.avgPurchasePrice
    ),
    retailPriceExTaxPropName: propName(
      initialValues,
      (o) => o.retailPriceExTax
    ),
    retailPriceIncTaxPropName: propName(
      initialValues,
      (o) => o.retailPriceIncTax
    ),
    taxIdPropName: propName(initialValues, (o) => o.taxId),
    markupPropName: propName(initialValues, (o) => o.markup),
  };
};

export const calculatePurchasePrice = ({
  markup = 0,
  retailPriceExTax = 0,
}) => {
  //P+(P*M%) = S .... OR ... P = S / (1+M%)
  const purchasePrice = retailPriceExTax / (1 + markup / 100);
  return toFloat(purchasePrice);
};

export const calculateMarkup = ({ purchasePrice, retailPriceExTax }) => {
  if (purchasePrice === 0) {
    return 0;
  }
  return toFloat(((retailPriceExTax - purchasePrice) / purchasePrice) * 100);
};

export const calculateRetailPriceExTaxFromRetailPriceIncTax = ({
  retailPriceIncTax,
  taxId,
  taxes = [],
}) => {
  const taxObj = taxes.filter((x) => x.taxId === taxId)[0];
  const taxPercent = taxObj ? taxObj.percentage : 0;

  //x + x*tax% = RetailIncTax => e-g:RetailIncTax = 26.25 & tax% = 5% ===>  x + (x *5/100) = 26.25 => 1.05 x = 26.25 => x = 26.25 / 1.05 = 25
  let retailPriceExTax = retailPriceIncTax / (1 + taxPercent / 100);

  return toFloat(retailPriceExTax);
};

export const calculateRetailPriceExTax = ({ purchasePrice, markup }) => {
  return toFloat(purchasePrice + (purchasePrice * markup) / 100);
};

export const calculateRetailPriceIncTax = ({
  purchasePrice,
  markup,
  taxId,
  taxes = [],
}) => {
  const priceExTax = calculateRetailPriceExTax({
    purchasePrice: purchasePrice,
    markup: markup,
  });

  const taxObj = taxes.filter((x) => x.taxId === taxId)[0];
  const taxPercent = taxObj ? taxObj.percentage : 0;

  const taxAmount = toFloat((priceExTax * taxPercent) / 100);

  return toFloat(priceExTax + taxAmount);
};

class Price extends React.Component {
  constructor(props) {
    super(props);
    this.onMarkupChange = this.onMarkupChange.bind();
    this.onTaxChange = this.onTaxChange.bind();
    this.onPurchasePriceChange = this.onPurchasePriceChange.bind();
    this.onRetailPriceExTaxChange = this.onRetailPriceExTaxChange.bind();
    this.onRetailPriceIncTaxChange = this.onRetailPriceIncTaxChange.bind();
    this.onAvgPriceReset = this.onAvgPriceReset.bind();
  }

  onMarkupChange = (value) => {
    let { initialValues, form, propNames, taxes } = this.props;

    propNames = propNames || GetPropNames(initialValues);

    let purchasePrice = toFloat(
      form.getFieldValue(propNames.purchasePricePropName)
    );
    let retailPriceExTax = toFloat(
      form.getFieldValue(propNames.retailPriceExTaxPropName)
    );
    let retailPriceIncTax = toFloat(
      form.getFieldValue(propNames.retailPriceIncTaxPropName)
    );

    const taxId = form.getFieldValue(propNames.taxIdPropName);

    const isAutoCalculatePurchasePrice =
      form.getFieldValue("autoCalculatePurchasePrice") || false;

    const markup = toFloat(value);

    if (isAutoCalculatePurchasePrice === true) {
      purchasePrice = calculatePurchasePrice({
        markup: markup,
        retailPriceExTax: retailPriceExTax,
      });
      form.setFieldsValue(
        constructFormJson(propNames.purchasePricePropName, purchasePrice)
      );
    } else {
      retailPriceExTax = calculateRetailPriceExTax({
        purchasePrice: purchasePrice,
        markup: markup,
      });

      retailPriceIncTax = calculateRetailPriceIncTax({
        purchasePrice: purchasePrice,
        markup: markup,
        taxId: taxId,
        taxes: taxes,
      });

      form.setFieldsValue(
        constructFormJson(propNames.retailPriceExTaxPropName, retailPriceExTax)
      );

      form.setFieldsValue(
        constructFormJson(
          propNames.retailPriceIncTaxPropName,
          retailPriceIncTax
        )
      );
    }
  };
  onTaxChange = (value) => {
    let { initialValues, form, propNames, taxes } = this.props;
    propNames = propNames || GetPropNames(initialValues);

    const purchasePrice = toFloat(
      form.getFieldValue(propNames.purchasePricePropName)
    );
    const markup = toFloat(form.getFieldValue(propNames.markupPropName));

    const retailPriceIncTax = calculateRetailPriceIncTax({
      purchasePrice: purchasePrice,
      markup: markup,
      taxId: value,
      taxes: taxes,
    });

    form.setFieldsValue(
      constructFormJson(propNames.retailPriceIncTaxPropName, retailPriceIncTax)
    );
  };
  onPurchasePriceChange = (value) => {
    let { initialValues, form, propNames, taxes } = this.props;
    const purchasePrice = toFloat(value);
    propNames = propNames || GetPropNames(initialValues);

    const markup = toFloat(form.getFieldValue(propNames.markupPropName));

    const taxId = form.getFieldValue(propNames.taxIdPropName);

    const retailPriceExTax = calculateRetailPriceExTax({
      purchasePrice: purchasePrice,
      markup: markup,
    });

    const retailPriceIncTax = calculateRetailPriceIncTax({
      purchasePrice: purchasePrice,
      markup: markup,
      taxId: taxId,
      taxes: taxes,
    });

    form.setFieldsValue(
      constructFormJson(propNames.retailPriceExTaxPropName, retailPriceExTax)
    );

    form.setFieldsValue(
      constructFormJson(propNames.retailPriceIncTaxPropName, retailPriceIncTax)
    );
  };
  onRetailPriceExTaxChange = (value) => {
    let { initialValues, form, propNames, taxes } = this.props;
    const retailPriceExTax = toFloat(value);
    propNames = propNames || GetPropNames(initialValues);

    const taxId = form.getFieldValue(propNames.taxIdPropName);

    let purchasePrice = toFloat(
      form.getFieldValue(propNames.purchasePricePropName)
    );

    let markup = toFloat(form.getFieldValue(propNames.markupPropName));

    const isAutoCalculatePurchasePrice =
      form.getFieldValue("autoCalculatePurchasePrice") || false;

    if (isAutoCalculatePurchasePrice === true) {
      purchasePrice = calculatePurchasePrice({
        markup: markup,
        retailPriceExTax: retailPriceExTax,
      });
      form.setFieldsValue(
        constructFormJson(propNames.purchasePricePropName, purchasePrice)
      );
    } else {
      markup = calculateMarkup({
        purchasePrice: purchasePrice,
        retailPriceExTax: retailPriceExTax,
      });

      form.setFieldsValue(constructFormJson(propNames.markupPropName, markup));
    }

    const retailPriceIncTax = calculateRetailPriceIncTax({
      purchasePrice: purchasePrice,
      markup: markup,
      taxId: taxId,
      taxes: taxes,
    });

    form.setFieldsValue(
      constructFormJson(propNames.retailPriceIncTaxPropName, retailPriceIncTax)
    );
  };
  onRetailPriceIncTaxChange = (value) => {
    let { initialValues, form, propNames, taxes } = this.props;
    const retailPriceIncTax = toFloat(value);
    propNames = propNames || GetPropNames(initialValues);

    const taxId = form.getFieldValue(propNames.taxIdPropName) || "";
    let purchasePrice = toFloat(
      form.getFieldValue(propNames.purchasePricePropName)
    );
    let markup = toFloat(form.getFieldValue(propNames.markupPropName));
    const isAutoCalculatePurchasePrice =
      form.getFieldValue("autoCalculatePurchasePrice") || false;

    const retailPriceExTax = calculateRetailPriceExTaxFromRetailPriceIncTax({
      retailPriceIncTax: retailPriceIncTax,
      taxId: taxId,
      taxes: taxes,
    });

    if (isAutoCalculatePurchasePrice === true) {
      purchasePrice = calculatePurchasePrice({
        markup: markup,
        retailPriceExTax: retailPriceExTax,
      });
      form.setFieldsValue(
        constructFormJson(propNames.purchasePricePropName, purchasePrice)
      );
    } else {
      markup = calculateMarkup({
        purchasePrice: purchasePrice,
        retailPriceExTax: retailPriceExTax,
      });

      form.setFieldsValue(constructFormJson(propNames.markupPropName, markup));
    }

    form.setFieldsValue(
      constructFormJson(propNames.retailPriceExTaxPropName, retailPriceExTax)
    );
  };
  onAvgPriceReset = () => {
    let { initialValues, form, propNames } = this.props;
    propNames = propNames || GetPropNames(initialValues);
    let purchasePrice = toFloat(
      form.getFieldValue(propNames.purchasePricePropName)
    );
    form.setFieldsValue(
      constructFormJson(propNames.avgPurchasePricePropName, purchasePrice)
    );
  };

  render() {
    let { initialValues, form, propNames, taxes, productType } = this.props;
    propNames = propNames || GetPropNames(initialValues);

    return (
      <Row gutter={[16, 8]}>
        <Col xs={24}>
          <Title level={4}>Price</Title>
        </Col>
        <Col xs={24}>
          <FormItem
            valuePropName="checked"
            propertyName={"autoCalculatePurchasePrice"}
            showFormTitle={false}
          >
            {({ placeholder, ...restOptions }) => (
              <Switch
                defaultChecked={false}
                checkedChildren={"Calculate purchase price"}
                unCheckedChildren={"Calculate purchase price"}
                onChange={(checked, e) => {
                  console.log(checked);
                }}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={8}>
          <FormItem
            propertyName={propNames.purchasePricePropName}
            viewModelKeyValue={viewModel[propNames.purchasePricePropName]}
          >
            {({ placeholder, ...restOptions }) => (
              <PriceInput
                style={{ width: "100%" }}
                {...restOptions}
                onBlur={this.onPurchasePriceChange}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={8}>
          <FormItem
            propertyName={propNames.taxIdPropName}
            viewModelKeyValue={viewModel[propNames.taxIdPropName]}
            form={form}
          >
            {(options, setValue) => (
              <Select
                allowClear={true}
                style={{ width: "100%" }}
                placeholder={options.placeholder}
                onChange={(value) => {
                  setValue(value);
                  this.onTaxChange(value);
                }}
              >
                {taxes.map((tax, index) => {
                  const key = `${tax.taxId}${index}`;
                  return (
                    <Option key={key} value={tax.taxId}>
                      {tax.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col xs={8}>
          {productType === PRODUCTTYPES.StandardProduct && (
            <Space size={0}>
              <FormItem
                propertyName={propNames.avgPurchasePricePropName}
                viewModelKeyValue={
                  viewModel[propNames.avgPurchasePricePropName]
                }
              >
                {({ placeholder, ...restOptions }) => (
                  <PriceInput
                    disabled={true}
                    style={{ width: "100%" }}
                    {...restOptions}
                  />
                )}
              </FormItem>
              <Button
                layout="confirm"
                confirmText="Reset Avg price same as purchase price ?"
                type="link"
                icon={<RetweetOutlined />}
                onClick={this.onAvgPriceReset}
              >
                Reset
              </Button>
            </Space>
          )}
        </Col>
        <Col xs={8}>
          <FormItem
            propertyName={propNames.markupPropName}
            viewModelKeyValue={viewModel[propNames.markupPropName]}
          >
            {({ placeholder, ...restOptions }) => (
              <InputDecimal
                style={{ width: "100%" }}
                {...restOptions}
                onBlur={this.onMarkupChange}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={8}>
          <FormItem
            propertyName={propNames.retailPriceExTaxPropName}
            viewModelKeyValue={viewModel[propNames.retailPriceExTaxPropName]}
          >
            {({ placeholder, ...restOptions }) => (
              <PriceInput
                style={{ width: "100%" }}
                {...restOptions}
                onBlur={this.onRetailPriceExTaxChange}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={8}>
          <FormItem
            propertyName={propNames.retailPriceIncTaxPropName}
            viewModelKeyValue={viewModel[propNames.retailPriceIncTaxPropName]}
          >
            {({ placeholder, ...restOptions }) => (
              <PriceInput
                style={{ width: "100%" }}
                {...restOptions}
                onBlur={this.onRetailPriceIncTaxChange}
              />
            )}
          </FormItem>
        </Col>
      </Row>
    );
  }
}

export default Price;
