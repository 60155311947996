import React from "react";
import "./index.scss";
import Bucket from "~/components/Sell/Bucket";
import { Row, Col, Typography } from "antd";
import Spin from "~/components/Common/Spin";
import { Trans, withNamespaces } from "react-i18next";
import SearchProduct from "~/components/Sell/SearchProduct";
import QuickKeys from "~/components/QuickKeys";
import { prepareOfflineSell } from "~/actions/offlineActions";
import {
  reCreateProductsIndex,
  selectProductFromLocalDb,
  getAllSalesTaxesFromLocalDb,
} from "~/_offline/offlineProducts";
import { sellBucketShowPriceIncTax, constructUrl } from "~/config";
import { OFFLINESTATE, TRANSLATION_NAMESPACE } from "~/_utils/consts";
import { translate } from "~/_utils";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Button from "~/components/Common/Button";

const { Text } = Typography;

const SetUpQuickKeys = (options = {}) => {
  const { history = {}, match } = options;
  return (
    <Row className="sell-container">
      <Col
        xs={24}
        style={{
          textAlign: "center",
          minHeight: "200px",
          paddingTop: "150px",
          fontWeight: "bold",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <span style={{ fontSize: "20px" }}>Quick keys</span>
          </Col>
          <Col xs={24}>
            <Button
              type="dashed"
              style={{ width: "100%" }}
              onClick={() => {
                history.push(
                  constructUrl({ match, to: "/Sell/QuickKeysSetup" })
                );
              }}
            >
              Setup quick keys
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

class Sell extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind();
    this.selectProduct = this.selectProduct.bind();
    this.handleBucketUpdate = this.handleBucketUpdate.bind();
    this.reCreateProductsIndex = this.reCreateProductsIndex.bind();
    this.handleHotKey = this.handleHotKey.bind();

    this.state = {
      sellProductBucket: {},
      loading: true,
      preload: true,
    };

    this.searchProductRef = null;
    this.customerRef = null;
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      expandLastBucketProduct = false,
      expandSubTotal = false,
      triggerPay = false,
    } = this.state;
    if (expandLastBucketProduct === true) {
      this.setState({ expandLastBucketProduct: false });
    }
    if (expandSubTotal === true) {
      this.setState({ expandSubTotal: false });
    }
    if (triggerPay === true) {
      this.setState({ triggerPay: false });
    }
  }
  componentDidMount() {
    const {
      prepareOfflineSell,
      offlineState,
      sellProductBucket = {},
      flexSearchProduct,
    } = this.props;

    const { location } = this.props;
    const queryParams = queryString.parse(location.search) || {};
    const isSaleInProgress =
      queryParams.isSaleInProgress === "true" ||
      sellProductBucket.isSaleInProgress;

    if (
      !flexSearchProduct ||
      (offlineState !== OFFLINESTATE.InProgress && isSaleInProgress !== true)
    ) {
      prepareOfflineSell({ dispatchOfflinePayLoad: false });
    }

    this.setState({ preload: false });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      flexSearchProduct,
      quickKeys,
      sellProductBucket = {},
      offlineReady = false,
    } = nextProps;

    console.log("still loading: ", offlineReady !== true);

    return {
      flexSearchProduct: flexSearchProduct,
      quickKeys: quickKeys,
      sellProductBucket: { ...sellProductBucket, isSaleInProgress: false },
      loading: offlineReady !== true,
      preload: prevState.preload,
    };
  }

  reCreateProductsIndex = () => {
    const _this = this;
    reCreateProductsIndex()
      .then(function(result) {
        _this.setState({
          flexSearchProduct: result,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };
  handleBucketUpdate = () => {
    //After bucket is updatd then reset partial state
    const { selectedProduct, sellProductBucket = {} } = this.state;
    if (selectedProduct || sellProductBucket.isDiscarded === true)
      this.setState({
        selectedProduct: null,
        sellProductBucket: { ...sellProductBucket, isDiscarded: false },
      });
  };

  handleSelect = (value) => {
    this.selectProduct(value);
  };
  selectProduct = (value) => {
    const _this = this;
    selectProductFromLocalDb(value).then(function(result) {
      _this.setState({ selectedProduct: { ...result } });
    });
  };
  handleHotKey = (event) => {
    if (!event || event.altKey === false) {
      return;
    }

    event.preventDefault();

    switch (event.key.toLowerCase()) {
      case "s":
        this.searchProductRef && this.searchProductRef.focus();
        break;
      case "i":
        this.setState({ expandLastBucketProduct: true });
        break;
      case "p":
        this.setState({ triggerPay: true });
        break;
      case "c":
        this.customerRef && this.customerRef.focus();
        break;
      case "t":
        this.setState({ expandSubTotal: true });
        break;
    }
  };

  render() {
    const {
      selectedProduct = {},
      flexSearchProduct = {},
      sellProductBucket,
      quickKeys = [],
      loading = true,
      preload = false,
      expandLastBucketProduct = false,
      triggerPay = false,
      expandSubTotal = false,
    } = this.state;

    const { history, match } = this.props;

    if (loading === true || preload === true) {
      return (
        <Spin spinning={this.state.loading}>
          <Row className="sell-container">
            <Col
              xs={24}
              style={{
                textAlign: "center",
                minHeight: "200px",
                paddingTop: "150px",
                fontWeight: "bold",
              }}
            >
              <Text>
                {translate("OfflineSaleLoading", {
                  namespace: TRANSLATION_NAMESPACE.Sell,
                })}
              </Text>
            </Col>
          </Row>
        </Spin>
      );
    }

    return (
      <Row
        gutter={[32, 0]}
        className="sell-container"
        tabIndex={0}
        onKeyDown={(event) => {
          event.altKey === true && this.handleHotKey(event);
        }}
      >
        <Col xs={24} lg={14} xl={16} className="sell-product-container">
          <Row>
            <Col xs={24} className="search-product-container">
              <SearchProduct
                onSelect={this.handleSelect}
                flexSearch={flexSearchProduct}
                onProductsIndexOutOfSync={this.reCreateProductsIndex}
                setRef={(el) => (this.searchProductRef = el)}
              />
            </Col>
          </Row>
          <Row className="quickKeysLayout-container">
            <Col xs={0} lg={24}>
              {quickKeys && quickKeys.length > 0 ? (
                <QuickKeys
                  onSelect={this.selectProduct}
                  products={quickKeys}
                  size="md"
                />
              ) : (
                <SetUpQuickKeys history={history} match={match} />
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={10} xl={8} className="sell-bucket-container">
          <Bucket
            expand={expandLastBucketProduct}
            triggerPay={triggerPay}
            sellBucket={sellProductBucket} //TODO:get it from the db if there is any such record
            isPriceIncludingTax={sellBucketShowPriceIncTax}
            selectedProduct={{
              ...selectedProduct,
              isPriceIncludingTax: sellBucketShowPriceIncTax,
              price:
                (selectedProduct &&
                  (sellBucketShowPriceIncTax === true
                    ? selectedProduct.retailPriceIncTax
                    : selectedProduct.retailPriceExTax)) ||
                0,
            }}
            onBucketUpdate={this.handleBucketUpdate}
            setCustomerRef={(el) => (this.customerRef = el)}
            onCustomerSelect={(customer) =>
              this.searchProductRef && this.searchProductRef.focus()
            }
            expandSubTotal={expandSubTotal}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    flexSearchProduct: state.offline.flexSearchProduct,
    quickKeys: state.offline.quickKeys,
    offlineReady: state.offline.state === OFFLINESTATE.Ready,
    offlineState: state.offline.state,
    sellProductBucket: state.sale,
  };
};

export default connect(mapStateToProps, { prepareOfflineSell })(
  withRouter(withNamespaces()(Sell))
);
