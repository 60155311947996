import React from "react";
import { Space } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  IdcardOutlined,
  DownOutlined,
} from "@ant-design/icons";
import Button from "~/components/Common/Button";
import { getLoggedInUser } from "~/_utils";
import { Menu, Dropdown } from "antd";
import { signOut } from "~/actions/userActions";
import { Trans, withNamespaces } from "react-i18next";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";
import { constructUrl } from "~/config";

const menu = (props = {}) => {
  const { disabled, history, match } = props;
  return (
    <Menu className="topMenu profileMenu">
      <Menu.Item
        key={"userprofile"}
        onClick={() => {
          history.push(
            constructUrl({
              match,
              to: `/user/userProfile`,
            })
          );
        }}
        disabled={disabled}
      >
        <span>
          <IdcardOutlined />
          <span style={{ marginLeft: "8px" }}>
            <Trans i18nKey="EditProfile">Edit profile</Trans>
          </span>
        </span>
      </Menu.Item>
      <Menu.Item key={"signout"} onClick={signOut}>
        <span>
          <LogoutOutlined />
          <span style={{ marginLeft: "8px" }}>
            <Trans i18nKey="Logout">Log out</Trans>
          </span>
        </span>
      </Menu.Item>
    </Menu>
  );
};

const ProfileMenu = (props = {}) => {
  const currentUser = getLoggedInUser() || {};

  return (
    <div className="menuContainer profileMenuContainer">
      <Dropdown
        overlay={menu(props)}
        trigger={["click"]}
        arrow={{ pointAtCenter: true }}
      >
        <Space style={{ cursor: "pointer" }}>
          <Button
            type="link"
            className="avatarContainer"
            style={{}}
            icon={<UserOutlined />}
          >
            <span className="buttonText ellipsis">
              {currentUser.displayName}
            </span>
          </Button>
          <DownOutlined />
        </Space>
      </Dropdown>
    </div>
  );
};

export default withNamespaces([TRANSLATION_NAMESPACE.Common])(ProfileMenu);
