import React from "react";
import { Button, Space, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const ColumnTextSearch = (dataIndexes) => {
  let searchInput;

  const handleSearch = (confirm) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    handleSearch(confirm);
  };

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={(el) => {
            searchInput = el;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      let foundMatch = false;
      dataIndexes.forEach((dataIndex) => {
        const isRecordMatched = record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());

        foundMatch = foundMatch || isRecordMatched;
      });

      return foundMatch;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(
          () => searchInput.current && searchInput.current.select(),
          100
        );
      }
    },
    render: (text) => text,
  };
};
