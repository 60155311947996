import React from "react";
import { Row, Col, Divider, Tabs, Typography } from "antd";
import { PageTitle } from "~/components/Common/Text";
import { getProducts } from "~/actions/productActions";
import Spin from "~/components/Common/Spin";

const { Text } = Typography;
const { TabPane } = Tabs;

const Title = (props = {}) => {
  const { product = {} } = props;
  const name = product.name;
  const variantName = ((product.productVariants || [])[0] || {}).name;

  return (
    <Row className="title-container">
      <Col xs={24}>
        <PageTitle size={5}>{name}</PageTitle>
      </Col>
      {variantName && (
        <Col xs={24}>
          <Text>{variantName}</Text>
        </Col>
      )}
    </Row>
  );
};

const InventoryTab = (props) => {
  const {
    barcode,
    supplierName = "",
    currentInventory = 0,
    reOrderPoint = 0,
  } = props;
  return (
    <Row gutter={[16, 8]}>
      <Col xs={24}>
        <Row gutter={[16, 8]}>
          <Col xs={8}>
            <Text style={{ fontWeight: "bold" }}>Barcode</Text>{" "}
          </Col>
          <Col xs={12}>
            <Text style={{ fontWeight: "bold" }}>Supplier</Text>{" "}
          </Col>
          <Col xs={4}>
            <Text style={{ fontWeight: "bold" }}> Inventory</Text>{" "}
          </Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col xs={8}>{barcode}</Col>
          <Col xs={12}>{supplierName}</Col>
          <Col xs={4}>{currentInventory}</Col>
        </Row>
      </Col>
    </Row>
  );
};

const PriceTab = (props) => {
  const {
    barcode,
    purchasePrice,
    avgPurchasePrice,
    retailPriceExTax,
    retailPriceIncTax,
    taxName,
    markup,
  } = props;
  return (
    <Row gutter={[16, 8]}>
      <Col xs={24}>
        <Row gutter={[16, 8]}>
          <Col xs={6}>
            <Text style={{ fontWeight: "bold" }}> Purchase price</Text>{" "}
          </Col>
          <Col xs={5}>
            <Text style={{ fontWeight: "bold" }}> Avg price</Text>{" "}
          </Col>
          <Col xs={4}>
            <Text style={{ fontWeight: "bold" }}> Markup%</Text>{" "}
          </Col>
          <Col xs={3}>
            <Text style={{ fontWeight: "bold" }}> Tax%</Text>{" "}
          </Col>
          <Col xs={6}>
            <Text style={{ fontWeight: "bold" }}> Retail Price</Text>{" "}
          </Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col xs={6}>{purchasePrice}</Col>
          <Col xs={5}>{avgPurchasePrice}</Col>
          <Col xs={4}>{markup}</Col>
          <Col xs={3}>{taxName}</Col>
          <Col xs={6}>{retailPriceIncTax}</Col>
        </Row>
      </Col>
    </Row>
  );
};

const DescriptionTab = (props) => {
  const { description = "" } = props;
  return (
    <Row gutter={[16, 8]}>
      <Col xs={24}>{description}</Col>
    </Row>
  );
};

const Detail = (props = {}) => {
  const { product = {} } = props;
  const { description = "" } = product;
  const actualProduct = (product.productVariants || [])[0] || product || {};
  const {
    barcode,
    supplierName,
    currentInventory,
    reOrderPoint,
    purchasePrice,
    avgPurchasePrice,
    retailPriceExTax,
    retailPriceIncTax,
    taxName,
    markup,
  } = actualProduct;
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Inventory" key="1">
        <InventoryTab
          barcode={barcode}
          supplierName={supplierName}
          currentInventory={currentInventory}
          reOrderPoint={reOrderPoint}
        />
      </TabPane>
      <TabPane tab="Price" key="2">
        <PriceTab
          barcode={barcode}
          purchasePrice={purchasePrice}
          avgPurchasePrice={avgPurchasePrice}
          retailPriceExTax={retailPriceExTax}
          retailPriceIncTax={retailPriceIncTax}
          taxName={taxName}
          markup={markup}
        />
      </TabPane>
      <TabPane tab="Description" key="3">
        <DescriptionTab description={description} />
      </TabPane>
    </Tabs>
  );
};

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: props.product || {},
      loading: false,
    };
    this.getProductWithFilteredVariant = this.getProductWithFilteredVariant.bind();
  }
  componentDidMount() {
    const { barcode } = this.props;
    const _this = this;
    if (barcode) {
      this.setState({ loading: true });
      const filter = { barcode: barcode };
      getProducts({ filterValues: { ...filter } })
        .then(function(result) {
          const { data = [] } = result;
          const product = data[0] || {};
          _this.setState({
            product: _this.getProductWithFilteredVariant(product),
            loading: false,
          });
        })
        .catch(function(err) {
          console.log(err);
          _this.setState({ loading: false });
        });
    }
  }
  getProductWithFilteredVariant = (product = {}) => {
    const { barcode = "" } = this.props;
    let variants = [...(product.productVariants || [])];
    if (variants && variants.length > 0 && barcode) {
      variants = variants.filter((x) => x.barcode === barcode) || [];
    }

    return { ...product, productVariants: [...variants] };
  };
  render() {
    const { loading = false, product = {} } = this.state;
    return (
      <Spin spinning={loading}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Title product={product} />
          </Col>
          <Col xs={24}>
            <Detail product={product} />
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default ProductDetail;
