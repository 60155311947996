import { OFFLINE_READY, OFFLINE_STATECHANGE } from "~/actions/types";

const initialState = { isOnline: true };

export default function(state = initialState, action) {
  switch (action.type) {
    case OFFLINE_STATECHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case OFFLINE_READY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
