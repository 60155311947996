import React from "react";
import { Row, Col, Form, Divider, Button } from "antd";
import { Trans, withNamespaces } from "react-i18next";
import PriceInput from "~/components/Common/Input/PriceInput";
import InputDecimal from "~/components/Common/InputDecimal";

import { PageTitle } from "~/components/Common/Text";
import FormItem from "~/components/Form/FormItem";

import { propName } from "~/_utils";
import viewModel from "./viewModel";

const GetInitialValues = () => {
  const initialValues = {
    unitsPerPack: 1,
    packPurchasePriceExTax: 0,
    packPurchaseQuantity: 1,
  };

  return initialValues;
};

class PurchaseProductPriceCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.state = { formRef: null };

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.setFormRef = this.setFormRef.bind();
  }

  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  handleSubmit = (values) => {
    const { onCalculateUnitPurchasePriceSubmit, index = 0 } = this.props;
    onCalculateUnitPurchasePriceSubmit({ ...values, index: index });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const { showTitle } = this.props;
    const { formRef } = this.state;
    const initialValues = GetInitialValues();

    return (
      <Row gutter={[16, 8]}>
        <Col xs={24}>
          {showTitle === true && (
            <>
              <PageTitle>{"Unit price & quantity calculator"}</PageTitle>
              <Divider />
              <br />
            </>
          )}
          <Col xs={24}>
            <Form
              validateTrigger="onBlur"
              onFinish={this.handleSubmit}
              onFinishFailed={this.onFinishFailed}
              className="purchasePriceCalculator-form"
              initialValues={initialValues}
              ref={this.setFormRef}
            >
              <Row gutter={[16, 8]}>
                <Col xs={24}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(
                      initialValues,
                      (o) => o.unitsPerPack
                    )}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.unitsPerPack)]
                    }
                  >
                    {(options) => (
                      <InputDecimal {...options} style={{ width: "100%" }} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(
                      initialValues,
                      (o) => o.packPurchasePriceExTax
                    )}
                    viewModelKeyValue={
                      viewModel[
                        propName(initialValues, (o) => o.packPurchasePriceExTax)
                      ]
                    }
                  >
                    {({ placeholder, ...restOptions }) => (
                      <PriceInput style={{ width: "100%" }} {...restOptions} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(
                      initialValues,
                      (o) => o.packPurchaseQuantity
                    )}
                    viewModelKeyValue={
                      viewModel[
                        propName(initialValues, (o) => o.packPurchaseQuantity)
                      ]
                    }
                  >
                    {(options) => (
                      <InputDecimal {...options} style={{ width: "100%" }} />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="save-button"
                    >
                      <Trans i18nKey="CalculateUnitPriceQuantity">
                        {"Calculate"}
                      </Trans>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
    );
  }
}

export default PurchaseProductPriceCalculator;
