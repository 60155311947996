import React from "react";
import Button from "~/components/Common/Button";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const Info = (props) => {
  const { title = "", color = "blue", ...restProps } = props;

  return (
    <Tooltip title={title} color={color} {...restProps}>
      <Button type="link" icon={<InfoCircleOutlined />} onClick={() => {}} />
    </Tooltip>
  );
};

export default Info;
