import React from "react";
import "./index.scss";
import QuickKeys from "~/components/QuickKeys";
import SearchProduct from "~/components/Sell/SearchProduct";
import Button from "~/components/Common/Button";
import { Trans, withNamespaces } from "react-i18next";
import { saveQuickKey, getQuickKey } from "~/actions/productActions";
import { generalError } from "~/actions/generalActions";
import { connect } from "react-redux";
import Spin from "~/components/Common/Spin";

import {
  getProductFlexSearch,
  selectProductFromLocalDb,
} from "~/_offline/offlineProducts";
import { Row, Col, Typography, Divider } from "antd";
import { PageTitle } from "~/components/Common/Text";

const { Title, Text } = Typography;

class QuickKeysSetup extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind();
    this.onKeysOrderChange = this.onKeysOrderChange.bind();
    this.onSave = this.onSave.bind();

    this.state = { quickKeysProducts: [] };
  }
  handleSelect = (value) => {
    const { quickKeysProducts = [] } = this.state;

    const isProductAlreadySelected =
      quickKeysProducts.filter((x) => x.productId === value).length > 0;

    if (isProductAlreadySelected === true) {
      this.props.generalError("quickKeys", {
        customMessage: "Product already exists",
      });
      return;
    }

    const _this = this;
    selectProductFromLocalDb(value).then(function(result) {
      const { quickKeysProducts } = _this.state;
      quickKeysProducts.push({
        ...result,
        color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      });
      _this.setState({ quickKeysProducts: quickKeysProducts });
    });
  };
  onKeysOrderChange = (items = []) => {
    this.setState({
      quickKeysProducts: [...items],
    });
  };
  hadleKeyDelete = (index) => {
    const { quickKeysProducts } = this.state;
    quickKeysProducts.splice(index, 1);
    this.setState({
      quickKeysProducts: quickKeysProducts,
    });
  };
  componentDidMount() {
    const _this = this;

    _this.setState({ loading: true });

    getQuickKey()
      .then(function(result = {}) {
        const data = result.data || {};
        const quickKeyProducts = data.quickKeyProducts || [];
        const quickKeyId = data.quickKeyId;
        _this.setState({
          loading: false,
          quickKeyId: quickKeyId,
          quickKeysProducts: [...quickKeyProducts],
        });
      })
      .catch(function(err) {
        _this.setState({ loading: false });
        console.log(err);
      });

    getProductFlexSearch().then(function(result = {}) {
      _this.setState({
        flexSearch: result,
      });
    });
  }
  onSave = () => {
    const _this = this;
    _this.setState({ loading: true });
    const { quickKeyId, quickKeysProducts = [] } = this.state;
    const { saveQuickKey } = this.props;

    let updatedQuickKeyProducts = quickKeysProducts.map((item, i) => {
      return {
        ...item,
        sequence: i + 1,
      };
    });

    const savePayload = {
      quickKeyId: quickKeyId,
      quickKeyProducts: updatedQuickKeyProducts.map(
        ({ productId, sequence, color, name, variant, ...restValues }) => {
          return {
            productId: productId,
            sequence: sequence,
            color: color,
            name: name,
            variant: variant,
          };
        }
      ),
    };

    console.log("save payload: ", savePayload);

    saveQuickKey({ ...savePayload })
      .then(function(result = {}) {
        const quickKeyId = result.quickKeyId;
        _this.setState({ loading: false, quickKeyId: quickKeyId });
      })
      .catch(function(err) {
        _this.setState({ loading: false });
      });
  };
  render() {
    const { quickKeysProducts = [], flexSearch, loading = true } = this.state;

    return (
      <div className="quickKeysLayout-container">
        <Spin spinning={loading}>
          <Row>
            <Col xs={24}>
              <PageTitle>Quick keys setup</PageTitle>

              <Divider />
              <br />
            </Col>
            <Col xs={24} xl={20} xxl={16}>
              <Row>
                <Col xs={24} className="searchProduct">
                  <SearchProduct
                    onSelect={this.handleSelect}
                    flexSearch={flexSearch}
                  />
                </Col>
                <Col xs={24} className="quickKeys">
                  <QuickKeys
                    products={quickKeysProducts}
                    isEditable={true}
                    onOrderChange={this.onKeysOrderChange}
                    onDelete={this.hadleKeyDelete}
                  />
                </Col>
                <Col xs={24}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="save-button"
                    onClick={this.onSave}
                  >
                    <Trans i18nKey="Save">Save</Trans>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveQuickKey, generalError })(
  withNamespaces()(QuickKeysSetup)
);
