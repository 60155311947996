//TODO: using FormItem for large vriants could be performant issue as render is called with every value change, need to check with Form.Item
import React from "react";
import {
  Row,
  Col,
  Input,
  Collapse,
  Typography,
  Select,
  Switch,
  Form,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { combineArrayValues, toFloat } from "~/_utils";
import { PRODUCTTYPES } from "~/_utils/consts";

import FormItem, { FormItemTitle } from "~/components/Form/FormItem";
import PriceInput from "~/components/Common/Input/PriceInput";

import SupplierSelect from "~/pages/Products/Product/SupplierSelect";
import VariantDefinitionExpandSection from "./VariantDefinitionExpandSection";

const { Text } = Typography;
const { Option } = Select;

const constructVariantsValuesCombination = (variants = []) => {
  let variantValues = [];
  variants &&
    variants.forEach((variant = {}) => {
      variant.attributeId &&
        variant.values &&
        variant.values.length > 0 &&
        variantValues.push(variant.values);
    });

  const variantsValuesCombination = combineArrayValues(variantValues, " / ");

  return variantsValuesCombination ? variantsValuesCombination : [];
};

const VariantDefinitionHeaderTitle = () => {
  return (
    <Row gutter={[16]} className={"variantDefinitionHeaderTitleContainer"}>
      <Col xs={12} md={6} className={"variantDefinitionHeader-text"}>
        <FormItemTitle title="Variant Name" />
      </Col>
      <Col xs={12} md={8}>
        <FormItemTitle title="Supplier" />
      </Col>
      <Col xs={4}>
        <FormItemTitle title="Purchase price" />
      </Col>
      <Col xs={4}>
        <FormItemTitle title="Retail price (Excl. tax)" />
      </Col>
      <Col xs={2}>
        <FormItemTitle title="Enable" />
      </Col>
    </Row>
  );
};

const VariantDefinitionHeader = (
  variantDefinition = {},
  form,
  index,
  suppliers
) => {
  return (
    <>
      <Row style={{ height: 0 }}>
        <Col xs={6}>
          <FormItem
            propertyName={[index, "productId"]}
            showFieldTitle={false}
            form={form}
          >
            {(options) => <Input type="hidden" />}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16]} className={"variantDefinitionHeaderContainer"}>
        <Col
          xs={12}
          md={6}
          className={"variantDefinitionHeader-text"}
          title={variantDefinition.name}
        >
          <Text>{variantDefinition.name}</Text>
          <FormItem
            propertyName={[index, "name"]}
            showFieldTitle={false}
            form={form}
          >
            {(options) => <Input type="hidden" />}
          </FormItem>
        </Col>
        <Col xs={12} md={8}>
          <FormItem
            propertyName={[index, "supplierId"]}
            form={form}
            showFieldTitle={false}
          >
            {(options, setValue) => (
              <SupplierSelect
                suppliers={suppliers}
                placeholder={options.placeholder}
                onChange={(value) => setValue(value)}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={4}>
          <FormItem
            propertyName={[index, "purchasePrice"]}
            showFieldTitle={false}
          >
            {({ placeholder, ...restOptions }) => (
              <PriceInput
                style={{ width: "100%" }}
                {...restOptions}
                disabled={true}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={4}>
          <FormItem
            propertyName={[index, "retailPriceExTax"]}
            showFieldTitle={false}
          >
            {({ placeholder, ...restOptions }) => (
              <PriceInput
                style={{ width: "100%" }}
                {...restOptions}
                disabled={true}
              />
            )}
          </FormItem>
        </Col>
        <Col xs={2}>
          <FormItem
            propertyName={[index, "sellOnPointOfSale"]}
            form={form}
            showFieldTitle={false}
          >
            {(options, setValue) => (
              <Row>
                <Col>
                  <Text>{options.placeholder}</Text>{" "}
                  <Switch
                    defaultChecked={variantDefinition.sellOnPointOfSale}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked, e) => {
                      e.stopPropagation();
                      let variantsDefinitions =
                        form.getFieldValue("productVariants") || [];
                      variantsDefinitions[index].sellOnPointOfSale = checked;
                    }}
                  />
                </Col>
              </Row>
            )}
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

class VariantsDefinitions extends React.Component {
  constructor(props) {
    super(props);

    const { initialValues = {} } = this.props;
    const { productVariants = [] } = initialValues;

    this.state = {
      variantsDefinitions: productVariants,
    };
  }

  componentDidUpdate() {
    const { form = {} } = this.props;

    const { variantsDefinitions } = this.state;

    form && form.setFieldsValue({ productVariants: [...variantsDefinitions] });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { form, variants = [] } = nextProps;
    const {
      variantsDefinitions: prevStateVariantsDefinitions = [],
    } = prevState;

    const productAttributes = form && form.getFieldValue("productAttributes");

    if (!productAttributes || productAttributes.length === 0) {
      return { variantsDefinitions: [] };
    }

    let prevVariantsDefinitions =
      (form && form.getFieldValue("productVariants")) ||
      prevStateVariantsDefinitions;

    const prevVariantsDefinitionsVariantNames = prevVariantsDefinitions.map(
      (y) => y.name
    );

    let combinedVariantsValues = constructVariantsValuesCombination(variants);
    combinedVariantsValues = combinedVariantsValues
      ? combinedVariantsValues
      : [];

    if (
      prevVariantsDefinitionsVariantNames.length === 0 &&
      combinedVariantsValues.length === 0
    ) {
      return null;
    }

    if (
      prevVariantsDefinitionsVariantNames.length ===
        combinedVariantsValues.length &&
      prevVariantsDefinitionsVariantNames.forEach(
        (x, index) => x === combinedVariantsValues[index]
      )
    ) {
      return null;
    }

    //TODO: made below code more readable
    //Map old indexes to new Indexes. Loop through new variantsDefinitions and for the mapped indexes get values from old indexes
    //Mapped indexes: VariantName exists completely in new VariantName or viseVersa have a mapped index

    const purchasePrice = form && toFloat(form.getFieldValue("purchasePrice"));
    const taxId = form && form.getFieldValue("taxId");
    const markup = form && toFloat(form.getFieldValue("markup"));
    const retailPriceExTax =
      form && toFloat(form.getFieldValue("retailPriceExTax"));
    const retailPriceIncTax =
      form && toFloat(form.getFieldValue("retailPriceIncTax"));

    let variantsDefinitions = combinedVariantsValues.map((x, index) => {
      let mappedOldVariantIndex = prevVariantsDefinitionsVariantNames.indexOf(
        x
      );
      mappedOldVariantIndex =
        !mappedOldVariantIndex === -1
          ? mappedOldVariantIndex
          : prevVariantsDefinitionsVariantNames.findIndex(
              (y) =>
                `${y} /`.startsWith(`${x} /`) || `${x} /`.startsWith(`${y} /`)
            );

      let prevVariantDefinition = prevVariantsDefinitions[mappedOldVariantIndex]
        ? {
            ...prevVariantsDefinitions[mappedOldVariantIndex],
            name: x,
          }
        : {
            sellOnPointOfSale: true,
            purchasePrice: purchasePrice,
            taxId: taxId,
            markup: markup,
            retailPriceExTax: retailPriceExTax,
            retailPriceIncTax: retailPriceIncTax,
            name: x,
          };

      return {
        ...prevVariantDefinition,
      };
    });
    variantsDefinitions = variantsDefinitions ? variantsDefinitions : [];

    form && form.setFieldsValue({ productVariants: [...variantsDefinitions] });

    return { variantsDefinitions: variantsDefinitions };
  }

  render() {
    const { Panel } = Collapse;
    const { variants, form, suppliers, taxes } = this.props;
    const { variantsDefinitions = [] } = this.state;

    return (
      <Row gutter={[16, 8]} className="variantsDefinitionsContainer">
        <Col xs={24}>
          {variantsDefinitions && variantsDefinitions.length > 0 && (
            <VariantDefinitionHeaderTitle />
          )}
        </Col>
        <Col xs={24}>
          <Form.List name="productVariants">
            {(fields, { add, remove }) => {
              return (
                <Collapse accordion>
                  {variantsDefinitions.map((variantDefinition, index) => {
                    return (
                      <Panel
                        header={VariantDefinitionHeader(
                          variantDefinition,
                          form,
                          index,
                          suppliers
                        )}
                        key={variantDefinition.name + index}
                      >
                        <VariantDefinitionExpandSection
                          form={form}
                          index={index}
                          taxes={taxes}
                        />
                      </Panel>
                    );
                  })}
                </Collapse>
              );
            }}
          </Form.List>
        </Col>
      </Row>
    );
  }
}

export default VariantsDefinitions;
