import React, { useMemo } from "react";
import { PageTitle } from "~/components/Common/Text";
import "./index.scss";
import { Card as AntdCard, Col, Row, Avatar, Radio } from "antd";
import Card from "./Card";

import ProductsLowInventoryCard from "./ProductsLowInvetoryCard";
import ReceivablesCard from "./ReceivablesCard";
import ProductsExpiringCard from "./ProductsExpiringCard";
import SaleCard from "./SaleCard";
import SaleChart from "./SaleChart";
import TopProductsChart from "./TopProductsChart";

import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProductsStockCard from "./ProductsStockCard";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { history, match } = this.props;

    return (
      <div className="dashboard-card-numbers-container">
        <Row gutter={[16, 16]}>
          <Col xs={24} xxl={16}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8} lg={8} xxl={8}>
                <SaleCard history={history} match={match} />
              </Col>
              <Col xs={24} md={8} lg={8} xxl={8}>
                <ProductsExpiringCard history={history} match={match} />
              </Col>
              <Col xs={24} md={8} lg={8} xxl={8}>
                <ReceivablesCard history={history} match={match} />
              </Col>
              <Col xs={24} md={8} lg={8} xxl={8}>
                <ProductsLowInventoryCard history={history} match={match} />
              </Col>
              <Col xs={24} md={8} lg={8} xxl={8}>
                <ProductsStockCard history={history} match={match} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ paddingTop: "30px" }}>
          <Col xs={24} lg={16}>
            <SaleChart />
          </Col>
          <Col xs={24} lg={8}>
            <TopProductsChart />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withNamespaces()(Dashboard)));
