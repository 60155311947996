import {
  recordSavedSuccess,
  recordSavedFailed,
  recordDeleteSuccess,
} from "./generalActions";
import { treatDateAsUtcDateTime } from "~/_utils";
import { mutate, query } from "~/_utils/httpClient";
import { batch } from "react-redux";

const SAVE_PURCHASE_COLUMNS = `
  purchaseId
`;

const SAVE_PURCHASEPRODUCT_COLUMNS = `
    purchaseProductId
`;

const GET_PURCHASE_COLUMNS = `

  purchaseId
  refNumber
  supplierId
  supplierName
  purchaseDate
  additionalTaxPercentage
  additionalDiscountPercentage
  includeAdditionalTaxInProductPrice
  totalPurchasePriceExTax
  totalPurchasePrice
  notes
  purchaseProducts{
    purchaseProductId
    productId        
    productName
    productVariantName
    productBarCode        
    batchNumber
    expiryDate
    purchasePrice
    quantity
    inStock
    discountPercentage
    taxPercentage
    purchasePriceExTax
    totalPurchasePriceExTax
    totalPurchasePrice
   }
`;

const GET_PURCHASES_COLUMNS = `

  purchaseId
  refNumber
  supplierId
  supplierName
  purchaseDate
  totalPurchasePrice
  totalTaxAmount            
  purchaseProducts{
    purchaseProductId
    productId        
    productName
    productVariantName
    productBarCode        
    batchNumber
    expiryDate
    purchasePrice
    totalPurchasePrice
    quantity
    inStock
   }
`;

export const getPurchase = (options) => {
  const { id } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_PURCHASE_COLUMNS,
        entity: "purchase",
        id: id,
        idType: "Uuid!",
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getPurchases = (options = {}) => {
  const { filterValues, sort, pageInfo = {}, enablePaging = true } = options;

  return new Promise(function(resolve, reject) {
    query({
      columns: GET_PURCHASES_COLUMNS,
      entity: "purchases",
      filter: adjustProductFilter(filterValues),
      filterType: "PurchaseDtoFilterInput",
      sort: sort,
      sortType: "[PurchaseDtoSortInput!]",
      isList: true,
      pageInfo: pageInfo,
      enablePaging: enablePaging,
    })
      .then(function(result = {}) {
        resolve(applyFilterToPurchaseProducts(result, filterValues));
        //resolve(result);
      })
      .catch(function(err) {
        reject(err);
      });
  });
};

const applyFilterToPurchaseProducts = (result = {}, filter = {}) => {
  const { data = [] } = result;
  const updatedData = data.map((purchase) => {
    let filteredPurchaseProducts = purchase.purchaseProducts;
    filteredPurchaseProducts = filter.batchNumber_eq
      ? filteredPurchaseProducts.filter(
          (x) => x.batchNumber === filter.batchNumber_eq
        )
      : filteredPurchaseProducts;
    filteredPurchaseProducts = filter.productBarCode_eq
      ? filteredPurchaseProducts.filter(
          (x) => x.productBarCode === filter.productBarCode_eq
        )
      : filteredPurchaseProducts;
    filteredPurchaseProducts = filter.expiryDate_gte
      ? filteredPurchaseProducts.filter(
          (x) =>
            treatDateAsUtcDateTime(x.expiryDate) >= filter.expiryDate_gte &&
            treatDateAsUtcDateTime(x.expiryDate) <= filter.expiryDate_lte
        )
      : filteredPurchaseProducts;

    filteredPurchaseProducts =
      filter.inStock_eq || filter.inStock_eq === false
        ? filteredPurchaseProducts.filter(
            (x) => x.inStock === filter.inStock_eq
          )
        : filteredPurchaseProducts;

    return { ...purchase, purchaseProducts: filteredPurchaseProducts };
  });

  return { ...result, data: updatedData };
};

// const transformPurcahseResult = (result = {}) => {
//   debugger;
//   const { data = [] } = result;
//   const res = data.reduce((acc, curr) => {
//     if (!acc[curr.purchaseId]) {
//       acc.purchaseId = curr.purchaseId;
//       acc.refNumber = curr.refNumber;
//       acc.supplierName = curr.supplierName;
//       acc.purchaseDate = curr.purchaseDate;
//       acc.purchaseProducts = []; //If this purchaseId wasn't previously stored
//       acc.purchaseProducts.push(curr);
//     } else {
//       const matchedIndex = acc
//         .map((x) => x.purchaseId)
//         .indexOf(curr.purchaseId);
//       acc[matchedIndex].purchaseProducts.push(curr);
//     }

//     return acc;
//   }, {});

//   result.data = res;
//   return result;
// };

const adjustProductFilter = (originalFilter = {}) => {
  const filter = { ...originalFilter };
  if (!filter || filter.length === 0) {
    return filter;
  }

  const batchNumber = filter["batchNumber_eq"];
  const barcode = filter["productBarCode_eq"];
  const expiryDate_gte = filter["expiryDate_gte"];
  const expiryDate_lte = filter["expiryDate_lte"];
  const inStock = filter["inStock_eq"];

  let batchNumberObj = {};
  let barcodeObj = {};
  let expiryDateObj = {};
  let inStockObj = {};

  if (batchNumber) {
    batchNumberObj = { batchNumber: { eq: batchNumber } };
  }
  if (barcode) {
    barcodeObj = { productBarCode: { eq: barcode } };
  }
  if (expiryDate_gte) {
    expiryDateObj = {
      expiryDate: { gte: expiryDate_gte, lte: expiryDate_lte },
    };
  }
  if (inStock || inStock === false) {
    inStockObj = { inStock: { eq: inStock } };
  }

  filter["and"] = {
    purchaseProducts: {
      some: {
        ...batchNumberObj,
        ...barcodeObj,
        ...expiryDateObj,
        ...inStockObj,
      },
    },
  };
  delete filter["batchNumber_eq"];
  delete filter["productBarCode_eq"];
  delete filter["expiryDate_gte"];
  delete filter["expiryDate_lte"];
  delete filter["inStock_eq"];

  return filter;
};

export const deletePurchase = () => {};

export const savePurchase = (purchase) => (dispatch) => {
  console.log("purchaseAction - purchase : ", purchase);
  return new Promise(function(resolve, reject) {
    try {
      let updatedPurchase = purchase;
      mutate({
        command: purchase.purchaseId ? "updatePurchase" : "createPurchase",
        commandType: purchase.purchaseId
          ? "UpdatePurchaseCommandInput!"
          : "CreatePurchaseCommandInput!",
        data: purchase,
        columns: SAVE_PURCHASE_COLUMNS,
      })
        .then(function(result) {
          updatedPurchase.purchaseId = result.data.purchaseId;
          dispatch(recordSavedSuccess("purchase", updatedPurchase));
          resolve(updatedPurchase);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("purchase", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const updatePurchaseProduct = (purchaseProductCommand) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      let updatedPurchaseProduct = purchaseProductCommand;
      mutate({
        command: "updatePurchaseProduct",
        commandType: "UpdatePurchaseProductCommandInput",
        data: purchaseProductCommand,
        columns: SAVE_PURCHASEPRODUCT_COLUMNS,
      })
        .then(function(result) {
          dispatch(
            recordSavedSuccess("purchaseProduct", updatedPurchaseProduct)
          );
          resolve(updatedPurchaseProduct);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("purchaseProduct", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};
