import React from "react";
import "./index.scss";
import Logo from "~/components/Logo";
import { Form, Row, Col } from "antd";
import { Button, Input, Typography, Avatar } from "antd";
import {
  LockOutlined,
  ExceptionOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import viewModel from "./viewModel";
import FormItem from "~/components/Form/FormItem";
import Link from "~/components/Link";
import { withRouter } from "react-router-dom";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { resetPassword } from "~/actions/userActions";
import { propName } from "~/_utils";
import Recaptcha from "~/components/Common/Recaptcha";
import queryString from "query-string";
const { Text } = Typography;

const Footer = () => {
  return (
    <Row gutter={[16, 16]} style={{ paddingTop: "25px" }}>
      <Col>
        <Link to="/Login">
          <Trans i18nKey="Login">Login</Trans>
        </Link>
      </Col>
      <Col>{"|"}</Col>
      <Col>
        <Link to="/Signup">
          <Trans i18nKey="RegisterNow">Register now</Trans>
        </Link>
      </Col>
      <Col>{"|"}</Col>
      <Col>
        <Link to="/ForgotPassword">
          <Trans i18nKey="ForgotPassword">Forgot password</Trans>
        </Link>
      </Col>
    </Row>
  );
};

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { formRef: null, success: false };
    this.handleSubmit = this.handleSubmit.bind();
    this.setFormRef = this.setFormRef.bind();
    this.resetPassword = this.resetPassword.bind();
  }
  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  handleSubmit = (values = {}) => {
    const { location, customer = {} } = this.props;
    const queryParams = queryString.parse(location.search);

    const userId = (queryParams.uid || "").replaceAll("-", "");
    const companyId = (queryParams.cid || "").replaceAll("-", "");

    values.companyId = companyId;
    values.userId = userId;
    values.code = queryParams.code;

    this.resetPassword(values);
  };
  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  resetPassword = (values = {}) => {
    const _this = this;

    this.setState({
      loading: true,
    });

    const { formRef } = this.state;
    const { resetPassword, history, match } = this.props;
    values.recaptchaToken = formRef.getFieldValue("recaptchaToken");
    console.log("values:", values);
    resetPassword(values)
      .then(function() {
        console.log("success");
        _this.setState({ success: true, loading: false });
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });
        if (err.code === "400") {
          _this.setState({ isInvalidRequest: true });
        }

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };

  render() {
    const {
      formRef,
      loading = false,
      success = false,
      isInvalidRequest = false,
    } = this.state;
    if (success === true) {
      return (
        <div className="resetPassword-form-container">
          <div className="resetPassword-success">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="icon-container">
                <Avatar
                  size={50}
                  icon={<CheckCircleOutlined />}
                  style={{ color: "#00c9a7", background: "#00c9a71a" }}
                />
              </div>
              <div style={{ paddingLeft: "10px", paddingTop: "15px" }}>
                <Text>Password is reset successfully! </Text>
              </div>
            </div>
            <Row gutter={[16, 16]} style={{ paddingTop: "25px" }}>
              <Col>
                <Link to="/Login">
                  <Trans i18nKey="BackToLogin">Back to login</Trans>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    if (isInvalidRequest === true) {
      return (
        <div className="resetPassword-form-container">
          <div className="resetPassword-success">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="icon-container">
                <Avatar
                  size={50}
                  icon={<ExclamationCircleOutlined />}
                  style={{ color: "#cb9a08", backgroundColor: "#f5f0e3" }}
                />
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <Text>
                  This reset URL is invalid. It's also possible that you've
                  already reset your password using this link, or it's been more
                  than 24 hours since we sent the link to you{" "}
                </Text>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      );
    }
    return (
      <div className="resetPassword-form-container">
        <Form
          validateTrigger="onBlur"
          onFinish={this.handleSubmit}
          className="resetPassword-form"
          initialValues={{}}
          ref={this.setFormRef}
        >
          <Logo />
          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.password)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.password)]
            }
          >
            {({ options }) => (
              <Input.Password prefix={<LockOutlined />} {...options} />
            )}
          </FormItem>
          <Form.Item>
            <Recaptcha form={formRef} isFormSubmitted={loading === true}>
              <Button
                type="primary"
                htmlType="submit"
                className="resetPassword-form-button"
                loading={loading}
              >
                <Trans i18nKey="Submit">Submit</Trans>
              </Button>
            </Recaptcha>
            <Footer />
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { resetPassword })(
  withRouter(withNamespaces()(ResetPasswordForm))
);
