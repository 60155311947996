import React from "react";
import { Modal } from "antd";
import SalesTax from "~/pages/Setup/SalesTaxes/SalesTax";

const SalesTaxModal = (props) => {
  const { salesTaxId, onSave, ...restProps } = props;
  return (
    <Modal
      title="SalesTax"
      centered
      footer={null}
      destroyOnClose={true}
      {...restProps}
    >
      <SalesTax salesTaxId={salesTaxId} onSave={onSave} showTitle={false} />
    </Modal>
  );
};

export default SalesTaxModal;
