import React, { useContext } from "react";
import { defaultBaseUrl } from "~/config";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "~/_auth/Auth";
import { convertDateToUtcIso } from "~/_utils";
import Subscription from "~/pages/Subscription";
import moment from "moment";

const PrivateLayoutRoute = ({
  component: ComponentToRender,
  layout: Layout,
  ...rest
}) => {
  if (!ComponentToRender || !Layout) {
    return <></>;
  }

  const { currentUser = {} } = useContext(AuthContext);
  const isSubscriptionExpiryClaimExists =
    currentUser.claims &&
    currentUser.claims.subscriptionExpiryDateUtc !== undefined &&
    currentUser.claims.subscriptionExpiryDateUtc !== "";

  const todayUtc = convertDateToUtcIso(new Date());
  const subscriptionRemainingDays =
    isSubscriptionExpiryClaimExists === true
      ? moment(currentUser.claims.subscriptionExpiryDateUtc).diff(
          moment(todayUtc),
          "days"
        ) + 1
      : undefined;

  const isSubscriptionExpired =
    subscriptionRemainingDays !== undefined && subscriptionRemainingDays <= 0
      ? true
      : false;

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        currentUser.isAuthenticated === true ? (
          <Layout
            {...matchProps}
            subscription={{
              isSubscriptionExpired: isSubscriptionExpired,
              subscriptionRemainingDays: subscriptionRemainingDays,
            }}
          >
            {isSubscriptionExpired === true ? (
              <Subscription />
            ) : (
              <ComponentToRender />
            )}
          </Layout>
        ) : (
          <Redirect to={defaultBaseUrl + "/Login"} />
        )
      }
    />
  );
};

export default PrivateLayoutRoute;
