import React from "react";
import { Modal } from "antd";

import Supplier from "~/pages/Suppliers/Supplier";

const SupplierModal = (props) => {
  const { onSave, supplierId, ...restProps } = props;
  return (
    <Modal
      title="Supplier"
      centered
      footer={null}
      destroyOnClose={true}
      {...restProps}
    >
      <Supplier
        showTitle={false}
        onSave={onSave}
        supplierId={supplierId}
        size="MD"
      />
    </Modal>
  );
};

export default SupplierModal;
