import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";
import { Detector } from "react-detect-offline";
import { urlForPollingOnline } from "~/config";
import { AuthProvider } from "~/_auth/Auth";

const polling = {
  interval: 20000,
  timeout: 3000,
  url: urlForPollingOnline,
};

ReactDOM.render(
  <AuthProvider>
    <Provider store={store}>
      <Router>
        <I18nextProvider i18n={i18n}>
          <Detector
            polling={polling}
            render={({ online }) => <App isOnline={online} />}
          />
        </I18nextProvider>
      </Router>
    </Provider>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
