import React from "react";
import { constructUrl } from "~/config";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Divider, Typography, Form } from "antd";
import { FileTextOutlined, SearchOutlined } from "@ant-design/icons";
import { PageTitle } from "~/components/Common/Text";
import Button from "~/components/Common/Button";
import Table from "~/components/Common/Table";
import { getTaxPayables } from "~/actions/reportActions";
import PriceLabel from "~/components/Common/PriceLabel";
import RangePicker from "~/components/Common/DatePicker/RangePicker";
import { generateCode, formatDateTime } from "~/_utils";

import "../index.scss";
import moment from "moment";

const { Text } = Typography;
const Footer = (options = {}) => {
  const { totalTaxPayable = 0 } = options;
  return (
    <Row
      className="report-footer-container"
      gutter={[8, 0]}
      style={{
        maxWidth: "370px",
      }}
    >
      <Col xs={16}>
        <Text className="primaryTitle">{"Total Tax Payable: "}</Text>
      </Col>
      <Col xs={8}>
        <PriceLabel className="primaryText" value={totalTaxPayable} />
      </Col>
    </Row>
  );
};

const PrintHeader = (options) => {
  const { startDate, endDate } = options;

  return (
    <Row>
      {startDate && (
        <Col xs={24}>{`${formatDateTime(startDate, false)} - ${formatDateTime(
          endDate,
          false
        )}`}</Col>
      )}
    </Row>
  );
};

const Filters = (props) => {
  const { onSearch, onDateRangeChange, initialValues = {} } = props;
  const onFinish = (values) => {
    onSearch(values);
  };

  const { selectedDateRange = [] } = initialValues;
  return (
    <Form name="filter" onFinish={onFinish} initialValues={initialValues}>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={12}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RangePicker
              picker="month"
              onChange={onDateRangeChange}
              placeholder={["Start month", "End month"]}
              defaultValue={selectedDateRange}
              inputReadOnly={true}
              allowClear={false}
            />
            <Form.Item style={{ marginBottom: "0px", marginLeft: "12px" }}>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

class TaxPayables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
    };

    this.columns = [
      {
        title: "Type",
        dataIndex: "taxType",
        key: "taxType",
        fixed: "left",
        sorter: (a, b) => a.taxType.localeCompare(b.taxType),
      },
      {
        title: "Month",
        dataIndex: "month",
        key: "month",
        sorter: (a, b) => a.month - b.month,
      },
      {
        title: "Year",
        dataIndex: "year",
        key: "year",
        sorter: (a, b) => a.year - b.year,
      },
      {
        title: "Base amount",
        dataIndex: "taxableAmount",
        key: "taxableAmount",
        sorter: (a, b) => a.taxableAmount - b.taxableAmount,
      },
      {
        title: "Tax %",
        dataIndex: "taxPercentage",
        key: "taxPercentage",
        sorter: (a, b) => a.taxPercentage - b.taxPercentage,
      },
      {
        title: "Tax payable",
        dataIndex: "taxPayable",
        key: "taxPayable",
        sorter: (a, b) => a.taxPayable - b.taxPayable,
      },
      {
        title: "",
        dataIndex: "",
        key: "actions",
        // fixed: "right",
        width: 45,
        render: (text, record) => (
          <Row>
            <Col xs={24}>
              {/* <Button
                type="link"
                icon={<FileTextOutlined />}
                onClick={() =>
                  this.onDetail({
                    month: record.month,
                    year: record.year,
                    taxType: record.taxType,
                    taxPercentage: record.taxPercentage,
                  })
                }
              /> */}
            </Col>
          </Row>
        ),
      },
    ];

    var defaultSelectedDateRenge = [
      moment().startOf("month"),
      moment().endOf("month"),
    ];
    this.state = {
      selectedDateRange: defaultSelectedDateRenge,
      initialValues: { selectedDateRange: defaultSelectedDateRenge },
    };
    this.handleFetch = this.handleFetch.bind();
    this.onDetail = this.onDetail.bind();
    this.resetTableState = this.resetTableState.bind();
    this.onDateRangeChange = this.onDateRangeChange.bind();
  }
  componentDidMount() {
    this.handleFetch();
  }
  handleFetch = () => {
    const _this = this;

    let { filterValues = {}, sort = {}, selectedDateRange = [] } = this.state;

    if (selectedDateRange.length > 0) {
      filterValues.startDateTimeUtc = selectedDateRange[0].toISOString();
      filterValues.endDateTimeUtc = selectedDateRange[1].toISOString();
    }

    this.setState({ loading: true });

    getTaxPayables({ filterValues, sort })
      .then(function(result = {}) {
        let { data = [] } = result;
        data = data.map((obj) => ({ ...obj, key: generateCode() }));

        const totalTaxPayable =
          data[0] && data[0].totalTaxPayable ? data[0].totalTaxPayable : 0;
        _this.setState({
          loading: false,
          data: { totalTaxPayable: totalTaxPayable, taxPayables: [...data] },
        });
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });
        console.log(err);
      });
  };
  onDateRangeChange = (dates) => {
    if (!dates || dates.length < 2) {
      return;
    }
    const startDate = dates[0] && dates[0].startOf("month");
    const endDate = dates[1] && dates[1].endOf("month");

    const updatedDateRangeArray = [startDate, endDate];

    this.setState({ selectedDateRange: updatedDateRangeArray });
  };

  resetTableState = (options, callback) => {
    const { sort = this.state.sort } = options;
    this.setState(
      {
        sort: sort || {},
      },
      callback
    );
  };
  onDetail = (options = {}) => {
    const { history, match } = this.props;
    const { year = "", month = "", taxType = "", taxPercentage = "" } = options;

    history.push(
      constructUrl({
        match,
        to: `/reports/taxPayableDetails?year=${year}&month=${month}&taxType=${taxType}&taxPercentage=${taxPercentage}`,
        isNested: false,
      })
    );
  };

  render() {
    const columns = this.columns;
    const {
      loading = false,
      data = {},
      initialValues = {},
      selectedDateRange = [],
    } = this.state;
    const { totalTaxPayable, taxPayables = [] } = data;
    const _this = this;
    return (
      <div className="report-container">
        <Row>
          <Col xs={24}>
            <Row>
              <Col xs={12}>
                <PageTitle>Tax Report</PageTitle>
              </Col>
              <Divider />
              <br />
            </Row>
          </Col>
          <Col xs={24} xl={18}>
            <Filters
              initialValues={initialValues}
              onDateRangeChange={this.onDateRangeChange}
              onSearch={(filterValues) => {
                _this.resetTableState(
                  { filterValues: filterValues },
                  _this.handleFetch
                );
              }}
            />
          </Col>
          <Col xs={24}>
            <Divider />
            <br />
          </Col>
          <Col xs={24}>
            <Table
              allowCsvExport={true}
              clientSidePaging={true}
              style={{ marginBottom: 20 }}
              loading={loading}
              columns={columns}
              dataSource={taxPayables}
              footer={<Footer totalTaxPayable={totalTaxPayable} />}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withNamespaces()(TaxPayables)));
