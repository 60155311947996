import React, { useEffect, useRef, useState, useContext } from "react";
import authApp from "./base.js";
import { getUniqueCompanyNameFromEmail } from "~/_utils";
import { SESSIONSTORAGE } from "~/_utils/consts";
import { user } from "../_backend/samples/loginSuccess";

export const AuthContext = React.createContext();

export const forceTokenRefresh = () => {
  return new Promise(function(resolve, reject) {
    try {
      const currentUser = authApp.auth().currentUser;
      if (!currentUser) {
        resolve();
      }
      currentUser.getIdToken(true).then(() => {
        resolve();
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    authApp.auth().onIdTokenChanged((currentUser) => {
      const isAuthenticated = !!currentUser;

      const uniqueCompanyName =
        sessionStorage.getItem(SESSIONSTORAGE.uniqueCompanyName) || "";
      //User should already be signed-in via onAuthStateChanged
      if (isAuthenticated !== true || uniqueCompanyName === "") {
        return;
      }
      currentUser.getIdTokenResult &&
        currentUser.getIdTokenResult().then((tokenResult) => {
          const updatedCurrentUser = {
            ...currentUser,
            ...user,
            isAuthenticated: isAuthenticated,
            claims: tokenResult.claims,
          };
          setCurrentUser({
            ...updatedCurrentUser,
          });

          setPending(false);
        });
    });

    authApp.auth().onAuthStateChanged((currentUser) => {
      const isAuthenticated = !!currentUser;

      if (isAuthenticated === true) {
        currentUser.getIdTokenResult &&
          currentUser.getIdTokenResult().then((tokenResult) => {
            const updatedCurrentUser = {
              ...currentUser,
              ...user,
              isAuthenticated: isAuthenticated,
              claims: tokenResult.claims,
            };
            setCurrentUser({
              ...updatedCurrentUser,
            });

            setPending(false);

            const uniqueCompanyName = getUniqueCompanyNameFromEmail(
              currentUser.email
            );
            sessionStorage.setItem(
              SESSIONSTORAGE.uniqueCompanyName,
              uniqueCompanyName
            );
          });
      } else {
        setCurrentUser({
          ...currentUser,
          isAuthenticated: isAuthenticated,
        });
        setPending(false);
      }
    });
  }, []);

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
