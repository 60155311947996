import React from "react";
import "./index.scss";
import Logo from "~/components/Logo";
import { Form, Row, Col } from "antd";
import { Button, Input, Typography } from "antd";
import { LockOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";
import viewModel from "./viewModel";
import FormItem from "~/components/Form/FormItem";
import Link from "~/components/Link";
import { withRouter } from "react-router-dom";
import { constructUrl, HelpPageUrl } from "~/config";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { forgotPassword } from "~/actions/userActions";
import { propName } from "~/_utils";
import Recaptcha from "~/components/Common/Recaptcha";
import sendSuccessful from "~/assets/successful-send.png";
const { Text } = Typography;

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { formRef: null, success: false };
    this.handleSubmit = this.handleSubmit.bind();
    this.setFormRef = this.setFormRef.bind();
    this.forgotPassword = this.forgotPassword.bind();
  }
  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  handleSubmit = (values = {}) => {
    // console.log("Received values of form: ", values);
    this.forgotPassword(values);
  };
  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  forgotPassword = (values = {}) => {
    const _this = this;

    this.setState({
      loading: true,
    });

    const { formRef } = this.state;
    const { forgotPassword, history, match } = this.props;
    values.recaptchaToken = formRef.getFieldValue("recaptchaToken");
    console.log("values:", values);
    forgotPassword(values)
      .then(function() {
        console.log("success");
        _this.setState({ success: true, loading: false });
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };

  render() {
    const { formRef, loading = false, success = false } = this.state;

    if (success === true) {
      return (
        <div className="forgotPassword-form-container">
          <div className="forgotPassword-success">
            <Row gutter={[16, 16]}>
              <Col style={{ width: "130px" }}>
                <img
                  src={sendSuccessful}
                  style={{ width: "109px" }}
                  alt="sendSuccessful"
                />
              </Col>
              <Col style={{ width: "65%", top: "70px" }}>
                <Text>
                  You'll get an email with further instructions. If you don't
                  hear from us in the next 15 minutes, please double check your
                  spam folder.
                </Text>
              </Col>
            </Row>
            <br />
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Text>
                {`If you need any more help, you can write to support@cloudwinkel.com`}
              </Text>
            </div>
            <Row gutter={[16, 16]}>
              <Col>
                <Link to="/Login" keepQueryParams={true}>
                  <Trans i18nKey="BackToLogin">Back to login</Trans>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    return (
      <div className="forgotPassword-form-container">
        <Form
          validateTrigger="onBlur"
          onFinish={this.handleSubmit}
          className="forgotPassword-form"
          initialValues={{}}
          ref={this.setFormRef}
        >
          <Logo />
          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.uniqueCompanyName)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.uniqueCompanyName)]
            }
          >
            {({ options }) => <Input prefix={<ShopOutlined />} {...options} />}
          </FormItem>
          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.userName)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.userName)]
            }
          >
            {({ options }) => <Input prefix={<UserOutlined />} {...options} />}
          </FormItem>
          <Form.Item>
            <Recaptcha form={formRef} isFormSubmitted={loading === true}>
              <Button
                type="primary"
                htmlType="submit"
                className="forgotPassword-form-button"
                loading={loading}
              >
                <Trans i18nKey="Submit">Submit</Trans>
              </Button>
            </Recaptcha>
            <Row gutter={[16, 16]}>
              <Col>
                <Link to="/Login" keepQueryParams={true}>
                  <Trans i18nKey="Login">Login</Trans>
                </Link>
              </Col>
              <Col>{"|"}</Col>
              <Col>
                <Link to="/Signup" keepQueryParams={true}>
                  <Trans i18nKey="RegisterNow">Register now</Trans>
                </Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { forgotPassword })(
  withRouter(withNamespaces()(ForgotPasswordForm))
);
