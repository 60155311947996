import * as firebase from "firebase/app";
import "firebase/auth";

export const auth = firebase.auth;

const authApp = firebase.initializeApp({
  // apiKey: "AIzaSyANlkkpTnsDeQ1bdmlvUPVcdhxtrMq7jxk", //process.env.REACT_APP_FIREBASE_KEY,
  // authDomain: "cloudretail-prod.firebaseapp.com", //process.env.REACT_APP_FIREBASE_DOMAIN,
  // databaseURL: "https://react-firebase-fc06e.firebaseio.com", //process.env.REACT_APP_FIREBASE_DATABASE,
  // projectId: "cloudretail-prod", //process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: "cloudretail-prod.appspot.com", //process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: "449287628083", //process.env.REACT_APP_FIREBASE_SENDER_ID,
  // appId: "1:449287628083:web:7ee85b370d8797beba8a46", //process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: "G-V5MLTZRCBK", //process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export default authApp;
