import React from "react";
import { ArrowLeftOutlined, PrinterOutlined } from "@ant-design/icons";
import { Row, Col, Typography } from "antd";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, withNamespaces } from "react-i18next";
import ReactToPrint from "react-to-print";

import {
  priceFloat,
  price,
  getCurrentDate,
  getCurrentDateUtcIso,
  generateCode,
  localStorageGetItem,
  translate,
} from "~/_utils";
import {
  PAYMENTMETHODS,
  PAYMENTSTATUS,
  LOCALSTORAGE,
  TRANSLATION_NAMESPACE,
} from "~/_utils/consts";
import { constructUrl } from "~/config";
import Button from "~/components/Common/Button";
import AmountToPay from "./AmountToPay";
import ActionButtons from "./ActionButtons";
import Receipt from "./Receipt";
import { completeSale, changeCustomer } from "~/actions/sellActions";
import SearchCustomer from "~/components/Sell/Bucket/SearchCustomer";
import Prompt from "~/components/Common/Prompt";

import { pay } from "~/actions/sellActions";

import "./index.scss";

const { Title, Text } = Typography;

const getPaymentJson = (props) => {
  let { paymentMethod, paymentAmount, paymentCode = generateCode() } = props;

  return `{"salePaymentCode": "${paymentCode}", "paymentMethod": ${paymentMethod}, "amount": ${paymentAmount}}`;
};

class Pay extends React.Component {
  constructor(props) {
    super(props);
    let {
      totalAmount,
      customer,
      change = 0,
      amountPaid,
      payments = [],
    } = props.sale;

    const cashBackPayments = payments.filter((p) => p.amount < 0) || [];
    const totalCashBack =
      cashBackPayments
        .map((p) => (p && p.amount ? p.amount : 0))
        .reduce((a, b) => a + b, 0) * -1;

    let totalAmountToPay = priceFloat(totalAmount + totalCashBack - amountPaid);

    //f item is removed and need to pay back then show it as chnage to give back
    if (totalAmountToPay < 0) {
      change = -1 * totalAmountToPay;
      totalAmountToPay = 0;

      const changeAsNegativePayment = getPaymentJson({
        paymentMethod: PAYMENTMETHODS.CASH,
        paymentAmount: -1 * change,
      });

      payments.push(JSON.parse(changeAsNegativePayment));
    }

    this.state = {
      amountToPay: priceFloat(totalAmountToPay),
      updatedTotalAmount: priceFloat(totalAmountToPay),
      originalTotalAmount: priceFloat(totalAmount),
      amountLeftToPay: priceFloat(totalAmountToPay),
      amountPaid: priceFloat(amountPaid),
      payments: payments,
      change: change,
      customer: customer,
    };

    this.onTotalAmountChange = this.onTotalAmountChange.bind();
    this.onPayment = this.onPayment.bind();
    this.onSaleComplete = this.onSaleComplete.bind();
    this.onCustomerSelect = this.onCustomerSelect.bind();
    this.scrollBucketContainerToBottom = this.scrollBucketContainerToBottom.bind();
    this.goBack = this.goBack.bind();
    this.updateAmounts = this.updateAmounts.bind();
    this.deletePayment = this.deletePayment.bind();
    this.parkSaleToStore = this.parkSaleToStore.bind();
    this.navigateBackToSell = this.navigateBackToSell.bind();
  }

  componentDidMount() {
    this.scrollBucketContainerToBottom();
  }

  onTotalAmountChange = (value) => {
    let { updatedTotalAmount } = this.state;
    value = priceFloat(value);

    this.setState({
      amountToPay: value,
      payment: value,
      amountLeftToPay: updatedTotalAmount - value,
    });
  };

  onPayment = (paymentMethod) => {
    let {
      amountToPay, //current paid amount
      amountPaid, // total amount paid
      amountLeftToPay,
      payments = [],
      originalTotalAmount,
    } = this.state;

    const paymentAmount = priceFloat(amountToPay);
    amountPaid = priceFloat(amountPaid + paymentAmount);

    const change = priceFloat(amountPaid - originalTotalAmount);
    const cashBackPayments = payments.filter((p) => p.amount < 0) || [];
    const totalCashBack =
      cashBackPayments
        .map((p) => (p && p.amount ? p.amount : 0))
        .reduce((a, b) => a + b, 0) * -1;

    const remainingChange = change - totalCashBack;

    const updatedTotalAmount =
      remainingChange > 0 ? 0 : priceFloat(amountLeftToPay);

    const payment = getPaymentJson({
      paymentMethod: paymentMethod,
      paymentAmount: paymentAmount,
    });

    const updatedPayments = [...payments];
    updatedPayments.push(JSON.parse(payment));

    if (remainingChange > 0) {
      const changeAsNegativePayment = getPaymentJson({
        paymentMethod: PAYMENTMETHODS.CASH,
        paymentAmount: -1 * remainingChange,
      });

      updatedPayments.push(JSON.parse(changeAsNegativePayment));
    }

    this.setState({
      amountPaid: amountPaid,
      amountToPay: updatedTotalAmount,
      updatedTotalAmount: updatedTotalAmount,
      amountLeftToPay: updatedTotalAmount,
      payment: 0,
      change: remainingChange,
      payments: [...updatedPayments],
    });

    this.scrollBucketContainerToBottom();
  };
  navigateBackToSell = () => {
    let { match, history } = this.props;
    history.push(constructUrl({ match, to: "/Sell?isSaleInProgress=true" }));
  };

  onSaleComplete = (e, autoNavigateToSell = true) => {
    const _this = this;

    e && e.preventDefault();

    this.setState({
      loading: true,
      status: PAYMENTSTATUS.COMPLETED,
    });

    let { match, history, sale, completeSale } = this.props;
    const { payments, updatedTotalAmount, customer, amountPaid } = this.state;

    sale = { ...sale, customer: customer };

    const payload = {
      ...sale,
      payments,
      amountPaid: amountPaid,
      remainingAmountToPay: updatedTotalAmount,
      date: sale.date || getCurrentDate(),
      saleDateTimeUtc: sale.saleDateTimeUtc || getCurrentDateUtcIso(),
      status: PAYMENTSTATUS.COMPLETED,
    };

    completeSale(payload)
      .then(function(result) {
        autoNavigateToSell === true && _this.navigateBackToSell();
      })
      .catch(function() {
        _this.setState({
          loading: false,
        });
      });
  };

  componentWillUnmount() {
    this.parkSaleToStore();
  }
  parkSaleToStore = () => {
    const { pay, sale = {} } = this.props;
    const {
      payments,
      updatedTotalAmount,
      customer,
      amountPaid,
      status,
    } = this.state;

    if (status === PAYMENTSTATUS.COMPLETED) {
      return pay({});
    }

    const updatedSale = { ...sale, customer: customer };

    const payload = {
      ...updatedSale,
      isSaleInProgress: true,
      payments,
      amountPaid: amountPaid,
      remainingAmountToPay: updatedTotalAmount,
      date: getCurrentDate(),
    };

    return pay(payload);
  };
  goBack = (e) => {
    e.preventDefault();
    let { history } = this.props;

    this.parkSaleToStore().then(function(result) {
      history.goBack();
    });
  };

  onCustomerSelect = (value) => {
    const isCustomerSelected = value && Object.keys(value).length > 0;
    let { payments = [], change } = this.state;

    let updatedPayments = payments;
    let totalCreditAmount = 0;

    if (!isCustomerSelected) {
      //if customer is removed then remove all credit payments, and paid amounts in credit
      const creditPayments = payments.filter(
        (payment) => payment.paymentMethod === PAYMENTMETHODS.CREDIT
      );
      totalCreditAmount =
        (creditPayments &&
          creditPayments.reduce(
            (accumulator, creditPayment) => accumulator + creditPayment.amount,
            0
          )) ||
        0;
      updatedPayments =
        payments.filter(
          (payment) => payment.paymentMethod !== PAYMENTMETHODS.CREDIT
        ) || [];

      if (totalCreditAmount > 0) {
        this.updateAmounts({ amountToAdjust: totalCreditAmount });
      }
    }

    const { changeCustomer } = this.props;

    this.setState({
      customer: value,
      payments: updatedPayments,
      change: updatedPayments.length !== payments.length ? 0 : change, // if payments changed then don't show GIVE change
    });

    changeCustomer({ customer: value });
  };

  updateAmounts = (options = {}) => {
    const { amountToAdjust = 0, reset = false } = options;
    let {
      amountPaid = 0,
      amountToPay = 0,
      originalTotalAmount = 0,
    } = this.state;

    if (amountToAdjust === 0) {
      return;
    }

    if (reset === true) {
      console.log("reset");
      this.setState({
        amountPaid: 0,
        updatedTotalAmount: originalTotalAmount,
        amountToPay: originalTotalAmount,
        amountLeftToPay: originalTotalAmount,
      });
      return;
    }

    const isCashBack = amountToAdjust < 0;
    amountPaid = isCashBack ? amountPaid : amountPaid - amountToAdjust;
    const updatedTotalAmount = amountToPay + amountToAdjust;
    amountToPay = updatedTotalAmount;
    const amountLeftToPay = amountToPay;

    this.setState({
      amountPaid: priceFloat(amountPaid),
      updatedTotalAmount: priceFloat(updatedTotalAmount),
      amountToPay: priceFloat(amountToPay),
      amountLeftToPay: priceFloat(amountLeftToPay),
    });
  };

  deletePayment = (options = {}) => {
    const { paymentCode } = options;
    const { payments = [] } = this.state;

    if (!paymentCode) {
      return;
    }

    const deletedPayment = (payments.filter(
      (x) => x.salePaymentCode === paymentCode
    ) || [])[0];

    const updatedPayments =
      payments.filter((x) => x.salePaymentCode !== paymentCode) || [];

    this.updateAmounts({
      amountToAdjust: deletedPayment.amount,
      reset: updatedPayments.length === 0,
    });
    this.setState({ payments: [...updatedPayments], change: 0 });
  };

  scrollBucketContainerToBottom = () => {
    this.sellBucketContainer.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    const { sale, history, flexSearchCustomer } = this.props;
    const printSettings = JSON.parse(
      localStorageGetItem(LOCALSTORAGE.printSettings) || "{}"
    );
    const { autoPrintOnSaleComplete = false } = printSettings;
    const {
      products,
      originalSubTotal,
      subTotal,
      totalItems,
      totalTaxAmount,
      additionalDiscountPercent,
      groupedTaxes,
      taxes,
      saleCode,
      date = getCurrentDate(),
      notes,
      isPriceIncludingTax,
    } = sale;
    const {
      amountToPay,
      amountLeftToPay,
      payments,
      updatedTotalAmount,
      originalTotalAmount,
      change,
      customer = sale.customer,
      loading = false,
      payment,
    } = this.state;

    return (
      <>
        <Row>
          <Col xs={24}>
            <Prompt
              when={products && products.length > 0}
              onlyPromptOnWindowUnload={true}
            />
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              style={{ paddingLeft: "0px" }}
              onClick={this.goBack}
            >
              Sale
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pay-container">
          <Col
            xs={24}
            lg={10}
            xl={8}
            className="sell-bucket-container readonly"
          >
            <Row>
              <Col
                className="sell-bucket-form"
                style={{
                  maxHeight: `calc(100vh - ${200}px)`,
                }}
              >
                <Row className="bottom">
                  <Col xs={24}>
                    <Receipt
                      saleCode={saleCode}
                      products={products}
                      originalSubTotal={originalSubTotal}
                      subTotal={subTotal}
                      isPriceIncludingTax={isPriceIncludingTax}
                      totalItems={totalItems}
                      totalTaxAmount={totalTaxAmount}
                      groupedTaxes={groupedTaxes}
                      taxes={taxes}
                      additionalDiscountPercent={additionalDiscountPercent}
                      originalTotalAmount={originalTotalAmount}
                      payments={payments}
                      updatedTotalAmount={updatedTotalAmount}
                      change={change}
                      date={date}
                      customer={customer}
                      notes={notes}
                      onDeletePayment={this.deletePayment}
                      ref={(el) => (this.componentRef = el)}
                    />
                    <div
                      style={{
                        float: "left",
                        clear: "both",
                        paddingTop: "60px",
                      }}
                      ref={(el) => {
                        this.sellBucketContainer = el;
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={10} xl={8} xxl={6} className="amountToPay-container">
            {change > 0 && (
              <Row style={{ textAlign: "center" }}>
                <Col xs={24}>
                  <Title level={3}>{`Give ${price(change)} change`}</Title>
                </Col>
              </Row>
            )}
            {
              <>
                {updatedTotalAmount > 0 && (
                  <AmountToPay
                    disabled={updatedTotalAmount <= 0}
                    amountToPay={amountToPay}
                    amountLeftToPay={amountLeftToPay}
                    onTotalAmountChange={this.onTotalAmountChange}
                    payment={payment}
                  />
                )}
                <Row>
                  <Col xs={24} style={{ textAlign: "left" }}>
                    <SearchCustomer
                      flexSearch={flexSearchCustomer}
                      defaultSelectedCustomer={customer}
                      onSelect={this.onCustomerSelect}
                    />
                  </Col>
                </Row>
                <ActionButtons
                  disabled={amountToPay <= 0 || !payment || payment === 0}
                  onPayment={this.onPayment}
                  isCustomerSelected={
                    customer && Object.keys(customer).length > 0
                  }
                />
              </>
            }
            {updatedTotalAmount <= 0 && (
              <Row
                gutter={[16, 16]}
                style={{ textAlign: "center", marginTop: "20px" }}
              >
                <Col xs={12}>
                  {autoPrintOnSaleComplete === true ? (
                    <ReactToPrint
                      bodyClass={"receipt-container print"}
                      onBeforeGetContent={(e) => this.onSaleComplete(e, false)}
                      onAfterPrint={this.navigateBackToSell}
                      copyStyles={false}
                      trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        const _this = this;
                        return (
                          <Button
                            loading={loading}
                            type="primary"
                            style={{ width: "100%" }}
                          >
                            {`${translate("CompleteSale", {
                              namespace: TRANSLATION_NAMESPACE.Sell,
                            })}`}
                          </Button>
                        );
                      }}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <Button
                      loading={loading}
                      type="primary"
                      style={{ width: "100%" }}
                      onClick={this.onSaleComplete}
                    >
                      {`${translate("CompleteSale", {
                        namespace: TRANSLATION_NAMESPACE.Sell,
                      })}`}
                    </Button>
                  )}
                </Col>
                <Col xs={12}>
                  <ReactToPrint
                    bodyClass={"receipt-container print"}
                    copyStyles={false}
                    trigger={() => {
                      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                      // to the root node of the returned component as it will be overwritten.
                      return (
                        <Button
                          type="secondary"
                          style={{ width: "100%" }}
                          onClick={this.onPrint}
                          icon={<PrinterOutlined />}
                        >
                          {"Print"}
                        </Button>
                      );
                    }}
                    content={() => this.componentRef}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sale: state.sale,
    flexSearchCustomer: state.offline.flexSearchCustomer,
  };
};

export default connect(mapStateToProps, { completeSale, changeCustomer, pay })(
  withRouter(withNamespaces()(Pay))
);
