import { translateFieldValidation, translate } from "~/_utils";
import {
  FIELD_VALIDATION_TYPE,
  TRANSLATION_NAMESPACE,
  REGEX,
} from "~/_utils/consts";

let viewModel = {
  name: {
    props: {
      placeholder: translate("SupplierName", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        type: "string",
        required: true,
        pattern: new RegExp(REGEX.Name, "g"),
        max: 100,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.name.props.placeholder
          );
        },
      },
    ],
  },
  email: {
    props: {
      placeholder: translate("Email", {
        namespace: TRANSLATION_NAMESPACE.Account,
      }),
    },
    rules: [
      {
        type: "email",
        max: 300,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Invalid,
            viewModel.email.props.placeholder
          );
        },
      },
    ],
  },
  phone: {
    props: {
      placeholder: translate("Phone", {
        namespace: TRANSLATION_NAMESPACE.Account,
      }),
    },
    rules: [
      {
        type: "string",
        pattern: new RegExp(REGEX.Phone, "g"),
        max: 20,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Invalid,
            viewModel.phone.props.placeholder
          );
        },
      },
    ],
  },
  address: {
    props: {
      placeholder: translate("Address", {
        namespace: TRANSLATION_NAMESPACE.Account,
      }),
    },
    rules: [
      {
        type: "string",
        max: 200,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Invalid,
            viewModel.address.props.placeholder
          );
        },
      },
    ],
  },
  description: {
    props: {
      placeholder: translate("Description", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        type: "string",
        max: 300,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Invalid,
            viewModel.description.props.placeholder
          );
        },
      },
    ],
  },
};

export default viewModel;
