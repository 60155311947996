export const SIDE_MENU_TOGGLE = "sideMenu/TOGGLE";
export const LOGIN_SUCCESS = "login/SUCCESS";
export const OFFLINE_READY = "offline/READY";
export const OFFLINE_STATECHANGE = "offline/STATECHANGE";
export const RECORD_SAVE_SUCCESS = "general/SAVE_SUCCESS";
export const RECORD_SAVE_FAILED = "general/SAVE_FAILED";
export const RECORD_DELETE_SUCCESS = "general/DELETE_SUCCESS";
export const RECORD_DELETE_FAILED = "general/DELETE_FAILED";
export const GENERAL_ERROR = "general/ERROR";
export const GENERAL_SUCCESS = "general/SUCCESS";
export const GENERAL_DIALOG = "general/DIALOG";
export const SELL_PAY = "sale/PAY";
export const SELL_PAY_COMPLETESALE = "sale/pay/COMPLETESALE";
export const SELL_PAY_CUSTOMER_CHANGE = "sale/pay/CUSTOMER_CHANGE";
export const VERSION_UPDATE = "general/VERSION_UPDATE";
