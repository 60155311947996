import React from "react";
import { Row, Col, Input, Form, Divider } from "antd";
import { PageTitle } from "~/components/Common/Text";
import { Trans, withNamespaces } from "react-i18next";
import PurchaseInfo from "./PurchaseInfo";
import PurchaseProducts, { CalculatePrices } from "./PurchaseProducts";
import { removeItemFromIndex, treatDateAsUtcDateTime, toFloat } from "~/_utils";
import moment from "moment";
import { getSuppliers } from "~/actions/supplierActions";
import { savePurchase, getPurchase } from "~/actions/purchaseActions";
import { getProduct } from "~/actions/productActions";
import queryString from "query-string";
import { recordSavedFailed } from "~/actions/generalActions";
import Button from "~/components/Common/Button";
import Prompt from "~/components/Common/Prompt";
import Spin from "~/components/Common/Spin";

import {
  reCreateProductsIndex,
  selectProductFromLocalDb,
} from "~/_offline/offlineProducts";
import { prepareOfflineSell } from "~/actions/offlineActions";
import { OFFLINESTATE, HTTPSTATUS } from "~/_utils/consts";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./index.scss";

const GetInitialValues = (purchase = {}) => {
  const purchaseDate = treatDateAsUtcDateTime(purchase.purchaseDate);
  const purchaseProducts = [...(purchase.purchaseProducts || [])];

  const updatedPurchaseProducts = purchaseProducts.map((p, index) => {
    let purchaseProduct = {
      ...p,
      expiryDate: p.expiryDate ? moment(p.expiryDate) : null,
    };
    return purchaseProduct;
  });
  const initialValues = {
    ...purchase,
    purchaseId: purchase.purchaseId,
    notes: purchase.notes,
    updateProductPrice:
      purchase.updateProductPrice === false || purchase.purchaseId
        ? false
        : true, // in edit mode don't set updaeProductPrice by default
    refNumber: purchase.refNumber,
    supplierId: purchase.supplierId,
    purchaseDate: purchaseDate ? moment(purchaseDate) : moment(),

    purchaseProducts: [...updatedPurchaseProducts],
  };

  return initialValues;
};

class Purchase extends React.Component {
  constructor(props) {
    super(props);
    let { purchase } = this.props;
    this.state = { purchase: purchase, formRef: null, suppliers: [] };
    this.setFormRef = this.setFormRef.bind();
    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.reCreateProductsIndex = this.reCreateProductsIndex.bind();
    this.handleSelect = this.handleSelect.bind();
    this.selectProduct = this.selectProduct.bind();
    this.removeProduct = this.removeProduct.bind();
    this.getPurchase = this.getPurchase.bind();
  }
  componentDidMount() {
    const { prepareOfflineSell, location } = this.props;
    const _this = this;
    prepareOfflineSell();
    getSuppliers({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          suppliers: [...data],
        });
      })
      .catch(function(err) {
        console.log(err);
      });

    const queryParams = queryString.parse(location.search);
    const purchaseId = queryParams.purchaseId;

    purchaseId && this.getPurchase(purchaseId);
  }

  getPurchase = (id) => {
    const _this = this;

    getPurchase({ id: id })
      .then(function(result = {}) {
        const purchase = result.data || {};
        const purchaseProducts = purchase.purchaseProducts || [];
        const updatedPurchaseProducts = purchaseProducts.map((p, index) => {
          let purchaseProduct = {
            ...p,
            name: p.productName,
            variant: p.productVariantName,
            barcode: p.productBarCode,
          };
          return purchaseProduct;
        });
        const updatedPurchase = {
          ...purchase,
          purchaseProducts: [...updatedPurchaseProducts],
        };
        _this.setState(
          {
            purchase: updatedPurchase,
            isEditMode: true, //getPurchase is only called in edit mode
          },
          _this.state.formRef.setFieldsValue(GetInitialValues(updatedPurchase))
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };
  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };
  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });

    const { savePurchase } = this.props;
    const { formRef, isEditMode } = this.state;
    const _this = this;

    let updatedPurchaseProducts = values.purchaseProducts || [];
    updatedPurchaseProducts = updatedPurchaseProducts.map(
      ({
        name,
        variant,
        barcode,
        productName,
        productVariantName,
        productBarCode,
        totalTaxableAmount,
        ...restValues
      }) => restValues
    ); //remove property which don;t want to submit

    savePurchase({ ...values, purchaseProducts: [...updatedPurchaseProducts] })
      .then(function(result) {
        _this.setState({
          loading: false,
          isFormChanged: false,
        });

        if (isEditMode === true) {
          formRef.setFieldsValue({
            purchaseId: result.purchaseId,
          });

          //Get all values from server, especially if new purchase products are added then need to associate Ids for them
          _this.getPurchase(result.purchaseId);
        } else {
          _this.setState(
            {
              purchase: {},
            },
            formRef.resetFields
          );
        }
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };
  reCreateProductsIndex = () => {
    const _this = this;
    reCreateProductsIndex()
      .then(function(result) {
        _this.setState({
          flexSearchProduct: result,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };
  handleSelect = (value) => {
    this.selectProduct(value);
  };
  selectProduct = (value) => {
    const _this = this;
    this.setState({
      loading: true,
    });
    const { purchase = {}, formRef } = this.state;
    const { purchaseProducts = [] } = purchase;
    selectProductFromLocalDb(value).then(function(result = {}) {
      const selectedProduct = { ...result };
      const updatedPurchaseProducts = [...purchaseProducts];

      //Update recent selected product with product info (purchasePrice, tax)
      let updatedSelectedProduct = { ...selectedProduct };
      let productPurchaseInfo = {};
      getProduct({ id: selectedProduct.productId, infoOnly: true })
        .then(function(result) {
          const productInfo = result.data || {};
          const taxPercentage = { ...(productInfo.tax || {}) }.percentage;
          const purchasePrice = { ...productInfo }.purchasePrice;
          productPurchaseInfo = {
            purchasePriceExTax: purchasePrice || 0,
            taxPercentage: taxPercentage || 0,
          };
        })
        .catch(function(err) {
          console.log(err);
        })
        .finally(function() {
          _this.setState({
            loading: false,
          });
          updatedPurchaseProducts.push(
            (updatedSelectedProduct = {
              ...updatedSelectedProduct,
              ...productPurchaseInfo,
            })
          );
          _this.setState(
            {
              purchase: {
                ...purchase,
                purchaseProducts: [...updatedPurchaseProducts],
              },
            },
            () => {
              //recent form row will be the new one so set purchase price & tax for it
              let purchaseProductsInForm = Object.assign(
                [],
                formRef.getFieldValue("purchaseProducts")
              );
              const lastIndex = (purchaseProductsInForm.length || 1) - 1;
              purchaseProductsInForm[lastIndex] = {
                ...purchaseProductsInForm[lastIndex],
                ...productPurchaseInfo,
              };

              formRef.setFieldsValue({
                purchaseProducts: [...purchaseProductsInForm],
              });
            }
          );
        });
    });
  };
  removeProduct = (index) => {
    const { purchase = {}, formRef } = this.state;
    if (!formRef) {
      return;
    }

    const { purchaseProducts = [] } = purchase;

    const updatedPurchaseProducts = removeItemFromIndex(
      purchaseProducts,
      index
    );

    //remove from form
    const purchaseProductsInForm = Object.assign(
      [],
      formRef.getFieldValue("purchaseProducts")
    );

    const updatedPurchaseProductsInForm = removeItemFromIndex(
      purchaseProductsInForm,
      index
    );

    formRef.setFieldsValue({
      purchaseProducts: [...updatedPurchaseProductsInForm],
    });

    purchase.purchaseProducts = [...updatedPurchaseProducts];
    this.setState(
      { purchase: { ...purchase } },
      CalculatePrices({ form: formRef })
    );
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //dispatch error message
    this.props.recordSavedFailed("purchase", {
      code: HTTPSTATUS.ValidationError,
    });
  };
  render() {
    const _this = this;
    const {
      formRef,
      suppliers = [],
      loading = false,
      purchase = {},
      isFormChanged = false,
    } = this.state;

    const { flexSearchProduct, offlineState } = this.props;
    const { purchaseProducts } = purchase;

    const initialValues = GetInitialValues(purchase);
    const {
      updateProductPrice = initialValues.updateProductPrice,
    } = this.state;

    return (
      <Row gutter={[4, 8]}>
        <Col xs={24}>
          <>
            <PageTitle>Purchase</PageTitle>
            <Divider />
            <br />
          </>
        </Col>
        <Col xs={24} xl={24} xxl={18}>
          <Spin spinning={loading}>
            <Form
              validateTrigger="onBlur"
              onFinish={this.handleSubmit}
              onFinishFailed={this.onFinishFailed}
              className="purchase-form"
              initialValues={initialValues}
              ref={this.setFormRef}
              onFieldsChange={() => {
                isFormChanged !== true &&
                  _this.setState({ isFormChanged: true });
              }}
            >
              <Prompt when={isFormChanged} />
              <Row gutter={[4, 8]}>
                <Col xs={24}>
                  <PurchaseInfo
                    form={formRef}
                    initialValues={initialValues}
                    suppliers={suppliers}
                    handleProductSelect={this.handleSelect}
                    flexSearch={
                      offlineState === OFFLINESTATE.InProgress
                        ? undefined
                        : flexSearchProduct
                    }
                    onProductsIndexOutOfSync={this.reCreateProductsIndex}
                    onUpdateProductPriceChange={(updateProductPrice) => {
                      this.setState({ updateProductPrice: updateProductPrice });
                    }}
                  />
                </Col>
                <Col xs={24} style={{ marginTop: "15px" }}>
                  <PurchaseProducts
                    form={formRef}
                    initialValues={initialValues}
                    purchaseProducts={purchaseProducts}
                    onProductRemove={this.removeProduct}
                  />
                </Col>
                <Col xs={24}>
                  <Form.Item style={{ marginBottom: "0px" }}>
                    <Button
                      confirmButtonStyle={{ maxWidth: "215px" }}
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      className="save-button"
                      layout="confirm"
                      confirmText={`Update product price is ${
                        updateProductPrice === true ? "ON" : "OFF"
                      }`}
                      secondaryButtonTitle={`Proceed with${
                        updateProductPrice === true ? "" : "out"
                      } price update`}
                      onClick={() => {
                        formRef.submit();
                      }}
                    >
                      <Trans i18nKey="Save">Save</Trans>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    flexSearchProduct: state.offline.flexSearchProduct,
    offlineReady: state.offline.state === OFFLINESTATE.Ready,
    offlineState: state.offline.state,
  };
};

export default connect(mapStateToProps, {
  prepareOfflineSell,
  savePurchase,
  recordSavedFailed,
})(withRouter(withNamespaces()(Purchase)));
