import React from "react";
import Card from "./Card";
import { getTopProducts } from "~/actions/dashboardActions";
import Spin from "~/components/Common/Spin";
import {
  Chart,
  Axis,
  Tooltip,
  Interval,
  Interaction,
  Coordinate,
} from "bizcharts";
import { translate } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";

const ProductsChart = (props) => {
  const { data = [] } = props;
  return (
    <Chart data={data} height={300} autoFit>
      <Coordinate type="theta" radius={0.8} innerRadius={0.75} />
      <Axis visible={false} />
      <Tooltip showTitle={false} />
      <Interval
        adjust="stack"
        position="value"
        color="type"
        shape="sliceShape"
      />
      <Interaction type="element-single-selected" />
    </Chart>
  );
};

class TopProductsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, topProductData: [] };
  }
  componentDidMount() {
    const _this = this;
    getTopProducts()
      .then(function(result = {}) {
        _this.setState({
          loading: false,
          topProductData: result.data,
        });
      })
      .catch(function(err) {
        _this.setState({ loading: false });
        console.log(err);
      });
  }
  render() {
    const { topProductData = [], loading = true } = this.state;
    const title = `${translate("TopProducts", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })}`;
    const footer = `${translate("TopProductsBasedOnSales", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })
      .replace("{0}", "10")
      .replace("{1}", "1-month")}`;

    return (
      <Card title={title} style={{ height: "375px" }} footer={footer}>
        <Spin spinning={loading}>
          <ProductsChart data={topProductData} />
        </Spin>
      </Card>
    );
  }
}

export default TopProductsChart;
