import React from "react";
import { Button as AntdButton } from "antd";
import ButtonWithInputField from "./ButtonWithInputField";
import ButtonWithConfirmation from "./ButtonWithConfirmation";

const Button = (props) => {
  const { layout, children } = props;
  if (layout === "inputfield") {
    return <ButtonWithInputField {...props}>{children}</ButtonWithInputField>;
  }
  if (layout === "confirm") {
    return (
      <ButtonWithConfirmation {...props}>{children}</ButtonWithConfirmation>
    );
  }
  return <AntdButton {...props}>{children}</AntdButton>;
};

export default Button;
