import React from "react";
import ConnectedParticles from "../components/ConnectedParticles";
import "./EmptyLayout.scss";
import Notification from "~/components/Notification";

const EmptyLayout = (props) => {
  return (
    <div className="emptylayout-main-container">
      {props.children}
      <ConnectedParticles />
      <Notification />
    </div>
  );
};

export default EmptyLayout;
