import { translateFieldValidation, translate } from "~/_utils";
import { FIELD_VALIDATION_TYPE, TRANSLATION_NAMESPACE } from "~/_utils/consts";

let viewModel = {
  unitsPerPack: {
    props: {
      placeholder: translate("UnitsPerPack", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.unitsPerPack.props.placeholder
          );
        },
      },
    ],
  },
  packPurchasePriceExTax: {
    props: {
      placeholder: translate("PackPurchasePriceExTax", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.packPurchasePriceExTax.props.placeholder
          );
        },
      },
    ],
  },
  packPurchaseQuantity: {
    props: {
      placeholder: translate("PackPurchaseQuantity", {
        namespace: TRANSLATION_NAMESPACE.Product,
      }),
    },
    rules: [
      {
        required: true,
        get message() {
          return translateFieldValidation(
            FIELD_VALIDATION_TYPE.Required,
            viewModel.packPurchaseQuantity.props.placeholder
          );
        },
      },
    ],
  },
};

export default viewModel;
