import React from "react";
import Card from "./Card";
import { getProductsStockStatistics } from "~/actions/dashboardActions";
import { FallOutlined, FileDoneOutlined } from "@ant-design/icons";
import Spin from "~/components/Common/Spin";
import { translate, price, toFloat } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";
import { constructUrl } from "~/config";

class ProductsStockCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stockCost: "",
      stockWorth: "",
      totalProducts: "",
    };
    this.onClick = this.onClick.bind();
  }
  componentDidMount() {
    const _this = this;
    getProductsStockStatistics()
      .then(function(result = {}) {
        _this.setState({
          loading: false,
          ...result.data,
        });
      })
      .catch(function(err) {
        _this.setState({ loading: false });
        console.log(err);
      });
  }
  onClick = () => {
    const { history, match } = this.props;
    history.push(
      constructUrl({
        match,
        to: `/reports/stockDetails`,
      })
    );
  };
  render() {
    const {
      stockCost = "",
      stockWorth = "",
      totalProducts = "",
      loading = true,
    } = this.state;
    const title = `${translate("StockWorth", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })}`;
    const footer = `${translate("StockCost", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })}: ${price(stockCost)} | ${translate("Products", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })}: ${totalProducts}`;

    return (
      <Card
        icon={<FileDoneOutlined />}
        iconStyle={{ color: "#00c9a7", background: "#00c9a71a" }}
        title={title}
        footer={loading === true ? "" : footer}
        onClick={this.onClick}
      >
        <Spin spinning={loading}>
          <h1 className="font-weight-bold">{price(stockWorth)}</h1>
        </Spin>
      </Card>
    );
  }
}

export default ProductsStockCard;
