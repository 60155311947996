import React from "react";
import { TagOutlined } from "@ant-design/icons";
import { Row, Col, Input, Form, Divider, Button } from "antd";
import queryString from "query-string";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  saveCustomerPayment,
  getCustomerPayment,
} from "~/actions/customerActions";

import { PageTitle } from "~/components/Common/Text";
import FormItem from "~/components/Form/FormItem";
import DatePicker from "~/components/Common/DatePicker";
import SearchCustomer from "~/components/Sell/Bucket/SearchCustomer";
import PriceInput from "~/components/Common/Input/PriceInput";
import moment from "moment";

import { propName, treatDateAsUtcDateTime } from "~/_utils";
import viewModel from "./viewModel";

const { TextArea } = Input;

const GetInitialValues = (customerPayment = {}) => {
  const paymentDateTime = treatDateAsUtcDateTime(
    customerPayment.paymentDateTimeUtc
  );

  const initialValues = {
    customerPaymentId: customerPayment.customerPaymentId,
    customerId: customerPayment.customerId,
    paymentDateTimeUtc: paymentDateTime ? moment(paymentDateTime) : moment(),
    notes: customerPayment.notes,
    amount: customerPayment.amount,
  };

  return initialValues;
};

class CustomerPayment extends React.Component {
  constructor(props) {
    super(props);

    let { location = {}, customerPayment, customerPaymentId } = this.props;

    this.state = {
      customerPayment: customerPayment,
      formRef: null,
      isEditMode: !!customerPaymentId,
    };

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.getCustomerPayment = this.getCustomerPayment.bind();
    this.setFormRef = this.setFormRef.bind();
  }

  getCustomerPayment = (id) => {
    const _this = this;

    getCustomerPayment({ id: id })
      .then(function(result) {
        const paymentDateTimeUtc = treatDateAsUtcDateTime(
          result.data.paymentDateTimeUtc
        );
        let data = { ...result.data };
        data.paymentDateTimeUtc = paymentDateTimeUtc
          ? moment(paymentDateTimeUtc)
          : moment();

        _this.setState(
          {
            customerPayment: data,
            isEditMode: true, //getCustomerPayment is only called in edit mode
          },

          _this.state.formRef.setFieldsValue(data)
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  componentDidMount() {
    const {
      location,
      customerPaymentId: customerPaymentIdFromProps,
    } = this.props;

    const queryParams = queryString.parse(location.search);
    const customerPaymentId =
      queryParams.customerPaymentId || customerPaymentIdFromProps;

    customerPaymentId && this.getCustomerPayment(customerPaymentId);
  }
  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });

    const { saveCustomerPayment, onSave } = this.props;
    const { formRef, isEditMode } = this.state;
    const _this = this;

    saveCustomerPayment(values)
      .then(function(result) {
        onSave && onSave(result);

        _this.setState({
          loading: false,
        });

        if (isEditMode === true) {
          formRef.setFieldsValue({
            customerPaymentId: result.customerPaymentId,
          });
          _this.setState({
            customerPayment: result,
          });
        } else {
          _this.setState(
            {
              customerPayment: {},
              defaultSelectedCustomer: {},
            },
            formRef.resetFields
          );
        }
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const { showTitle, flexSearchCustomer } = this.props;
    const {
      formRef,
      loading = false,
      customerPayment = {},
      defaultSelectedCustomer = {},
      isEditMode,
    } = this.state;
    const initialValues = GetInitialValues(customerPayment);

    return (
      <Row gutter={[16, 8]}>
        <Col xs={24}>
          {showTitle === true && (
            <>
              <PageTitle>Customer Payment</PageTitle>
              <Divider />
              <br />
            </>
          )}
          <Col xs={24}>
            <Form
              validateTrigger="onBlur"
              onFinish={this.handleSubmit}
              onFinishFailed={this.onFinishFailed}
              className="customerPayment-form"
              initialValues={initialValues}
              ref={this.setFormRef}
            >
              <Row gutter={[16, 8]}>
                <Col xs={24}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.customerId)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.customerId)]
                    }
                  >
                    {({ options }, setValue) => {
                      return isEditMode === true ? (
                        <>
                          {customerPayment.customerName}
                          <Input type="hidden" {...options} />
                        </>
                      ) : (
                        <SearchCustomer
                          flexSearch={flexSearchCustomer}
                          defaultSelectedCustomer={defaultSelectedCustomer}
                          onSelect={(value) => {
                            setValue(value.customerId);
                          }}
                          showAddCustomer={false}
                        />
                      );
                    }}
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(
                      initialValues,
                      (o) => o.paymentDateTimeUtc
                    )}
                    viewModelKeyValue={
                      viewModel[
                        propName(initialValues, (o) => o.paymentDateTimeUtc)
                      ]
                    }
                  >
                    {({ options }, setValue) => (
                      <DatePicker showTime disabled={isEditMode === true} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.amount)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.amount)]
                    }
                  >
                    {({ placeholder, ...restOptions }) => (
                      <PriceInput
                        disabled={isEditMode === true}
                        style={{ width: "100%" }}
                        {...restOptions}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.notes)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.notes)]
                    }
                  >
                    {({ options }) => (
                      <TextArea
                        rows={2}
                        style={{ resize: "none" }}
                        {...options}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="save-button"
                      loading={loading}
                    >
                      <Trans i18nKey="Save">Save</Trans>
                    </Button>
                  </Form.Item>
                </Col>

                <FormItem
                  form={formRef}
                  propertyName={propName(
                    initialValues,
                    (o) => o.customerPaymentId
                  )}
                  viewModelKeyValue={propName(
                    initialValues,
                    (o) => o.customerPaymentId
                  )}
                >
                  {(options) => <Input type="hidden" {...options} />}
                </FormItem>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  flexSearchCustomer: state.offline.flexSearchCustomer,
});

export default connect(mapStateToProps, { saveCustomerPayment })(
  withRouter(withNamespaces()(CustomerPayment))
);
