import React from "react";
import {
  Row,
  Col,
  Button as AntdButton,
  Menu,
  Dropdown,
  Typography,
} from "antd";
import Button from "./";
const { Text } = Typography;

const menuWithText = (props) => {
  const {
    onClick,
    secondaryButtonTitle = "ok",
    confirmText = "Are you sure ?",
    confirmButtonStyle = { width: "100%" },
  } = props;

  return (
    <Menu>
      <Menu.ItemGroup>
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Text>{confirmText} </Text>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Button
                type="primary"
                style={{ marginTop: "8px", ...confirmButtonStyle }}
                onClick={() => onClick()}
              >
                {secondaryButtonTitle}
              </Button>
            </Col>
          </Row>
        </div>
      </Menu.ItemGroup>
    </Menu>
  );
};

class ButtonWithConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = { visible: false };
    this.onClick = this.onClick.bind();
    this.onDropdownClick = this.onDropdownClick.bind();
    this.handleVisibleChange = this.handleVisibleChange.bind();
  }
  onClick = (options) => {
    const { onClick } = this.props;

    onClick();

    this.setState({
      visible: false,
    });
  };
  onDropdownClick = () => {
    const { visible } = this.state;
    this.setState({
      visible: !visible,
    });
  };
  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };
  render() {
    const {
      children,
      secondaryButtonTitle,
      confirmText,
      onClick,
      confirmButtonStyle,
      ...otherProps
    } = this.props;

    return (
      <Dropdown
        overlay={menuWithText({
          secondaryButtonTitle: secondaryButtonTitle,
          confirmText: confirmText,
          onClick: this.onClick,
          confirmButtonStyle: confirmButtonStyle,
        })}
        onClick={this.onDropdownClick}
        onVisibleChange={this.handleVisibleChange}
        visible={this.state.visible}
        trigger={["click"]}
        {...otherProps}
      >
        <AntdButton {...otherProps}>{children}</AntdButton>
      </Dropdown>
    );
  }
}

export default ButtonWithConfirmation;
