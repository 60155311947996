import React from "react";
import { Divider, Space, Badge, Row, Col, notification } from "antd";
import {
  BellOutlined,
  NotificationOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import Button from "~/components/Common/Button";
import { localStorageSetItem } from "~/_utils";
import { Menu, Dropdown } from "antd";
import { Trans, withNamespaces } from "react-i18next";
import { TRANSLATION_NAMESPACE, LOCALSTORAGE } from "~/_utils/consts";
import { getNotifications } from "~/actions/generalActions";
import { connect } from "react-redux";

const notificationType = {
  releaseNotes: 1,
};

const MenuItemContent = (options = {}) => {
  const { title = "", text = "", url = "", isRead = false } = options;
  return (
    <div>
      <Badge dot={isRead === false}>
        <div className="menuItemText">
          <span style={{ fontWeight: "bold" }}>{title}</span>
        </div>
      </Badge>
      <div className="menuItemText">
        <span>{text}</span>
      </div>
    </div>
  );
};

const MenuItemContentFooter = (options = {}) => {
  const { url = "", isRead = false, onRead } = options;
  return (
    <Row className="menuItemContentFooter">
      <Col xs={12}>
        {url && (
          <a href={url} target="_blank">
            <span>
              <ReadOutlined /> Read more{" "}
            </span>
          </a>
        )}
      </Col>
      <Col xs={12}>
        {isRead !== true && (
          <Button
            style={{ width: "100%", textAlign: "right" }}
            type="link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRead();
            }}
          >
            Mark as read
          </Button>
        )}
      </Col>
    </Row>
  );
};

const menu = (props = {}) => {
  const { notifications = {}, onRead, totalNotificationsCount = 0 } = props;
  const { releaseNotes = [] } = notifications;

  return (
    <Menu className="topMenu notificationMenu">
      {/* Below code for Menu.Item cannot be in a separate component otherwise it renders multiple times*/}
      {releaseNotes && releaseNotes.length > 0 && (
        <>
          <Menu.Item
            key={"newFeatures"}
            disabled={true}
            className="menuItemTitle"
          >
            <Row>
              <Col xs={12}>
                <span>
                  <NotificationOutlined />
                  <span style={{ marginLeft: "8px" }}>
                    <Trans i18nKey="NewFeatures">New features</Trans>
                  </span>
                </span>
              </Col>
              <Col xs={12}>
                {totalNotificationsCount > 0 && (
                  <Button
                    style={{ width: "100%", textAlign: "right" }}
                    type="link"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onRead({
                        type: notificationType.releaseNotes,
                        index: -1,
                      });
                    }}
                  >
                    Mark all as read
                  </Button>
                )}
              </Col>
            </Row>
            <Divider />
          </Menu.Item>
          {releaseNotes.map((releaseNote = {}, index) => {
            const { url = "", epoch, isRead = false } = releaseNote;

            return (
              <Menu.Item
                key={`${index}`}
                style={{
                  marginLeft: "8px",
                }}
                onClick={() => {
                  onRead({
                    type: notificationType.releaseNotes,
                    id: epoch,
                    index: index,
                  });
                }}
              >
                {url !== "" ? (
                  <a href={url} target="_blank">
                    <MenuItemContent {...releaseNote} />
                  </a>
                ) : (
                  <MenuItemContent {...releaseNote} />
                )}
                <MenuItemContentFooter
                  url={url}
                  isRead={isRead}
                  onRead={() => {
                    onRead({
                      type: notificationType.releaseNotes,
                      id: epoch,
                      index: index,
                    });
                  }}
                />
              </Menu.Item>
            );
          })}
        </>
      )}
    </Menu>
  );
};

const NotificationMenuComponent = (options = {}) => {
  const { totalNotificationsCount = 0 } = options;
  return (
    <div className="menuContainer notificationMenuContainer">
      <Dropdown
        overlay={menu(options)}
        trigger={["click"]}
        arrow={{ pointAtCenter: true }}
      >
        <Space style={{ cursor: "pointer" }}>
          <Button
            type="link"
            className="avatarContainer"
            style={{ width: "50px" }}
            icon={
              <>
                <Badge
                  count={totalNotificationsCount}
                  overflowCount={10}
                  size="small"
                >
                  <BellOutlined style={{ width: "100%" }} />
                </Badge>
              </>
            }
          >
            <span className="buttonText ellipsis"></span>
          </Button>
        </Space>
      </Dropdown>
    </div>
  );
};

const countUnReadObjects = (arr = []) => {
  return arr.reduce((count, obj) => {
    if (!obj.hasOwnProperty("isRead") || obj.isRead !== true) {
      return count + 1;
    }
    return count;
  }, 0);
};

class NotificationMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { processedActionId: props.actionId || "", notifications: {} };
    this.getNotifications = this.getNotifications.bind();
    this.getUnreadNotificationsCount = this.getUnreadNotificationsCount.bind();
  }
  getNotifications = (options = {}) => {
    const _this = this;
    const { isOnline = true, getNotifications } = this.props;
    if (isOnline === false) {
      return;
    }
    getNotifications()
      .then(function(data = {}) {
        _this.setState({
          totalNotificationsCount: _this.getUnreadNotificationsCount(data),
          notifications: { ...data },
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };
  getUnreadNotificationsCount = (options = {}) => {
    const { releaseNotes = [] } = options;
    const releaseNotesUnreadCount = countUnReadObjects(releaseNotes);
    const totalUnreadCount = releaseNotesUnreadCount;
    return totalUnreadCount;
  };
  onRead = (options = {}) => {
    const _this = this;
    const {
      id = "",
      type = notificationType.releaseNotes,
      index = 0,
    } = options;
    console.log("Read notification id :", id);
    debugger;
    const { notifications = {} } = this.state;
    if (type === notificationType.releaseNotes) {
      const { releaseNotes = [] } = notifications;
      const updatedReleaseNotes = releaseNotes.map((obj, i) => {
        if (index === -1) {
          return { ...obj, ["isRead"]: true };
        }
        return i === index ? { ...obj, ["isRead"]: true } : obj;
      });

      const updatedNotifications = {
        ...notifications,
        releaseNotes: [...updatedReleaseNotes],
      };

      this.setState({
        totalNotificationsCount: _this.getUnreadNotificationsCount(
          updatedNotifications
        ),
        notifications: { ...updatedNotifications },
      });

      localStorageSetItem(
        LOCALSTORAGE.notifications,
        JSON.stringify(updatedNotifications)
      );
    }
  };
  componentDidMount() {
    const intervalSec = 3600; //1-hour
    this.getNotifications();
    setInterval(this.getNotifications, intervalSec * 1000);
  }
  render() {
    return (
      <>
        <NotificationMenuComponent
          {...this.props}
          {...this.state}
          onRead={this.onRead}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getNotifications,
})(withNamespaces([TRANSLATION_NAMESPACE.Common])(NotificationMenu));
