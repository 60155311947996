import {
  recordSavedSuccess,
  recordSavedFailed,
  recordDeleteSuccess,
  recordDeleteFailed,
  generalError,
  generalSuccess,
} from "./generalActions";
import { mutate, query } from "~/_utils/httpClient";
import { LOCALSTORAGE } from "~/_utils/consts";
import { localStorageSetItem } from "~/_utils";

/////////// SALES TAX ///////////////////////////////////////////////////

const SAVE_SALESTAX_COLUMNS = `
      taxId
  `;

const GET_SALESTAXES_COLUMNS = `
        taxId,
        name
        percentage
        `;
const GET_SALESTAX_COLUMNS = GET_SALESTAXES_COLUMNS;

const SAVE_PRINTSETTING_COLUMNS = `
      title,
      header,
      footer,
      autoPrintOnSaleComplete,
      printCustomerName,
			printNotes
  `;

const GET_PRINTSETTING_COLUMNS = `
      title,
      header,
      footer,
      autoPrintOnSaleComplete,
      printCustomerName,
			printNotes
  `;

const SAVE_COMPANYSETTING_COLUMNS = `
  companyName,
  uniqueCompanyName,
  currency
  `;

const GET_COMPANYSETTING_COLUMNS = `
  companyName,
  uniqueCompanyName,
  currency
  `;

const GET_SUBSCRIPTION_COLUMNS = `
subscriptionType,
subscriptionExpiryDateUtc,
subscriptionCurrencyCode,
companyTimeZone,
subscriptionAmount
  `;
const GET_SUBSCRIPTIONPAYMENTS_COLUMNS = `
  subscriptionPaymentId
  amount
  status
  paymentDateTimeUtc
  currencyCode
    `;
const SAVE_SUBSCRIPTION_COLUMNS = `
  subscriptionPaymentId
  status
    `;
const GET_SUBSCRIPTIONPAYMENTLINK_COLUMNS = `
paymentLink
  `;

export const saveSalesTax = (salesTax) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      let updatedSalesTax = salesTax;
      mutate({
        command: salesTax.taxId ? "updateTax" : "createTax",
        commandType: salesTax.taxId
          ? "UpdateTaxCommandInput!"
          : "CreateTaxCommandInput!",
        data: salesTax,
        columns: SAVE_SALESTAX_COLUMNS,
      })
        .then(function(result) {
          updatedSalesTax.taxId = result.data.taxId;
          dispatch(recordSavedSuccess("salesTax", updatedSalesTax));
          resolve(updatedSalesTax);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("salesTax", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getSalesTax = (options) => {
  const { id } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_SALESTAX_COLUMNS,
        entity: "tax",
        id: id,
        idType: "Uuid!",
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getSalesTaxes = (options = {}) => {
  const { filterValues, sort, pageInfo = {}, enablePaging = true } = options;

  return new Promise(function(resolve, reject) {
    try {
      //TODO: If API call fails because of no connectivity then get customers from local storage

      query({
        columns: GET_SALESTAXES_COLUMNS,
        entity: "taxes",
        filter: filterValues,
        filterType: "TaxDtoFilterInput",
        sort: sort,
        sortType: "[TaxDtoSortInput!]",
        isList: true,
        pageInfo: pageInfo,
        enablePaging: enablePaging,
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const deleteSalesTax = ({ id }) => (dispatch) => {
  const salesTax = { taxId: id };
  return new Promise(function(resolve, reject) {
    try {
      const deleteCommand = {
        taxId: salesTax.taxId,
      };
      mutate({
        command: "deleteTax",
        commandType: "DeleteTaxCommandInput",
        data: deleteCommand,
        columns: {},
      }).then(function(result) {
        dispatch(recordDeleteSuccess("salesTax", salesTax));
        resolve(result);
      });
    } catch (err) {
      dispatch(recordDeleteFailed("salesTax", err));
      reject(err);
    }
  });
};
///////////////////////////////////////////////////////////////////////

////////////// PRINT SETTINGS ///////////////////////////////////////

export const getPrintSettings = (options = {}) => {
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_PRINTSETTING_COLUMNS,
        entity: "printSetting",
      }).then(function(result = {}) {
        localStorageSetItem(
          LOCALSTORAGE.printSettings,
          JSON.stringify(result.data)
        );
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const savePrintSettings = (printSettings = {}) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      mutate({
        command: "createPrintSetting",
        commandType: "CreateOrUpdatePrintSettingCommandInput!",
        data: printSettings,
        columns: SAVE_PRINTSETTING_COLUMNS,
      })
        .then(function(result) {
          localStorageSetItem(
            LOCALSTORAGE.printSettings,
            JSON.stringify(printSettings)
          );
          dispatch(recordSavedSuccess("printSettings", printSettings));
          resolve(printSettings);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("printSettings", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

////////////////////////////////////////////////////////////////////////

////////////// COMPANY SETTINGS ///////////////////////////////////////

export const getCompanySettings = (options = {}) => {
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_COMPANYSETTING_COLUMNS,
        entity: "companySetting",
      }).then(function(result = {}) {
        localStorageSetItem(
          LOCALSTORAGE.companySettings,
          JSON.stringify(result.data)
        );
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const saveCompanySettings = (companySettings = {}) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      mutate({
        command: "createCompanySetting",
        commandType: "CreateOrUpdateCompanySettingCommandInput!",
        data: companySettings,
        columns: SAVE_COMPANYSETTING_COLUMNS,
      })
        .then(function(result) {
          localStorageSetItem(
            LOCALSTORAGE.companySettings,
            JSON.stringify(companySettings)
          );
          dispatch(recordSavedSuccess("companySettings", companySettings));
          resolve(companySettings);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("companySettings", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

////////////////////////////////////////////////////////////////////////

///////// Company Subscription /////////////
export const getSubscription = (options = {}) => {
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_SUBSCRIPTION_COLUMNS,
        entity: "companySetting",
      }).then(function(result = {}) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getSubscriptionPayments = (options = {}) => {
  const {
    filterValues = {},
    sort = {},
    pageInfo = {},
    enablePaging = true,
  } = options;

  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_SUBSCRIPTIONPAYMENTS_COLUMNS,
        entity: "subscriptionPayments",
        filter: filterValues,
        filterType: "SubscriptionPaymentDtoFilterInput",
        sort: sort,
        sortType: "[SubscriptionPaymentDtoSortInput!]",
        isList: true,
        pageInfo: pageInfo,
        enablePaging: enablePaging,
      })
        .then(function(result) {
          resolve(result);
        })
        .catch(function(err) {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const saveSubscriptionPayment = (subscriptionPayment = {}) => (
  dispatch
) => {
  const { success = true } = subscriptionPayment;
  return new Promise(function(resolve, reject) {
    try {
      let updatedSubscriptionPayment = {
        externalReference: subscriptionPayment.externalReference,
        paymentProvider: subscriptionPayment.paymentProvider,
        queryString: subscriptionPayment.queryString,
        reference: subscriptionPayment.reference,
      };

      mutate({
        command: "createSubscriptionPayment",
        commandType: "CreateSubscriptionPaymentCommandInput",
        data: updatedSubscriptionPayment,
        columns: SAVE_SUBSCRIPTION_COLUMNS,
      })
        .then(function(result = {}) {
          updatedSubscriptionPayment.subscriptionPaymentId =
            result.data && result.data.subscriptionPaymentId;
          updatedSubscriptionPayment.status = result.data && result.data.status;

          //payment is completed
          if (
            updatedSubscriptionPayment.subscriptionPaymentId &&
            updatedSubscriptionPayment.status === 1
          ) {
            dispatch(
              generalSuccess("subscriptionPayment", {
                customMessage: "Payment is successful.",
              })
            );
          } // payment is rejected
          else if (
            updatedSubscriptionPayment.subscriptionPaymentId &&
            updatedSubscriptionPayment.status === 3
          ) {
            dispatch(
              generalError("subscriptionPayment", {
                customMessage: "Payment is not successful.",
              })
            );
          } else if (success === false) {
            dispatch(
              generalError("subscriptionPayment", {
                customMessage: "Payment is not successful.",
              })
            );
          }

          resolve(updatedSubscriptionPayment);
        })
        .catch(function(err) {
          if (success === false) {
            dispatch(
              generalError("subscriptionPayment", {
                customMessage: "Payment is not successful.",
              })
            );

            return reject("");
          }
          reject(err);
        });
    } catch (err) {
      if (success === false) {
        dispatch(
          generalError("subscriptionPayment", {
            customMessage: "Payment is not successful.",
          })
        );

        return reject("");
      }
      reject(err);
    }
  });
};

export const getSubscriptionPaymentLink = (options = {}) => {
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_SUBSCRIPTIONPAYMENTLINK_COLUMNS,
        entity: "subscriptionPaymentLink",
        additionalParameterName: "query",
        additionalParameter: options,
        additionalParameterType: "GetSubscriptionPaymentLinkQueryInput",
      }).then(function(result = {}) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};
