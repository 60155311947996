import React from "react";
import Card from "./Card";
import { getReceivableStatistics } from "~/actions/dashboardActions";
import { WalletOutlined } from "@ant-design/icons";
import Spin from "~/components/Common/Spin";
import { translate, price } from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";
import { constructUrl } from "~/config";

class ReceivablesCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, totalReceivables: "" };
    this.onClick = this.onClick.bind();
  }
  componentDidMount() {
    const _this = this;
    getReceivableStatistics()
      .then(function(result = {}) {
        _this.setState({
          loading: false,
          totalReceivables: result.data,
        });
      })
      .catch(function(err) {
        _this.setState({ loading: false });
        console.log(err);
      });
  }
  onClick = () => {
    const { history, match } = this.props;
    history.push(
      constructUrl({
        match,
        to: `/reports/receivables`,
      })
    );
  };
  render() {
    const { totalReceivables = "", loading = true } = this.state;
    const title = `${translate("Receivables", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })}`;
    const footer = `${translate("TotalReceivables", {
      namespace: TRANSLATION_NAMESPACE.Report,
    })}`;
    return (
      <Card
        icon={<WalletOutlined />}
        iconStyle={{ color: "#00c9a7", background: "#00c9a71a" }}
        title={title}
        footer={footer}
        onClick={this.onClick}
      >
        <Spin spinning={loading}>
          <h1 className="font-weight-bold">{price(totalReceivables)}</h1>
        </Spin>
      </Card>
    );
  }
}

export default ReceivablesCard;
