import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Input,
  Typography,
  Switch,
  Select as SelectAntD,
} from "antd";
import orderBy from "lodash/orderBy";

import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

import { propName, translate } from "~/_utils";
import viewModel from "./viewModel";
import FormItem from "~/components/Form/FormItem";
import Select from "~/components/Common/Select";
import Brand from "~/pages/Products/Brands/Brand";
import CategoryModal from "~/pages/Products/Categories/Category/CategoryModal";
import BrandModal from "~/pages/Products/Brands/Brand/BrandModal";
import { PRODUCTTYPES, TRANSLATION_NAMESPACE } from "~/_utils/consts";

import { getBrands, getCategories } from "~/actions/productActions";

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Option } = SelectAntD;

class ProductInfo extends React.Component {
  constructor(props) {
    super(props);
    const { initialValues = {} } = props;
    this.state = {
      brandModalVisible: false,
      brands: [],
      categories: [],
      sellOnPointOfSale: initialValues.sellOnPointOfSale,
    };
    this.onSaveBrand = this.onSaveBrand.bind();
    this.onSaveCategory = this.onSaveCategory.bind();
  }
  componentDidMount() {
    const _this = this;
    getBrands({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          brands: orderBy([...data], ["name"], ["asc"]),
        });
      })
      .catch(function(err) {
        console.log(err);
      });

    getCategories({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          categories: orderBy([...data], ["name"], ["asc"]),
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  onSaveBrand = (brand) => {
    let { brands } = this.state;
    brands.push(brand);

    this.setState({
      brandModalVisible: false,
      brands: orderBy(brands, ["name"], ["asc"]),
    });
  };

  onSaveCategory = (category) => {
    let { categories } = this.state;
    categories.push(category);

    this.setState({
      categoryModalVisible: false,
      categories: orderBy(categories, ["name"], ["asc"]),
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { sellOnPointOfSale, ownStateChange } = prevState;
    const { initialValues = {} } = nextProps;

    if (ownStateChange === true) {
      return { sellOnPointOfSale: sellOnPointOfSale, ownStateChange: false };
    }

    if (sellOnPointOfSale !== initialValues.sellOnPointOfSale) {
      return { sellOnPointOfSale: initialValues.sellOnPointOfSale };
    }

    return null;
  }

  render() {
    const { initialValues, form, onProductTypeChange } = this.props;
    const { brands = [], categories = [], sellOnPointOfSale } = this.state;
    const _this = this;

    return (
      <Row gutter={[16, 8]}>
        <Col xs={24} md={12}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.name)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.name)]
            }
          >
            {(options) => <Input {...options} />}
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.brandId)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.brandId)]
            }
            form={form}
          >
            {(options, setValue) => (
              <Select
                allowClear={true}
                showSearch
                placeholder={options.placeholder}
                optionFilterProp="children"
                onChange={(value) => setValue(value)}
                newItemOptions={{
                  allowAddNewItem: true,
                  title: "Add new brand",
                  onClick: () => {
                    this.setState({ brandModalVisible: true });
                  },
                }}
                data={brands.map((brand) => {
                  return { value: brand.brandId, name: brand.name };
                })}
                filterOption={(input, option) =>
                  typeof option.children === "string"
                    ? option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : option.children
                }
              />
            )}
          </FormItem>
          <BrandModal
            visible={this.state.brandModalVisible}
            onCancel={() => this.setState({ brandModalVisible: false })}
            onSave={this.onSaveBrand}
          />
        </Col>
        <Col xs={24} md={24}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.description)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.description)]
            }
          >
            {(options) => (
              <TextArea rows={2} style={{ resize: "none" }} {...options} />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={10}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.categoryId)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.categoryId)]
            }
            form={form}
          >
            {(options, setValue) => (
              <Select
                allowClear={true}
                showSearch
                placeholder={options.placeholder}
                optionFilterProp="children"
                onChange={(value) => {
                  setValue(value);
                }}
                newItemOptions={{
                  allowAddNewItem: true,
                  title: "Add new category",
                  onClick: () => {
                    this.setState({ categoryModalVisible: true });
                  },
                }}
                data={categories.map((category) => {
                  return {
                    value: category.categoryId,
                    name: category.name,
                  };
                })}
                filterOption={(input, option) =>
                  typeof option.children === "string"
                    ? option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : option.children
                }
              />
            )}
          </FormItem>
          <CategoryModal
            visible={this.state.categoryModalVisible}
            onCancel={() => this.setState({ categoryModalVisible: false })}
            onSave={this.onSaveCategory}
          />
        </Col>

        <Col xs={24} md={8}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.productType)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.productType)]
            }
            form={form}
          >
            {(options, setValue) => (
              <SelectAntD
                placeholder={options.placeholder}
                onChange={(value) => {
                  setValue(value);
                  onProductTypeChange(value);
                }}
              >
                {Object.keys(PRODUCTTYPES).map((productTypeKey, index) => {
                  const productType = PRODUCTTYPES[productTypeKey];

                  const key = `${productType}${index}`;
                  return (
                    <Option key={key} value={productType}>
                      {`${translate(productTypeKey, {
                        namespace: TRANSLATION_NAMESPACE.Product,
                      })}`}
                    </Option>
                  );
                })}
              </SelectAntD>
            )}
          </FormItem>
        </Col>

        <Col xs={24} md={6}>
          <FormItem
            propertyName={propName(initialValues, (o) => o.sellOnPointOfSale)}
            viewModelKeyValue={
              viewModel[propName(initialValues, (o) => o.sellOnPointOfSale)]
            }
            form={form}
          >
            {(options, setValue) => (
              <Row>
                <Col>
                  <Text>{options.placeholder}</Text>{" "}
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={sellOnPointOfSale}
                    defaultChecked={sellOnPointOfSale}
                    onChange={(checked, e) => {
                      e.stopPropagation();
                      _this.setState(
                        {
                          ownStateChange: true,
                          sellOnPointOfSale: checked,
                        },
                        setValue(checked)
                      );
                      //form.setFieldsValue({ sellOnPointOfSale: checked });
                    }}
                  />
                </Col>
              </Row>
            )}
          </FormItem>
        </Col>

        <FormItem
          propertyName={propName(initialValues, (o) => o.productId)}
          viewModelKeyValue={propName(initialValues, (o) => o.productId)}
        >
          {(options) => <Input type="hidden" {...options} />}
        </FormItem>

        <FormItem
          propertyName={propName(initialValues, (o) => o.sku)}
          viewModelKeyValue={propName(initialValues, (o) => o.sku)}
        >
          {(options) => <Input type="hidden" {...options} />}
        </FormItem>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withNamespaces()(ProductInfo));
