import { dbProducts, productsIndex } from "../config";
import FlexSearch from "flexsearch";
import store from "~/store";
import {
  selectDocumentFromLocalDb,
  addUpdateDocumentsInDb,
  getAllDocuments,
} from "./";
import { query } from "~/_utils/httpClient";
import { getSalesTaxes } from "~/actions/setupActions";
import { getQuickKey } from "~/actions/productActions";
import { LOCALSTORAGE, SESSIONSTORAGE } from "~/_utils/consts";
import {
  initializeDB,
  localStorageSetItem,
  localStorageGetItem,
} from "~/_utils";

const dbKeyPrefix = "products";
const dbKeyProperty = "productId";
const dbIndexName = "productIndex";

//TODO: add salesTax also in the indexedDB

const GET_PRODUCT_OFFLINE_COLUMNS = `
      productId
      name
      barcode      
      parentProductId
      productType
      retailPriceExTax
      retailPriceIncTax
      sellOnPointOfSale
      taxId
      isDeleted
      productVariants{
        barcode        
        name
        parentProductId
        productId        
        retailPriceExTax
        retailPriceIncTax
        sellOnPointOfSale        
        taxId
        isDeleted      
      }  
`;

export const getProductsForOffline = (options = {}) => {
  const {
    filterValues,
    sort,
    pageInfo = {},
    enablePaging = true,
    pageSizeValue,
  } = options;
  return new Promise(function(resolve, reject) {
    query({
      columns: GET_PRODUCT_OFFLINE_COLUMNS,
      entity: "productsForOffline",
      filter: filterValues,
      filterType: "ProductDtoFilterInput",
      sort: sort,
      sortType: "[ProductDtoSortInput!]",
      isList: true,
      pageInfo: pageInfo,
      enablePaging: enablePaging,
      pageSizeValue: pageSizeValue,
    })
      .then(function(products) {
        getSalesTaxes({ enablePaging: false })
          .then(function(taxes = {}) {
            addUpdateSalesTaxesInDb(taxes.data)
              .then(function() {
                const transformedProductsModel =
                  products && transformProductOfflineModel(products.data);
                resolve({ ...products, data: transformedProductsModel });
              })
              .catch(function(err) {
                console.log(err);
                reject(err);
              });
          })
          .catch(function(err) {
            console.log(err);
            reject(err);
          });
      })
      .catch(function(err) {
        reject(err);
      });
  });
};

const transformProductOfflineModel = (products = []) => {
  const finalProducts = [];

  // parent standard product without variants
  products
    .filter(
      (p) =>
        !p.parentProductId &&
        (!p.productVariants || p.productVariants.length === 0)
    )
    .forEach((p) => {
      const { productVariants, ...restProps } = p;
      const excludeFromSearch =
        p.isDeleted === true || p.sellOnPointOfSale === false;
      finalProducts.push({
        ...restProps,
        excludeFromSearch: excludeFromSearch,
        id: p.productId,
      });
    });

  //parent products with variants
  products
    .filter(
      (p) =>
        !p.parentProductId && p.productVariants && p.productVariants.length > 0
    )
    .forEach((p) => {
      p.productVariants.forEach((v) => {
        const { name: variantName, ...restProps } = v;
        const excludeFromSearch =
          v.isDeleted === true || v.sellOnPointOfSale === false;
        const product = {
          name: p.name,
          variant: variantName,
          ...restProps,
          excludeFromSearch: excludeFromSearch,
          id: v.productId,
        };
        finalProducts.push(product);
      });
    });

  return finalProducts;
};

export function getProductsDb() {
  return initializeDB(dbProducts, { revs_limit: 1, auto_compaction: true });
}

//reCreate of index should only happen on the page load of Sell, if the localDB is out or sync
export const reCreateProductsIndex = () => {
  return new Promise(function(resolve, reject) {
    resolve(getProductFlexSearch());
  });
};

export const getAllProductsFromLocalDb = () => {
  return new Promise(function(resolve, reject) {
    const db = getProductsDb();

    getAllDocuments(db, dbKeyPrefix, true)
      .then(function(result = {}) {
        resolve(result.rows && result.rows.map((x) => x.doc));
      })
      .catch(function(err) {
        console.log(err);
        reject(err);
      });
  });
};

export const selectProductFromLocalDb = (value) => {
  return selectDocumentFromLocalDb({
    dbName: dbProducts,
    keyPrefix: dbKeyPrefix,
    value: value,
  });
};

export const getProductFlexSearch = (options = {}) => {
  const { areProductsInLocalDBChanged = false } = options;

  return new Promise(function(resolve, reject) {
    var flexSearchFromState = store.getState().offline.flexSearchProduct;

    if (flexSearchFromState && areProductsInLocalDBChanged === false) {
      console.log("got existing flexsearch");
      resolve(flexSearchFromState);
      return;
    }

    console.log("re-creating flexsearch...");
    var flexSearch = initializeProductsIndex();
    getAllProductsFromLocalDb()
      .then(function(products) {
        flexSearch.add([
          ...products.filter(
            (x) => !(x._deleted === true || x.excludeFromSearch === true)
          ),
        ]);

        resolve(flexSearch);
      })
      .catch(function() {
        resolve(flexSearch);
      });
  });
};

//products list should be filtered list from the server for whch addition or update of products should be needed
export const addUpdateProductsInDb = (db, products = []) => {
  return new Promise(function(resolve, reject) {
    if (products.length === 0) {
      return resolve();
    }

    addUpdateDocumentsInDb(db, dbKeyPrefix, dbKeyProperty, products).then(
      function(result) {
        resolve();
      }
    );
  });
};

export const addUpdateSalesTaxesInDb = (taxes = []) => {
  return new Promise(function(resolve, reject) {
    localStorageSetItem(LOCALSTORAGE.saleTaxes, JSON.stringify(taxes));
    resolve();
  });
};

export const getAllSalesTaxesFromLocalDb = () => {
  return new Promise(function(resolve, reject) {
    const saleTaxesFromLocalDb =
      localStorageGetItem(LOCALSTORAGE.saleTaxes) || "[]";
    resolve(JSON.parse(saleTaxesFromLocalDb));
  });
};

// export const addUpdateQuickKeysInDb = (db, quickKeys = []) => {
//   return new Promise(function(resolve, reject) {
//     addUpdateDocumentsInDb(db, "quickKeys", "", [{ quickKeys }])
//       .then(function(result) {
//         resolve(result);
//       })
//       .catch(function(err) {
//         console.log(err);
//         reject(err);
//       });
//   });
// };

export const getQuickKeys = () => {
  return new Promise(function(resolve, reject) {
    const quickKeyFromLocalDB = sessionStorage.getItem(SESSIONSTORAGE.quickKey);
    if (quickKeyFromLocalDB) {
      const quickKeyObj = JSON.parse(quickKeyFromLocalDB) || {};
      return resolve(quickKeyObj.quickKeyProducts || []);
    }

    getQuickKey()
      .then(function(result = {}) {
        const data = result.data || {};
        const quickKeyProducts = data.quickKeyProducts || [];
        sessionStorage.setItem(SESSIONSTORAGE.quickKey, JSON.stringify(data));
        return resolve(quickKeyProducts);
      })
      .catch(function(err) {
        console.log(err);
        reject(err);
      });
  });
};

function initializeProductsIndex() {
  return new FlexSearch(dbIndexName, {
    encode: "icase",
    tokenize: "forward",
    doc: {
      id: "productId",
      field: productsIndex,
    },
  });
}
