import React from "react";
import PriceLabel from "../../Common/PriceLabel";
import InputDecimal from "../../Common/InputDecimal";
import { price, toFloat, translate } from "~/_utils";
import { Button, Divider, Row, Col, Input, Collapse, Typography } from "antd";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";

const { Title, Text } = Typography;

const PaySubTotalHeader = (props) => {
  let {
    subTotal,
    originalSubTotal,
    totalItems,
    isPriceIncludingTax = true,
    totalTaxAmount = 0,
  } = props;
  subTotal = toFloat(subTotal);
  originalSubTotal = toFloat(originalSubTotal);

  let headerText = `${translate("SubTotal", {
    namespace: TRANSLATION_NAMESPACE.Sell,
  })}`;

  headerText =
    isPriceIncludingTax === true && totalTaxAmount !== 0
      ? `${headerText} (Incl. Tax)`
      : headerText;
  headerText =
    isPriceIncludingTax === false && totalTaxAmount !== 0
      ? `${headerText} (Excl. Tax)`
      : headerText;

  return (
    <div className="paySubTotalHeader" style={{ width: "100%" }}>
      <Row gutter={12}>
        <Col xs={24}>
          <Text style={{ whiteSpace: "pre-wrap" }}>{headerText}</Text>
          <div className="priceContainer" style={{ paddingLeft: "5px" }}>
            <PriceLabel value={subTotal} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} className="subSection">
          <span>
            {`${translate("TotalItems", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}:`}{" "}
            {totalItems}
          </span>
          {subTotal !== originalSubTotal && (
            <div className="priceContainer" style={{ paddingLeft: "5px" }}>
              <PriceLabel value={originalSubTotal} />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

class PaySubTotalExpandSection extends React.Component {
  constructor(props) {
    super(props);
    this.subTotalRef = null;
  }
  componentDidMount() {
    this.subTotalRef && this.subTotalRef.focus && this.subTotalRef.focus();
  }
  render() {
    const { TextArea } = Input;
    const {
      notes,
      subTotal,
      additionalDiscountPercent,
      onSubTotalChange,
      onAdditionalDiscountChange,
      onNotesChange,
    } = this.props;

    return (
      <div>
        <Row gutter={4}>
          <Col xs={12}>
            <span>{`${translate("SubTotal", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}`}</span>
          </Col>
          <Col xs={12}>
            <span>{`${translate("Discount", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}(%)`}</span>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col xs={12}>
            <InputDecimal
              step={0.01}
              defaultValue={toFloat(subTotal)}
              value={toFloat(subTotal)}
              onChange={onSubTotalChange}
              setRef={(el) => (this.subTotalRef = el)}
            />
          </Col>
          <Col xs={12}>
            <InputDecimal
              step={0.01}
              defaultValue={toFloat(additionalDiscountPercent)}
              value={toFloat(additionalDiscountPercent)}
              onChange={onAdditionalDiscountChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <span>{`${translate("Notes", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}`}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <TextArea
              value={notes}
              defaultValue={notes}
              rows={2}
              style={{ resize: "none" }}
              onChange={(e) => onNotesChange(e.target.value)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

PaySubTotalExpandSection.defaultProps = {
  subTotal: 0,
  additionalDiscountPercent: 0,
};

const PaySubTotalTaxHeader = (props) => {
  const {
    totalTaxAmount = 0,
    groupedTaxes,
    subTotal = 0,
    isPriceIncludingTax = true,
  } = props;

  const numberOfTaxes = Object.keys(groupedTaxes).filter((x) => x !== "null")
    .length;

  if (numberOfTaxes === 0) {
    return <></>;
  }

  return (
    <div className="paySubTotalTax">
      <Row gutter={12}>
        {isPriceIncludingTax === true && totalTaxAmount !== 0 ? (
          <Col xs={24}>
            {`${translate("Sub Total (Excl. Tax)", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}`}
            <div className="priceContainer" style={{ paddingLeft: "5px" }}>
              <PriceLabel value={subTotal - totalTaxAmount} />
            </div>
          </Col>
        ) : (
          <></>
        )}
        <Col xs={24}>
          {`${translate("Tax", {
            namespace: TRANSLATION_NAMESPACE.Sell,
          })}`}
          <div className="priceContainer" style={{ paddingLeft: "5px" }}>
            <PriceLabel value={totalTaxAmount} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} className="subSection">
          <span>
            {`${translate("NumberOfTaxes", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}:`}{" "}
            {numberOfTaxes}
          </span>
        </Col>
      </Row>
    </div>
  );
};

const PaySubTotalTaxExpandSection = (props) => {
  const { groupedTaxes, taxes = [] } = props;

  const taxesExpandSection = Object.entries(groupedTaxes).map(
    ([taxKey, taxAmount]) => {
      const taxDetails = taxes.filter((t) => t.taxId === taxKey)[0];
      if (!taxDetails) {
        return <div key={`taxDetail-${taxKey}`} />;
      }
      return (
        <Col
          xs={24}
          style={{ paddingBottom: "10px" }}
          key={`taxDetail-${taxKey}`}
          className="expandedTaxDetail"
        >
          <span>{`${taxDetails.name} : ${price(taxAmount)}`}</span>
        </Col>
      );
    }
  );

  return <Row gutter={4}>{taxesExpandSection}</Row>;
};

class PayTotal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeKey: props.expandKey };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { expandKey = "" } = nextProps;
    if (expandKey !== "" && expandKey !== prevState.activeKey) {
      return { activeKey: expandKey };
    }

    return null;
  }

  render() {
    const {
      numberofProducts = 0,
      subTotal,
      originalSubTotal,
      totalItems,
      totalTaxAmount = 0,
      groupedTaxes = [],
      taxes,
      additionalDiscountPercent,
      onSubTotalChange,
      onAdditionalDiscountChange,
      readonly = false,
      toPay,
      onPay,
      onNotesChange,
      notes,
      isPriceIncludingTax = true,
    } = this.props;
    const { Panel } = Collapse;
    const { activeKey = "" } = this.state;
    const _this = this;

    return (
      <div>
        <Divider />
        <Row>
          <Col xs={24}>
            <Collapse
              destroyInactivePanel={true}
              activeKey={activeKey}
              onChange={(key) => {
                _this.setState({ activeKey: key });
              }}
              accordion
              defaultActiveKey={[activeKey]}
            >
              <Panel
                showArrow={!readonly}
                disabled={readonly}
                header={PaySubTotalHeader({
                  subTotal: subTotal,
                  originalSubTotal: originalSubTotal,
                  totalItems: totalItems,
                  isPriceIncludingTax: isPriceIncludingTax,
                  totalTaxAmount: totalTaxAmount,
                })}
                key="subTotal"
              >
                <PaySubTotalExpandSection
                  notes={notes}
                  subTotal={subTotal}
                  originalSubTotal={originalSubTotal}
                  additionalDiscountPercent={additionalDiscountPercent}
                  onSubTotalChange={onSubTotalChange}
                  onAdditionalDiscountChange={onAdditionalDiscountChange}
                  onNotesChange={onNotesChange}
                />
              </Panel>
              {Object.keys(groupedTaxes).length > 0 && (
                <Panel
                  className="expandTaxDetil-container"
                  showArrow={!readonly}
                  disabled={readonly}
                  header={PaySubTotalTaxHeader({
                    totalTaxAmount: totalTaxAmount,
                    groupedTaxes: groupedTaxes,
                    subTotal: subTotal,
                    isPriceIncludingTax: isPriceIncludingTax,
                  })}
                  key="subTotalTax"
                >
                  <PaySubTotalTaxExpandSection
                    groupedTaxes={groupedTaxes}
                    taxes={taxes}
                  />
                </Panel>
              )}
            </Collapse>
          </Col>
        </Row>
        {readonly === true ? (
          <Row>
            <Col xs={12} className="sell-bucket-form-total-title">
              <Title level={4}>
                {`${translate("Total", {
                  namespace: TRANSLATION_NAMESPACE.Sell,
                })}`}
              </Title>
            </Col>
            <Col xs={12} className="sell-bucket-form-total-price">
              <PriceLabel value={toPay} />
            </Col>
          </Row>
        ) : (
          <Button
            style={{ maxWidth: "100%" }}
            type="primary"
            onClick={onPay}
            disabled={numberofProducts === 0}
            className="sell-bucket-form-button"
          >
            <Row>
              <Col xs={12} className="sell-bucket-form-button-title">
                <Title level={4}>
                  {`${translate("Pay", {
                    namespace: TRANSLATION_NAMESPACE.Sell,
                  })}`}
                </Title>
              </Col>
              <Col xs={12} className="sell-bucket-form-button-price">
                <PriceLabel value={toPay} />
              </Col>
            </Row>
          </Button>
        )}
      </div>
    );
  }
}

export default PayTotal;
