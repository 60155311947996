import React from "react";
import { Row, Col, Form, Input, Button, Switch } from "antd";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  saveCompanySettings,
  getCompanySettings,
} from "~/actions/setupActions";
import FormItem from "~/components/Form/FormItem";

import { propName } from "~/_utils";
import viewModel from "./viewModel";

const { TextArea } = Input;

const GetInitialValues = (companySettings = {}) => {
  const initialValues = {
    companyName: companySettings.companyName,
    uniqueCompanyName: companySettings.uniqueCompanyName,
    currency: companySettings.currency,
  };

  return initialValues;
};

class CompanySettings extends React.Component {
  constructor(props) {
    super(props);

    let { location = {}, companySettings } = this.props;

    this.state = { companySettings: companySettings, formRef: null };

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.getCompanySettings = this.getCompanySettings.bind();
    this.setFormRef = this.setFormRef.bind();
  }
  getCompanySettings = () => {
    const _this = this;

    getCompanySettings()
      .then(function(result) {
        _this.setState(
          {
            companySettings: result.data,
          },
          _this.state.formRef.setFieldsValue(result.data)
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  componentDidMount() {
    this.getCompanySettings();
  }
  handleSubmit = (values = {}) => {
    this.setState({
      loading: true,
    });

    const { saveCompanySettings } = this.props;
    const { formRef } = this.state;
    const _this = this;

    const { uniqueCompanyName, ...restValues } = values;
    saveCompanySettings({ ...restValues })
      .then(function(result) {
        _this.setState({
          loading: false,
        });
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  render() {
    const { formRef, loading = false, companySettings = {} } = this.state;
    const initialValues = GetInitialValues(companySettings);

    return (
      <Row gutter={[16, 16]}>
        <Col xs={24} md={14}>
          <Form
            validateTrigger="onBlur"
            onFinish={this.handleSubmit}
            onFinishFailed={this.onFinishFailed}
            className="companySettings-form"
            initialValues={initialValues}
            ref={this.setFormRef}
          >
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <FormItem
                  form={formRef}
                  propertyName={propName(
                    initialValues,
                    (o) => o.uniqueCompanyName
                  )}
                  viewModelKeyValue={
                    viewModel[
                      propName(initialValues, (o) => o.uniqueCompanyName)
                    ]
                  }
                >
                  {({ options }) => <Input {...options} disabled={true} />}
                </FormItem>
              </Col>
              <Col xs={24}>
                <FormItem
                  form={formRef}
                  propertyName={propName(initialValues, (o) => o.companyName)}
                  viewModelKeyValue={
                    viewModel[propName(initialValues, (o) => o.companyName)]
                  }
                >
                  {({ options }) => <Input {...options} />}
                </FormItem>
              </Col>
              <Col xs={24}>
                <FormItem
                  form={formRef}
                  propertyName={propName(initialValues, (o) => o.currency)}
                  viewModelKeyValue={
                    viewModel[propName(initialValues, (o) => o.currency)]
                  }
                >
                  {({ options }) => <Input {...options} />}
                </FormItem>
              </Col>

              <Col xs={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="save-button"
                    loading={loading}
                  >
                    <Trans i18nKey="Save">Save</Trans>
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveCompanySettings })(
  withRouter(withNamespaces()(CompanySettings))
);
