import React from "react";
import { Modal } from "antd";
import Category from "~/pages/Products/Categories/Category";

const CategoryModal = (props) => {
  const { categoryId, onSave, ...restProps } = props;
  return (
    <Modal
      title="Category"
      centered
      footer={null}
      destroyOnClose={true}
      {...restProps}
    >
      <Category categoryId={categoryId} onSave={onSave} showTitle={false} />
    </Modal>
  );
};

export default CategoryModal;
