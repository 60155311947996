import React from "react";
import { DeleteOutlined, FileImageOutlined } from "@ant-design/icons";
import { Card, Avatar } from "antd";
import Button from "~/components/Common/Button";
const { Meta } = Card;

const QuickKeyCard = (props = {}) => {
  const {
    index,
    id,
    title,
    description,
    img,
    isEditable,
    onDelete,
    color,
    onSelect,
  } = props;

  const randomColor =
    color || `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  const className = `card ${isEditable === true ? "card-edit" : ""}`;

  return (
    <div
      className="quickkeys-card-container"
      onClick={() => {
        isEditable !== true && onSelect(id);
      }}
    >
      <Card
        className={className}
        bordered={false}
        hoverable
        cover={
          img ? (
            <div
              className="card-cover"
              style={{
                backgroundImage: `url(${img})`,
              }}
            />
          ) : (
            <div className="card-cover">
              <Avatar
                size={75}
                style={{
                  color: "#000000",
                  backgroundColor: `${randomColor}`,
                  verticalAlign: "middle",
                  fontSize: "30px",
                }}
              >
                {title && title.substring(0, 1)}
              </Avatar>
            </div>
          )
        }
        actions={
          isEditable === true && [
            // <Button
            //   type="link"
            //   icon={<FileImageOutlined />}
            //   onClick={(event) => {
            //     console.log("File uplod event");
            //     event.stopPropagation();
            //   }}
            // />,
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={(event) => {
                onDelete(index);
                event.stopPropagation();
              }}
            />,
          ]
        }
      >
        <Meta title={title} description={description} />
      </Card>
    </div>
  );
};

QuickKeyCard.defaultProps = {
  sku: "",
};
export default QuickKeyCard;
