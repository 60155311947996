import React from "react";
import "./index.scss";
import Logo from "../../components/Logo";
import {
  LockOutlined,
  ShopOutlined,
  UserOutlined,
  MailOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Row, Col } from "antd";
import viewModel from "./viewModel";
import FormItem from "../../components/Form/FormItem";
import Link from "../../components/Link";
import { propName } from "~/_utils";
import { Trans, withNamespaces } from "react-i18next";
import { signup } from "~/actions/userActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { constructUrl } from "~/config";
import { signOut } from "~/actions/userActions";
import Recaptcha from "~/components/Common/Recaptcha";
import queryString from "query-string";

class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { formRef: null };
    this.handleSubmit = this.handleSubmit.bind();
    this.setFormRef = this.setFormRef.bind();
  }
  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };
  handleSubmit = (values) => {
    const _this = this;
    const { formRef } = this.state;
    const { location } = this.props;

    //default Values
    values.companyCategory = 3;
    values.countryCode = "PK";
    values.recaptchaToken = formRef.getFieldValue("recaptchaToken");

    const queryParams = (location && queryString.parse(location.search)) || {};
    values.reference = queryParams.ref;

    this.setState({
      loading: true,
    });
    const { signup, history, match } = this.props;
    signup(values)
      .then(function() {
        history.push(constructUrl({ match, to: "/Login" })); //history is injected from hoc withRouter
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });

    console.log("Received values of form: ", values);
  };
  handleCompanyNameChange = (e) => {
    const { formRef } = this.state;
    formRef.setFieldsValue({
      uniqueCompanyName: e.target.value.replace(/[^A-Z0-9]/gi, ""), //replace spaces with empty character
    });
  };
  componentDidMount() {
    signOut({ reload: false });
  }

  render() {
    const { formRef, loading = false } = this.state;
    return (
      <div className="signup-form-container">
        <Form
          validateTrigger="onBlur"
          onFinish={this.handleSubmit}
          className="signup-form"
          initialValues={{}}
          ref={this.setFormRef}
        >
          <Logo />
          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.companyName)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.companyName)]
            }
          >
            {({ options }) => (
              <Input
                prefix={<ShopOutlined />}
                {...options}
                onChange={this.handleCompanyNameChange}
              />
            )}
          </FormItem>

          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.uniqueCompanyName)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.uniqueCompanyName)]
            }
          >
            {({ options }) => <Input prefix={<ShopOutlined />} {...options} />}
          </FormItem>

          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.displayName)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.displayName)]
            }
          >
            {({ options }) => (
              <Input prefix={<IdcardOutlined />} {...options} />
            )}
          </FormItem>

          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.email)}
            viewModelKeyValue={viewModel[propName(viewModel, (o) => o.email)]}
          >
            {({ options }) => <Input prefix={<MailOutlined />} {...options} />}
          </FormItem>

          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.userName)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.userName)]
            }
          >
            {({ options }) => <Input prefix={<UserOutlined />} {...options} />}
          </FormItem>

          <FormItem
            form={formRef}
            propertyName={propName(viewModel, (o) => o.password)}
            viewModelKeyValue={
              viewModel[propName(viewModel, (o) => o.password)]
            }
          >
            {({ options }) => (
              <Input.Password prefix={<LockOutlined />} {...options} />
            )}
          </FormItem>

          <Form.Item>
            <Recaptcha form={formRef} isFormSubmitted={loading === true}>
              <Button
                type="primary"
                htmlType="submit"
                className="save-button"
                loading={loading}
              >
                <Trans i18nKey="Register">Register</Trans>
              </Button>
            </Recaptcha>
            <Row gutter={[16, 16]}>
              <Col>
                <Link to="/Login" keepQueryParams={true}>
                  <Trans i18nKey="Login">Login</Trans>
                </Link>
              </Col>
              <Col>{"|"}</Col>
              <Col>
                <Link to="/ForgotPassword" keepQueryParams={true}>
                  <Trans i18nKey="ForgotPassword">Forgot password</Trans>
                </Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { signup })(
  withRouter(withNamespaces()(SignupForm))
);
