import React from "react";
import Media from "react-media";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Divider, Input, Typography, Card, Form } from "antd";
import {
  EditTwoTone,
  DeleteTwoTone,
  UserOutlined,
  SearchOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import Button from "~/components/Common/Button";
import FormItem from "~/components/Form/FormItem";
import { PageTitle } from "~/components/Common/Text";
import { getSuppliers, deleteSupplier } from "~/actions/supplierActions";
import { constructUrl } from "~/config";
import Table from "~/components/Common/Table";

const { Text } = Typography;

const Filters = (props) => {
  const { onSearch } = props;
  const onFinish = (values) => {
    onSearch(values);
  };
  return (
    <Form name="filter" onFinish={onFinish}>
      <Row gutter={[8, 8]}>
        <Col xs={12} md={6}>
          <FormItem
            propertyName="name_contains"
            placeholder="Name"
            showFormTitle={false}
            showPlaceholder={true}
          >
            {({ placeholder, ...restOptions }) => (
              <Input prefix={<UserOutlined />} placeholder={placeholder} />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              Search
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const CardGrid = (props) => {
  const { translateKey, title, value } = props;
  const gridStyle = {
    width: "100%",
    textAlign: "left",
  };

  return (
    <Card.Grid hoverable={false} style={gridStyle}>
      <div style={{ float: "left", paddingRight: 10 }}>
        <Text>
          <Trans i18nKey={translateKey}>{title}</Trans>
          {": "}
        </Text>
      </div>
      <div style={{ float: "left" }}>
        <Text>{value}</Text>
      </div>
    </Card.Grid>
  );
};

const ExpandedRow = (props) => {
  const { supplier = {} } = props;
  const { name, email, phone, address, description } = supplier;

  return (
    <Row>
      <Col xs={24} lg={12} xl={8}>
        <Card
          title={name}
          bordered={false}
          style={{
            width: "100%",
            height: 302,
            marginTop: "unset",
            background: "unset",
          }}
        >
          <CardGrid translateKey="Email" title="Email" value={email} />
          <CardGrid translateKey="Phone" title="Phone" value={phone} />
          <CardGrid translateKey="Address" title="Address" value={address} />
          <CardGrid
            translateKey="Description"
            title="Description"
            value={description}
          />
        </Card>
      </Col>
    </Row>
  );
};

class Suppliers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suppliers: [],

      filterValues: props.filterValues || {},
    };

    this.onEdit = this.onEdit.bind();
    this.onDelete = this.onDelete.bind();
    this.updateData = this.updateData.bind();
    this.handleFetch = this.handleFetch.bind();
    this.resetTableState = this.resetTableState.bind();
    this.handleTableChange = this.handleTableChange.bind();

    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: true,
      },
      { title: "Phone", dataIndex: "phone", key: "phone" },

      { title: "Email", dataIndex: "email", key: "email" },

      {
        title: "",
        dataIndex: "",
        key: "actions",
        // fixed: "right",
        width: 85,

        render: (text, record) => (
          <Row>
            <Col xs={12}>
              <Button
                type="link"
                icon={<EditTwoTone />}
                onClick={() =>
                  this.onEdit({
                    id: record.supplierId,
                  })
                }
              />
            </Col>
            <Col xs={12}>
              <Button
                layout="confirm"
                confirmText="Are you sure ?"
                type="link"
                icon={<DeleteTwoTone />}
                onClick={() =>
                  this.onDelete({
                    id: record.supplierId,
                  })
                }
              />
            </Col>
          </Row>
        ),
      },
    ];
  }

  componentDidMount() {
    this.handleFetch();
  }

  resetTableState = (options, callback) => {
    const {
      sort = this.state.sort,
      filterValues = this.state.filterValues,
    } = options;
    this.setState(
      {
        sort: sort || {},
        pageInfo: {}, // always reset page to page 1
        filterValues: filterValues || {},
      },
      callback
    );
  };

  handleFetch = (options = {}) => {
    const _this = this;

    let { filterValues = {}, sort = {}, pageInfo = {} } = this.state;
    if (pageInfo.hasNextPage === false) {
      return;
    }

    this.setState({ loading: true });
    getSuppliers({ filterValues, sort, pageInfo })
      .then(function(result) {
        const { data, totalCount, pageInfo = {} } = result;
        _this.updateData(data);
        _this.setState({
          loading: false,
          pageInfo: pageInfo,
          totalCount: totalCount,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  updateData = (data = []) => {
    const _this = this;
    const {
      suppliers: alreadyLoadedSuppliers = [],
      pageInfo = {},
    } = this.state;
    let suppliers =
      Object.keys(pageInfo).length === 0 ? [] : [...alreadyLoadedSuppliers]; //If page is reset start from empty list

    const startIndex = suppliers.length;
    ////
    data.forEach((supplier, index) => {
      const { supplierId, name, email, phone, address, description } = supplier;

      suppliers.push({
        key: startIndex + index,
        supplierId: supplierId,
        name: name,
        email: email,
        phone: phone,
        address: address,
        description: description,
      });
    });

    _this.setState({
      suppliers: suppliers,
    });
  };

  onEdit = ({ id }) => {
    const { history, match } = this.props;

    history.push(
      constructUrl({
        match,
        to: `/supplier?supplierId=${id}`,
        isNested: true,
      })
    );
  };

  onDelete = (options) => {
    const _this = this;
    const { sort, filterValues } = this.state;
    const { deleteSupplier } = this.props;
    deleteSupplier(options)
      .then(function() {
        //keep existing filtr & sort state

        _this.resetTableState(
          {
            filterValues: filterValues,
            sort: sort,
          },
          _this.handleFetch
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  handleTableChange = (pagination, filters, sorter = {}) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";

    const sort = JSON.parse(`{"${sortField}": "${sortOrder}"}`);

    this.resetTableState({ sort: sort }, this.handleFetch);
  };

  render() {
    const columns = this.columns;
    const _this = this;
    const { suppliers, pageInfo = {} } = this.state;

    return (
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={12}>
              <PageTitle>Suppliers</PageTitle>
            </Col>

            <Divider />
            <br />
          </Row>
        </Col>
        <Col xs={24} xl={18}>
          <Filters
            onSearch={(filterValues) => {
              _this.resetTableState(
                { filterValues: filterValues },
                _this.handleFetch
              );
            }}
          />
        </Col>
        <Col xs={24}>
          <Divider />

          <Table
            loading={this.state.loading}
            columns={columns}
            onChange={this.handleTableChange}
            expandedRowRender={(record) => <ExpandedRow supplier={record} />}
            dataSource={suppliers}
            hasNextPage={pageInfo.hasNextPage}
            onPageChange={this.handleFetch}
          />
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { deleteSupplier })(
  withRouter(withNamespaces()(Suppliers))
);
