import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { reCaptchaKey } from "~/config";

class Recaptcha extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isRecaptchaValid: false };
    this.onVerificationChange = this.onVerificationChange.bind();
    this.setCaptchaRef = this.setCaptchaRef.bind();
    this.resetCaptcha = this.resetCaptcha.bind();
  }
  setCaptchaRef = (ref) => {
    const { captcha } = this.state;
    if (!captcha) {
      this.setState({ captcha: ref });
    }
  };
  resetCaptcha = () => {
    // maybe set it till after is submitted
    const { captcha } = this.state;
    if (captcha) {
      console.log("resetting captcha");
      captcha.reset();
    }
  };
  onVerificationChange = (value) => {
    console.log("reCaptcha: ", value);
    const { form } = this.props;

    form && form.setFieldsValue({ recaptchaToken: value });

    if (value) {
      this.setState({ isRecaptchaValid: true });
    } else {
      this.setState({ isRecaptchaValid: false });
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { isFormSubmitted = false } = nextProps;
    const { captcha } = prevState;

    if (captcha && isFormSubmitted === true) {
      console.log("resetting captcha from derived props");
      captcha.reset();
      return { isRecaptchaValid: false };
    }
    return null;
  }
  render() {
    const _this = this;
    const { children } = this.props;
    const { isRecaptchaValid = false } = this.state;
    const options = { disabled: isRecaptchaValid != true };

    return (
      <>
        <ReCAPTCHA
          ref={(r) => _this.setCaptchaRef(r)}
          sitekey={reCaptchaKey}
          onChange={this.onVerificationChange}
          style={{ paddingBottom: "10px" }}
        />
        {React.cloneElement(children, { ...options })}
      </>
    );
  }
}

export default Recaptcha;
