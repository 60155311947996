import React from "react";
import { Row, Col } from "antd";
import { toFloat } from "~/_utils";
import Button from "~/components/Common/Button";
import { PAYMENTMETHODS } from "~/_utils/consts";

const ActionButtons = (props) => {
  const { onPayment, isCustomerSelected = false, disabled = false } = props;

  return (
    <Row gutter={[8, 8]} className="amountToPay-actionButtons">
      <Col xs={8}>
        <Button
          type="dashed"
          disabled={disabled}
          onClick={() => onPayment(PAYMENTMETHODS.CASH)}
        >
          Cash
        </Button>
      </Col>
      <Col xs={8}>
        <Button
          type="dashed"
          disabled={disabled}
          onClick={() => onPayment(PAYMENTMETHODS.CREDITCARD)}
        >
          Credit card
        </Button>
      </Col>
      <Col xs={8}>
        <Button
          type="dashed"
          disabled={disabled || !isCustomerSelected}
          onClick={() => onPayment(PAYMENTMETHODS.CREDIT)}
        >
          Credit
        </Button>
      </Col>
    </Row>
  );
};

export default ActionButtons;
