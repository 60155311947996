import { OFFLINE_READY, OFFLINE_STATECHANGE } from "./types";
import {
  updateClientDbForProducts,
  updateClientDbForCustomers,
} from "~/_offline";
import { LOCALSTORAGE, OFFLINESTATE } from "~/_utils/consts";
import {
  getCurrentDateUtcIso,
  isOnline,
  localStorageGetItem,
  localStorageSetItem,
} from "~/_utils";
import {
  getProductsForOffline,
  getQuickKeys,
  getProductFlexSearch,
} from "~/_offline/offlineProducts";
import {
  getCustomerFlexSearch,
  getCustomersForOffline,
} from "~/_offline/offlineCustomers";

export const onlineStateChange = (options = {}) => (dispatch) => {
  const { isOnline = true } = options;
  dispatch({
    type: OFFLINE_STATECHANGE,
    payload: { isOnline: isOnline },
  });
};

export const prepareOfflineSell = (options = {}) => (dispatch) => {
  const {
    dispatchOfflinePayLoad = false,
    isNewCustomerAdded = false,
  } = options;

  if (isNewCustomerAdded === true) {
    return dispatchForOffline(dispatch, { totalCustomersUpdated: 1 });
  }

  if (isOnline() === false || dispatchOfflinePayLoad === true) {
    return dispatchForOffline(dispatch);
  }

  dispatch({
    type: OFFLINE_READY,
    payload: { state: OFFLINESTATE.InProgress },
  });

  const currentUtcDateTime = getCurrentDateUtcIso();

  const offlineProductsPromise = prepareOfflineSellProductsPromise();
  const offlineCustomersPromise = prepareOfflineSellCustomersPromise();

  const offlineQuickKeysPromise = new Promise(function(resolve, reject) {
    resolve([]);
  }); // implement quickkeys offline mode

  Promise.all([
    offlineProductsPromise,
    offlineCustomersPromise,
    offlineQuickKeysPromise,
  ])
    .then(function(results) {
      console.log("offline results: ", results);

      localStorageSetItem(
        LOCALSTORAGE.offlineSyncUtcDateTime,
        currentUtcDateTime
      );

      const totalProductsUpdated = results[0].totalProductsUpdated;
      const totalCustomersUpdated = results[1].totalCustomersUpdated;

      dispatchForOffline(dispatch, {
        totalProductsUpdated: totalProductsUpdated,
        totalCustomersUpdated: totalCustomersUpdated,
      });
    })
    .catch(function(err) {
      dispatchForOffline(dispatch);
      console.log(err);
    });
};

//** CUSTOMERS OFFLINE */
const prepareOfflineSellCustomersPromise = () => {
  return new Promise(function(resolve, reject) {
    prepareOfflineSellCustomers({ resolve: resolve });
  });
};

const prepareOfflineSellCustomers = (props) => {
  let {
    lastSyncUtcDate,
    pageInfo = {},
    resolve,
    pageSizeValue = 1000,
    totalCustomersUpdated = 0,
  } = props;

  const lastOfflineCustomersSyncUtcDate =
    lastSyncUtcDate ||
    localStorageGetItem(LOCALSTORAGE.offlineCustomersSyncUtcDateTime);

  const filterOfflineCustomers = lastOfflineCustomersSyncUtcDate && {
    lastModifiedForOfflineSync: { gt: `${lastOfflineCustomersSyncUtcDate}` }, //TODO: add offset -5-min
  };
  getCustomersForOffline({
    filterValues: filterOfflineCustomers,
    enablePaging: true,
    pageSizeValue: pageSizeValue,
    pageInfo: pageInfo,
  })
    .then(function(result = {}) {
      const { data = [], pageInfo: pageInfoFromResponse = {} } = result;

      totalCustomersUpdated = totalCustomersUpdated + data.length;

      console.log("customersBatch: ", {
        customersCount: data.length,
        totalCustomersUpdated: totalCustomersUpdated,
        hasNextPage: pageInfoFromResponse.hasNextPage,
        pageInfoFromResponse,
      });

      updateClientDbForCustomers(data).then(function() {
        if (pageInfoFromResponse.hasNextPage === true) {
          prepareOfflineSellCustomers({
            lastSyncUtcDate: lastOfflineCustomersSyncUtcDate,
            pageInfo: pageInfoFromResponse,
            resolve: resolve,
            pageSizeValue: pageSizeValue,
            totalCustomersUpdated: totalCustomersUpdated,
          });
        } else {
          localStorageSetItem(
            LOCALSTORAGE.offlineCustomersSyncUtcDateTime,
            getCurrentDateUtcIso()
          );
          resolve({ totalCustomersUpdated: totalCustomersUpdated });
        }
      });
    })
    .catch(function(err) {
      console.log(err);
      resolve({ totalCustomersUpdated: 0 });
    });
};

///////////////

//* PRODUCTS OFFLINE */
const prepareOfflineSellProductsPromise = () => {
  return new Promise(function(resolve, reject) {
    prepareOfflineSellProducts({ resolve: resolve });
  });
};

const prepareOfflineSellProducts = (props) => {
  let {
    lastSyncUtcDate,
    pageInfo = {},
    resolve,
    pageSizeValue = 100000,
    totalProductsUpdated = 0,
  } = props;

  const lastOfflineProductsSyncUtcDate =
    lastSyncUtcDate ||
    localStorageGetItem(LOCALSTORAGE.offlineProductsSyncUtcDateTime);

  const filterOfflineProducts = lastOfflineProductsSyncUtcDate && {
    lastModifiedForOfflineSync: { gt: `${lastOfflineProductsSyncUtcDate}` }, //TODO: add offset -5-min
  };
  getProductsForOffline({
    filterValues: filterOfflineProducts,
    enablePaging: true,
    pageSizeValue: pageSizeValue,
    pageInfo: pageInfo,
  })
    .then(function(result = {}) {
      const { data = [], pageInfo: pageInfoFromResponse = {} } = result;

      totalProductsUpdated = totalProductsUpdated + data.length;

      console.log("productsBatch: ", {
        productsCount: data.length,
        totalProductsUpdated: totalProductsUpdated,
        hasNextPage: pageInfoFromResponse.hasNextPage,
        pageInfoFromResponse,
      });

      updateClientDbForProducts(data).then(function() {
        if (pageInfoFromResponse.hasNextPage === true) {
          prepareOfflineSellProducts({
            lastSyncUtcDate: lastOfflineProductsSyncUtcDate,
            pageInfo: pageInfoFromResponse,
            resolve: resolve,
            pageSizeValue: pageSizeValue,
            totalProductsUpdated: totalProductsUpdated,
          });
        } else {
          localStorageSetItem(
            LOCALSTORAGE.offlineProductsSyncUtcDateTime,
            getCurrentDateUtcIso()
          );
          resolve({ totalProductsUpdated: totalProductsUpdated });
        }
      });
    })
    .catch(function(err) {
      console.log(err);
      resolve({ totalProductsUpdated: 0 });
    });
};

///////////////////////

export const dispatchForOfflineFlexSearchReady = (options = {}) => (
  dispatch
) => {
  return dispatchForOffline(dispatch, {});
};

const dispatchForOffline = (dispatch, options = {}) => {
  const { totalProductsUpdated = 0, totalCustomersUpdated = 0 } = options;
  let payload = {};
  Promise.all([
    getProductFlexSearch({
      areProductsInLocalDBChanged: totalProductsUpdated > 0,
    }),
    getCustomerFlexSearch({
      areCustomersInLocalDBChanged: totalCustomersUpdated > 0,
    }),
    getQuickKeys(),
  ]).then(function(results) {
    payload = {
      flexSearchProduct: results[0],
      flexSearchCustomer: results[1],
      quickKeys: results[2],
    };

    console.log("dispatching for offline flex search");
    dispatch({
      type: OFFLINE_READY,
      payload: { ...payload, state: OFFLINESTATE.Ready },
    });
  });
};
